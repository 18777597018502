import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabViewModule } from 'primeng/tabview';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';

interface Bank {
	name: string
	code: string
}

interface Branch {
	name: string
	code: string
}

interface City {
	name: string;
	code: string;
}

interface PayoutsHistory {
	id?: string;
	date?: string;
	payoutId?: string;
	amount?: string;
	receipt?: string;
	bankAccount?: string;
	bankFlag?: string;
}

@Component({
	selector: 'app-paymentspayouts',
	standalone: true,
	imports: [CommonModule, TabViewModule, InputSwitchModule, RadioButtonModule, FormsModule, CheckboxModule, DropdownModule, TableModule, CalendarModule],
	templateUrl: './paymentspayouts.component.html',
	styleUrl: './paymentspayouts.component.scss'
})
export class PaymentspayoutsComponent {
	ingredient!: string;
	checked: boolean = false;
	popupopen: boolean = false;
	updateBankingInformation: boolean = false
	manualTransfer: boolean = false
	sheduledPayments: boolean = true
	showPayoutHistory: boolean = false

	banks: Bank[] | undefined;
	branchs: Branch[] | undefined;
	cities: City[] | undefined;

	date: Date[] | undefined;

	payoutHistory: PayoutsHistory[] | any;

	paymentsAll: [] | any;

	disabledDates: Date[] = [];

	selectedAccountId: number | null = null;
	accounts = [
		{ id: 1, accountNo: 'xxxxxxx5678', isDefault: true },
		{ id: 2, accountNo: 'xxxxxxx1234', isDefault: false }
	];

	sortingOptions: [] | any;
	selectedOptionSort = { name: 'Most Recent', code: 'mr' };

	constructor() {
		this.disableNonFridays(new Date().getMonth(), new Date().getFullYear());
	}

	openPopup() {
		this.popupopen = true
	}

	disableNonFridays(month: number, year: number) {
		this.disabledDates = []; // Clear previous disabled dates

		const daysInMonth = new Date(year, month + 1, 0).getDate(); // Get the number of days in the month

		// Iterate through each day of the specified month
		for (let i = 1; i <= daysInMonth; i++) {
			const date = new Date(year, month, i);

			// Disable dates that are not Fridays
			if (date.getDay() !== 5) { // 5 is Friday
				this.disabledDates.push(date);
			}
		}
	}

	onMonthChange(event: any) {
		const { month, year } = event;
		this.disableNonFridays(month, year); // Recalculate disabled dates for the new month
	}

	ngOnInit() {
		this.payoutHistory = [
			{
				id: "123123",
				date: "25/04/24",
				payoutId: "aa163472394",
				amount: "$1250",
				receipt: "receipt",
				bankAccount: "xxxxxxx5645",
				bankFlag: "string"
			}
		]

		this.paymentsAll = [
			{
				id: "123123",
				date: "25/04/24",
				clientName: "Yohan Rozairo",
				amount: "$1250",
				status: "Success",
				invoice: "invoice"
			},
			{
				id: "123123",
				date: "25/04/24",
				clientName: "Don Draper",
				amount: "$1250",
				status: "Success",
				invoice: "invoice"
			}, {
				id: "123123",
				date: "25/04/24",
				clientName: "Alexander Bartholomew",
				amount: "$1250",
				status: "Success",
				invoice: "invoice"
			}
		]

		this.sortingOptions = [
			{ name: 'Most Recent', code: 'mr' }
		];
	}


}
