<div class="invoice-wrapper">
    <div class="invoice-header hide-in-mobile">
        <h4>Gigs</h4>
    </div>

    <div class="tab-view-main-wrapper">
        <p-tabView>
            <p-tabPanel header="Upload New Gig">
                <div class="gig-create-first">
                    <!-- <h2 class="hide-in-desktop hide-in-tablet wrapper-title">Upload Your First Gig</h2> -->
                    <div class="horizantal-step-section">
                        <div class="step-wrapper">
                            <div class="stepper">
                                <div class="step step-first" [class.active]="currentStepGig === 1" (click)="goToGigStep(1)">
                                    <div *ngIf="currentStepGig > 1" class="icon"><img
                                            src="../../../assets/images/icons/gig-complete-mark.png" alt="">
                                    </div>
                                    <div class="step-title" [class.active]="currentStepGig === 1">Overview</div>
                                    <div *ngIf="currentStepGig < 5" class="line"></div>
                                </div>
                                <div class="step" [class.active]="currentStepGig === 2" (click)="goToGigStep(2)">
                                    <div *ngIf="currentStepGig > 2" class="icon"><img
                                            src="../../../assets/images/icons/gig-complete-mark.png" alt="">
                                    </div>
                                    <div class="step-title" [class.active]="currentStepGig === 2">Pricing</div>
                                    <div *ngIf="currentStepGig < 5" class="line"></div>
                                </div>
                                <div class="step" [class.active]="currentStepGig === 3" (click)="goToGigStep(3)">
                                    <div *ngIf="currentStepGig > 3" class="icon"><img
                                            src="../../../assets/images/icons/gig-complete-mark.png" alt="">
                                    </div>
                                    <div class="step-title" [class.active]="currentStepGig === 3">Requirements
                                    </div>
                                    <div *ngIf="currentStepGig < 5" class="line"></div>
                                </div>
                                <div class="step" [class.active]="currentStepGig === 4" (click)="goToGigStep(4)">
                                    <div *ngIf="currentStepGig > 4" class="icon"><img
                                            src="../../../assets/images/icons/gig-complete-mark.png" alt="">
                                    </div>
                                    <div class="step-title" [class.active]="currentStepGig === 4">Gallery</div>
                                    <div *ngIf="currentStepGig < 5" class="line"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- //gig overview gigstep 1 -->
                    <div [hidden]="currentStepGig !== 1">
                        <div class="input-wrapper mb-1">
                            <label for="" class="label-secondary">Gig Title</label>
                            <input type="text" class="mb-0 register gig-title-input"
                                placeholder="i will do something 1'm really good at">
                            <small class="text-right gig-title-input">(0/80)</small>
                        </div>

                        <div class="input-wrapper">
                            <label class="label-secondary" for="">Gig Description</label>
                            <!-- <textarea rows="7" cols="30" class="text-area" id="textEditor">
                            </textarea> -->
                            <quill-editor #descriptionQuill class="gig-description" placeholder="Enter Text"
                                [modules]="quillConfig"></quill-editor>
                            <small class="text-right">(0/1200)</small>
                        </div>

                        <div class="input-wrapper">
                            <label class="label-secondary" for="">Category</label>
                            <div class="category-dropdown-wrapper">
                                <p-dropdown [options]="cities" optionLabel="name" placeholder="Select a Category"
                                    class="m-w100" />
                                <p-dropdown [options]="cities" optionLabel="name" placeholder="Select a Subcategory"
                                    class="m-w100" />
                            </div>
                        </div>
                        <div class="button-wrapper-step">
                            <button class="primary-1-button w-fit" (click)="goToGigStep(2)">Next</button>
                        </div>
                    </div>
                    <!-- //gig pricing gigstep 2 -->
                    <div [hidden]="currentStepGig !== 2">
                        <div class="input-wrapper">
                            <label for="" class="label-secondary">Starting Price</label>
                            <input type="text" class="mb-0 w-60 register t-w80 m-w100" placeholder="$USD">
                            <small class="price-starting-small-text">(Starting price should be higher than
                                $100)</small>
                        </div>
                        <div class="button-wrapper">
                            <div class="w-fit">
                                <button class="back-button-underline" (click)="goToGigStep(1)"><span><img
                                            src="../../../../assets/images/icons/back-arrow-2.png"
                                            alt=""></span>Back</button>
                            </div>
                            <div class="w-fit">
                                <button class="primary-1-button" (click)="goToGigStep(3)">Next</button>
                            </div>
                        </div>

                    </div>
                    <!-- //gig requirements gigstep 3 -->
                    <div [hidden]="currentStepGig !== 3">
                        <div class="input-wrapper">
                            <label class="label-secondary" for="">Add a Question</label>
                            <textarea rows="7" cols="30" class="text-area"
                                placeholder="Request necessary details from the client"></textarea>
                            <small class="text-right">(0/400)</small>
                        </div>

                        <div class="input-wrapper">
                            <label class="label-secondary" for="">Get the Answer in a Form of:</label>
                            <div class="get-anwser-drop m-w100 t-w80">
                                <p-dropdown [options]="cities" optionLabel="name" placeholder="Free Text" class="" />
                            </div>
                        </div>
                        <div class="button-wrapper">
                            <div class="w-fit">
                                <button class="back-button-underline" (click)="goToGigStep(2)"><span><img
                                            src="../../../../assets/images/icons/back-arrow-2.png"
                                            alt=""></span>Back</button>
                            </div>
                            <div class="w-fit">
                                <button class="primary-1-button" (click)="goToGigStep(4)">Next</button>
                            </div>
                        </div>
                    </div>

                    <!-- //gig gallery gigstep 4 -->
                    <div [hidden]="currentStepGig !== 4">
                        <label class="label-secondary about-small-text mb-2" for="">To comply with AfterFive's terms of service, make sure to upload only content you either own or you have the permission or license to use (Images of 16:9 ratio are ideal)</label>

                        <div class="image-upload-wrapper">
                            <div class="main-card front-image">
                                <img src="../../../assets/images/icons/image-icon.png" alt="">
                                <p>Drag & Drop a Photo or</p>
                                <h6>Browse</h6>
                            </div>
                            <div class="main-card back-image">
                                <img src="../../../assets/images/icons/image-icon.png" alt="">
                                <p>Drag & Drop a Photo or</p>
                                <h6>Browse</h6>
                            </div>
                        </div>

                        <div class="button-wrapper">
                            <div class="w-fit">
                                <button class="back-button-underline" (click)="goToGigStep(4)"><span><img
                                            src="../../../../assets/images/icons/back-arrow-2.png"
                                            alt=""></span>Back</button>
                            </div>
                            <div class="w-fit">
                                <button class="primary-1-button">Publish Gig</button>
                            </div>
                        </div>
                    </div>
                </div>

            </p-tabPanel>
            <p-tabPanel header="Existing Gigs">
                <div class="gig-wrapper">
                    <div class="gig-inner">
                        <div class="img-wrapper">
                            <img src="../../../../../assets/images/temp/Frame 1321316473.jpg" alt="">
                        </div>
                        <div class="gig-content-wrapper">
                            <h3>Full-stack Web Development</h3>
                            <p>Starting at $450</p>
                            <p>Total orders: 2</p>
                        </div>
                        <div class="button-wrapper">
                            <div class="edit-delete-button-wrapper">
                                <button class="delete-button-wrapper"><img
                                        src="../../../assets/images/icons/delete-bin-white.svg" alt=""></button>
                                <button class="underline-button">Edit</button>
                            </div>
                        </div>
                    </div>
                    <div class="gig-inner">
                        <div class="img-wrapper">
                            <img src="../../../../../assets/images/temp/Frame 1321316473.jpg" alt="">
                        </div>
                        <div class="gig-content-wrapper">
                            <h3>Full-stack Web Development</h3>
                            <p>Starting at $450</p>
                            <p>Total orders: 2</p>
                        </div>
                        <div class="button-wrapper">
                            <div class="edit-delete-button-wrapper">
                                <button class="delete-button-wrapper"><img
                                        src="../../../assets/images/icons/delete-bin-white.svg" alt=""></button>
                                <button class="underline-button">Edit</button>
                            </div>
                        </div>
                    </div>
                    <div class="gig-inner">
                        <div class="img-wrapper">
                            <img src="../../../../../assets/images/temp/Frame 1321316473.jpg" alt="">
                        </div>
                        <div class="gig-content-wrapper">
                            <h3>Full-stack Web Development</h3>
                            <p>Starting at $450</p>
                            <p>Total orders: 2</p>
                        </div>
                        <div class="button-wrapper">
                            <div class="edit-delete-button-wrapper">
                                <button class="delete-button-wrapper"><img
                                        src="../../../assets/images/icons/delete-bin-white.svg" alt=""></button>
                                <button class="underline-button">Edit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>