<div class="page md:flex items-start md:gap-4 p-2 mt-4">
  <div class="md:w-60 p-6 left-panel">
    <div class="step-panel flex justify-between md:block">
      <div
        *ngFor="let step of steps; index as i"
        class="step-wrapper md:flex md:items-center"
        [ngClass]="{
          active: i === currentStep - 1,
          completed: i < currentStep - 1
        }"
      >
        <div class="w-10">
          <span
            (click)="activateCallback(i + 1)"
            class="step w-10 h-10 rounded-full block cursor-pointer"
            >{{ i + 1 }}</span
          >
        </div>
        <div class="flex-1 font-bold title hidden md:block ml-2">
          {{ step }}
        </div>
      </div>
    </div>
  </div>
  <div class="flex-1 p-2">
    <div [ngSwitch]="currentStep">
      <app-account-personal
        *ngSwitchCase="1"
        [name]="user?.name"
        [personal]="user?.personal"
        (formSubmitted)="activateCallback(2)"
      ></app-account-personal>

      <app-account-banking
        *ngSwitchCase="2"
        [banking]="user?.banking"
        (goBack)="activateCallback(1)"
        (formSubmitted)="activateCallback(3)"
      ></app-account-banking>

      <app-account-public-profile
        *ngSwitchCase="3"
        [publicProfile]="user?.publicProfile"
        [name]="user?.name"
        (goBack)="activateCallback(2)"
        (formSubmitted)="activateCallback(4)"
      ></app-account-public-profile>

      <app-account-verification
        *ngSwitchCase="4"
        [verification]="user?.profileVerification"
        (goBack)="activateCallback(3)"
        (formSubmitted)="activateCallback(5)"
      ></app-account-verification>
    </div>
  </div>
</div>
