<div class="board-main-wrapper">
    <div class="board-main-header hide-in-mobile">
        <h4>Project Management</h4>
        <button class="tutorial-button">Watch Tutorial</button>
    </div>

    <!-- Drag and drop section -->
    <div class="board">
        <div *ngFor="let list of lists" class="list">
            <h3>{{ list.name }}</h3>
            <div pDroppable (onDrop)="drop(list)" class="droppable-area">
                <div *ngFor="let item of list.items" pDraggable (onDragStart)="dragStart(item, list)"
                    (onDragEnd)="dragEnd()" class="draggable-item">
                    {{ item.name }}
                    <p>{{item.description}}</p>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- // add new client & exisiting client  -->
<p-dialog [modal]="true" [(visible)]="addNewClientModal" class="add-new-client-main-wrapper">
    <div class="add-client-inner-wrapper">
        <h1>Add New Project</h1>
        <div class="project-inner-wrapper">
            <div class="client-inner">
                <h4>Client Information</h4>
                <div class="exisiting-client-wrapper">
                    <div class="existing-inner">
                        <h5>Existing client ?</h5>
                        <p>Toggle this if you have entered this client to afterFIVE.app before.</p>
                    </div>
                    <div class="inner-main-wrapper">
                        <p-inputSwitch />
                    </div>
                </div>
                <div class="add-client-form-wrapper" *ngIf="addNewClient">
                    <div class="">
                        <div class="input-wrapper">
                            <label class="label-secondary" for="">First Name</label>
                            <input type="text" class="mb-0 register" placeholder="First name of your client">
                        </div>
                        <div class="input-wrapper">
                            <label class="label-secondary" for="">Country</label>
                            <p-dropdown [options]="cities" optionLabel="name" placeholder="Your Country"
                                class="country-wrapper" />
                        </div>
                        <div class="input-wrapper phone-number">
                            <label class="label-secondary" for="">Phone Number</label>
                            <intl-telephone-input [enablePlaceholder]="true" [searchCountryFlag]="true"
                                [separateDialCode]="false" [enableAutoCountrySelect]="true"
                                [searchCountryField]="SearchCountryField.All" [phoneValidation]="false"
                                [numberFormat]="PhoneNumberFormat.International" [inputId]="'phone'"
                                [separateDialCode]="true" [enablePlaceholder]="false" class="input-field">
                            </intl-telephone-input>

                            <ng-template #customItemTemplate let-item>
                                <div class="dropdown-item">
                                    <img [src]="item.flag" alt="{{ item.name }}" class="flag-icon">
                                    {{ item.name }} ({{ item.dialCode }})
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div>
                        <div class="input-wrapper">
                            <label class="label-secondary" for="">Last Name</label>
                            <input type="text" class="mb-0 register" placeholder="Last name of your client">
                        </div>
                        <div class="input-wrapper">
                            <label class="label-secondary" for="">Business Name</label>
                            <input type="text" class="mb-0 register" placeholder="First name of your client">
                        </div>
                        <div class="input-wrapper">
                            <label class="label-secondary" for="">Email</label>
                            <input type="text" class="mb-0 register email-input"
                                placeholder="Email address of your client">
                        </div>
                    </div>
                </div>
                <div class="add-exisitng-client" *ngIf="!addNewClient">
                    <div class="exisitng-lient-adding-form">
                        <div class="input-wrapper">
                            <label class="label-secondary" for="">Search Existing Client </label>
                            <input type="text" class="mb-0 register" placeholder="Type client’s name here">
                        </div>
                    </div>

                </div>
                <div class="client-inner">
                    <h4>Project Information</h4>
                    <div class="add-client-form-wrapper">
                        <div class="">
                            <div class="input-wrapper">
                                <label class="label-secondary" for="">Project Title</label>
                                <input type="text" class="mb-0 register" placeholder="First name of your client">
                            </div>
                        </div>
                        <div>
                            <div class="input-wrapper">
                                <label class="label-secondary" for="">Country</label>
                                <p-dropdown [options]="cities" optionLabel="name" placeholder="Your Country"
                                    class="country-wrapper" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="calender-wrapper-project">
                <div class="calender-wrapper">
                    <p>Project Due Date</p>
                    <p-calendar class="max-w-full" [inline]="true" [showWeek]="false" [firstDayOfWeek]='1' />
                </div>
            </div>
        </div>
        <div class="button-wrapper">
            <button class="add-client-button">Add Project</button>
        </div>
    </div>
</p-dialog>


<p-dialog [modal]="true" [(visible)]="projectMangeModal" class="add-new-project-main-wrapper">
    <div class="add-client-inner-wrapper">
        <h1>Logo Design</h1>
        <div class="project-inner-wrapper">
            <div class="left-side-project">
                <div class="client-inner">
                    <div class="image-text-wrapper">
                        <img src="../../../../../../assets/images/icons/Book.svg" alt="">
                        <div>
                            <h4>Logo Design</h4>
                            <small>in list <span>On-Time</span></small>
                        </div>
                    </div>
                </div>
                <div class="client-inner">
                    <h5><img src="../../../../../../assets/images/icons/user-circle.svg" alt=""><span>Alice
                            Hasnolice</span></h5>
                </div>
                <div class="client-inner description-wrapper">
                    <h5><img src="../../../../../../assets/images/icons/description-project.svg"
                            alt=""><span>Description</span></h5>
                    <div class="description-wrapper-inner">
                        <textarea name="" id=""></textarea>
                    </div>
                </div>
                <div class="client-inner activity-wrapper">
                    <h5><img src="../../../../../../assets/images/icons/activity.svg" alt=""><span>Activity</span></h5>
                    <button class="activity-button">Show details</button>
                </div>
                <div class="client-inner comment-main-wrapper">
                    <img src="../../../../../../assets/images/icons/comment-icon.svg" alt="">
                    <textarea class="comment-wrapper" name="" id=""></textarea>
                </div>
            </div>
            <div class="right-side-project">
                <h4>Add to card</h4>
                <div class="card-add-main-wrapper">
                    <button class="card-add-button">Labels</button>
                    <button class="card-add-button">Checklist</button>
                    <button class="card-add-button">Dates</button>
                    <button class="card-add-button">Attachment</button>
                    <button class="card-add-button">Cover</button>
                </div>

                <h4>Actions</h4>
                <div class="card-add-main-wrapper action-wrapper">
                    <button class="card-add-button">Move</button>
                    <button class="card-add-button">Copy</button>
                </div>
            </div>


        </div>
        <div class="button-wrapper">
            <button class="add-client-button">Save</button>
        </div>
    </div>
</p-dialog>