import { Component } from '@angular/core';
import { TabViewModule } from 'primeng/tabview';
import { DropdownModule } from 'primeng/dropdown';
import { CommonModule } from '@angular/common';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';

import { InputMaskModule } from 'primeng/inputmask';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';

import { SelectButtonModule } from 'primeng/selectbutton';

interface City {
  name: string;
  code: string;
}

@Component({
  selector: 'app-contract',
  standalone: true,
  imports: [TabViewModule, DropdownModule, CommonModule, InputSwitchModule, DialogModule, TableModule, InputMaskModule,
    InputGroupModule,
    InputGroupAddonModule, 
    SelectButtonModule],
  templateUrl: './contract.component.html',
  styleUrl: './contract.component.scss'
})
export class ContractComponent {
  currentStep: number = 3;
  cities: City[] | undefined;
  popupopen: boolean = false;
  contracts: [] | any;

  addnewProjectExistingClinet: boolean = true;
  notExisitngClient: boolean = false

  webpages: any[] = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' }
  ];

  revesions: any[] = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: 'Unlimited', value: 'Unlimited' },
  ];

  openPopup() {
    this.popupopen = true
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  goToStep(stepNumber: number) {
    this.scrollToTop()
    this.currentStep = stepNumber;
  }

  ngOnInit() {
    this.contracts = [
      {
        id: '1000',
        clientName: "Lex Fridman",
        projectType: 'Web Design & Development',
        dueDate: '31/08/24',
        total: '$1350',
        status: "signed"
      },
      {
        id: '1000',
        clientName: "Lex Fridman",
        projectType: 'Web Design & Development',
        dueDate: '31/08/24',
        total: '$1350',
        status: "pending"
      }]
  }
}
