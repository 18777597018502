export interface Bank {
    name: string
    branches: string[]
}

export const BankList: Bank[] = [
    { 
        name: 'Alliance Finance Company PlcAmana Bank Ltd',
        branches: [
            'Nuwara Eliya',
            'Hingurakgoda',
            'Horana',
            'Malambe',
            'Maharagama',
            'Kegalle',
            'Ja-ela',
            'Ampara',
            'Kandy',
            'Embilipitiya',
            'Matara',
            'Aluthgama',
            'Anuradhapura',
            'Puttalam',
            'Kilinochchi',
            'Chilaw',
            'Negombo',
            'Ganemulla',
            'Kaduruwela',
            'Ambalanthota',
            'Head Office',
            'Kuliyapitiya',
            'Gampaha',
            'Galewela',
            'Ratnapura',
            'Avissawella',
            'Galle',
            'Kalpitiya',
            'Vavuniya',
            'Kurunegala',
            'Trincomalee',
            'Kadawatha',
            'Batticaloa',
            'Dambulla',
            'Jaffna',
            'Kelaniya'
        ]
    },
    { 
        name: 'Axis Bank Limited',
        branches: [
            'Katugastota',
            'Akurana',
            'Gampola',
            'Sammanthurai',
            'Mawanella',
            'Kurunegala',
            'Akkaraipattu',
            'Dehiwala',
            'Nintavur',
            'Kuliyapitiya',
            'Eravur',
            'Negombo',
            'Badulla',
            'Kaduruwela',
            'Head Office',
            'Puttalam',
            'Pettah',
            'Kinniya',
            'Kandy',
            'Ratnapura',
            'Kattankudy',
            'Kalmunai Unity Square',
            'Ladies',
            'Old Moor Street',
            'Kalmunai',
            'Dematagoda',
            'Kirulapana',
            'Galle',
            'Oddamavadi'
        ]
    },
    { 
        name: 'Bank Of Ceylon',
        branches: [
            'Galaha',
            'Bentota',
            'Welpalla',
            'Muttur',
            'Galenbindunuwewa',
            'Padavi Parakramapura',
            'Imaduwa',
            'Weeraketiya',
            'Yatawatta',
            'Pemaduwa',
            'Tirappane',
            'Medawachchiya',
            'Rikillagaskada',
            'Kobeigane',
            'Sewagama',
            'Horawpathana',
            'Ipalogama',
            'Medagama',
            'Tawalama',
            'Malkaduwawa',
            'Thanthirimale',
            'Mawathagama',
            'Elakanda',
            'Rathgama',
            'Diyatalawa',
            'Katuwana',
            'Kekanadura',
            'Kosmodera',
            'Kudawella',
            'Lunugamvehera',
            'Maha Edanda',
            'Makandura Matara',
            'Malimbada',
            'Morawaka',
            'Pasgoda',
            'Pitabeddara',
            'Digana',
            'Weli Oya',
            'Ahangama',
            'Aluthwala',
            'Barawakumbuka',
            'Karapitiya',
            'Manipay',
            'Kitulgala',
            'Kolonna',
            'Kotiyakumbura',
            'Morontota',
            'Sabaragamuwa University',
            'Pinnawala',
            'Sabaragamuwa Provincial Counci',
            'Seethawakapura',
            'Udawalawe',
            'Weligepola',
            'Dodangoda',
            'Karawanella',
            'Karawita',
            'Kegalle Hospital',
            'Urubokka',
            'Makandura',
            'Marawila',
            'Palaviya',
            'Pallama',
            'Paragahadeniya',
            'Thoduwawa',
            'Udappuwa',
            'Wayamba University',
            'Weerapokuna',
            'Wellawa',
            'Bulathkohupitiya',
            'Embilipitiya City',
            'Endana',
            'Galigamuwa',
            'Ratnapura Hospital',
            'Gonagaldeniya',
            'Kiriella',
            'Potuvil',
            'Mahawewa',
            'Balleketuwa',
            'Thanamalwila',
            'Kochchikade',
            'Kumbukgete',
            'Kuruwita',
            'Thirumurukandi',
            'Visuvamadu',
            'Ambanpola',
            'Anawilundawa',
            'Dambadeniya',
            'Katuneriya',
            'Katupotha',
            'Kirimetiyana',
            'Mihintale',
            'Thalaimannar Pier',
            'Pussellawa',
            'Savalkaddu',
            'Sirupiddy',
            'Wattegama',
            'Puthukudieruppu',
            'Puthukulam',
            'Uva - Paranagama',
            'Pesalai',
            'Poonagary',
            'Poovarasankulam',
            'Punnalaikadduvan',
            'Padiyatalawa',
            'Mallavi',
            'Manthikai',
            'Maruthankerny',
            'Mulankavil',
            'Mullativu',
            'Murungan',
            'Nainativu',
            'Nallur',
            'Nanatan',
            'Nedunkerny',
            'Oddusudan',
            'Omanthai',
            'Pallai',
            'Paranthan',
            'Hasalaka',
            'Jaffna Bus Stand',
            'Jaffna Main Street',
            'Jaffna University',
            'Kaithady',
            'Kalviyankadu',
            'Karanavai',
            'Kayts',
            'Kodikamam',
            'Kokuvil',
            'Madhu',
            'Wariyapola',
            'Alaveddy',
            'Andankulam',
            'Cheddikulam',
            'Delft',
            'Meegahakiwula',
            'Vavunathivu',
            'Vellaveli',
            'Diyabeduma',
            'Diyasenpura',
            'Doramadalawa',
            'Galamuna',
            'General Hospital (Anuradhapura)',
            'Habarana',
            'Minneriya',
            'Padaviya',
            'Rajanganaya',
            'Rajina Junction',
            'Ranajayapura',
            'Sevanapitiya',
            'Thalawa',
            'Ayagama',
            'Oddamavady',
            'Oluwil',
            'Palugamam',
            'Polwatte',
            'Palmuddai',
            'Sainthamarathu',
            'Serunuwara',
            'Thambiluvil',
            'Thampalakamam',
            'Thoppur',
            'Uhana',
            'Uppuvely',
            'Vakarai',
            'Siyambalanduwa',
            'Mollipothana',
            'Morawewa',
            'Navithanvely',
            'Nilavely',
            'Seeduwa',
            'Malwatte',
            'Mamangama',
            'Maruthamunai',
            'Pundaluoya',
            'Kallady',
            'Kallar',
            'Karadiyanaru',
            'Karaitivu',
            'Kiran',
            'Kokkadicholai',
            'Galewela',
            'Divulupitiya',
            'Wellawaya',
            'China Bay',
            'Eastern University',
            'Gonagolla',
            'Irakkamam',
            'Samanthurai',
            'Pujapitiya',
            'Ragala',
            'Sigiriya',
            'Ukuwela',
            'University Of Peradeniya',
            'Upcott',
            'Wilgamuwa',
            'Addalachchenai',
            'Alankerny',
            'Araiyampathy',
            'Batticaloa Town',
            'Independent Square',
            'Kandy Hospital',
            'Kotagala',
            'Marassana',
            'Meepilimana',
            'Menikhinna',
            'Palapathwela',
            'Botanical Gardens Peradeniya',
            'Haldummulla',
            'Boi Pallekelle',
            'Bokkawala',
            'Danture',
            'Daulagala',
            'Digana Village',
            'Gampola City',
            'Ginigathhena',
            'Hatharaliyadda',
            'Kandy City Centre',
            'Court Complex Kandy',
            'Ettampitiya',
            'Yatiyantota',
            'Adikarigama',
            'Agarapathana',
            'Akurana',
            'Ankumbura',
            'Bogawantalawa',
            'Padiyapalella',
            'Andiambalama',
            'Dankotuwa',
            'Alawwa',
            'Wijerama Junction',
            'Jaffna 2nd',
            'Chavakachcheri',
            'Kaduruwela',
            'Passara',
            'Devinuwara',
            'Wattala',
            'Maskeliya',
            'Kahawatte',
            'Wennappuwa',
            'Hingurana',
            'Kalmunai',
            'Mulliayawalai',
            'Thimbirigasyaya',
            'Kurunegala Bazaar',
            'Galnewa',
            'Bandarawela',
            'Thalawathugoda',
            'Walasmulla',
            'Middeniya',
            'Sri Jayawardenapura Hospital',
            'Hyde Park',
            'Batapola',
            'Gelioya',
            'Baddegama',
            'Polgahawela',
            'Welisara',
            'Deniyaya',
            'Kamburupitiya',
            'Avissawella',
            'Talawakelle',
            'Ridigama',
            'Pitakotte',
            'Narammala',
            'Embilipitiya',
            'Kegalle Bazaar',
            'Ambalantota',
            'Tissamaharama',
            'Beliatta',
            'Badalkubura',
            'Pelawatte City Kalutara',
            'Mahiyangana',
            'Kiribathgoda',
            'Madampe',
            'Minuwangoda',
            'Pannala',
            'Nikaweratiya',
            'Anamaduwa',
            'Galgamuwa',
            'Weligama',
            'Anuradhapura Bazaar',
            'National Institute Of Education',
            'Giriulla',
            'Bingiriya',
            'Melsiripura',
            'Matugama',
            'Moratumulla',
            'Waikkal',
            'Mawanella',
            'Buttala',
            'Dematagoda',
            'Warakapola',
            'Dharga Town',
            'Maho',
            'Madurankuliya',
            'Aranayake',
            'Dedicated Economic Centre-meegoda',
            'Homagama',
            'Hiripitiya',
            'Hettipola',
            'Kirindiwela',
            'Negombo Bazaar',
            'Central Bus Stand',
            'Mankulam',
            'Gampola',
            'Dambulla',
            'Lunugala',
            'Yakkalamulla',
            'Bibile',
            'Dummalsuriya',
            'Madawala',
            'Rambukkana',
            'Mattegoda',
            'Wadduwa',
            'Ruwanwella',
            'Pilimatalawa',
            'Peradeniya',
            'Kalpitiya',
            'Akkaraipattu',
            'Nintavur',
            'Dikwella',
            'Milagiriya',
            'Rakwana',
            'Kolonnawa',
            'Talgaswela',
            'Nivitigala',
            'Nawalapitiya',
            'Aralaganwila',
            'Jayanthipura',
            'Hingurakgoda',
            'Kirulapana',
            'Lanka Hospital',
            'Ingiriya',
            'Kankasanthurai',
            'Udadumbara',
            'Panadura Bazaar',
            'Kaduwela',
            'Hikkaduwa',
            'Pitigala',
            'Kaluwanchikudy',
            'Lake View',
            'Akuressa',
            'Matara City',
            'Galagedara',
            'Kataragama',
            'Keselwatte',
            'Metropolitan',
            'Elpitiya',
            'Kesbewa',
            'Kebithigollawa',
            'Kahatagasdigiliya',
            'Kantale Bazaar',
            'Trincomalee Bazaar',
            'Katukurunda',
            'Valachchenai',
            'Regent Street',
            'Grand Pass',
            'Koslanda',
            'Chenkalady',
            'Katubedda Campus',
            'Kandapola',
            'Dehiowita',
            'Lake House',
            'Nelliady',
            'Rattota',
            'Pallepola',
            'Medirigiriya',
            'Deraniyagala',
            'Gonapola',
            'Parliamentary Complex',
            'Kalawana',
            'Boralesgamuwa',
            'Lunuwatte',
            'Kattankudy',
            'Kandy 2nd',
            'Talatuoya',
            'Bombuwela',
            'Bakamuna',
            'Galkiriyagama',
            'Madatugama',
            'Thambuttegama',
            'Nochchiyagama',
            'Agalawatta',
            'Katunayake Ipz',
            'Gurugoda',
            'Corporate',
            'Baduraliya',
            'Kotahena',
            'Pothuhera',
            'Bandaragama',
            'Katugastota',
            'Neluwa',
            'Borella 2nd',
            'Girandurukotte',
            'Kollupitiya 2nd',
            'Central Super Market',
            'Bulathsinhala',
            'Enderamulla',
            'Nittambuwa',
            'Kekirawa',
            'Weliweriya',
            'Padukka',
            'Battaramulla',
            'Aluthgama',
            'Personal',
            'Veyangoda',
            'Pelmadulla',
            'Ratnapura Bazaar',
            'Ward Place',
            'Dehiattakandiya',
            'Raddolugama',
            'Balangoda',
            'Ratmalana',
            'Pelawatta',
            'Hakmana',
            'Eppawala',
            'Ruhunu Campus',
            'Bogahakumbura',
            'Dambagalla',
            'Ella',
            'Ethiliwewa',
            'Keppetipola',
            'Moneralaga Town',
            'Okkampitiya',
            'Pelawatta Suger Industries Com',
            'Batuwatte',
            'Bopitiya',
            'Asiri Central',
            'Katuwellegama Courtaulds Cloth',
            'Dalugama',
            'Delgoda',
            'Demanhandiya',
            'Fish Market Peliyagoda',
            'Ganemulla',
            'Gothatuwa',
            'Katana',
            'Mulleriyawa New Town',
            'Naiwala',
            'Meegalewa',
            'Badulla City',
            'Welimada',
            'Ceybank Credit Card Centre',
            'Biyagama',
            'Warapitiya',
            'Kinniya',
            'Piliyandala',
            'Hanwella',
            'Walapana',
            'Walgama',
            'Rajagiriya',
            'Taprobane',
            'Uragasmanhandiya',
            'Karainagar',
            'Koggala E.p.z',
            'Suriyawewa',
            'Thihagoda',
            'Udugama',
            'Ahungalla',
            'Athurugiriya',
            'Treasury Division',
            'Thrirunelvely',
            'Narahenpita',
            'Malabe',
            'Ragama',
            'Pugoda',
            'Mount Lavinia',
            'Ranna',
            'Alawathugoda',
            'Yakkala',
            'Ibbagamuwa',
            'Kandana',
            'Hemmathagama',
            'Kottawa',
            'Angunakolapelassa',
            'Visakha',
            'Islamic Banking Unit',
            'Electronic Banking Unit',
            'Atchuvely',
            'Norochcholei',
            'Kadawatha 2nd City',
            'Teldeniya',
            'Rambewa',
            'Polpithigama',
            'Deiyandara',
            'Hali - Ela',
            'Godakawela',
            'Kopay',
            'Boc Premier',
            'Makola',
            'Eravur',
            'Valvettithurai',
            'Chankanai',
            'Vavuniya City',
            'Urumpirai',
            'Boragas',
            'Mattala Airport',
            'Medawala',
            'Thalduwa',
            'Nelundeniya',
            'Wanduramba',
            'Provincial Council Complex,pallakelle',
            'Welioya Sampath Nuwara',
            'Vaddukoddai',
            'Madawakkulama',
            'Mahaoya',
            'Bogaswewa',
            'Kurunduwatte',
            'Ethimale',
            'Central Camp',
            'Aladeniya',
            'Kothalawala Defence University',
            'Saliyawewa',
            'Wahalkada',
            'Pallebedda',
            'Welikanda',
            'Nagoda',
            'Corporate 2nd',
            'Head Office',
            'City Office',
            'Kandy',
            'Galle Fort',
            'Pettah',
            'Jaffna',
            'Trincomalee',
            'Panadura',
            'Kurunegala',
            'Badulla',
            'Batticaloa',
            'Central Office (Ccd)',
            'Kalutura S/g',
            'Negombo',
            'Chilaw',
            'Ampara',
            'Anuradhapura',
            'Wellawatte',
            'Matara',
            'Main Street',
            'Kegalle',
            'Point Pedro',
            'Nuwara Eliya',
            'Katubedda',
            'Ratnapura',
            'Aluthkade',
            'Kollupitiya',
            'Haputale',
            'Bambalapitiya',
            'Borella S/g',
            'Ja Ela',
            'Hatton',
            'Maradana',
            'Peliyagoda',
            'Union Place',
            'Vavuniya',
            'Gampaha S / G',
            'Mannar',
            'Ambalangoda',
            'Puttalam',
            'Nugegoda',
            'Nattandiya',
            'Dehiwala',
            'Kuliyapitiya',
            'Chunnakam',
            'Horana',
            'Maharagama',
            'Tangalle',
            'Eheliyagoda',
            'Beruwala',
            'Kadawatha',
            'Fifth City',
            'Idama Moratuwa',
            'Velanai',
            'Matale',
            'Monaragala',
            'Polonnaruwa New Town',
            'Hambantota',
            'International Division',
            'Mirigama',
            'Galle Bazaar',
            'Naula',
            'Kilinochchi',
            'Anuradhapura New Town',
            'Primary Dealer'
        ]
    },
    { 
        name: 'Cargills Bank Limited',
        branches: [
            'Central Processing Office',
            'Rajagiriya',
            'Jaffna City Center',
            'Chunnakkam',
            'Head Office',
            'Galle',
            'Maitland Crescent',
            'Nuwara Eliya',
            'Old Moor Street',
            'Kandy',
            'Matara',
            'Peradeniya',
            'Maharagama',
            'Ratnapura',
            'Vavuniya',
            'Chilaw',
            'Hawaeliya',
            'Wattala',
            'Thanamalwila',
            'Kaduruwela',
            'Kurunegala'
        ]
    },
    { 
        name: 'Central Finance Co Plc',
        branches: [
            'Colombo',
            'Kandy',
            'Nugegoda'
        ]
    },
    { 
        name: 'Citizens Development Business Finance Plc',
        branches: [
            'Mahiyanganaya',
            'Thalawathugoda',
            'Giriulla',
            'Kotahena',
            'Colombo Office',
            'Jaffna',
            'Vavuniya',
            'Batticaloa',
            'Trincomalee',
            'Bandarawela',
            'Head Office',
            'Kadana',
            'Ragama',
            'Ela Kanda',
            'Eheliyagoda',
            'Boralesgamuwa',
            'Marawila',
            'Kuliyapitiya',
            'Moratuwa',
            'Ratmalana',
            'Negombo',
            'Panadura',
            'Kurunegala',
            'Nugegoda',
            'Kelaniya',
            'Premier Center',
            'Kandy',
            'Gampaha',
            'Rathnapura',
            'Badulla',
            'Horana',
            'Pelmadulla',
            'Kochchokade',
            'Rajagiriya',
            'Chilaw',
            'Katugasthota',
            'Anuradhapura',
            'Matale',
            'Wellawatta',
            'Piliyandala',
            'Wattala',
            'Ambalangoda',
            'Ja-ela',
            'Malabe',
            'Kaduwela',
            'Minuwangoda',
            'Kegalle',
            'Nittambuwa',
            'Wennappuwa',
            'Mathugama',
            'Dehiwala',
            'Warakapola',
            'Kottawa',
            'Narammala',
            'Kaluthara',
            'Mawathagama',
            'Maharagama',
            'Avissawella',
            'Matara',
            'Wariyapola',
            'Embilipitiya',
            'Nikaweratiya',
            'Battaramulla',
            'Kuruvita',
            'Tissamaharama',
            'Aluthgama',
            'Mahara',
            'Galle',
            'Dambulla',
            'Kaduruwela'
        ]
    },
    {
        name: 'City Bank N.a',
        branches: [
            'Cash mgmt',
            'Head Office'
        ]
    },
    {
        name: 'Commercial Bank Of Ceylon Plc',
        branches: [
            'Ampara',
            'Nawala C.s.p',
            'Gampola',
            'Elpitiya',
            'Kamburupitiya',
            'Batticaloa',
            'Bambalapitiya',
            'Chunakkam',
            'Nelliady',
            'Pilimathalawa',
            'Kekirawa',
            'Deniyaya',
            'Weligama',
            'Baseline Road',
            'Katubedda',
            'Hatton',
            'Reid Avenue',
            'Pitakotte (Csp)',
            'Negombo Extension Office',
            'Kotikawatta',
            'Monaragala',
            'Kottawa',
            'Kurunegala City Office',
            'Tangalle',
            'Tissamaharama',
            'Neluwa',
            'Chavakachcheri',
            'Stanley Road Jaffna',
            'Warakapola',
            'Udugama',
            'Athurugiriya',
            'Raddolugama',
            'Boralesgamuwa Csp',
            'Kahawatta',
            'Delkanda',
            'Karapitiya',
            'Welimada',
            'Mahiyanganaya',
            'Kalawana',
            'Kirindiwela',
            'Digana',
            'Polgahawela',
            'Boralesgamuwa',
            'Hanwella',
            'Pannala',
            'Ward Place',
            'Wadduwa',
            'Biyagama',
            'Puttalam',
            'Pelmadulla',
            'Kandy City Office',
            'Matara City Office',
            'Kalmunai',
            'Manipay',
            'Mannar',
            'Kilinochchi',
            'Atchchuvely',
            'Thirunelvely',
            'Eheliyagoda',
            'Valachchenai',
            'Wellawaya',
            'Mawathagama',
            'Thambuttegama',
            'Ruwanwella',
            'Dankotuwa',
            'Peliyagoda Csp',
            'Hambantota',
            'Katubedda - Csp',
            'Chenkalady',
            'Priority Banking Centre',
            'Velanai',
            'Vavuniya Extension Office',
            'Akkaraipattu',
            'Kataragama',
            'Wariyapola',
            'Kuruwita',
            'Middeniya',
            'Ganemulla',
            'World Trade Centre',
            'Wellawatte 2nd',
            'Divulapitiya',
            'Beliatta',
            'Giriulla',
            'Marawila',
            'Thalawakelle',
            'Anuradhapura New Town',
            'Passara',
            'Padukka',
            'Alawwa',
            'Panadura Extention Office',
            'Katunayake 24 / 7 Center',
            'Kattankudy',
            'Pottuvil',
            'Maskeliya',
            'Liberty Plaza Csp',
            'Godakawela Csp',
            'Kodikamam',
            'Makola',
            'Medawachchiya',
            'Slic - Csp',
            'Palavi',
            'Nawala Service Point',
            'Maharagama Minicom',
            'Moratuwa Minicom',
            'Union Place (Keels Super)',
            'Kurunegala Service Point',
            'Ratnapura (Laugfs Super)',
            'Ramanayake Matwatha',
            'Kirulapone Minicom',
            'Gampaha Csp',
            'Akurana Service Point',
            'Beruwala Csp',
            'Ragama',
            'Matara Minicomcentre',
            'Panadura Csp',
            'Horana Service Point',
            'Wattala Minicom',
            'Bokundara Csp',
            'Katukurunda Csp',
            'Weliweriya',
            'Mulliyawalai',
            'Ja - Ela (K-zone)',
            'Attidiya',
            'Hulftsdorp',
            'Rajagirirya (Keells Super)',
            'Kadawatha (Arpico Super)',
            'Urubokka',
            'Colombo Gold Centre',
            'Nawinna(arpico Super)',
            'Chankanai',
            'Batapola',
            'Nochchiyagama',
            'Batticaloa Second',
            'Badulla Second',
            'Katugastota (Minicom)',
            'Wattegama',
            'Bandarawatta (Laugfs Super)',
            'Wennapuwa (Arpico) Super',
            'Bia Counter',
            'Veyangoda',
            'Dehiwala Arpico Super Centre',
            'Battaramulla Arpico Super Cent',
            'Hyde Park Arpico Service Centr',
            'Anniwatte Csp',
            'Kundasale (Dumbara Super Csp)',
            'Negombo Arpico Centre',
            'Kiribathgoda Service Point',
            'Holiday Banking Centre-majestic City',
            'Nawam Mawatha',
            'Wattala Arpico Super Center',
            'Nittambuwa Csp',
            'Pelawatte Csp',
            'Hendala Service Point',
            'Gelioya Service Point',
            'Kohuwala Service Point',
            'Kalutara ( Arpico Super )',
            'Mattegoda (Laugfs Super)',
            'Delgoda Laugfs Super',
            'Orion City Abc',
            'Galle Main Street',
            'Kilinochchi Second',
            'Point Pedro',
            'Kelaniya',
            'Deiyandara',
            'Morawaka',
            'Rambukkana',
            'Katana',
            'Bopitiya',
            'Yatiyantota',
            'Kolonnawa',
            'Islamic Banking Unit',
            'Head Office',
            'Head Office',
            'Head Office',
            'City Office',
            'Foreign',
            'Kandy',
            'Galle Fort',
            'Jaffna',
            'Matara',
            'Matale',
            'Galewela',
            'Wellawatte',
            'Kollupitiya',
            'Kotahena',
            'Negombo',
            'Hikkaduwa',
            'Hingurakgoda',
            'Kurunegala',
            'Old Moor Street',
            'Maharagama',
            'Borella',
            'Nugegoda',
            'Kegalle',
            'Narahenpita',
            'Mutuwal',
            'Pettah',
            'Katunayake Ftz',
            'Wennappuwa',
            'Galle City',
            'Koggala',
            'Battaramulla',
            'Embilipitiya',
            'Kandana',
            'Maradana',
            'Minuwangoda',
            'Nuwara Eliya',
            'Akuressa',
            'Kalutara',
            'Trincomalee',
            'Panchikawatte',
            'Keyzer Street',
            'Aluthgama',
            'Panadura',
            'Kaduwela',
            'Chilaw',
            'Gampaha',
            'Katugastota',
            'Ratmalana',
            'Kirulapone',
            'Union Place',
            'Ratnapura',
            'Colombo 07',
            'Kuliyapitiya',
            'Badulla',
            'Anuradhapura',
            'Dambulla',
            'Nattandiya',
            'Wattala',
            'Grand Pass',
            'Dehiwala',
            'Moratuwa',
            'Narammala',
            'Vavuniya',
            'Rajagiriya',
            'Ambalantota',
            'Seeduwa',
            'Nittambuwa',
            'Mirigama',
            'Kadawatha',
            'Duplication Road',
            'Kiribathgoda',
            'Avissawella',
            'Ekala C.s.p',
            'Pettah Main Street',
            'Peradeniya C.s.p',
            'Kochchikade',
            'Homagama',
            'Horana',
            'Piliyandala',
            'Thalawathugoda C.s.p',
            'Mawanella',
            'Bandarawela',
            'Ja Ela',
            'Balangoda',
            'Nikaweratiya',
            'Bandaragama C.s.p',
            'Yakkala',
            'Malabe C.s.p',
            'Kohuwala',
            'Kaduruwela',
            'Nawalapitiya',
            'Mount Lavinia C.s.p',
            'Card Centre',
            'Mathugama',
            'Ambalangoda',
            'Baddegama'
        ]
    },
    { 
        name: 'Commercial Credit And Finance Plc',
        branches: [
            'Welikanda',
            'Muthur',
            'Kirindiwela',
            'Teldeniya',
            'Hatton',
            'Giriulla',
            'Thanamalwila',
            'Pothuvil',
            'Bibile',
            'Warakapola',
            'Mahaoya',
            'Battaramulla',
            'Polpithigama',
            'Chavakachcheri',
            'Akkaraipattu',
            'Valaichchenai',
            'Pussellawa',
            'Mullativu',
            'Deraniyagala',
            'Chilaw',
            'Angunakolapelessa',
            'Pilimathalawa',
            'Tissamaharama',
            'Ingiriya',
            'Baddegama',
            'Tangalle',
            'Naththandiya',
            'Anamaduwa',
            'Kinniya',
            'Kalawana',
            'Godakawela',
            'Divulapitiya',
            'Beruwala',
            'Ja-ela',
            'Piliyandala',
            'Kaluwanchikudy',
            'Galgamuwa',
            'Galagedara',
            'Akurana',
            'Kiribathgoda',
            'Wellawaya',
            'Kandy',
            'City Office-j',
            'Kcc',
            'Aralaganwila',
            'Avissawella',
            'Wellawatte',
            'Anuradhapura',
            'Ampara',
            'Jaffna',
            'Horana',
            'Welimada',
            'Aluthgama',
            'Nochchiyagama',
            'Horowpathana',
            'Neluwa',
            'Siyambalanduwa',
            'Pulmude',
            'Kekirawa',
            'Ambalangoda',
            'Delft',
            'Batticaloa',
            'Bandarawela',
            'City Office-i',
            'Ambalantota',
            'Kurunegala',
            'Kanthale',
            'Badulla',
            'City Office-e',
            'Chunnakam',
            'Panadura',
            'Weligama',
            'Gampola',
            'Wariyapola',
            'Udugama',
            'Melsiripura',
            'Bakamuna',
            'Kalutara',
            'Akuressa',
            'Wattala',
            'Mount Lavinia',
            'Kalmunai',
            'Embilipitiya',
            'City Office-b',
            'Wennappuwa',
            'Kaduwela',
            'Hingurakgoda',
            'Matale',
            'Nelliady',
            'Trincomalee',
            'Padaviya',
            'Balangoda',
            'Nuwara Eliya',
            'Vavuniya',
            'Nittambuwa',
            'Polonnaruwa',
            'Ratnapura',
            'Puttalam',
            'Medawachchiya',
            'Moratuwa',
            'Mathugama',
            'Homagama',
            'Nugegoda',
            'City Office-m',
            'Negombo',
            'Nikaweratiya',
            'City Office-s',
            'Nawalapitiya',
            'Galnewa',
            'Rikillagaskada',
            'Gampaha',
            'Thambuththegama',
            'Mahiyanganaya',
            'Matara',
            'Mannar',
            'Kalpitiya',
            'City Office-p',
            'Maharagama',
            'City Office-colombo',
            'City Office-none Fd',
            'Bandaragama',
            'Kuliyapitiya',
            'Kilinochchi',
            'Monaragala',
            'City Office-g',
            'City Office-f',
            'City Office-k',
            'City Office-c',
            'City Office-q',
            'Dehiattakandiya',
            'Elpitiya',
            'Deniyaya',
            'Dambulla',
            'City Office-a',
            'Galle',
            'City Office-h',
            'Galenbindunuwewa',
            'City Office-d',
            'Kegalle'
        ]
    },
    { 
        name: 'Commercial Leasing Co Plc',
        branches: [
            'Head Office',
            'Virtual Branch'
        ]
    },
    {
        name: 'Dfcc Vardhana',
        branches: [
            'Matara City',
            'Nivithigala',
            'Urubokka',
            'Buttala',
            'Wellawaya',
            'Eppawala',
            'Mawanella',
            'Southern Province Slp Units',
            'Western Province Slp Units',
            'North Western Province Slp Uni',
            'Central Province Slp Units',
            'Sabaragamuwa Province Slp Unit',
            'North Central Province Slp Uni',
            'Eastern Province Slp Units',
            'Uva Province Slp Units',
            'Premier Banking Centre',
            'Central Operations',
            'Head Office',
            'Narammala',
            'Nikaweraitya',
            'Mount Lavinia',
            'Hatton',
            'Welimada',
            'Ibbagamuwa',
            'Gangodawila',
            'Weligama',
            'Kaduwela',
            'Hingurakgoda',
            'Balangoda',
            'Bandaragama',
            'Peradeniya',
            'Kandy City Centre',
            'Dehiattakandiya',
            'Aralaganwila',
            'Medirigiriya',
            'Pettah-main Street',
            'Kahawatta',
            'Head Office',
            'Kochchikade',
            'Gangodawila',
            'Malabe',
            'Matara',
            'Kurunegala',
            'Katugastota',
            'City Office',
            'Rathnapura',
            'Anuradhapura',
            'Giriulla',
            'Wennappuwa',
            'Hikkaduwa',
            'Nawalapitiya',
            'Dankotuwa',
            'Ambalantota',
            'Wattegama',
            'Tissamaharama',
            'Gampaha',
            'Morawaka',
            'Badulla',
            'Baddegama',
            'Borella',
            'Kaduruwela',
            'Bandaranayake Mawatha',
            'Maharagama',
            'Bandarawela',
            'Negambo',
            'Kottawa',
            'Dambulla',
            'Wattala',
            'Kuliyapitiya',
            'Panadura',
            'Piliyandala',
            'Deniyaya',
            'Kaluthara',
            'Kiribathgoda',
            'Nawala',
            'Kadawatha',
            'Gampola',
            'Matale',
            'Chilaw',
            'Wellawatte',
            'Horana',
            'Galle',
            'Nuwara Eliya',
            'Kalawana',
            'Ambalangoda',
            'Avissawella',
            'Batticaloa',
            'Ampara',
            'Jaffna',
            'Moratuwa',
            'Trincomalee',
            'Embilipitiya',
            'Pettah',
            'Vavuniya',
            'Katugastota',
            'Kegalle',
            'Monaragala',
            'Sainthamaruthu',
            'Kilinochchi',
            'Elpitiya',
            'Akuressa',
            'Kattankudy',
            'Tangalle',
            'Oddamavadi',
            'Akkaraipattu',
            'Chunnakam',
            'Manipai',
            'Nelliady',
            'Hambantota',
            'Ja Ela',
            'Kotahena',
            'Digana',
            'Thambuttegama',
            'Galewela',
            'Eheliyagoda',
            'Mathugama'
        ]
    },
    {
        name: 'Deutsche Bank Ag',
        branches: [
            'Head Office',
            'Main Branch'
        ]
    },
    { 
        name: 'Hdfc Bank Ltd',
        branches: [
            'Housing Development Finance Corporation'
        ]
    },
    { 
        name: 'Hnb Grameen Finance Limited',
        branches: [
            'Head Office',
            'Polonnaruwa',
            'Mahiyanganaya',
            'Moneragala',
            'Hambantota',
            'Galle',
            'Mathugama',
            'Medawachchiya',
            'Horana',
            'Rathnapura',
            'Nikaweratiya',
            'Galgamuwa',
            'Nittambuwa',
            'Gampaha',
            'Nawalapitiya',
            'Wennappuwa',
            'City',
            'Avissawella',
            'Moratuwa',
            'Kaduwela',
            'Jaffna',
            'Mannar',
            'Trincomalee',
            'Padaviya',
            'Bandaragama',
            'Balangoda',
            'Vavuniya',
            'Ampara',
            'Kurunegala',
            'Wattala',
            'Kalmunai',
            'Nellyadi',
            'Mullaitivu',
            'Pottuvil',
            'Kandy',
            'Mallavi',
            'Anuradhapura',
            'Norocholai',
            'Nuwara Eliya',
            'Morawaka',
            'Matale',
            'Kuliyapitiya',
            'Badulla',
            'Nedunkeni',
            'Chilaw',
            'Nochchiyagama',
            'Puttalam',
            'Kegalle',
            'Kaluthara',
            'Batticaloa',
            'Kilinochchi',
            'Kekirawa',
            'Galenbindunuwewa',
            'Rikillagaskada',
            'Ja-ela',
            'Bandarawela',
            'Matara',
            'Walapane',
            'Welimada',
            'Tissamaharama',
            'Dambulla',
            'Hatton',
            'Kantale',
            'Pitigala',
            'Ambalangoda',
            'Dehiattakandiya',
            'Homagama',
            'Negombo',
            'Beliatta',
            'Embilipitiya'
        ]
    },
    {
        name: 'Hsbc',
        branches: [
            'Head Office',
            'Kohuwela',
            'Jaffna',
            'Hongkong & Shanghai Bank',
            'Galle',
            'Fort',
            'Union Place',
            'Kandy',
            'Colpetty',
            'Wattala',
            'Wellawatte',
            'Premier Centre',
            'Nugegoda',
            'World Trade Center',
            'Negombo',
            'Bambalapitiya',
            'Moratuwa',
            'Pelawatte'
        ]
    },
    {
        name: 'Habib Bank Ltd',
        branches: [
            'Head Office',
            'Habib Bank Ltd',
            'Pettah',
            'Dharmapala Mawatha',
            'Kalmunai',
            'Kandy',
            'Galle',
            'Wellawatte',
            'Islamic Banking'
        ]
    },
    { 
        name: 'Hatton National Bank Plc',
        branches: [
            'Katugastota',
            'Pelmadulla',
            'Ragama',
            'Dematagoda',
            'Narahenpita',
            'Treasury Division',
            'Wellawaya',
            'Elpitiya',
            'Maradana',
            'Aluthgama',
            'Wennappuwa',
            'Avissawella',
            'Boralesgamuwa',
            'Card Centre',
            'Central Colombo',
            'Kollupitiya',
            'Chunnakam',
            'Nelliady',
            'Kandana',
            'Deniyaya Customer Centre',
            'Nikaweratiya',
            'Delgoda',
            'Alawwa',
            'Mahiyanganaya',
            'Mathugama',
            'Warakapola',
            'Middeniya',
            'Galgamuwa',
            'Kohuwela',
            'Weliweriya',
            'Hendala',
            'Point Pedro',
            'Norochchole',
            'Thirukovil',
            'Eravur',
            'Ganemulla',
            'Chavakachcheri',
            'Kelaniya',
            'Hanwella',
            'Padukka',
            'Pilimatalawa',
            'Thalawathugoda',
            'Medawachchiya',
            'Thirunelvely',
            'Negombo Metro',
            'Kilinochchi South',
            'Nawala',
            'Giriulla',
            'Galewela',
            'Manipay',
            'Akuressa',
            'Hettipola',
            'Wariyapola',
            'Athurugiriya',
            'Kochchikade',
            'Malabe',
            'Chankanai',
            'Pottuvil',
            'Ninthavur',
            'Beruwela',
            'Velanai',
            'Rikillagaskada',
            'Yakkala',
            'Thandenweli',
            'Kaluwanchikudy',
            'Pugoda',
            'Valachchenai',
            'Madampe',
            'Kinniya',
            'Siyabalanduwa',
            'Udappuwa',
            'Nanattan',
            'Ginigathhena',
            'Mullipothanai',
            'Uppuvelli',
            'Digana',
            'Anamaduwa',
            'Dikwella',
            'Medirigiriya',
            'Mirigama',
            'Padavi Parakramapura',
            'Uhana',
            'Mullativu',
            'Karaitivu',
            'Maruthamunai',
            'Serunuwara',
            'Pitigala',
            'Kundasale',
            'Atchchuvely',
            'Kodikamam',
            'Muthur',
            'Kallady',
            'Aralaganwila',
            'Kolonnawa',
            'Kilinochchi North',
            'Dehiattakandiya',
            'Kalawana',
            'Galaha',
            'Urubokka',
            'Hakmana',
            'Bandaragama',
            'Hikkaduwa',
            'Wadduwa',
            'Mirihana',
            'Mulliyawalai',
            'Kurumankadu',
            'Jampettah Street',
            'Ratmalana',
            'Seeduwa',
            'Pamunugama',
            'Kattankudy',
            'Mallavi',
            'Weligama',
            'Veyangoda',
            'Batapola',
            'Yakkalamulla',
            'Walasmulla',
            'Gelioya',
            'Jaffna',
            'Passara',
            'Pamankada',
            'Sammanthurai',
            'Peradeniya',
            'Kurunegala Metro',
            'Trincomalee Metro',
            'Wijerama',
            'Karapitiya',
            'Peliyagoda',
            'Divulapitiya',
            'Ekala',
            'Kekirawa',
            'Anuradhapura Metro',
            'Bibile',
            'Haputale',
            'Godakawela',
            'Akurana',
            'Koggala',
            'Devinuwara',
            'Agunukolapelassa',
            'Kataragama',
            'Kaithady',
            'Kuruduwatta',
            'Rangala',
            'Inglriya',
            'Thalawakele',
            'Pettah Metro',
            'Lotus Road',
            'Asiri Surgical Hospital',
            'Islamic Banking Unit',
            'Lanka Hospital',
            'Digital Branch',
            'Nawam Mawatha',
            'World Trade Centre',
            'Aluthkade',
            'City Office',
            'Head Office Branch',
            'Head Office',
            'Green Path',
            'Maligawatte',
            'Pettah',
            'Wellawatta',
            'Anuradhapura',
            'Badulla',
            'Bandarawela',
            'Galle',
            'Gampola',
            'Hatton',
            'Jaffna Metro',
            'Kahawatte',
            'Kandy',
            'Kurunegala',
            'Mannar',
            'Maskeliya',
            'Moratuwa',
            'Nawalapitiya',
            'Negombo',
            'Nittambuwa',
            'Nochchiyagama',
            'Nugegoda',
            'Nuwara Eliya',
            'Pussellawa',
            'Rathnapura',
            'Tricomalee',
            'Vavuniya',
            'Welimada',
            'Kalutara',
            'Wattala',
            'Rajagiriya',
            'Piliyandala',
            'Bambalapitiya',
            'Chilaw',
            'Kegalle',
            'Matara',
            'Kirulapone',
            'Polonnaruwa',
            'Ambalantota',
            'Grandpass',
            'Biyagama',
            'Dambulla',
            'Katunayake',
            'Embilipitiya',
            'Gampaha',
            'Horana',
            'Monaragala',
            'International Division',
            'Borella',
            'Kiribathgoda',
            'Batticaloa',
            'Ampara',
            'Panchikawatta',
            'Bogawanthalawa',
            'Mount Lavinia',
            'Hulftsdorp',
            'Maharagama',
            'Matale',
            'Pinnawala',
            'Suriyawewa',
            'Hambantota',
            'Panadura',
            'Dankotuwa',
            'Balangoda',
            'Sea Street',
            'Moratumulla',
            'Kuliyapitiya',
            'Buttala',
            'Cinnamon Gardens',
            'Homagama',
            'Akkaraipattu',
            'Marandagahamula',
            'Marawila',
            'Ambalangoda',
            'Kaduwela',
            'Puttalam',
            'Kadawatha',
            'Talangama',
            'Tangalle',
            'Ja Ela',
            'Thambuttegama',
            'Mawanella',
            'Tissamaharama',
            'Kalmunai',
            'Thimbirigasyaya',
            'Dehiwela',
            'Minuwangoda',
            'Kantale',
            'Kotahena',
            'Mutwal',
            'Kottawa',
            'Kirindiwela'
        ]
    },
    { 
        name: 'Icici Bank',
        branches: [
            'Colombo'
        ]
    },
    { 
        name: 'Indian BankIndian',
        branches: [
            'Head Office',
            'Colombo',
            'Jaffna'
        ]
    },
    {
        name: 'Overseas Bank',
        branches: [
            'Head Office',
            'Matara'
        ]
    },
    { 
        name: 'Kanrich Finance Limited',
        branches: [
            'Head Office'
        ]
    },
    { 
        name: 'L B Finance Plc',
        branches: [
            'Jaffna Premier',
            'Valaichchenai',
            'Wattala',
            'Kottawa',
            'Dehiattakandiya',
            'Kokkadicholai',
            'Gelioya',
            'Kinniya',
            'Anamaduwa',
            'Puthukkudiyiruppu',
            'Delgoda',
            'Eheliyagoda',
            'Kekirawa',
            'Divulapitiya',
            'Narammala',
            'Medirigiriya',
            'Achchuveli',
            'Mannar',
            'City Office',
            'Wadduwa',
            'Dharga Town',
            'Karapitiya',
            'Minuwangoda',
            'Kandy City',
            'Warakapola',
            'Malabe',
            'Kaduwela',
            'Nawalapitiya',
            'Homagama',
            'Kurunegala City',
            'Maskeliya',
            'Pitigala',
            'Samanturei',
            'Saindamarthu',
            'Kotte',
            'Badulla City',
            'Ja Ela',
            'Delkada',
            'Kaththankudi',
            'Dam Street',
            'Akuressa',
            'Monaragala',
            'Head Office',
            'Tangalle',
            'Corporate Office',
            'Nugegoda',
            'Negambo',
            'Kandy',
            'Badulla',
            'Panadura',
            'Anuradhapura',
            'Galle',
            'Mount Lavinia',
            'Neluwa',
            'Battaramulla',
            'Kurunegala Premier Centre',
            'Moratuwa',
            'Medawachchiya',
            'Chenkalady',
            'Pussellawa',
            'Kiribathgoda',
            'Deniyaya',
            'Kurunegala',
            'Jaffna City',
            'Ratnapura',
            'Matara',
            'Kalutara',
            'Ambalangoda',
            'Ampara',
            'Avissawella',
            'Gampaha',
            'Chilaw',
            'Polonnaruwa',
            'Piliyandala',
            'Boralesgamuwa',
            'Dambulla',
            'Balangoda',
            'Kegalle',
            'Bandarawela',
            'Dehiwala',
            'Embilipitiya',
            'Batticaloa',
            'Elpitiya',
            'Maradana',
            'Kandana',
            'Mahiyanganaya',
            'Tissamaharama',
            'Matale',
            'Maharagama',
            'Kuliyapitiya',
            'Puttalam',
            'Trincomalee',
            'Gampola',
            'Horana',
            'Kadawatha',
            'Jaffna',
            'Chunnakam',
            'Manipay',
            'Chavakachcheri',
            'Kilinochchi',
            'Vavuniya',
            'Nuwara Eliya',
            'Hatton',
            'Nelliady',
            'Matugama',
            'Wennappuwa',
            'Thambuththegama',
            'Akkaraipattu',
            'Pothuvil',
            'Kochchikade',
            'Kotahena',
            'Anuradhapura 02',
            'Rajagiriya',
            'Welimada',
            'Pilimathalawa',
            'Katugasthota',
            'Kalmunai',
            'Moratumulla',
            'Kalawanchikudy',
            'Sea Street',
            'Matara City',
            'Aluthgama'
        ]
    },
    {
        name: 'Lolc Finance Plc',
        branches: [
            'Head Office',
        ]
    },
    { 
        name: 'Mcb Bank Limited',
        branches: [
            'Head Office',
            'Middle East Bank',
            'Head Office',
            'Pettah',
            'Maradana',
            'Islamic Banking Unit',
            'Wellawatte',
            'Kandy',
            'Batticaloa',
            'Galle',
            'Kollupitiya'
        ]
    },
    { 
        name: 'Mercantile Investment And Finance Plc',
        branches: [
            'Head Office'
        ]
    },
    { 
        name: 'Merchant Bank Of Sri Lanka And Finance Plc',
        branches: [
            'Union Place',
            'Kadawatha',
            'Gampaha',
            'Negombo',
            'Kottawa',
            'Kalutara',
            'Moratuwa',
            'Galle',
            'Nugegoda',
            'Kotahena',
            'Kurunegala',
            'Anuradhapura',
            'Ratnapura',
            'Ambalangoda',
            'Ampara',
            'Ambalantota',
            'Avissawella',
            'Bandarawela',
            'Batticaloa',
            'Chilaw',
            'City Office',
            'Dambulla',
            'Deniyaya',
            'Embilipitiya',
            'Hambantota',
            'Hingurakgoda',
            'Horana',
            'Hatton',
            'Ja-ela',
            'Jaffna',
            'Kandy',
            'Kiribathgoda',
            'Kandy City Office',
            'Kilinochchi',
            'Kakirawa',
            'Kandana',
            'Kuliyapitiya',
            'Maharagama',
            'Monaragala',
            'Malabe',
            'Matara',
            'Matugama',
            'Nittambuwa',
            'Panadura',
            'Puttlam',
            'Trincomalee',
            'Vavuniya',
            'Wennappuwa',
            'Wattala',
            'Head Office'
        ]
    },
    {
        name: 'Ndb',
        branches: [
            'Head Office(retail)',
            'Warakapola',
            'Hingurakgoda',
            'Marine Drive',
            'Thambuttegama',
            'Mahawewa',
            'Katana',
            'Kottawa',
            'Islamic Banking Unit',
            'Head Office (Co-op)',
            'Head Office',
            'Monaragala',
            'Athurugiriya',
            'Boralesgamuwa',
            'Ratmalana',
            'Narahenpita',
            'Mahiyangana',
            'Nuwara Eliya',
            'Balangoda',
            'Eheliyagoda',
            'Old Moor Street',
            'Kandy City Centre',
            'Uragasmanhandiya',
            'Katunayake',
            'Kekirawa',
            'Kahawatta',
            'Giriulla',
            'Pita Kotte',
            'Head Office',
            'Handala',
            'Nawam Mawatha',
            'Kochchikade',
            'Kandy',
            'Jawatta',
            'Nugegoda',
            'Rajagiriya',
            'Matara',
            'Kurunegala',
            'Wellawatte',
            'Negambo',
            'Battaramulla',
            'Narammala',
            'Tissamaharama',
            'Chenkalady',
            'Akkaraipattu',
            'Natthandiya',
            'Hanwella',
            'Manipai',
            'Chilaw',
            'Thirunelvely',
            'Wattala',
            'Chavakachcheri',
            'Maharagama',
            'Ratnapura',
            'Head Office',
            'Moratuwa',
            'Kalutara',
            'Kegalle',
            'Badulla',
            'Anuradhpura',
            'Mt. Lavinia',
            'Galle',
            'Pelawatte',
            'Piliyandala',
            'Kotahena',
            'Kiribathgoda',
            'Kadawatha',
            'Horana',
            'Kandana',
            'Gampaha',
            'Homagama',
            'Malabe',
            'Kohuwela',
            'Puttalam',
            'Avissawella',
            'Panadura',
            'Wennppuwa',
            'Jaffna',
            'Trincomalee',
            'Batticaloa',
            'Ampara',
            'Vavuniya',
            'Kuliyapitiya',
            'Pettah',
            'Ja Ela',
            'Matugama',
            'Matale',
            'Ambalangoda',
            'Ambalantota',
            'Pilimatalawa',
            'Gampola',
            'Bandarawela',
            'Borella',
            'Kalmunai',
            'Nittambuwa',
            'Kaduwela',
            'Wariyapola',
            'Hambantota',
            'Embilipitiya',
            'Aluthgama',
            'Kaduruwela',
            'Nawalapitiya',
            'Chunnakam',
            'Minuwangoda',
            'Dambulla',
            'Akuressa',
            'Nelliady',
            'Elpitiya',
            'Yakkala',
            'Nikaweratiya'
        ],
    },
    {
        name: 'National Savings Bank',
        branches: [
            'Nittambuwa',
            'Welimada',
            'Kottawa',
            'Dambulla',
            'Kahathuduwa',
            'Aluthgama',
            'Meegoda',
            'Manipay',
            'Thirunelvely',
            'Chenkaladi',
            'Uragasmanhandiya',
            'Nawala',
            'Deraniyagala',
            'Hikkaduwa',
            'Kalawanchikudy',
            'Kalubowila',
            'Hatton',
            'Welikada',
            'Samanthurai',
            'Delkanda',
            'Yakkala',
            'Karapitiya',
            'Kaduruwela',
            'Malabe',
            'Boralesgamuwa',
            'Moratumulla',
            'Bandaragama',
            'Mulgampola',
            'Badalkumbura',
            'Dankotuwa',
            'Mullative',
            'Kodikamam',
            'Alawathugoda',
            'Godakawela',
            'Periyakallar',
            'Thamabiluvil',
            'Horawpathana',
            'Galenbindunuwewa',
            'Pitakatte',
            'Pitigala',
            'Urubokka',
            'Kandana',
            'Maho',
            'Udugama',
            'Uhana',
            'Galagedara',
            'Siyambalanduwa',
            'Kopay',
            'Peradeniya',
            'City Plus',
            'Piliyandala 2nd',
            'Deiyandara',
            'Pannala',
            'Ragama Piyasa',
            'Wtc Piyasa',
            'Athurugiriya Piyasa',
            'Neluwa Piyasa',
            'Ganemulla Piyasa',
            'Wellampitiya Piyasa',
            'Narammala Piyasa',
            'Bibile Piyasa',
            'Hettipola Piyasa',
            'Bulathkohupitiya Nsb Piyasa',
            'Kandy City Centre',
            'Weeraketiya Nsb Piyasa',
            'Tangalle Nsb Piyasa',
            'Walasmulla Nsb Piyasa',
            'Wadduwa Nsb Piyasa',
            'Kelaniya Nsb Piyasa',
            'Nelliady Nsb Piyasa',
            'Atchuvely Nsb Piyasa',
            'Puwakaramba Piyasa',
            'Valaichenai Piyasa',
            'Aranayake',
            'Rikillagaskada Piyasa',
            'Katugastota Piyasa',
            'Middeniya Piyasa',
            'Chankanai Piyasa',
            'Polgahawela',
            'Arayampathy',
            'Raddolugama',
            'Galgamuwa',
            'Kayts',
            'Karainagar',
            'Mutwal',
            'Mulleriyawa New Town',
            'Baddegama',
            'Habaraduwa',
            'Pelmadulla',
            'Kahawatte',
            'Bulathsinhala',
            'Kalawana',
            'Wellawaya',
            'Buttala',
            'Alawwa',
            'Yatiyantota',
            'Pundaluoya',
            'Nochchiyagama',
            'Anamaduwa',
            'Delgoda',
            'Digana',
            'Mawathagama',
            'Hanwella',
            'Hali Ela',
            'Akkaraipattu',
            'Palugamam',
            'Sooriyawewa',
            'Galewela',
            'Tambuttegama',
            'Imaduwa',
            'Passara',
            'Kahatagasdigiliya',
            'Angunakolapelessa',
            'Madampe',
            'Kantale',
            'Medawachchiya',
            'Ingiriya',
            'Rambukkana',
            'Wariyapola',
            'Kebithigollawa',
            'Wattegama',
            'Pussellawa',
            'Pothuvil',
            'Ibbagamuwa',
            'Kuruwita',
            'Vankalai',
            'Mallavi',
            'Kekanadura',
            'Medirigiriya',
            'Nivithigala',
            'Kiriella',
            'Naula',
            'Melsiripura',
            'Hambantota',
            'Rideegama',
            'Deltota',
            'Ginigathhena',
            'Bingiriya',
            'Baduraliya',
            'Giriulla',
            'Eppawala',
            'Thalawathugoda',
            'Pugoda',
            'Gelioya',
            'Ragala',
            'Pbu - Head Office & Loan Centr',
            'Pbu - Kandy',
            'Pbu - Galle',
            'Pbu - Matara',
            'Pbu - Kegalle',
            'Pbu - Anuradhapura',
            'Pbu - Kalutara',
            'Pbu - Gampaha',
            'Pbu - Ampara',
            'Credit Division (H.l Division)',
            'Ibu (Nrfc)',
            'Br.management Division',
            'Pbu - Kurunegala',
            'Pbu - Jaffna',
            'Pbu - Eheliyagoda',
            'Head Office',
            'City',
            'Galle',
            'Matara',
            'Anuradhapura',
            'Jaffna',
            'Chilaw',
            'Kuliyapitiya',
            'Negombo',
            'Ratnapura',
            'Ambalantota',
            'Kalutara',
            'Embilipitiya',
            'Kekirawa',
            'Kandy',
            'Matale',
            'Kurunegala',
            'Kegalle',
            'Kilinochchi',
            'Moratuwa',
            'Batticaloa',
            'Badulla',
            'Bambalapitiya',
            'Dehiwala',
            'Peliyagoda',
            'Nugegoda',
            'Homagama',
            'Beruwala',
            'Wennappuwa',
            'Ampara',
            'Kochchikade',
            'Point - Pedro',
            'Ambalangoda',
            'Naththandiya',
            'Ruwanwella',
            'Ja Ela',
            'Gampaha',
            'Devinuwara',
            'Nikaweratiya',
            'Mahiyanganaya',
            'Warakapola',
            'Panadura',
            'Puttalam',
            'Matugama',
            'Monaragala',
            'Kalmunai',
            'Beliatta',
            'Mannar',
            'Nawalapitiya',
            'Pettah',
            'Katunayake',
            'Maharagama',
            'Deniyaya',
            'Akuressa',
            'Nuwara Eliya',
            'Avissawella',
            'Galnewa',
            'Mawanella',
            'Bandarawela',
            'Borella',
            'Hakmana',
            'Horana',
            'Narahenpita',
            'Kollupitiya 2nd',
            'Weligama',
            'Kiribathgoda',
            'Mount-lavinia',
            'Marawila',
            'Wellawatta',
            'Piliyandala',
            'Chunnakam',
            'Chavakachcheri',
            'Gampola',
            'Kadawatha',
            'Hingurakgoda',
            'Maligawatta',
            'Talawakelle',
            'Mirigama',
            'Battaramulla',
            'Kandy 2nd',
            'Dickwella',
            'Mahabage',
            'Pilimathalawa',
            'Wattala',
            'Kamburupitiya',
            'Kotahena',
            'Vavuniya',
            'Trincomalee',
            'Morawaka',
            'Balangoda',
            'Veyangoda',
            'Katubedda',
            'Elpitiya',
            'Kaduwela',
            'Divulapitiya',
            'Tissamaharama',
            'Minuwangoda',
            'Kirindiwela'
        ]
    },
    {
        name: 'Nations Trust Bank',
        branches: [
            'Card Center',
            'Liberty Plaza',
            'Wattala',
            'Mount Lavinia',
            'Nugegoda',
            'Head Office',
            'Kaduwela',
            'Old Moor Street',
            'Bandaragama',
            'Digana',
            'Monaragala',
            'Boralasgamuwa',
            'Kottawa',
            'Gothatuwa',
            'Wariyapola',
            'Kegalle',
            'Tissamaharama',
            'Narahenpita',
            'Elpitiya',
            'Giriulla',
            'Weligama',
            'Nittambuwa',
            'Minuwangoda',
            'Hikkaduwa',
            'Central Clearing',
            'Gampola',
            'Ntb Head Office',
            'Tangalle',
            'Colpetty',
            'Sri Sangaraja Mw',
            'Kandy',
            'Wellawatte',
            'Corporate',
            'Negombo C.s.p',
            'Pettah',
            'Mahabage',
            'Mawathagama',
            'Avissawella',
            'Matale',
            'Kandy City Center',
            'Battaramulla',
            'Cinnamon Gardens',
            'Kurunegala',
            'Maharagama',
            'Moratuwa',
            'Borella',
            'Kiribathgoda',
            'Panadura',
            'Gampaha',
            'Kotahena',
            'Ward Place',
            'Kadawatha',
            'Crescat',
            'Dehiwala',
            'Nawam Mawatha',
            'Havelock Town',
            'Peradeniya',
            'Nawala',
            'Matara',
            'Galle',
            'Thalawathugoda',
            'Homagama',
            'Bandarawela',
            'Vavuniya',
            'Batticaloa',
            'Jaffna',
            'Horana',
            'Kalmunai',
            'Malabe',
            'Anuradhapura',
            'Piliyandala',
            'Ratnapura',
            'Nuwara Eliya',
            'Chilaw',
            'Nelliady',
            'Kaduruwela',
            'Pettah Second',
            'Aluthgama',
            'Wennappuwa',
            'Trincomalee',
            'World Trade Centre',
            'Wattala',
            'Mount Lavinia',
            'Nugegoda',
            'Kohuwala',
            'Kuliyapitiya',
            'Ambalangoda',
            'Akkaraipattu',
            'Hambantota',
            'Badulla',
            'Ja - Ela',
            'Embilipitiya',
            'Ambalantota',
            'Akuressa',
            'Balangoda',
            'Deniyaya',
            'Matugama',
            'Karapitiya',
            'Rajagiriya',
            'Katugastota'
        ]
    },
    {
        name: 'Pan Asia Banking Corporation Plc',
        branches: [
            'International Remittance',
            'Head Office',
            'Warakapola',
            'Galewela',
            'Akuressa',
            'Trincomalee',
            'Gampola',
            'Tangalle',
            'Borella',
            'Hatton',
            'Anuradhapura',
            'Homagama',
            'Kalutara',
            'Horana',
            'Vauvniya',
            'Colombo Gold Center',
            'Malabe',
            'Nawala',
            'Chillaw',
            'Jaffna',
            'Embilipitiya',
            'Matale',
            'Kottawa',
            'Kaduwela',
            'Mawanella',
            'Dankotuwa',
            'Batticaloa',
            'Hikkaduwa',
            'Ambalangoda',
            'Weligama',
            'Kalmunai',
            'Kilinochchi',
            'Kandy City Centre',
            'Wtc',
            'Badulla',
            'Panchikawatte',
            'Kuliyapitiya',
            'Kollupitiya',
            'Kalubowila',
            'Pettah',
            'Bandarawela',
            'Kandy',
            'Dambulla',
            'Rajagiriya',
            'Ratnapura',
            'Nugegoda',
            'Bambalapitiya',
            'Ratmalana',
            'Peradeniya',
            'Kaduruwela',
            'Ambalanthota',
            'Negambo',
            'Kiribathgoda',
            'Gampaha',
            'Piliyandala',
            'Kurunegala',
            'Nelliady',
            'Matara',
            'Kaththankudy',
            'Kotahena',
            'Kundasale',
            'Dehiwela',
            'Monaragala',
            'Wattala',
            'Panadura',
            'Old Moor Street',
            'Dam Street',
            'Akkeraipattu',
            'Chunnakam',
            'Balangoda',
            'Battaramulla',
            'Katugastota',
            'Puttlam',
            'Narahenpita',
            'Pilimathalawa',
            'Kirulapone',
            'Ja-ela',
            'Maharagama',
            'Kekirawa',
            'Moratuwa',
            'Thalawathugoda',
            'Galle',
            'Minuwangoda',
            'Kadawatha',
            'Kegalle',
            'Wennappuwa',
            'Wellawatte'
        ]
    },
    { 
        name: 'People\'s Bank',
        branches: [
            'Marandagahamula',
            'Rambukkana',
            'Valaichechenai',
            'Piliyandala',
            'Jaffna Main Street',
            'Kayts',
            'Nelliady',
            'Atchchuvely',
            'Chankanai',
            'Chunnakam',
            'Chavakachcheri',
            'Paranthan',
            'Teldeniya',
            'Batticaloa Town',
            'Galagedara',
            'Galewela',
            'Passara',
            'Akuressa',
            'Delgoda',
            'Narahenpita',
            'Walasmulla',
            'Bandaragama',
            'Wilgamuwa',
            'Eravur',
            'Nikaweratiya',
            'Kalpitiya',
            'Grandpass',
            'Nildandahinna',
            'Ratthota',
            'Rakwana',
            'Hakmana',
            'Udugama',
            'Deniyaya',
            'Kamburupitiya',
            'Nuwaraeliya',
            'Dickwella',
            'Hikkaduwa',
            'Makandura',
            'Dambulla',
            'Pettah',
            'Hasalaka',
            'Valvetiturai',
            'Kochchikade',
            'Suduwella',
            'Hettipola',
            'Wellawatte',
            'Naula',
            'Buttala',
            'Panadura',
            'Alawwa',
            'Kabithigollawa',
            'Diyatalawa',
            'Matara Dharmapala Mawatha',
            'Akurana',
            'Balapitiya',
            'Kahawatte',
            'Uva Paranagama',
            'Menikhinna',
            'Senkadagala',
            'Kadugannawa',
            'Pelmadulla',
            'Bulathsinghala',
            'Jaffna University',
            'Wariyapola',
            'Potuvil',
            'Mankulam',
            'Murunkan',
            'Town Hall',
            'Kataragama',
            'Galle Main Street',
            'Eppawela',
            'Nochchiyagama',
            'Bingiriya',
            'Pundaluoya',
            'Nugegoda',
            'Kandana',
            'Mid City',
            'Galenbindunuwewa',
            'Maskeliya',
            'Galnewa',
            'Deraniyagala',
            'Mahaoya',
            'Ankumbura',
            'Galgamuwa',
            'Galigamuwa',
            'Hatton',
            'Ahangama',
            'Uhana',
            'Kaluwanchikudy',
            'Malwana',
            'Nivitigala',
            'Ridigama',
            'Kolonnawa',
            'Haldummulla',
            'Kaduwela',
            'Uragasmanhandiya',
            'Mirigama',
            'Mawathagama',
            'Majestic City',
            'Ukuwela',
            'Kirindiwela',
            'Habarana',
            'Head Quarters',
            'Angunakolapelessa',
            'Davulagala',
            'Ibbagamuwa',
            'Battharamulla',
            'Boralanda',
            'Kollupitiya Co-op House',
            'Panwila',
            'Mutuwal',
            'Madampe',
            'Haputale',
            'Mahara',
            'Horowpathana',
            'Tambuttegama',
            'Anuradhapura Nuwarawewa',
            'Hemmathagama',
            'Wattala',
            'Karaitivu',
            'Thirukkovil',
            'Hali Ela',
            'Kurunegala Maliyadewa',
            'Chenkalady',
            'Addalachchena',
            'Hanwella',
            'Tanamalwila',
            'Medirigiriya',
            'Polonnaruwa Town',
            'Serunuwara',
            'Batapola',
            'Kalawana',
            'Maradana',
            'Kiribathgoda',
            'Gonagaldeniya',
            'Ja Ela',
            'Keppetipola',
            'Pallepola',
            'Bakamuna',
            'Devinuwara',
            'Beliatta',
            'Godakawela',
            'Meegalewa',
            'Imaduwa',
            'Aranayake',
            'Neboda',
            'Kandeketiya',
            'Lunugala',
            'Bulathkohupitiya',
            'Aralaganwila',
            'Welikanda',
            'Trincomalee Town',
            'Pilimatalawa',
            'Deltota',
            'Medagama',
            'Kehelwatte',
            'Koslanda',
            'Pelawatte',
            'Wadduwa',
            'Kuruwita',
            'Suriyawewa',
            'Middeniya',
            'Kiriella',
            'Anamaduwa',
            'Girandurukotte',
            'Badulla Muthiyangana',
            'Thulhiriya',
            'Urubokka',
            'Talgaswela',
            'Kadawatha',
            'Pussellawa',
            'Olcott Mawatha',
            'Katunayake',
            'Sea Street',
            'Nittambuwa',
            'Pitakotte',
            'Pothuhera',
            'Kobeigane',
            'Maggona',
            'Baduraliya',
            'Jaffna Kannathidy',
            'Point Pedro',
            'Kudawella',
            'Kaltota',
            'Moratumulla',
            'Dankotuwa',
            'Udapussellawa',
            'Dehiowita',
            'Alawathugoda',
            'Udawalawe',
            'Ninthavur',
            'Dam Street',
            'Ginthupitiya',
            'Kegalle Bazaar',
            'Ingiriya',
            'Galkiriyagama',
            'Ginigathhena',
            'Mahawewa',
            'Walasgala',
            'Maharagama',
            'Gandara',
            'Kotahena',
            'Liberty Plaza',
            'Bambalapitiya',
            'Beruwala',
            'Malwatthe Road',
            'Katubedda',
            'Thalawa',
            'Ragama',
            'Rathnapura Town',
            'Pamunugama',
            'Kirulapane',
            'Borella Town',
            'Panadura Town',
            'Marawila',
            'Seeduwa',
            'Wanduramba',
            'Capricon',
            'Kesbewa',
            'Kottawa',
            'Koggala',
            'Dehiattakandiya',
            'Lucky Plaza',
            'Ganemulla',
            'Yakkala',
            'Kurunegala Ethugalpura',
            'Nugegoda City',
            'Mount Lavinia',
            'Dehiwala Galle Road',
            'Sainthamaruthu',
            'Kallar',
            'Oddamavady',
            'Hataraliyadda',
            'Kokkaddicholai',
            'Karapitiya',
            'Melsiripura',
            'Ranna',
            'Maruthamunai',
            'Badalkumbura',
            'Boralesgamuwa',
            'Pallebedda',
            'Weeraketiya',
            'Thambala',
            'Pulmudai',
            'Rikillagaskada',
            'Bogawanthalawa',
            'Kotiyakumbura',
            'Cheddikulam',
            'Kandy City Centre',
            'Poojapitiya',
            'Piliyandala City',
            'Polpithigama',
            'Kodikamam',
            'Card Center',
            'Overseas Customers Unit',
            'Ccd',
            'Duke Street',
            'Matale',
            'Kandy',
            'International Division',
            'Polonnaruwa',
            'Hingurakgoda',
            'Hambantota',
            'Anuradhapura',
            'Puttalam',
            'Badulla',
            'Bibile',
            'Kurunegala',
            'Galle Fort',
            'Union Place',
            'Ampara',
            'Welimada',
            'Balangoda',
            'Gampola',
            'Dehiwala',
            'Mullativu',
            'Minuwangoda',
            'Hanguranketha',
            'Kalmunai',
            'Chilaw',
            'Hyde Park Corner',
            'Gampaha',
            'Kegalle',
            'Kuliyapitiya',
            'Avissawella',
            'Jaffna Stanley Road',
            'Tellipalai',
            'Matara Uyanwatta',
            'Queens',
            'Negombo',
            'Ambalangoda',
            'Ragala',
            'Bandarawela',
            'Talawakele',
            'Kalutara',
            'Vavuniya',
            'Horana',
            'Kekirawa',
            'Padaviya',
            'Mannar',
            'Embilipitiya',
            'First City',
            'Yatiyantota',
            'Kilinochchi',
            'Homagama',
            'Kahatagasdigiliya',
            'Maho',
            'Nawalapitiya',
            'Warakapola',
            'Kelaniya',
            'Sri Sangaraja Mawatha',
            'Peradeniya',
            'Mahiyangana',
            'Polgahawela',
            'Morawaka',
            'Tissamaharama',
            'Wellawaya',
            'Akkaraipattu',
            'Samanthurai',
            'Kattankudy',
            'Trincomalee',
            'Tangalle',
            'Monaragala',
            'Mawanella',
            'Mathugama',
            'Dematagoda',
            'Ambalantota',
            'Elpitiya',
            'Watthegama',
            'Batticaloa',
            'Wennappuwa',
            'Weligama',
            'Borella',
            'Veyangoda',
            'Ratmalana',
            'Ruwanwella',
            'Narammala',
            'Natthandiya',
            'Aluthgama',
            'Eheliyagoda',
            'Thimbirigasyaya',
            'Baddegama',
            'Rathnapura',
            'Katugastota',
            'Kantale',
            'Moratuwa',
            'Giriulla',
            'Pugoda',
            'Kinniya',
            'Muttur',
            'Medawachchiya',
            'Gangodawila',
            'Kotikawatte'
        ]
    },
    { 
        name: 'Public Bank Berhad',
        branches: [
            'Head Office'
        ]
    },
    { 
        name: 'Regional Development Bank',
        branches: [
            'Provincial Office - Western',
            'Bulathsinghala',
            'Walagedara',
            'Agalawatte',
            'Millaniya',
            'Goonapola',
            'Moranthuduwa',
            'Beruwala',
            'Panadura',
            'Horana',
            'Warakagoda',
            'Ingiriya',
            'Dodangoda',
            'Meegahathenna',
            'Baduraliya',
            'Kalutara',
            'Gampaha',
            'Mawaramandiya',
            'Minuwangoda',
            'Meerigama',
            'Moragahahena',
            'Mathugama',
            'Negombo',
            'Divulapitiya',
            'Nittambuwa',
            'Homagama',
            'Kolonnawa',
            'Awissawella',
            'Piliyandala',
            'Ragama',
            'Wadduwa',
            'Kirindiwela',
            'J-ela',
            'Miriswaththa',
            'Kelaniya - Head Quarter',
            'Gampaha District Office',
            'Kalutara District Office',
            'Provincial Office - Southern',
            'Hakmana',
            'Urubokka',
            'Deiyandara',
            'Akuressa',
            'Weligama',
            'Gandara',
            'Kekanadura',
            'Ambalantota',
            'Angunukolapalassa',
            'Katuwana',
            'Beliatta',
            'Elpitiya',
            'Batapola',
            'Pitigala',
            'Gonagalapura',
            'Imaduwa',
            'Baddegama',
            'Tissamaharama',
            'Lunugamwehera',
            'Pitabaddera',
            'Thalgaswala',
            'Akmeemana',
            'Karandeniya',
            'Sooriyawewa',
            'Kamburugamuwa',
            'Deniyaya',
            'Kamburupitiya',
            'Galle',
            'Uragasmanhandiya',
            'Yakkalamulla',
            'Weerakatiya',
            'Thihagoda',
            'City - Matara',
            'Tangalle',
            'Neluwa',
            'Mawarala',
            'Morawaka',
            'Hambantota',
            'Walasmulla',
            'Barawakumbuka',
            'Udugama',
            'Ranna',
            'Ahangama',
            'Hikkaduwa',
            'Kirinda',
            'Middeniya',
            'Dikwella',
            'Karapitiya',
            'Balapitiya',
            'Pamburana',
            'Mirissa',
            'Kaluwella',
            'Warapitiya',
            'Devinuwara',
            'District Office - Hambantota',
            'District Office - Galle',
            'District Office - Matara',
            'Wayamba Provincial Office',
            'Kuliyapitiya',
            'Pannala',
            'Panduwasnuwara',
            'Alawwa',
            'Dummalasooriya',
            'Pothuhera',
            'Nikaweratiya',
            'Rideegama',
            'Mawathagama',
            'Wariyapola',
            'Kurunegala',
            'Galgamuwa',
            'Chilaw',
            'Palakuda',
            'Anamaduwa',
            'Nattandiya',
            'Kirimetiyana',
            'Puttlam',
            'Maho',
            'Giriulla',
            'Ibbagamuwa',
            'Mundel',
            'Nawagattegama',
            'Mampuri',
            'Mahawewa',
            'Narammala',
            'Polpithigama',
            'Bowatte',
            'Hqb',
            'Melsiripura',
            'Ambanpola',
            'Santha Anapura',
            'Puttlam District Office',
            'Kurunegala District Office',
            'Provincial Office - North Central',
            'Mihinthale',
            'Town Branch - Anuradhapura',
            'Thambuththegama',
            'Kahatagasdigiliya',
            'Galnewa',
            'Thalawa',
            'Medawachchiya',
            'Thirappane',
            'Rambewa',
            'Polonnaruwa',
            'Medirigiriya',
            'Pulasthigama',
            'Hingurakgoda',
            'Bakamoona',
            'Galenbindunuwewa',
            'Gonapathirawa',
            'Manampitiya',
            'Galamuna',
            'New Town - Anuradhapura',
            'Siripura',
            'Kaduruwela',
            'Kekirawa',
            'Aralaganwila',
            'Economic Centre',
            'Sevanapitiya',
            'Dritrict Office Polonnaruwa',
            'Provincial Office - Sabaragamuwa',
            'Kegalle',
            'Pitagaldeniya',
            'Rambukkana',
            'Mawanella',
            'Warakapola',
            'Aranayaka',
            'Kithulgala',
            'Ruwanwella',
            'Dewalegama',
            'Bulathkohupitiya',
            'Deraniyagala',
            'Rathnapura',
            'Pelmadulla',
            'Balangoda',
            'Embilipitiya',
            'Hemmathagama',
            'Kolonna',
            'Eheliyagoda',
            'Nelumdeniya',
            'Kalawana',
            'Yatiyantota',
            'Godakawela',
            'Erathna',
            'Kuruwita',
            'Nivithigala',
            'Kahawatta',
            'Kotiyakumbura',
            'Rakwana',
            'Dehiovita',
            'Kiriella',
            'Pothupitiya',
            'Weligepola',
            'Sri Palabaddala',
            'Pulungupitiya',
            'Kegalle District Office',
            'Provincial Office - Central',
            'Gampola',
            'Udawela',
            'Hataraliyadda',
            'Marassana',
            'Danture',
            'Wattegama',
            'Morayaya',
            'Teldeniya',
            'Pujapitiya',
            'Nuwara Eliya',
            'Rikillagaskada',
            'Kandy Marketing Centre',
            'Ginigathena',
            'Poondaluoya',
            'Katugastota',
            'Nildandahinna',
            'Agarapathana',
            'Ududumbara',
            'Matale',
            'Dambulla',
            'Galewela',
            'Laggala',
            'Rattota',
            'Kotagala',
            'Menikhinna',
            'Hanguranketha',
            'Daulagala',
            'Naula',
            'Nawalapitiya',
            'Hedeniya',
            'Wilgamuwa',
            'Kandy',
            'Peradeniya',
            'Provincial Office - Uva',
            'Buttala',
            'Medagama',
            'Monaragala',
            'Thanamalvila',
            'Badulla',
            'Passara',
            'Welimada',
            'Kandaketiya',
            'Mahiyanganaya',
            'Wellawaya',
            'Badalkumbura',
            'Haputhale',
            'Rideemaliyadda',
            'Uvaparanagama',
            'Bandarawela',
            'Meegahakiula',
            'Lunugala',
            'Haldummulla',
            'Girandurukotte',
            'Bogahakumbura',
            'Bibile',
            'Uva Maligathenna',
            'Siyambalanduwa',
            'Diyathalawa',
            'Sewanagala',
            'Madulla',
            'Ampara',
            'Dehiatthakandiya',
            'Sammanthurai',
            'Hingurana',
            'Akkaraipaththu',
            'Kalmunai',
            'Mahaoya',
            'Pothuvil',
            'Uhana',
            'Nintavur',
            'Batticaloa',
            'Eraur',
            'Chenkalady',
            'Kanthale',
            'Valachenai',
            'Kathankudi',
            'Trincomalee',
            'Kalauwanchikudi',
            'Kokkadichcholai',
            'Muthtur',
            'Vavuniya',
            'Kanagarayankulam',
            'Mannar',
            'Chunnakam',
            'Jaffna',
            'Kilinochchi',
            'Bogaswewa',
            'Head Office',
            'Provincial Office - Central'
        ]
    },
    { 
        name: 'Sampath Bank Plc',
        branches: [
            'Kandy Metro',
            'Gampola',
            'Nikaweratiya',
            'Pelmadulla',
            'Ambalantota',
            'Wattegama',
            'Matugama',
            'Batticaloa',
            'Chilaw',
            'Mawathagama',
            'Hingurakgoda',
            'Akkaraipattu',
            'Kalmunai',
            'Wellawaya',
            'Embuldeniya',
            'Kattankudy',
            'Tangalle',
            'Kirulapone',
            'Baddegama',
            'Mannar',
            'Jaffna',
            'Chenkalady',
            'Rajagiriya',
            'Kandy City Centre',
            'Oddamavady',
            'Kaluwanchikudy',
            'Sainthamaruthu',
            'Grandpass',
            'Chunnakam',
            'Nelliady',
            'Pottuvil',
            'Platinum Plus',
            'Nattandiya',
            'Kundasale',
            'Kollupitiya',
            'Gangodawila',
            'Peliyagoda',
            'Hanwella',
            'Nochchiyagama',
            '2nd Branch Batticaloa',
            'Ingiriya',
            'Karapitiya',
            'Boralesgamuwa',
            'Anamaduwa',
            'Maradana',
            'Buttala',
            'Passara',
            'Manipay',
            'Kilinochchi',
            'Kekirawa',
            'Pilimatalawa',
            'Keselwatta',
            'Pussellawa',
            'Matara Bazaar',
            'Aralaganwila',
            'Moratumulla',
            'Puttalam',
            'Sooriyawewa',
            'Middeniya',
            'Galle Bazaar',
            'Mawanella',
            'Bibile',
            'Kaduwela',
            'Rikillagaskada',
            'Chankanai',
            'Kochchikade',
            'Pannala',
            'Dehiattakandiya',
            'Anuradhapura New Town',
            'Chavakachcheri',
            'Vavuniya Super',
            'Kayts',
            'Kantale',
            'Gothatuwa New Town',
            'Mallavi',
            'Colombo Super',
            'Mattegoda',
            'Kinniya',
            'Thalawathugoda',
            'Akuressa',
            'Beliatta',
            'Habaraduwa',
            'Ahangama',
            'Marandagahamula',
            'Menikhinna',
            'Ninthavur',
            'Thirunelvely',
            'Hettipola',
            'Rambukkana',
            'Madampe',
            'Galewela',
            'Panchikawatte',
            'Padukka',
            'Mutwal',
            'Marawila',
            'Nawalapitiya',
            'Dankotuwa',
            'Maho',
            'Divulapitiya',
            'Mount Lavinia',
            'Kiribathgoda Super Branch',
            'Ruwanwella',
            'Delgoda',
            'Kahatagasdigiliya',
            'Elpitiya',
            'Warakapola',
            'Kamburupitiya',
            'Makola',
            'Muttur',
            'Weligama',
            'Karagampitiya',
            'Talahena',
            'Gampaha Super',
            'Seeduwa',
            'Orugodawatte',
            'Kurunegala Metro',
            'Dickwella',
            'Prince Street',
            'Borella Super Branch',
            'World Trade Centre',
            'Heerasagala',
            'Poojapitiya',
            'Thalawakele',
            'Nawala Koswatta',
            'Kesbewa',
            'Godakawela',
            'Ratnapura',
            'Kelaniya',
            'Polgahawela',
            'Hikkaduwa',
            'Imports',
            'Sampath Viswa',
            'Central Clearing Department',
            'Card Centre',
            'Head Office',
            'City Office',
            'Pettah',
            'Nugegoda',
            'Borella',
            'Kiribathgoda',
            'Kurunegala',
            'Kandy Super',
            'Wattala',
            'Nawam Mawatha',
            'Matara',
            'Bambalapitiya',
            'Fort',
            'Maharagama',
            'Deniyaya',
            'Morawaka',
            'Gampaha',
            'Dehiwela',
            'Ratmalana',
            'Piliyandala',
            'Eheliyagoda',
            'Anuradhapura Super',
            'Avissawella',
            'Kuliyapitiya',
            'Negombo',
            'Matale',
            'Panadura Super',
            'Old Moor Street',
            'Tissamaharama',
            'Headquarters',
            'Wennappuwa',
            'Moratuwa',
            'Katugastota',
            'Ratnapura',
            'Thimbirigasyaya',
            'Galle Super',
            'Wellawatte Super',
            'Kotahena',
            'Kaduruwela',
            'Malabe',
            'Narahenpita',
            'Kalawana',
            'Main Street',
            'Embilipitiya',
            'Wariyapola - Pbc',
            'Wellampitiya - Pbc',
            'Bandarawela',
            'Panadura Wekada',
            'Thambuttegama - Pbc',
            'Deraniyagala - Pbc',
            'Kalutara',
            'Peradeniya - Pbc',
            'Kottawa',
            'Alawwa - Pbc',
            'Neluwa - Pbc',
            'Vavuniya',
            'Mahiyangana',
            'Horana',
            'Harbour View - Pbc',
            'Bandaragama',
            'Kadawatha',
            'Battaramulla',
            'Ampara',
            'Pelawatte - Pbc',
            'Kegalle',
            'Minuwangoda',
            'Trincomalee',
            'Athurugiriya - Pbc',
            'Yakkala - Pbc',
            'Homagama',
            'Gregorys Road - Pbc',
            'Nittambuwa',
            'Ambalangoda',
            'Ragama - Pbc',
            'Monaragala',
            'Wadduwa - Pbc',
            'Kandana',
            'Veyangoda - Pbc',
            'Ganemulla - Pbc',
            'Aluthgama',
            'Hatton',
            'Welimada',
            'Nawala',
            'Kirindiwela Pbc',
            'Nuwara Eliya',
            'Digana - Pbc',
            'Mirigama',
            'Pannipitiya',
            'Negombo 2nd',
            'Attidiya',
            'Dambulla',
            'Pitakotte',
            'Maharagama Super',
            'Badulla',
            'Kohuwela',
            'Giriulla',
            'Hendala',
            'Balangoda',
            'Ja Ela',
            'Narammala'
        ]
    },
    {
        name: 'Sanasa Development Bank Ltd',
        branches: [
            'Sanasa Development Bank Head Office',
            'Padavi Parakramapura',
            'Kekirawa',
            'Uhana',
            'Kanthale',
            'Ambalangoda',
            'Akkeripattu',
            'Matara',
            'Moratuwa',
            'Galle',
            'Hatharaliyadda',
            'Giriulla',
            'Hingurna',
            'Rambukkana',
            'Kaduwela',
            'Dambulla',
            'Narammala',
            'Thambuththegama',
            'Maho',
            'Wennappuwa',
            'Ampara',
            'Aluthgama',
            'Maharagama',
            'Gampaha',
            'Thalawa',
            'Medawachchiya',
            'Malabe',
            'Muthur',
            'Mahabage',
            'Jaffna',
            'Dehiwala',
            'Nanatan',
            'Ekala',
            'Badulla',
            'Nuwara Eliya',
            '1st Colombo City',
            'Pottuvil',
            'Chunnakam',
            'Kegalle',
            'Mapalagama',
            'Battaramulla',
            'Mathugama',
            'Embilipitiya',
            'Gampola',
            'Horana',
            'Deniyaya',
            'Kiribathgoda',
            'Karapitiya',
            'Akurassa',
            'Matale',
            'Mannar',
            'Tissamaharama',
            'Bandarawela',
            'Galewela',
            'Anamaduwa',
            'Piliyandala',
            'Angunakolapalassa',
            'Panadura',
            'Aralaganvila',
            'Galenbidunuwewa',
            'Kandy',
            'Wariyapola',
            'Chilaw',
            'Pilimathalawa',
            'Vavuniya',
            'Kirulapona',
            'Manaragala',
            'Deraniyagala',
            'Ruwanwella',
            'Dehiattakandiya',
            'Rathnapura',
            'Kalawanchikudi',
            'Warakapola',
            'Anuradhapura',
            'Sahasapura',
            'Rikillagaskada',
            'Samanthurei',
            'Siyabalanduwa',
            'Buttala',
            'Valachchena',
            'Kurunegala',
            'Elpitiya',
            'Trincomalle',
            'Nochchiyagama',
            'Kalmuani',
            'Yakkalamulla',
            'Ambalanthota',
            'Katuwana',
            'Mawanella',
            'Kalutara',
            'Kilinochchi',
            'Kuliyapitiya',
            'Negombo',
            'Polonnaruwa',
            'Batticaloa'
        ]
    },
    { 
        name: 'Senkadagala Finance Plc',
        branches: [
            'Head Office'
        ]
    },
    { 
        name: 'Seylan Bank Plc',
        branches: [
            'Akuressa',
            'Battaramulla',
            'Kaduruwela',
            'Dambulla',
            'Monaragala',
            'Ambalantota',
            'Narammala',
            'Mahiyanganaya',
            'Veyangoda',
            'Mawathagama',
            'Pusellawa',
            'Dummalasooriya',
            'Godagama',
            'Galenbidunuwewa',
            'Pitakotte',
            'Kanthale',
            'Akkaraipatthu',
            'Chankanai',
            'Kotahena',
            'Udappu',
            'Ingiriya',
            'Beruwala',
            'Kirulapone',
            'Gothatuwa',
            'Minuwangoda',
            'Moratumulla',
            'Kirindiwela',
            'Yatiyantota',
            'Ranpokunagama',
            'Aralaganwila',
            'Kalawanchikudy',
            'Manipay',
            'Medawachchiya',
            'Nochchiyagama',
            'Pallekelle',
            'Wijerama',
            'Kotiyakumbura',
            'Bowatta',
            'Eppawala',
            'Bambalapitiya',
            'Hikkaduwa',
            'Rideegama',
            'Wadduwa',
            'Kelaniya',
            'Colombo Gold Centre',
            'Baduraliya',
            'Kamburupitiya',
            'Kalpitiya',
            'Kataragama',
            'Katugastota',
            'Pothuvil',
            'Samanthurai',
            'Siyambalanduwa',
            'Padaviya',
            'Hasalaka',
            'Peredeniya',
            'Pilimathalawa',
            'Polonnaruwa',
            'Wennappuwa',
            'Dankotuwa',
            'Pannala',
            'Athurugiriya',
            'Karapitiya',
            'Matara Bazzar',
            'Chunnakam',
            'Mutwal',
            'Kandana',
            'Wariyapola',
            'Aluthgama',
            'Giriulla',
            'Hettipola',
            'Central Processing Unit',
            'Seylan Card Centre (Scc)',
            'Retail Remittance Center',
            'Head Office',
            'Warakapola',
            'Wattala',
            'Vavuniya',
            'Batticaloa',
            'Kaththankudy',
            'Avissawella',
            'Nawalapitiya',
            'Kekirawa',
            'Mirigama',
            'Soysapura',
            'Ruwanwella',
            'Hambantota',
            'Borella',
            'Havelock Town',
            'Marandagahamula',
            'Jaffna',
            'Millennium',
            'Nittambuwa',
            'Seylan Bank Head Office',
            'Trincomalee',
            'City Office',
            'Meegoda',
            'Matara',
            'Mount Lavinia',
            'Maharagama',
            'Panadura',
            'Kiribathgoda',
            'Ratnapura',
            'Kollupitiya',
            'Moratuwa',
            'Pelmadulla',
            'Ampara',
            'Nelliady',
            'Kilinochchi',
            'Mannar',
            'Chavakachcheri',
            'Mullativu',
            'Kalmunai',
            'Kegalle',
            'Chenkalady',
            'Gampaha',
            'Piliyandala',
            'Nugegoda',
            'Negombo',
            'Dehiwala',
            'Chilaw',
            'Galle',
            'Kandy',
            'Kurunegala',
            'Nuwara Eliya',
            'Balangoda',
            'Anuradhapura',
            'Grandpass',
            'Horana',
            'Ambalangoda',
            'Gampola',
            'Badulla',
            'Ja Ela',
            'Kadawatha',
            'Dehiattakandiya',
            'Colombo Fort',
            'Katunayake',
            'Cinnamon Gardens',
            'Kottawa',
            'Boralesgamuwa',
            'Yakkala',
            'Kalutara',
            'Tissamaharama',
            'Matale',
            'Hatton',
            'Sarikkamulla',
            'Attidiya',
            'Kalubowila',
            'Homagama',
            'Kuliyapitiya',
            'Embilipitiya',
            'Bandarawela',
            'Maradana',
            'Mawanella',
            'Puttalam',
            'Old Moor Street',
            'Hingurakgoda',
            'Nawala',
            'Manampitiya',
            'Bandaragama',
            'Katuneriya',
            'Koggala',
            'Welimada',
            'Kochchikade',
            'Bogawanthalawa',
            'Ganemulla',
            'Kotagala Talawakale',
            'Raddolugama',
            'Weliveriya',
            'Pettah',
            'Beliatta',
            'Matugama',
            'Malabe',
            'Wellawatte',
            'Dam Street'
        ]
    },
    { 
        name: 'Standard Chartered',
        branches: [
            'Head Office',
            'Union Place',
            'Head Office (Fort)',
            'Colpetty',
            'Wellawatte',
            'Kiribathgoda',
            'Kirulapone',
            'Moratuwa',
            'Rajagiriya',
            'Pettah'
        ]
    },
    {
        name: 'State Bank Of India',
        branches: [
            'Head Office',
            'Kandy',
            'Jaffna',
            'Kohuwala'
        ]
    },
    {
        name: 'State Mortgage And Investment Bank',
        branches: [
            'Kiribathgoda',
            'Kegalle',
            'Horana',
            'Ambalantota',
            'Batticaloa',
            'Jaffna',
            'Ampara',
            'Ratnapura',
            'Kaduruwela',
            'Vavuniya',
            'Hatton',
            'Badulla',
            'Head Office City',
            'Monaragala',
            'Kandy',
            'Anuradhapura',
            'Smib Finance Department',
            'Matale',
            'Gampaha',
            'Panadura',
            'Galle',
            'Kurunegala',
            'Matugama',
            'Chilaw',
            'Matara'
        ]
    },
    {
        name: 'Union Bank Of Colombo Ltd',
        branches: [
            'Ub Corporate',
            'Ratnapura',
            'Anuradhapura',
            'Dambulla',
            'Badulla',
            'Horana',
            'Ambalangoda',
            'Chilaw',
            'Marawila',
            'Panadura',
            'Narammala',
            'Pelawatte',
            'Angunakolapelessa',
            'Kebithigollewa',
            'Gampola',
            'Embilipitiya',
            'Head Office',
            'Kegalle',
            'Colpetty',
            'Warakapola',
            'Kandy',
            'Kekirawa',
            'Nawala',
            'Horowpathana',
            'Wellawatte',
            'Ambalanthota',
            'Pettah',
            'Old Moor Street',
            'Head Office Branch',
            'Kurunegala',
            'Minuwangoda',
            'Balangoda',
            'Maharagama',
            'Matugama',
            'Negombo',
            'Nawalapitiya',
            'Matara',
            'Ibbagamuwa',
            'Kotahena',
            'Kuliyapitiya',
            'Pilimathalawa',
            'Ja Ela',
            'Monaragala',
            'Nugegoda',
            'Ganemulla',
            'Moratuwa',
            'Wennappuwa',
            'Jaffna',
            'Mannar',
            'Elpitiya',
            'Medawachchiya',
            'Ratmalana',
            'Akuressa',
            'Vavuniya',
            'Rajagiriaya',
            'Chunnakkam',
            'Attidiya',
            'Atchuvely',
            'Kohuwala',
            'Wattala',
            'New Borella',
            'Gampaha',
            'Peradeniya',
            'Batticaloa',
            'Bandarawela',
            'Trincomalee',
            'Galle',
            'Piliyandala',
            'Kadawatha']
    },
    { 
        name: 'Vallibel One Plc',
        branches: ['Head Office']
    }
];