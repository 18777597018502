import { Component, EventEmitter, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { VIEW } from '../login-register/login-register.component';
import { UsersService } from '../../user.service';

@Component({
  selector: 'app-recover-password',
  standalone: false,
  templateUrl: './recover-password.component.html',
  styleUrl: './recover-password.component.scss',
})
export class RecoverPasswordComponent {
  @Output() viewChanged: EventEmitter<VIEW> = new EventEmitter<VIEW>();

  constructor(
    private readonly usersService: UsersService,
    private readonly messageService: MessageService
  ) {}

  recoverPasswrdForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  showLogin(): void {
    this.viewChanged.emit(VIEW.LOGIN);
  }

  showResetPassword(): void {
    this.viewChanged.emit(VIEW.RESET_PASSWORD);
  }

  submit(): void {
    if (this.recoverPasswrdForm.value.email) {
      this.usersService
        .forgotPassword(this.recoverPasswrdForm.value.email)
        .subscribe(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Password recovery email sent. Please check your email.',
          });
          this.showResetPassword();
        });
    }
  }
}
