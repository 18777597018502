<div class="login-and-security-wrapper">
    <div class="login-and-security-header">
        <h3 class="hide-in-tablet hide-in-mobile">Login & Security</h3>
        <h4>Login</h4>
    </div>

    <!-- //password -->
    <label for="" class="label-secondary mb-0">Password <span class="edit-link-wrapper"><button
                class="underline-button" (click) ="updatePassword()">Update</button></span></label>
    <p class="small mb-1">Last updated 2 months ago</p>
    <div class="login-wrapper-main" *ngIf="updatedPassword">
        <div class="input-wrapper m-w60">
            <h4 class="about-small-text">Current password</h4>
            <input type="text" class="mb-0 register" placeholder="">
            <small>Need a new password?</small>
        </div>
        <div class="input-wrapper m-w60">
            <h4 class="about-small-text">New password</h4>
            <input type="text" class="mb-0 register" placeholder="">
        </div>
        <div class="input-wrapper m-w60">
            <h4 class="about-small-text">Confirm new password</h4>
            <input type="text" class="mb-0 register" placeholder="">
        </div>
    </div>
    <div class="w-fit button-wrapper-public-profile" *ngIf="updatedPassword">
        <button class="primary-3-button">Update Password</button>
    </div>

    <hr>

    <div class="login-and-security-header">
        <h4 class="account-label">Account</h4>
    </div>

    <div class="deactivate-account-wrapper">
        <p class="small">Deactivate your account</p>
        <div class="edit-link-wrapper"><button
            class="underline-button">Deactivate</button></div>
    </div>
    

    <hr>
</div>