<p-dialog [modal]="true" [(visible)]="visible" [style]="{ 'width': '722px' }" class="login-modal hide-in-mobile">
    <div class="login-container-wrapper">
        <div class="left-pannel">
            <div class="left-pannel-inner">
                <h1>Success starts here</h1>
                <h6><span><img src="../../../../assets/images/icons/correct-icon.png" alt=""></span>
                    Over 600 categories
                </h6>
                <h6><span><img src="../../../../assets/images/icons/correct-icon.png" alt=""></span>
                    Over 1000+ trust freelancers
                </h6>
                <h6><span><img src="../../../../assets/images/icons/correct-icon.png" alt=""></span>
                    1000+ success stories
                </h6>
            </div>
        </div>
        <div class="right-pannel">
            <ng-container [ngSwitch]="rightPanelState">
                <!-- //default init tempalte -->
                <ng-template [ngSwitchCase]="'default'">
                    <h1>Create a new account</h1>
                    <h3>Already have an account? <span><button class="anchor-button" (click)="showEmailLogin(LFM.LOGIN)">Sign in</button></span></h3>
                    <div class="email-login-wrapper">
                        <button>
                            <span class="login-icon google-icon"><img
                                    src="../../../../assets/images/icons/google-icon.png" alt=""></span><span
                                class="login-text google-login">Continue with Google</span>
                        </button>
                        <button (click)="showEmailLogin()"><span class="login-icon email-icon"><img
                                    src="../../../../assets/images/icons/email-icon.png" alt=""></span><span
                                class="login-text email-login">Continue with Email</span></button>
                    </div>

                    <h6 class="login-seperator">or</h6>

                    <div class="social-media-login-wrapper">
                        <button><span class="social-icon apple-icon"><img
                                    src="../../../../assets/images/icons/apple-icon.png" alt=""></span><span
                                class="social-text apple-login">Apple</span></button>
                        <button><span class="social-icon facebook-icon"><img
                                    src="../../../../assets/images/icons/facebook-icon.png" alt=""></span><span
                                class="social-text facebook-login">facebook</span></button>
                    </div>
                    <div class="footer-note">
                        <p>By joining, you agree to the afterfive.app Terms of Service and to occasionally receive
                            emails from us. Please read our Privacy Policy to learn how we use your personal data.</p>
                    </div>
                </ng-template>

                <!-- //email registe template  -->
                <ng-template [ngSwitchCase]="'emailLogin'">
                    <div class="email-login-wrapper-temp">
                        <h1>Continue with your email</h1>
                        <!-- Add your email login form here -->
                        <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()">
                            <div class="email-input-wrapper-main">
                                <div class="input-wrapper">
                                    <label for="eemail">Email</label>
                                    <input formControlName="email" pInputText id="eemail" aria-describedby="email-help" />
                                </div>
                                <div class="input-wrapper">
                                    <label for="password">Password</label>
                                    <p-password formControlName="password" [toggleMask]="true" />
                                </div>

                                <input type="hidden" formControlName="mode">
                                <button [disabled]="!loginForm.valid" class="primary-2-button">Continue</button>
                            </div>
                        </form>

                        <p class="paragraph text-justify">
                            By joining, you agree to the afterfive.app Terms of Service and to occasionally receive
                            emails
                            from us. Please read our Privacy Policy to learn how we use your personal data.
                        </p>
                    </div>

                </ng-template>

                <ng-template [ngSwitchCase]="'confirmEmailOtp'">
                    <form [formGroup]="conformForm" (ngSubmit)="onConfirmEmailSubmit()">
                        <div class="otp-wrapper">
                            <h1>Confirm Your email</h1>
                            <p>Enter the verification code we emailed to:spectrify.analytics&#64;gmail.com.</p>
                            <a href="">Use a different email</a>

                            <div class="input-wrapper">
                                <p-inputOtp formControlName="code" [length]="6" />
                                <h5>Resend Code</h5>
                            </div>
                            <button [disabled]="!conformForm.valid" class="primary-2-button">Continue</button>

                        </div>
                    </form>
                </ng-template>

                <ng-template [ngSwitchCase]="'profileCreateTemp'">
                    <div class="profile-create-modal-wrapper">
                        <h1>Get Your Profile started</h1>
                        <!-- <div class="para-text-wrapper">
                            <p class="main-para-text">Add a username that’s unique to you, this is how you’’ll appear to
                                others. </p>
                            <p class="secondry-para-text">( You can’t change your username, so choose wisely
                                )
                            </p>
                        </div> -->

                        <form [formGroup]="createPorfileForm" (ngSubmit)="onNameSubmit()">
                            <div class="input-wrapper">
                                <label for="first-name">First Name</label>
                                <input formControlName="firstName" pInputText id="first-name" aria-describedby="first-name-help" placeholder="John" />
                            </div>

                            <div class="input-wrapper">
                                <label for="middle-name">Middle Name</label>
                                <input formControlName="middleName" pInputText id="middle-name" aria-describedby="middle-name-help" placeholder="Michael" />
                            </div>

                            <div class="input-wrapper">
                                <label for="last-name">Last Name</label>
                                <input formControlName="lastName" pInputText id="last-name" aria-describedby="last-name-help" placeholder="Doe" />
                            </div>
                            <!-- <small>Build trust by using your full name or business name</small> -->

                            <button [disabled]="!createPorfileForm.valid" class="primary-1-button">Create my account</button>
                        </form>
                    </div>
                </ng-template>
            </ng-container>
        </div>
    </div>
</p-dialog>

<!-- //profile extra  -->
<p-dialog [modal]="true" [(visible)]="profileExtraVisible" [style]='{"width":"920px"}'
    class="profile-extra-modal hide-in-mobile">
    <div class="profile-extra-wrapper">
        <ng-container [ngSwitch]="profileExtraState">
            <ng-template [ngSwitchCase]="1">
                <div class="first-step-wrapper">
                    <h3>Subhashi, your account has been created! What type of freelancer are you?</h3>
                    <p class="paragraph-primary">Whatever your style, we’ll make it work.</p>

                    <div class="extra-main-wrapper">
                        <div class="inner-extra-wrapper">
                            <div class="card" [class.active]="extraNumber === 1" (click)="extraClicked(1)">
                                <img class="image-1" src="../../../../assets/images/extra-profile/extra-1.png" alt="">
                                <p class="extra-profile-title">A side hustle</p>
                            </div>
                        </div>
                        <div class="inner-extra-wrapper">
                            <div class="card" [class.active]="extraNumber === 2" (click)="extraClicked(2)">
                                <img class="image-2" src="../../../../assets/images/extra-profile/extra-2.png" alt="">
                                <p class="extra-profile-title">Solo Freelancer</p>
                            </div>
                        </div>
                        <!-- <div class="inner-extra-wrapper">
                        <div class="card" [class.active]="extraNumber === 3"  (click)="extraClicked(3)">
                            <img class="image-3" src="../../../../assets/images/extra-profile/extra-3.png" alt="">
                            <p class="extra-profile-title">Agency employee</p>
                        </div>
                    </div>
                    <div class="inner-extra-wrapper">
                        <div class="card" [class.active]="extraNumber === 4"  (click)="extraClicked(4)">
                            <img class="image-4" src="../../../../assets/images/extra-profile/extra-4.png" alt="">
                            <p class="extra-profile-title">Agency owner</p>
                        </div>
                    </div> -->
                    </div>

                    <div class="extra-button-wrapper">
                        <button class="back-button" style="width: 200px;" (click)="backMainRegisterModal()"><span><img
                                    src="../../../../assets/images/icons/back-arrow.png" alt=""></span>Back</button>
                        <button class="primary-1-button" style="width: 150px;" (click)="nextExtraFrame(2)">Next</button>
                    </div>
                </div>

            </ng-template>

            <ng-template [ngSwitchCase]="2">
                <div class="second-step-wrapper">
                    <h3>How do you Freelance?</h3>
                    <p class="paragraph-primary">Whatever your style, we’ll make it work.</p>

                    <div class="extra-main-wrapper">
                        <div class="inner-extra-wrapper">
                            <div class="card" [class.active]="extraNumber2 === 5" (click)="extra2Clicked(5)">
                                <img class="image-5" src="../../../../assets/images/extra-profile/extra-5.png" alt="">
                                <p class="extra-profile-title">I’m just getting started</p>
                            </div>
                        </div>
                        <div class="inner-extra-wrapper">
                            <div class="card" [class.active]="extraNumber2 === 6" (click)="extra2Clicked(6)">
                                <img class="image-6" src="../../../../assets/images/extra-profile/extra-6.png" alt="">
                                <p class="extra-profile-title">Freelancing online</p>
                            </div>
                        </div>
                        <div class="inner-extra-wrapper">
                            <div class="card" [class.active]="extraNumber2 === 7" (click)="extra2Clicked(7)">
                                <img class="image-7" src="../../../../assets/images/extra-profile/extra-7.png" alt="">
                                <p class="extra-profile-title">Freelancing offline</p>
                            </div>
                        </div>
                        <div class="inner-extra-wrapper">
                            <div class="card" [class.active]="extraNumber2 === 8" (click)="extra2Clicked(8)">
                                <img class="image-8" src="../../../../assets/images/extra-profile/extra-8.png" alt="">
                                <p class="extra-profile-title">Both online and offline</p>
                            </div>
                        </div>
                    </div>

                    <div class="extra-button-wrapper">
                        <button class="back-button" style="width: 200px;" (click)="prevExtraFrame(1)"><span><img
                                    src="../../../../assets/images/icons/back-arrow.png" alt=""></span>Back</button>
                        <button class="primary-1-button" style="width: 150px;" (click)="nextExtraFrame(3)">Next</button>
                    </div>
                </div>
            </ng-template>

            <ng-template [ngSwitchCase]="3">
                <div class="final-step-wrapper">
                    <img src="../../../../assets/images/extra-profile/extra-6.png" alt="">
                    <h3 class="extra-last-main-title">Great, you’re almost there. Here’s what’s next:</h3>

                    <div class="complete-tasks-wrapper">
                        <h6>1. Complete your profile</h6>
                        <h6>2. Create your first gig</h6>
                        <h6>3. Publish it and start earning</h6>
                    </div>

                    <div class="final-extra-button-wrapper">
                        <button (click)="completeSellerProfile()" class="primary-1-button">Complete your seller
                            profile</button>
                        <button class="secondary-button">Explore First</button>
                    </div>
                </div>

            </ng-template>
        </ng-container>

    </div>
</p-dialog>


<!-- //mobile section  -->
<p-dialog [modal]="true" [(visible)]="visible" [style]="{ width: '100vw' }"
    class="login-modal mobile-registration-modal hide-in-desktop hide-in-tablet">
    <div class="login-container-wrapper">
        <div class="header-wrapper-mobile-box" *ngIf="rightPanelState == 'default'">
            <img (click)="goToHome()" src="../../../../assets/images/logo/logo.svg" alt="">
        </div>
        <div class="header-wrapper-mobile-box with-back-button" *ngIf="rightPanelState == 'emailLogin'">
            <button class="back-button" (click)="showDefault()"><img
                    src="../../../../assets/images/icons/back-arrow.svg" alt="">Back</button>
            <img (click)="goToHome()" src="../../../../assets/images/logo/logo.svg" alt="">
        </div>
        <div class="header-wrapper-mobile-box" *ngIf="rightPanelState == 'confirmEmailOtp'">
            <img (click)="goToHome()" src="../../../../assets/images/logo/logo.svg" alt="">
        </div>
        <div class="header-wrapper-mobile-box with-back-button" *ngIf="rightPanelState == 'profileCreateTemp'">
            <button class="back-button" (click)="showOtpTemplate()"><img
                    src="../../../../assets/images/icons/back-arrow.svg" alt="">Back</button>
            <img (click)="goToHome()" src="../../../../assets/images/logo/logo.svg" alt="">
        </div>
        <div class="right-pannel">
            <ng-container [ngSwitch]="rightPanelState">
                <!-- //default init tempalte -->
                <ng-template [ngSwitchCase]="'default'">
                    <div class="header-wrapper">
                        <h1>Create a new account</h1>
                        <h3>Already have an account? <span><a href="">Sign in</a></span></h3>
                    </div>
                    <div class="email-login-wrapper">
                        <button>
                            <span class="login-icon google-icon"><img
                                    src="../../../../assets/images/icons/google-icon.png" alt=""></span><span
                                class="login-text google-login">Continue with Google</span>
                        </button>
                        <button (click)="showEmailLogin()"><span class="login-icon email-icon"><img
                                    src="../../../../assets/images/icons/email-icon.png" alt=""></span><span
                                class="login-text email-login">Continue with Email</span></button>
                    </div>

                    <h6 class="login-seperator">or</h6>

                    <div class="social-media-login-wrapper">
                        <button><span class="social-icon apple-icon"><img
                                    src="../../../../assets/images/icons/apple-icon.png" alt=""></span><span
                                class="social-text apple-login">Apple</span></button>
                        <button><span class="social-icon facebook-icon"><img
                                    src="../../../../assets/images/icons/facebook-icon.png" alt=""></span><span
                                class="social-text facebook-login">facebook</span></button>
                    </div>
                    <div class="footer-note">
                        <p>By joining, you agree to the afterfive.app Terms of Service and to occasionally receive
                            emails from us. Please read our Privacy Policy to learn how we use your personal data.</p>
                    </div>
                </ng-template>

                <!-- //email registe template  -->
                <ng-template [ngSwitchCase]="'emailLogin'">
                    <div class="email-login-wrapper-temp">
                        <h1>Continue with your email</h1>
                        <!-- Add your email login form here -->
                        <div class="email-input-wrapper-main">
                            <div class="input-wrapper">
                                <label for="email">Email</label>
                                <input pInputText id="email" aria-describedby="email-help" />
                            </div>
                            <div class="input-wrapper">
                                <label for="password">Password</label>
                                <p-password [toggleMask]="true" />
                            </div>

                            <button class="primary-2-button" (click)="showOtpTemplate()">Continue</button>
                        </div>


                        <p class="paragraph text-justify">
                            By joining, you agree to the afterfive.app Terms of Service and to occasionally receive
                            emails
                            from us. Please read our Privacy Policy to learn how we use your personal data.
                        </p>
                    </div>

                </ng-template>

                <ng-template [ngSwitchCase]="'confirmEmailOtp'">
                    <div class="otp-wrapper">
                        <h1>Confirm Your email</h1>
                        <p>Enter the verification code we emailed to:spectrify.analytics&#64;gmail.com.</p>
                        <a href="">Use a different email</a>

                        <div class="input-wrapper">
                            <p-inputOtp [length]="6" />
                            <h5>Resend Code</h5>
                        </div>

                        <button class="primary-2-button" (click)="showProfileCreateTemp()">Continue</button>

                    </div>
                </ng-template>

                <ng-template [ngSwitchCase]="'profileCreateTemp'">
                    <div class="profile-create-modal-wrapper">
                        <h1>Get Your Profile started</h1>
                        <!-- <div class="para-text-wrapper">
                            <p class="main-para-text">Add a username that’s unique to you, this is how you’’ll appear to
                                others. </p>
                            <p class="secondry-para-text">( You can’t change your username, so choose wisely
                                )
                            </p>
                        </div> -->


                        <div class="input-wrapper">
                            <label for="first-name">First Name</label>
                            <input pInputText id="first-name" aria-describedby="first-name-help" placeholder="John" />
                        </div>

                        <div class="input-wrapper">
                            <label for="middle-name">Middle Name</label>
                            <input pInputText id="middle-name" aria-describedby="middle-name-help"
                                placeholder="Michael" />
                        </div>

                        <div class="input-wrapper">
                            <label for="last-name">Last Name</label>
                            <input pInputText id="last-name" aria-describedby="last-name-help" placeholder="Doe" />
                        </div>
                        <!-- <small>Build trust by using your full name or business name</small> -->

                        <button class="primary-1-button" (click)="createMyAccount()">Create my account</button>
                    </div>
                </ng-template>
            </ng-container>
        </div>
    </div>
</p-dialog>

<!-- //mobile profile extra  -->
<p-dialog [modal]="true" [(visible)]="profileExtraVisible" [style]="{ width: '100vw' }"
    class="mobile-registration-extra-modal profile-extra-modal hide-in-desktop hide-in-tablet">
    <div class="header-wrapper-mobile-box with-back-button" *ngIf="profileExtraState == 1">
        <button class="back-button" (click)="showProfileCreateTemp()"><img
                src="../../../../assets/images/icons/back-arrow.svg" alt="">Back</button>
        <img (click)="goToHome()" src="../../../../assets/images/logo/logo.svg" alt="">
    </div>
    <div class="header-wrapper-mobile-box with-back-button" *ngIf="profileExtraState == 2">
        <button class="back-button" (click)="nextExtraFrame(1)"><img
                src="../../../../assets/images/icons/back-arrow.svg" alt="">Back</button>
        <img (click)="goToHome()" src="../../../../assets/images/logo/logo.svg" alt="">
    </div>
    <div class="header-wrapper-mobile-box" *ngIf="profileExtraState == 3">
        <img (click)="goToHome()" src="../../../../assets/images/logo/logo.svg" alt="">
    </div>
    <div class="profile-extra-wrapper">
        <ng-container [ngSwitch]="profileExtraState">
            <ng-template [ngSwitchCase]="1">
                <h3>Subhashi, your account has been created! What type of freelancer are you?</h3>
                <p class="paragraph-primary">Whatever your style, we’ll make it work.</p>

                <div class="extra-main-wrapper">
                    <div class="inner-extra-wrapper">
                        <div class="card" [class.active]="extraNumber === 1" (click)="extraClicked(1)">
                            <img class="image-1" src="../../../../assets/images/extra-profile/extra-1.png" alt="">
                            <p class="extra-profile-title">A side hustle</p>
                        </div>
                    </div>
                    <div class="inner-extra-wrapper">
                        <div class="card" [class.active]="extraNumber === 2" (click)="extraClicked(2)">
                            <img class="image-2" src="../../../../assets/images/extra-profile/extra-2.png" alt="">
                            <p class="extra-profile-title">Solo Freelancer</p>
                        </div>
                    </div>
                    <!-- <div class="inner-extra-wrapper">
                        <div class="card" [class.active]="extraNumber === 3"  (click)="extraClicked(3)">
                            <img class="image-3" src="../../../../assets/images/extra-profile/extra-3.png" alt="">
                            <p class="extra-profile-title">Agency employee</p>
                        </div>
                    </div>
                    <div class="inner-extra-wrapper">
                        <div class="card" [class.active]="extraNumber === 4"  (click)="extraClicked(4)">
                            <img class="image-4" src="../../../../assets/images/extra-profile/extra-4.png" alt="">
                            <p class="extra-profile-title">Agency owner</p>
                        </div>
                    </div> -->
                </div>

                <div class="extra-button-wrapper">
                    <button class="primary-1-button" style="width: 150px;" (click)="nextExtraFrame(2)">Next</button>
                </div>
            </ng-template>

            <ng-template [ngSwitchCase]="2">
                <h3>How do you Freelance?</h3>
                <p class="paragraph-primary">Whatever your style, we’ll make it work.</p>

                <div class="extra-main-wrapper">
                    <div class="inner-extra-wrapper">
                        <div class="card" [class.active]="extraNumber2 === 5" (click)="extra2Clicked(5)">
                            <img class="image-5" src="../../../../assets/images/extra-profile/extra-5.png" alt="">
                            <p class="extra-profile-title">I’m just getting started</p>
                        </div>
                    </div>
                    <div class="inner-extra-wrapper">
                        <div class="card" [class.active]="extraNumber2 === 6" (click)="extra2Clicked(6)">
                            <img class="image-6" src="../../../../assets/images/extra-profile/extra-6.png" alt="">
                            <p class="extra-profile-title">Freelancing online</p>
                        </div>
                    </div>
                    <div class="inner-extra-wrapper">
                        <div class="card" [class.active]="extraNumber2 === 7" (click)="extra2Clicked(7)">
                            <img class="image-7" src="../../../../assets/images/extra-profile/extra-7.png" alt="">
                            <p class="extra-profile-title">Freelancing offline</p>
                        </div>
                    </div>
                    <div class="inner-extra-wrapper">
                        <div class="card" [class.active]="extraNumber2 === 8" (click)="extra2Clicked(8)">
                            <img class="image-8" src="../../../../assets/images/extra-profile/extra-8.png" alt="">
                            <p class="extra-profile-title">Both online and offline</p>
                        </div>
                    </div>
                </div>

                <div class="extra-button-wrapper">
                    <button class="primary-1-button" style="width: 150px;" (click)="nextExtraFrame(3)">Next</button>
                </div>
            </ng-template>

            <ng-template [ngSwitchCase]="3">
                <div class="final-step-wrapper">
                    <!-- <img src="../../../../assets/images/extra-profile/extra-6.png" alt=""> -->
                    <h3>Great, you’re almost there. Here’s what’s next:</h3>

                    <div class="complete-tasks-wrapper">
                        <h6>1. Complete your profile</h6>
                        <h6>2. Create your first gig</h6>
                        <h6>3. Publish it and start earning</h6>
                    </div>

                    <div class="final-extra-button-wrapper">
                        <button (click)="completeSellerProfile()" class="primary-1-button">Complete your seller
                            profile</button>
                        <button class="secondary-button">Explore First</button>
                    </div>
                </div>

            </ng-template>
        </ng-container>

    </div>
</p-dialog>