<footer class="flex justify-center items-center">
  <div class="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
    <div class="col-span-1 p-4">
      <img src="../../../assets/images/logo/logo-white.svg" alt="" />
    </div>
    <div class="col-span-1 p-4">
      <h6>PAGES</h6>
      <ul>
        <li><a href="#">About</a></li>
        <li>Contact</li>
        <li>Credit program</li>
      </ul>
    </div>
    <div class="col-span-1 p-4">
      <h6>COMPANY</h6>
      <ul>
        <li>About</li>
        <li>Contact</li>
        <li>Credit program</li>
      </ul>
    </div>
    <div class="col-span-1 p-4">
      <h6>CONTACT US</h6>
      <ul>
        <li>support&#64;afterfive.app</li>
      </ul>
    </div>
  </div>
</footer>
