<div class="font-bold text-2xl mb-4">Personal Information</div>
<form [formGroup]="personalForm" class="max-w-2xl" (ngSubmit)="submit()">
  <div formGroupName="name">
    <div class="mb-4">
      <app-shared-text-input
        formControlName="first"
        label="First Name"
      ></app-shared-text-input>
    </div>
    <div class="mb-4">
      <app-shared-text-input
        formControlName="middle"
        label="Middle Name (optional)"
      ></app-shared-text-input>
    </div>
    <div class="mb-4">
      <app-shared-text-input
        formControlName="last"
        label="Last Name"
      ></app-shared-text-input>
    </div>
  </div>

  <div formGroupName="personal">
    <div class="mb-4">
      <p-fieldset legend="Sex">
        <div class="flex flex-wrap gap-4">
          <div class="flex items-center">
            <p-radiobutton inputId="male" value="Male" formControlName="sex" />
            <label for="male" class="ml-2">Male</label>
          </div>
          <div class="flex items-center">
            <p-radiobutton
              inputId="female"
              value="Female"
              formControlName="sex"
            />
            <label for="female" class="ml-2">Female</label>
          </div>
        </div>
      </p-fieldset>
    </div>

    <div class="mb-4">
      <p-fieldset legend="Address">
        <div class="mb-4">
          <app-shared-text-input
            formControlName="address1"
            label="Address"
          ></app-shared-text-input>
        </div>
        <div class="mb-4">
          <app-shared-text-input
            formControlName="address2"
            label="Appartment, Suite, etc (optional)"
          ></app-shared-text-input>
        </div>
        <div class="flex gap-2">
          <div class="mb-4 w-2/3">
            <app-shared-text-input
              formControlName="city"
              label="City"
            ></app-shared-text-input>
          </div>
          <div class="mb-4 w-1/3">
            <app-shared-text-input
              formControlName="postalCode"
              label="Zip / Postal Code"
            ></app-shared-text-input>
          </div>
        </div>
        <app-shared-country-select
          formControlName="country"
        ></app-shared-country-select>
      </p-fieldset>
    </div>

    <div class="mb-4">
      <p-inputgroup>
        <p-inputgroup-addon>
          {{ getCountryCode(personalForm.value.personal?.country) }}
        </p-inputgroup-addon>
        <app-shared-text-input
          formControlName="phone"
          label="Phone Number"
        ></app-shared-text-input>
      </p-inputgroup>
    </div>

    <div class="mb-4">
      <p-fieldset legend="ID Type">
        <div class="flex flex-wrap gap-4 py-4">
          <div *ngFor="let type of idTypes" class="flex items-center">
            <p-radiobutton
              [inputId]="type"
              [value]="type"
              formControlName="idType"
            />
            <label [for]="type" class="ml-2">{{ getIdFieldLabel(type) }}</label>
          </div>
        </div>
        <div class="mb-4">
          <app-shared-text-input
            formControlName="idNumber"
            [label]="getIdFieldLabel(personalForm.value.personal?.idType)"
          ></app-shared-text-input>
        </div>
      </p-fieldset>
    </div>

    <div class="mb-4">
      <app-shared-text-input
        formControlName="tinOrPin"
        label="TIN or PIN (optional)"
      ></app-shared-text-input>
    </div>
  </div>

  <div class="py-6">
    <p-button label="Next" type="submit" [disabled]="!personalForm.valid" />
  </div>
</form>
