import * as i1 from '@angular/common';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, EventEmitter, inject, numberAttribute, booleanAttribute, ContentChildren, ContentChild, ViewChild, Output, Input, ViewEncapsulation, ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { uuid, setAttribute, find, getAttribute, findSingle } from '@primeuix/utils';
import { SharedModule, Header, Footer, PrimeTemplate } from 'primeng/api';
import { BaseComponent } from 'primeng/basecomponent';
import * as i2 from 'primeng/button';
import { ButtonModule } from 'primeng/button';
import { ChevronRightIcon, ChevronLeftIcon, ChevronDownIcon, ChevronUpIcon } from 'primeng/icons';
import { BaseStyle } from 'primeng/base';
const _c0 = ["item"];
const _c1 = ["header"];
const _c2 = ["footer"];
const _c3 = ["previousicon"];
const _c4 = ["nexticon"];
const _c5 = ["itemsContainer"];
const _c6 = ["indicatorContent"];
const _c7 = [[["p-header"]], [["p-footer"]]];
const _c8 = ["p-header", "p-footer"];
const _c9 = (a0, a1) => ({
  "p-carousel p-component": true,
  "p-carousel-vertical": a0,
  "p-carousel-horizontal": a1
});
const _c10 = a0 => ({
  height: a0
});
const _c11 = a0 => ({
  "p-carousel-prev-button": true,
  "p-disabled": a0
});
const _c12 = (a0, a1, a2) => ({
  "p-carousel-item p-carousel-item-clone": true,
  "p-carousel-item-active": a0,
  "p-carousel-item-start": a1,
  "p-carousel-item-end": a2
});
const _c13 = a0 => ({
  $implicit: a0
});
const _c14 = (a0, a1, a2) => ({
  "p-carousel-item": true,
  "p-carousel-item-active": a0,
  "p-carousel-item-start": a1,
  "p-carousel-item-end": a2
});
const _c15 = a0 => ({
  "p-carousel-next-button": true,
  "p-disabled": a0
});
const _c16 = a0 => ({
  "p-carousel-indicator": true,
  "p-carousel-indicator-active": a0
});
function Carousel_div_1_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Carousel_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 14);
    i0.ɵɵprojection(1);
    i0.ɵɵtemplate(2, Carousel_div_1_ng_container_2_Template, 1, 0, "ng-container", 15);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.headerTemplate);
  }
}
function Carousel_p_button_4_ng_template_1_ng_container_0_ChevronLeftIcon_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "ChevronLeftIcon", 20);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "carousel-prev-icon");
  }
}
function Carousel_p_button_4_ng_template_1_ng_container_0_ChevronUpIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "ChevronUpIcon", 20);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "carousel-prev-icon");
  }
}
function Carousel_p_button_4_ng_template_1_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Carousel_p_button_4_ng_template_1_ng_container_0_ChevronLeftIcon_1_Template, 1, 1, "ChevronLeftIcon", 19)(2, Carousel_p_button_4_ng_template_1_ng_container_0_ChevronUpIcon_2_Template, 1, 1, "ChevronUpIcon", 19);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.isVertical());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.isVertical());
  }
}
function Carousel_p_button_4_ng_template_1_span_1_1_ng_template_0_Template(rf, ctx) {}
function Carousel_p_button_4_ng_template_1_span_1_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Carousel_p_button_4_ng_template_1_span_1_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function Carousel_p_button_4_ng_template_1_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 21);
    i0.ɵɵtemplate(1, Carousel_p_button_4_ng_template_1_span_1_1_Template, 1, 0, null, 15);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.previousIconTemplate || ctx_r1._previousIconTemplate);
  }
}
function Carousel_p_button_4_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Carousel_p_button_4_ng_template_1_ng_container_0_Template, 3, 2, "ng-container", 17)(1, Carousel_p_button_4_ng_template_1_span_1_Template, 2, 1, "span", 18);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngIf", !ctx_r1.previousIconTemplate && !ctx_r1._previousIconTemplate && !(ctx_r1.prevButtonProps == null ? null : ctx_r1.prevButtonProps.icon));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", (ctx_r1.previousIconTemplate || ctx_r1._previousIconTemplate) && !(ctx_r1.prevButtonProps == null ? null : ctx_r1.prevButtonProps.icon));
  }
}
function Carousel_p_button_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "p-button", 16);
    i0.ɵɵlistener("click", function Carousel_p_button_4_Template_p_button_click_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.navBackward($event));
    });
    i0.ɵɵtemplate(1, Carousel_p_button_4_ng_template_1_Template, 2, 2, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(5, _c11, ctx_r1.isBackwardNavDisabled()))("disabled", ctx_r1.isBackwardNavDisabled())("text", true)("buttonProps", ctx_r1.prevButtonProps);
    i0.ɵɵattribute("aria-label", ctx_r1.ariaPrevButtonLabel());
  }
}
function Carousel_div_8_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Carousel_div_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵtemplate(1, Carousel_div_8_ng_container_1_Template, 1, 0, "ng-container", 22);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r4 = ctx.$implicit;
    const index_r5 = ctx.index;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(6, _c12, ctx_r1.totalShiftedItems * -1 === ctx_r1.value.length, 0 === index_r5, ctx_r1.clonedItemsForStarting.length - 1 === index_r5));
    i0.ɵɵattribute("aria-hidden", !(ctx_r1.totalShiftedItems * -1 === ctx_r1.value.length))("aria-label", ctx_r1.ariaSlideNumber(index_r5))("aria-roledescription", ctx_r1.ariaSlideLabel());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.itemTemplate || ctx_r1._itemTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(10, _c13, item_r4));
  }
}
function Carousel_div_9_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Carousel_div_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵtemplate(1, Carousel_div_9_ng_container_1_Template, 1, 0, "ng-container", 22);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r6 = ctx.$implicit;
    const index_r7 = ctx.index;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(6, _c14, ctx_r1.firstIndex() <= index_r7 && ctx_r1.lastIndex() >= index_r7, ctx_r1.firstIndex() === index_r7, ctx_r1.lastIndex() === index_r7));
    i0.ɵɵattribute("aria-hidden", !(ctx_r1.totalShiftedItems * -1 === ctx_r1.value.length))("aria-label", ctx_r1.ariaSlideNumber(index_r7))("aria-roledescription", ctx_r1.ariaSlideLabel());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.itemTemplate || ctx_r1._itemTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(10, _c13, item_r6));
  }
}
function Carousel_div_10_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Carousel_div_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵtemplate(1, Carousel_div_10_ng_container_1_Template, 1, 0, "ng-container", 22);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r8 = ctx.$implicit;
    const index_r9 = ctx.index;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(3, _c12, ctx_r1.totalShiftedItems * -1 === ctx_r1.numVisible, 0 === index_r9, ctx_r1.clonedItemsForFinishing.length - 1 === index_r9));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.itemTemplate || ctx_r1._itemTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(7, _c13, item_r8));
  }
}
function Carousel_p_button_11_ng_template_1_ng_container_0_ChevronRightIcon_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "ChevronRightIcon", 20);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "carousel-next-icon");
  }
}
function Carousel_p_button_11_ng_template_1_ng_container_0_ChevronDownIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "ChevronDownIcon", 20);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "carousel-next-icon");
  }
}
function Carousel_p_button_11_ng_template_1_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Carousel_p_button_11_ng_template_1_ng_container_0_ChevronRightIcon_1_Template, 1, 1, "ChevronRightIcon", 19)(2, Carousel_p_button_11_ng_template_1_ng_container_0_ChevronDownIcon_2_Template, 1, 1, "ChevronDownIcon", 19);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.isVertical());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.isVertical());
  }
}
function Carousel_p_button_11_ng_template_1_span_1_1_ng_template_0_Template(rf, ctx) {}
function Carousel_p_button_11_ng_template_1_span_1_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Carousel_p_button_11_ng_template_1_span_1_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function Carousel_p_button_11_ng_template_1_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 25);
    i0.ɵɵtemplate(1, Carousel_p_button_11_ng_template_1_span_1_1_Template, 1, 0, null, 15);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.nextIconTemplate || ctx_r1._nextIconTemplate);
  }
}
function Carousel_p_button_11_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Carousel_p_button_11_ng_template_1_ng_container_0_Template, 3, 2, "ng-container", 17)(1, Carousel_p_button_11_ng_template_1_span_1_Template, 2, 1, "span", 24);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngIf", !ctx_r1.nextIconTemplate && !ctx_r1._nextIconTemplate && !(ctx_r1.nextButtonProps == null ? null : ctx_r1.nextButtonProps.icon));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.nextIconTemplate || ctx_r1._nextIconTemplate && !(ctx_r1.nextButtonProps == null ? null : ctx_r1.nextButtonProps.icon));
  }
}
function Carousel_p_button_11_Template(rf, ctx) {
  if (rf & 1) {
    const _r10 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "p-button", 23);
    i0.ɵɵlistener("click", function Carousel_p_button_11_Template_p_button_click_0_listener($event) {
      i0.ɵɵrestoreView(_r10);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.navForward($event));
    });
    i0.ɵɵtemplate(1, Carousel_p_button_11_ng_template_1_Template, 2, 2, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(5, _c15, ctx_r1.isForwardNavDisabled()))("disabled", ctx_r1.isForwardNavDisabled())("buttonProps", ctx_r1.nextButtonProps)("text", true);
    i0.ɵɵattribute("aria-label", ctx_r1.ariaNextButtonLabel());
  }
}
function Carousel_ul_12_li_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 5)(1, "button", 27);
    i0.ɵɵlistener("click", function Carousel_ul_12_li_2_Template_button_click_1_listener($event) {
      const i_r13 = i0.ɵɵrestoreView(_r12).index;
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onDotClick($event, i_r13));
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const i_r13 = ctx.index;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(9, _c16, ctx_r1._page === i_r13));
    i0.ɵɵattribute("data-pc-section", "indicator");
    i0.ɵɵadvance();
    i0.ɵɵclassMap(ctx_r1.indicatorStyleClass);
    i0.ɵɵproperty("ngClass", "p-carousel-indicator-button")("ngStyle", ctx_r1.indicatorStyle)("tabindex", ctx_r1._page === i_r13 ? 0 : -1);
    i0.ɵɵattribute("aria-label", ctx_r1.ariaPageLabel(i_r13 + 1))("aria-current", ctx_r1._page === i_r13 ? "page" : undefined);
  }
}
function Carousel_ul_12_Template(rf, ctx) {
  if (rf & 1) {
    const _r11 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ul", 26, 2);
    i0.ɵɵlistener("keydown", function Carousel_ul_12_Template_ul_keydown_0_listener($event) {
      i0.ɵɵrestoreView(_r11);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onIndicatorKeydown($event));
    });
    i0.ɵɵtemplate(2, Carousel_ul_12_li_2_Template, 2, 11, "li", 10);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r1.indicatorsContentClass);
    i0.ɵɵproperty("ngClass", "p-carousel-indicator-list")("ngStyle", ctx_r1.indicatorsContentStyle);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", ctx_r1.totalDotsArray());
  }
}
function Carousel_div_13_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Carousel_div_13_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 28);
    i0.ɵɵprojection(1, 1);
    i0.ɵɵtemplate(2, Carousel_div_13_ng_container_2_Template, 1, 0, "ng-container", 15);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.footerTemplate || ctx_r1._footerTemplate);
  }
}
const theme = ({
  dt
}) => `
.p-carousel {
    display: flex;
    flex-direction: column;
}

.p-carousel-content-container {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.p-carousel-content {
    display: flex;
    flex-direction: row;
    gap: ${dt('carousel.content.gap')};
}

.p-carousel-content:dir(rtl) {
    flex-direction: row-reverse;
}

.p-carousel-viewport {
    overflow: hidden;
    width: 100%;
}

.p-carousel-item-list {
    display: flex;
    flex-direction: row;
}

.p-carousel-item-list:dir(rtl) {
    flex-direction: row-reverse;
}

.p-carousel-prev-button,
.p-carousel-next-button {
    align-self: center;
    flex-shrink: 0;
}

.p-carousel-indicator-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding: ${dt('carousel.indicator.list.padding')};
    gap: ${dt('carousel.indicator.list.gap')};
    margin: 0;
    list-style: none;
}

.p-carousel-indicator-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${dt('carousel.indicator.background')};
    width: ${dt('carousel.indicator.width')};
    height: ${dt('carousel.indicator.height')};
    border: 0 none;
    transition: background ${dt('carousel.transition.duration')}, color ${dt('carousel.transition.duration')}, outline-color ${dt('carousel.transition.duration')}, box-shadow ${dt('carousel.transition.duration')};
    outline-color: transparent;
    border-radius: ${dt('carousel.indicator.border.radius')};
    padding: 0;
    margin: 0;
    user-select: none;
    cursor: pointer;
}

.p-carousel-indicator-button:focus-visible {
    box-shadow: ${dt('carousel.indicator.focus.ring.shadow')};
    outline: ${dt('carousel.indicator.focus.ring.width')} ${dt('carousel.indicator.focus.ring.style')} ${dt('carousel.indicator.focus.ring.color')};
    outline-offset: ${dt('carousel.indicator.focus.ring.offset')};
}

.p-carousel-indicator-button:hover {
    background: ${dt('carousel.indicator.hover.background')};
}

.p-carousel-indicator-active .p-carousel-indicator-button {
    background: ${dt('carousel.indicator.active.background')};
}

.p-carousel-vertical .p-carousel-content {
    flex-direction: column;
}

.p-carousel-vertical .p-carousel-item-list {
    flex-direction: column;
    height: 100%;
}

.p-items-hidden .p-carousel-item {
    visibility: hidden;
}

.p-items-hidden .p-carousel-item.p-carousel-item-active {
    visibility: visible;
}
`;
const classes = {
  root: ({
    instance
  }) => ['p-carousel p-component', {
    'p-carousel-vertical': instance.isVertical(),
    'p-carousel-horizontal': !instance.isVertical()
  }],
  header: 'p-carousel-header',
  contentContainer: 'p-carousel-content-container',
  content: 'p-carousel-content',
  pcPrevButton: ({
    instance
  }) => ['p-carousel-prev-button', {
    'p-disabled': instance.backwardIsDisabled
  }],
  viewport: 'p-carousel-viewport',
  itemList: 'p-carousel-item-list',
  itemClone: ({
    index,
    value,
    totalShiftedItems,
    d_numVisible
  }) => ['p-carousel-item p-carousel-item-clone', {
    'p-carousel-item-active': totalShiftedItems * -1 === value.length + d_numVisible,
    'p-carousel-item-start': index === 0,
    'p-carousel-item-end': value.slice(-1 * d_numVisible).length - 1 === index
  }],
  item: ({
    instance,
    index
  }) => ['p-carousel-item', {
    'p-carousel-item-active': instance.firstIndex() <= index && instance.lastIndex() >= index,
    'p-carousel-item-start': instance.firstIndex() === index,
    'p-carousel-item-end': instance.lastIndex() === index
  }],
  pcNextButton: ({
    instance
  }) => ['p-carousel-next-button', {
    'p-disabled': instance.forwardIsDisabled
  }],
  indicatorList: 'p-carousel-indicator-list',
  indicator: ({
    instance,
    index
  }) => ['p-carousel-indicator', {
    'p-carousel-indicator-active': instance.d_page === index
  }],
  indicatorButton: 'p-carousel-indicator-button',
  footer: 'p-carousel-footer'
};
class CarouselStyle extends BaseStyle {
  name = 'carousel';
  theme = theme;
  classes = classes;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵCarouselStyle_BaseFactory;
    return function CarouselStyle_Factory(__ngFactoryType__) {
      return (ɵCarouselStyle_BaseFactory || (ɵCarouselStyle_BaseFactory = i0.ɵɵgetInheritedFactory(CarouselStyle)))(__ngFactoryType__ || CarouselStyle);
    };
  })();
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: CarouselStyle,
    factory: CarouselStyle.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CarouselStyle, [{
    type: Injectable
  }], null, null);
})();
/**
 *
 * Carousel is a content slider featuring various customization options.
 *
 * [Live Demo](https://www.primeng.org/carousel/)
 *
 * @module carouselstyle
 *
 */
var CarouselClasses;
(function (CarouselClasses) {
  /**
   * Class name of the root element
   */
  CarouselClasses["root"] = "p-carousel";
  /**
   * Class name of the header element
   */
  CarouselClasses["header"] = "p-carousel-header";
  /**
   * Class name of the content container element
   */
  CarouselClasses["contentContainer"] = "p-carousel-content-container";
  /**
   * Class name of the content element
   */
  CarouselClasses["content"] = "p-carousel-content";
  /**
   * Class name of the previous button element
   */
  CarouselClasses["pcPrevButton"] = "p-carousel-prev-button";
  /**
   * Class name of the viewport element
   */
  CarouselClasses["viewport"] = "p-carousel-viewport";
  /**
   * Class name of the item list element
   */
  CarouselClasses["itemList"] = "p-carousel-item-list";
  /**
   * Class name of the item clone element
   */
  CarouselClasses["itemClone"] = "p-carousel-item-clone";
  /**
   * Class name of the item element
   */
  CarouselClasses["item"] = "p-carousel-item";
  /**
   * Class name of the next button element
   */
  CarouselClasses["pcNextButton"] = "p-carousel-next-button";
  /**
   * Class name of the indicator list element
   */
  CarouselClasses["indicatorList"] = "p-carousel-indicator-list";
  /**
   * Class name of the indicator element
   */
  CarouselClasses["indicator"] = "p-carousel-indicator";
  /**
   * Class name of the indicator button element
   */
  CarouselClasses["indicatorButton"] = "p-carousel-indicator-button";
  /**
   * Class name of the footer element
   */
  CarouselClasses["footer"] = "p-carousel-footer";
})(CarouselClasses || (CarouselClasses = {}));

/**
 * Carousel is a content slider featuring various customization options.
 * @group Components
 */
class Carousel extends BaseComponent {
  el;
  zone;
  /**
   * Index of the first item.
   * @defaultValue 0
   * @group Props
   */
  get page() {
    return this._page;
  }
  set page(val) {
    if (this.isCreated && val !== this._page) {
      if (this.autoplayInterval) {
        this.stopAutoplay();
      }
      if (val > this._page && val <= this.totalDots() - 1) {
        this.step(-1, val);
      } else if (val < this._page) {
        this.step(1, val);
      }
    }
    this._page = val;
  }
  /**
   * Number of items per page.
   * @defaultValue 1
   * @group Props
   */
  get numVisible() {
    return this._numVisible;
  }
  set numVisible(val) {
    this._numVisible = val;
  }
  /**
   * Number of items to scroll.
   * @defaultValue 1
   * @group Props
   */
  get numScroll() {
    return this._numVisible;
  }
  set numScroll(val) {
    this._numScroll = val;
  }
  /**
   * An array of options for responsive design.
   * @see {CarouselResponsiveOptions}
   * @group Props
   */
  responsiveOptions;
  /**
   * Specifies the layout of the component.
   * @group Props
   */
  orientation = 'horizontal';
  /**
   * Height of the viewport in vertical layout.
   * @group Props
   */
  verticalViewPortHeight = '300px';
  /**
   * Style class of main content.
   * @group Props
   */
  contentClass = '';
  /**
   * Style class of the indicator items.
   * @group Props
   */
  indicatorsContentClass = '';
  /**
   * Inline style of the indicator items.
   * @group Props
   */
  indicatorsContentStyle;
  /**
   * Style class of the indicators.
   * @group Props
   */
  indicatorStyleClass = '';
  /**
   * Style of the indicators.
   * @group Props
   */
  indicatorStyle;
  /**
   * An array of objects to display.
   * @defaultValue null
   * @group Props
   */
  get value() {
    return this._value;
  }
  set value(val) {
    this._value = val;
  }
  /**
   * Defines if scrolling would be infinite.
   * @group Props
   */
  circular = false;
  /**
   * Whether to display indicator container.
   * @group Props
   */
  showIndicators = true;
  /**
   * Whether to display navigation buttons in container.
   * @group Props
   */
  showNavigators = true;
  /**
   * Time in milliseconds to scroll items automatically.
   * @group Props
   */
  autoplayInterval = 0;
  /**
   * Inline style of the component.
   * @group Props
   */
  style;
  /**
   * Style class of the viewport container.
   * @group Props
   */
  styleClass;
  /**
   * Used to pass all properties of the ButtonProps to the Button component.
   * @group Props
   */
  prevButtonProps = {
    severity: 'secondary',
    text: true,
    rounded: true
  };
  /**
   * Used to pass all properties of the ButtonProps to the Button component.
   * @group Props
   */
  nextButtonProps = {
    severity: 'secondary',
    text: true,
    rounded: true
  };
  /**
   * Callback to invoke after scroll.
   * @param {CarouselPageEvent} event - Custom page event.
   * @group Emits
   */
  onPage = new EventEmitter();
  itemsContainer;
  indicatorContent;
  headerFacet;
  footerFacet;
  _numVisible = 1;
  _numScroll = 1;
  _oldNumScroll = 0;
  prevState = {
    numScroll: 0,
    numVisible: 0,
    value: []
  };
  defaultNumScroll = 1;
  defaultNumVisible = 1;
  _page = 0;
  _value;
  carouselStyle;
  id;
  totalShiftedItems;
  isRemainingItemsAdded = false;
  animationTimeout;
  translateTimeout;
  remainingItems = 0;
  _items;
  startPos;
  documentResizeListener;
  clonedItemsForStarting;
  clonedItemsForFinishing;
  allowAutoplay;
  interval;
  isCreated;
  swipeThreshold = 20;
  /**
   * Template for carousel items.
   * @group Templates
   */
  itemTemplate;
  /**
   * Template for the carousel header.
   * @group Templates
   */
  headerTemplate;
  /**
   * Template for the carousel footer.
   * @group Templates
   */
  footerTemplate;
  /**
   * Template for the previous button icon.
   * @group Templates
   */
  previousIconTemplate;
  /**
   * Template for the next button icon.
   * @group Templates
   */
  nextIconTemplate;
  _itemTemplate;
  _headerTemplate;
  _footerTemplate;
  _previousIconTemplate;
  _nextIconTemplate;
  window;
  _componentStyle = inject(CarouselStyle);
  constructor(el, zone) {
    super();
    this.el = el;
    this.zone = zone;
    this.totalShiftedItems = this.page * this.numScroll * -1;
    this.window = this.document.defaultView;
  }
  ngOnChanges(simpleChange) {
    if (isPlatformBrowser(this.platformId)) {
      if (simpleChange.value) {
        if (this.circular && this._value) {
          this.setCloneItems();
        }
      }
      if (this.isCreated) {
        if (simpleChange.numVisible) {
          if (this.responsiveOptions) {
            this.defaultNumVisible = this.numVisible;
          }
          if (this.isCircular()) {
            this.setCloneItems();
          }
          this.createStyle();
          this.calculatePosition();
        }
        if (simpleChange.numScroll) {
          if (this.responsiveOptions) {
            this.defaultNumScroll = this.numScroll;
          }
        }
      }
    }
    this.cd.markForCheck();
  }
  templates;
  ngAfterContentInit() {
    this.id = uuid('pn_id_');
    if (isPlatformBrowser(this.platformId)) {
      this.allowAutoplay = !!this.autoplayInterval;
      if (this.circular) {
        this.setCloneItems();
      }
      if (this.responsiveOptions) {
        this.defaultNumScroll = this._numScroll;
        this.defaultNumVisible = this._numVisible;
      }
      this.createStyle();
      this.calculatePosition();
      if (this.responsiveOptions) {
        this.bindDocumentListeners();
      }
    }
    this.templates?.forEach(item => {
      switch (item.getType()) {
        case 'item':
          this._itemTemplate = item.template;
          break;
        case 'header':
          this._headerTemplate = item.template;
          break;
        case 'footer':
          this._footerTemplate = item.template;
          break;
        case 'previousicon':
          this._previousIconTemplate = item.template;
          break;
        case 'nexticon':
          this._nextIconTemplate = item.template;
          break;
        default:
          this._itemTemplate = item.template;
          break;
      }
    });
    this.cd.detectChanges();
  }
  ngAfterContentChecked() {
    if (isPlatformBrowser(this.platformId)) {
      const isCircular = this.isCircular();
      let totalShiftedItems = this.totalShiftedItems;
      if (this.value && this.itemsContainer && (this.prevState.numScroll !== this._numScroll || this.prevState.numVisible !== this._numVisible || this.prevState.value.length !== this.value.length)) {
        if (this.autoplayInterval) {
          this.stopAutoplay(false);
        }
        this.remainingItems = (this.value.length - this._numVisible) % this._numScroll;
        let page = this._page;
        if (this.totalDots() !== 0 && page >= this.totalDots()) {
          page = this.totalDots() - 1;
          this._page = page;
          this.onPage.emit({
            page: this.page
          });
        }
        totalShiftedItems = page * this._numScroll * -1;
        if (isCircular) {
          totalShiftedItems -= this._numVisible;
        }
        if (page === this.totalDots() - 1 && this.remainingItems > 0) {
          totalShiftedItems += -1 * this.remainingItems + this._numScroll;
          this.isRemainingItemsAdded = true;
        } else {
          this.isRemainingItemsAdded = false;
        }
        if (totalShiftedItems !== this.totalShiftedItems) {
          this.totalShiftedItems = totalShiftedItems;
        }
        this._oldNumScroll = this._numScroll;
        this.prevState.numScroll = this._numScroll;
        this.prevState.numVisible = this._numVisible;
        this.prevState.value = [...this._value];
        if (this.totalDots() > 0 && this.itemsContainer.nativeElement) {
          this.itemsContainer.nativeElement.style.transform = this.isVertical() ? `translate3d(0, ${totalShiftedItems * (100 / this._numVisible)}%, 0)` : `translate3d(${totalShiftedItems * (100 / this._numVisible)}%, 0, 0)`;
        }
        this.isCreated = true;
        if (this.autoplayInterval && this.isAutoplay()) {
          this.startAutoplay();
        }
      }
      if (isCircular) {
        if (this.page === 0) {
          totalShiftedItems = -1 * this._numVisible;
        } else if (totalShiftedItems === 0) {
          totalShiftedItems = -1 * this.value.length;
          if (this.remainingItems > 0) {
            this.isRemainingItemsAdded = true;
          }
        }
        if (totalShiftedItems !== this.totalShiftedItems) {
          this.totalShiftedItems = totalShiftedItems;
        }
      }
    }
  }
  createStyle() {
    if (!this.carouselStyle) {
      this.carouselStyle = this.renderer.createElement('style');
      this.carouselStyle.type = 'text/css';
      setAttribute(this.carouselStyle, 'nonce', this.config?.csp()?.nonce);
      this.renderer.appendChild(this.document.head, this.carouselStyle);
    }
    let innerHTML = `
            #${this.id} .p-carousel-item {
				flex: 1 0 ${100 / this.numVisible}%
			}
        `;
    if (this.responsiveOptions) {
      this.responsiveOptions.sort((data1, data2) => {
        const value1 = data1.breakpoint;
        const value2 = data2.breakpoint;
        let result = null;
        if (value1 == null && value2 != null) result = -1;else if (value1 != null && value2 == null) result = 1;else if (value1 == null && value2 == null) result = 0;else if (typeof value1 === 'string' && typeof value2 === 'string') result = value1.localeCompare(value2, undefined, {
          numeric: true
        });else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
        return -1 * result;
      });
      for (let i = 0; i < this.responsiveOptions.length; i++) {
        let res = this.responsiveOptions[i];
        innerHTML += `
                    @media screen and (max-width: ${res.breakpoint}) {
                        #${this.id} .p-carousel-item {
                            flex: 1 0 ${100 / res.numVisible}%
                        }
                    }
                `;
      }
    }
    this.carouselStyle.innerHTML = innerHTML;
  }
  calculatePosition() {
    if (this.responsiveOptions) {
      let matchedResponsiveData = {
        numVisible: this.defaultNumVisible,
        numScroll: this.defaultNumScroll
      };
      if (typeof window !== 'undefined') {
        let windowWidth = window.innerWidth;
        for (let i = 0; i < this.responsiveOptions.length; i++) {
          let res = this.responsiveOptions[i];
          if (parseInt(res.breakpoint, 10) >= windowWidth) {
            matchedResponsiveData = res;
          }
        }
      }
      if (this._numScroll !== matchedResponsiveData.numScroll) {
        let page = this._page;
        page = Math.floor(page * this._numScroll / matchedResponsiveData.numScroll);
        let totalShiftedItems = matchedResponsiveData.numScroll * this.page * -1;
        if (this.isCircular()) {
          totalShiftedItems -= matchedResponsiveData.numVisible;
        }
        this.totalShiftedItems = totalShiftedItems;
        this._numScroll = matchedResponsiveData.numScroll;
        this._page = page;
        this.onPage.emit({
          page: this.page
        });
      }
      if (this._numVisible !== matchedResponsiveData.numVisible) {
        this._numVisible = matchedResponsiveData.numVisible;
        this.setCloneItems();
      }
      this.cd.markForCheck();
    }
  }
  setCloneItems() {
    this.clonedItemsForStarting = [];
    this.clonedItemsForFinishing = [];
    if (this.isCircular()) {
      this.clonedItemsForStarting.push(...this.value.slice(-1 * this._numVisible));
      this.clonedItemsForFinishing.push(...this.value.slice(0, this._numVisible));
    }
  }
  firstIndex() {
    return this.isCircular() ? -1 * (this.totalShiftedItems + this.numVisible) : this.totalShiftedItems * -1;
  }
  lastIndex() {
    return this.firstIndex() + this.numVisible - 1;
  }
  totalDots() {
    return this.value?.length ? Math.ceil((this.value.length - this._numVisible) / this._numScroll) + 1 : 0;
  }
  totalDotsArray() {
    const totalDots = this.totalDots();
    return totalDots <= 0 ? [] : Array(totalDots).fill(0);
  }
  isVertical() {
    return this.orientation === 'vertical';
  }
  isCircular() {
    return this.circular && this.value && this.value.length >= this.numVisible;
  }
  isAutoplay() {
    return this.autoplayInterval && this.allowAutoplay;
  }
  isForwardNavDisabled() {
    return this.isEmpty() || this._page >= this.totalDots() - 1 && !this.isCircular();
  }
  isBackwardNavDisabled() {
    return this.isEmpty() || this._page <= 0 && !this.isCircular();
  }
  isEmpty() {
    return !this.value || this.value.length === 0;
  }
  navForward(e, index) {
    if (this.isCircular() || this._page < this.totalDots() - 1) {
      this.step(-1, index);
    }
    if (this.autoplayInterval) {
      this.stopAutoplay();
    }
    if (e && e.cancelable) {
      e.preventDefault();
    }
  }
  navBackward(e, index) {
    if (this.isCircular() || this._page !== 0) {
      this.step(1, index);
    }
    if (this.autoplayInterval) {
      this.stopAutoplay();
    }
    if (e && e.cancelable) {
      e.preventDefault();
    }
  }
  onDotClick(e, index) {
    let page = this._page;
    if (this.autoplayInterval) {
      this.stopAutoplay();
    }
    if (index > page) {
      this.navForward(e, index);
    } else if (index < page) {
      this.navBackward(e, index);
    }
  }
  onIndicatorKeydown(event) {
    switch (event.code) {
      case 'ArrowRight':
        this.onRightKey();
        break;
      case 'ArrowLeft':
        this.onLeftKey();
        break;
    }
  }
  onRightKey() {
    const indicators = [...find(this.indicatorContent.nativeElement, '[data-pc-section="indicator"]')];
    const activeIndex = this.findFocusedIndicatorIndex();
    this.changedFocusedIndicator(activeIndex, activeIndex + 1 === indicators.length ? indicators.length - 1 : activeIndex + 1);
  }
  onLeftKey() {
    const activeIndex = this.findFocusedIndicatorIndex();
    this.changedFocusedIndicator(activeIndex, activeIndex - 1 <= 0 ? 0 : activeIndex - 1);
  }
  onHomeKey() {
    const activeIndex = this.findFocusedIndicatorIndex();
    this.changedFocusedIndicator(activeIndex, 0);
  }
  onEndKey() {
    const indicators = [...find(this.indicatorContent.nativeElement, '[data-pc-section="indicator"]r')];
    const activeIndex = this.findFocusedIndicatorIndex();
    this.changedFocusedIndicator(activeIndex, indicators.length - 1);
  }
  onTabKey() {
    const indicators = [...find(this.indicatorContent.nativeElement, '[data-pc-section="indicator"]')];
    const highlightedIndex = indicators.findIndex(ind => getAttribute(ind, 'data-p-highlight') === true);
    const activeIndicator = findSingle(this.indicatorContent.nativeElement, '[data-pc-section="indicator"] > button[tabindex="0"]');
    const activeIndex = indicators.findIndex(ind => ind === activeIndicator.parentElement);
    indicators[activeIndex].children[0].tabIndex = '-1';
    indicators[highlightedIndex].children[0].tabIndex = '0';
  }
  findFocusedIndicatorIndex() {
    const indicators = [...find(this.indicatorContent.nativeElement, '[data-pc-section="indicator"]')];
    const activeIndicator = findSingle(this.indicatorContent.nativeElement, '[data-pc-section="indicator"] > button[tabindex="0"]');
    return indicators.findIndex(ind => ind === activeIndicator.parentElement);
  }
  changedFocusedIndicator(prevInd, nextInd) {
    const indicators = [...find(this.indicatorContent.nativeElement, '[data-pc-section="indicator"]')];
    indicators[prevInd].children[0].tabIndex = '-1';
    indicators[nextInd].children[0].tabIndex = '0';
    indicators[nextInd].children[0].focus();
  }
  step(dir, page) {
    let totalShiftedItems = this.totalShiftedItems;
    const isCircular = this.isCircular();
    if (page != null) {
      totalShiftedItems = this._numScroll * page * -1;
      if (isCircular) {
        totalShiftedItems -= this._numVisible;
      }
      this.isRemainingItemsAdded = false;
    } else {
      totalShiftedItems += this._numScroll * dir;
      if (this.isRemainingItemsAdded) {
        totalShiftedItems += this.remainingItems - this._numScroll * dir;
        this.isRemainingItemsAdded = false;
      }
      let originalShiftedItems = isCircular ? totalShiftedItems + this._numVisible : totalShiftedItems;
      page = Math.abs(Math.floor(originalShiftedItems / this._numScroll));
    }
    if (isCircular && this.page === this.totalDots() - 1 && dir === -1) {
      totalShiftedItems = -1 * (this.value.length + this._numVisible);
      page = 0;
    } else if (isCircular && this.page === 0 && dir === 1) {
      totalShiftedItems = 0;
      page = this.totalDots() - 1;
    } else if (page === this.totalDots() - 1 && this.remainingItems > 0) {
      totalShiftedItems += this.remainingItems * -1 - this._numScroll * dir;
      this.isRemainingItemsAdded = true;
    }
    if (this.itemsContainer) {
      this.itemsContainer.nativeElement.style.transform = this.isVertical() ? `translate3d(0, ${totalShiftedItems * (100 / this._numVisible)}%, 0)` : `translate3d(${totalShiftedItems * (100 / this._numVisible)}%, 0, 0)`;
      this.itemsContainer.nativeElement.style.transition = 'transform 500ms ease 0s';
    }
    this.totalShiftedItems = totalShiftedItems;
    this._page = page;
    this.onPage.emit({
      page: this.page
    });
    this.cd.markForCheck();
  }
  startAutoplay() {
    this.interval = setInterval(() => {
      if (this.totalDots() > 0) {
        if (this.page === this.totalDots() - 1) {
          this.step(-1, 0);
        } else {
          this.step(-1, this.page + 1);
        }
      }
    }, this.autoplayInterval);
    this.allowAutoplay = true;
    this.cd.markForCheck();
  }
  stopAutoplay(changeAllow = true) {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
      if (changeAllow) {
        this.allowAutoplay = false;
      }
    }
    this.cd.markForCheck();
  }
  isPlaying() {
    return !!this.interval;
  }
  onTransitionEnd() {
    if (this.itemsContainer) {
      this.itemsContainer.nativeElement.style.transition = '';
      if ((this.page === 0 || this.page === this.totalDots() - 1) && this.isCircular()) {
        this.itemsContainer.nativeElement.style.transform = this.isVertical() ? `translate3d(0, ${this.totalShiftedItems * (100 / this._numVisible)}%, 0)` : `translate3d(${this.totalShiftedItems * (100 / this._numVisible)}%, 0, 0)`;
      }
    }
  }
  onTouchStart(e) {
    let touchobj = e.changedTouches[0];
    this.startPos = {
      x: touchobj.pageX,
      y: touchobj.pageY
    };
  }
  onTouchMove(e) {
    if (e.cancelable) {
      e.preventDefault();
    }
  }
  onTouchEnd(e) {
    let touchobj = e.changedTouches[0];
    if (this.isVertical()) {
      this.changePageOnTouch(e, touchobj.pageY - this.startPos.y);
    } else {
      this.changePageOnTouch(e, touchobj.pageX - this.startPos.x);
    }
  }
  changePageOnTouch(e, diff) {
    if (Math.abs(diff) > this.swipeThreshold) {
      if (diff < 0) {
        this.navForward(e);
      } else {
        this.navBackward(e);
      }
    }
  }
  ariaPrevButtonLabel() {
    return this.config.translation.aria ? this.config.translation.aria.prevPageLabel : undefined;
  }
  ariaSlideLabel() {
    return this.config.translation.aria ? this.config.translation.aria.slide : undefined;
  }
  ariaNextButtonLabel() {
    return this.config.translation.aria ? this.config.translation.aria.nextPageLabel : undefined;
  }
  ariaSlideNumber(value) {
    return this.config.translation.aria ? this.config.translation.aria.slideNumber.replace(/{slideNumber}/g, value) : undefined;
  }
  ariaPageLabel(value) {
    return this.config.translation.aria ? this.config.translation.aria.pageLabel.replace(/{page}/g, value) : undefined;
  }
  bindDocumentListeners() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.documentResizeListener) {
        this.documentResizeListener = this.renderer.listen(this.window, 'resize', event => {
          this.calculatePosition();
        });
      }
    }
  }
  unbindDocumentListeners() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.documentResizeListener) {
        this.documentResizeListener();
        this.documentResizeListener = null;
      }
    }
  }
  ngOnDestroy() {
    if (this.responsiveOptions) {
      this.unbindDocumentListeners();
    }
    if (this.autoplayInterval) {
      this.stopAutoplay();
    }
  }
  static ɵfac = function Carousel_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || Carousel)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: Carousel,
    selectors: [["p-carousel"]],
    contentQueries: function Carousel_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, Header, 5);
        i0.ɵɵcontentQuery(dirIndex, Footer, 5);
        i0.ɵɵcontentQuery(dirIndex, _c0, 4);
        i0.ɵɵcontentQuery(dirIndex, _c1, 4);
        i0.ɵɵcontentQuery(dirIndex, _c2, 4);
        i0.ɵɵcontentQuery(dirIndex, _c3, 4);
        i0.ɵɵcontentQuery(dirIndex, _c4, 4);
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.headerFacet = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.footerFacet = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.itemTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.headerTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.footerTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.previousIconTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.nextIconTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    viewQuery: function Carousel_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c5, 5);
        i0.ɵɵviewQuery(_c6, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.itemsContainer = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.indicatorContent = _t.first);
      }
    },
    inputs: {
      page: "page",
      numVisible: "numVisible",
      numScroll: "numScroll",
      responsiveOptions: "responsiveOptions",
      orientation: "orientation",
      verticalViewPortHeight: "verticalViewPortHeight",
      contentClass: "contentClass",
      indicatorsContentClass: "indicatorsContentClass",
      indicatorsContentStyle: "indicatorsContentStyle",
      indicatorStyleClass: "indicatorStyleClass",
      indicatorStyle: "indicatorStyle",
      value: "value",
      circular: [2, "circular", "circular", booleanAttribute],
      showIndicators: [2, "showIndicators", "showIndicators", booleanAttribute],
      showNavigators: [2, "showNavigators", "showNavigators", booleanAttribute],
      autoplayInterval: [2, "autoplayInterval", "autoplayInterval", numberAttribute],
      style: "style",
      styleClass: "styleClass",
      prevButtonProps: "prevButtonProps",
      nextButtonProps: "nextButtonProps"
    },
    outputs: {
      onPage: "onPage"
    },
    features: [i0.ɵɵProvidersFeature([CarouselStyle]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature],
    ngContentSelectors: _c8,
    decls: 14,
    vars: 23,
    consts: [["itemsContainer", ""], ["icon", ""], ["indicatorContent", ""], ["role", "region", 3, "ngClass", "ngStyle"], ["class", "p-carousel-header", 4, "ngIf"], [3, "ngClass"], [1, "p-carousel-content"], [3, "ngClass", "disabled", "text", "buttonProps", "click", 4, "ngIf"], [1, "p-carousel-viewport", 3, "touchend", "touchstart", "touchmove", "ngStyle"], [1, "p-carousel-item-list", 3, "transitionend"], [3, "ngClass", 4, "ngFor", "ngForOf"], ["type", "button", 3, "ngClass", "disabled", "buttonProps", "text", "click", 4, "ngIf"], [3, "ngClass", "class", "ngStyle", "keydown", 4, "ngIf"], ["class", "p-carousel-footer", 4, "ngIf"], [1, "p-carousel-header"], [4, "ngTemplateOutlet"], [3, "click", "ngClass", "disabled", "text", "buttonProps"], [4, "ngIf"], ["class", "p-carousel-prev-icon", 4, "ngIf"], [3, "styleClass", 4, "ngIf"], [3, "styleClass"], [1, "p-carousel-prev-icon"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], ["type", "button", 3, "click", "ngClass", "disabled", "buttonProps", "text"], ["class", "next", 4, "ngIf"], [1, "next"], [3, "keydown", "ngClass", "ngStyle"], ["type", "button", 3, "click", "ngClass", "ngStyle", "tabindex"], [1, "p-carousel-footer"]],
    template: function Carousel_Template(rf, ctx) {
      if (rf & 1) {
        const _r1 = i0.ɵɵgetCurrentView();
        i0.ɵɵprojectionDef(_c7);
        i0.ɵɵelementStart(0, "div", 3);
        i0.ɵɵtemplate(1, Carousel_div_1_Template, 3, 1, "div", 4);
        i0.ɵɵelementStart(2, "div", 5)(3, "div", 6);
        i0.ɵɵtemplate(4, Carousel_p_button_4_Template, 3, 7, "p-button", 7);
        i0.ɵɵelementStart(5, "div", 8);
        i0.ɵɵlistener("touchend", function Carousel_Template_div_touchend_5_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onTouchEnd($event));
        })("touchstart", function Carousel_Template_div_touchstart_5_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onTouchStart($event));
        })("touchmove", function Carousel_Template_div_touchmove_5_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onTouchMove($event));
        });
        i0.ɵɵelementStart(6, "div", 9, 0);
        i0.ɵɵlistener("transitionend", function Carousel_Template_div_transitionend_6_listener() {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onTransitionEnd());
        });
        i0.ɵɵtemplate(8, Carousel_div_8_Template, 2, 12, "div", 10)(9, Carousel_div_9_Template, 2, 12, "div", 10)(10, Carousel_div_10_Template, 2, 9, "div", 10);
        i0.ɵɵelementEnd()();
        i0.ɵɵtemplate(11, Carousel_p_button_11_Template, 3, 7, "p-button", 11);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(12, Carousel_ul_12_Template, 3, 5, "ul", 12);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(13, Carousel_div_13_Template, 3, 1, "div", 13);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵclassMap(ctx.styleClass);
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(18, _c9, ctx.isVertical(), !ctx.isVertical()))("ngStyle", ctx.style);
        i0.ɵɵattribute("id", ctx.id);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.headerFacet || ctx.headerTemplate);
        i0.ɵɵadvance();
        i0.ɵɵclassMap(ctx.contentClass);
        i0.ɵɵproperty("ngClass", "p-carousel-content-container");
        i0.ɵɵadvance();
        i0.ɵɵattribute("aria-live", ctx.allowAutoplay ? "polite" : "off");
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.showNavigators);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(21, _c10, ctx.isVertical() ? ctx.verticalViewPortHeight : "auto"));
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngForOf", ctx.clonedItemsForStarting);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngForOf", ctx.value);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngForOf", ctx.clonedItemsForFinishing);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.showNavigators);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.showIndicators);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.footerFacet || ctx.footerTemplate || ctx._footerTemplate);
      }
    },
    dependencies: [CommonModule, i1.NgClass, i1.NgForOf, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle, ChevronRightIcon, ButtonModule, i2.Button, ChevronLeftIcon, ChevronDownIcon, ChevronUpIcon, SharedModule],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Carousel, [{
    type: Component,
    args: [{
      selector: 'p-carousel',
      standalone: true,
      imports: [CommonModule, ChevronRightIcon, ButtonModule, ChevronLeftIcon, ChevronDownIcon, ChevronUpIcon, SharedModule],
      template: `
        <div [attr.id]="id" [ngClass]="{ 'p-carousel p-component': true, 'p-carousel-vertical': isVertical(), 'p-carousel-horizontal': !isVertical() }" [ngStyle]="style" [class]="styleClass" role="region">
            <div class="p-carousel-header" *ngIf="headerFacet || headerTemplate">
                <ng-content select="p-header"></ng-content>
                <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
            </div>
            <div [class]="contentClass" [ngClass]="'p-carousel-content-container'">
                <div class="p-carousel-content" [attr.aria-live]="allowAutoplay ? 'polite' : 'off'">
                    <p-button
                        *ngIf="showNavigators"
                        [ngClass]="{ 'p-carousel-prev-button': true, 'p-disabled': isBackwardNavDisabled() }"
                        [disabled]="isBackwardNavDisabled()"
                        [attr.aria-label]="ariaPrevButtonLabel()"
                        (click)="navBackward($event)"
                        [text]="true"
                        [buttonProps]="prevButtonProps"
                    >
                        <ng-template #icon>
                            <ng-container *ngIf="!previousIconTemplate && !_previousIconTemplate && !prevButtonProps?.icon">
                                <ChevronLeftIcon *ngIf="!isVertical()" [styleClass]="'carousel-prev-icon'" />
                                <ChevronUpIcon *ngIf="isVertical()" [styleClass]="'carousel-prev-icon'" />
                            </ng-container>
                            <span *ngIf="(previousIconTemplate || _previousIconTemplate) && !prevButtonProps?.icon" class="p-carousel-prev-icon">
                                <ng-template *ngTemplateOutlet="previousIconTemplate || _previousIconTemplate"></ng-template>
                            </span>
                        </ng-template>
                    </p-button>
                    <div class="p-carousel-viewport" [ngStyle]="{ height: isVertical() ? verticalViewPortHeight : 'auto' }" (touchend)="onTouchEnd($event)" (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)">
                        <div #itemsContainer class="p-carousel-item-list" (transitionend)="onTransitionEnd()">
                            <div
                                *ngFor="let item of clonedItemsForStarting; let index = index"
                                [ngClass]="{
                                    'p-carousel-item p-carousel-item-clone': true,
                                    'p-carousel-item-active': totalShiftedItems * -1 === value.length,
                                    'p-carousel-item-start': 0 === index,
                                    'p-carousel-item-end': clonedItemsForStarting.length - 1 === index
                                }"
                                [attr.aria-hidden]="!(totalShiftedItems * -1 === value.length)"
                                [attr.aria-label]="ariaSlideNumber(index)"
                                [attr.aria-roledescription]="ariaSlideLabel()"
                            >
                                <ng-container *ngTemplateOutlet="itemTemplate || _itemTemplate; context: { $implicit: item }"></ng-container>
                            </div>
                            <div
                                *ngFor="let item of value; let index = index"
                                [ngClass]="{
                                    'p-carousel-item': true,
                                    'p-carousel-item-active': firstIndex() <= index && lastIndex() >= index,
                                    'p-carousel-item-start': firstIndex() === index,
                                    'p-carousel-item-end': lastIndex() === index
                                }"
                                [attr.aria-hidden]="!(totalShiftedItems * -1 === value.length)"
                                [attr.aria-label]="ariaSlideNumber(index)"
                                [attr.aria-roledescription]="ariaSlideLabel()"
                            >
                                <ng-container *ngTemplateOutlet="itemTemplate || _itemTemplate; context: { $implicit: item }"></ng-container>
                            </div>
                            <div
                                *ngFor="let item of clonedItemsForFinishing; let index = index"
                                [ngClass]="{
                                    'p-carousel-item p-carousel-item-clone': true,
                                    'p-carousel-item-active': totalShiftedItems * -1 === numVisible,
                                    'p-carousel-item-start': 0 === index,
                                    'p-carousel-item-end': clonedItemsForFinishing.length - 1 === index
                                }"
                            >
                                <ng-container *ngTemplateOutlet="itemTemplate || _itemTemplate; context: { $implicit: item }"></ng-container>
                            </div>
                        </div>
                    </div>
                    <p-button
                        type="button"
                        *ngIf="showNavigators"
                        [ngClass]="{ 'p-carousel-next-button': true, 'p-disabled': isForwardNavDisabled() }"
                        [disabled]="isForwardNavDisabled()"
                        (click)="navForward($event)"
                        [attr.aria-label]="ariaNextButtonLabel()"
                        [buttonProps]="nextButtonProps"
                        [text]="true"
                    >
                        <ng-template #icon>
                            <ng-container *ngIf="!nextIconTemplate && !_nextIconTemplate && !nextButtonProps?.icon">
                                <ChevronRightIcon *ngIf="!isVertical()" [styleClass]="'carousel-next-icon'" />
                                <ChevronDownIcon *ngIf="isVertical()" [styleClass]="'carousel-next-icon'" />
                            </ng-container>
                            <span *ngIf="nextIconTemplate || (_nextIconTemplate && !nextButtonProps?.icon)" class="next">
                                <ng-template *ngTemplateOutlet="nextIconTemplate || _nextIconTemplate"></ng-template>
                            </span>
                        </ng-template>
                    </p-button>
                </div>
                <ul #indicatorContent [ngClass]="'p-carousel-indicator-list'" [class]="indicatorsContentClass" [ngStyle]="indicatorsContentStyle" *ngIf="showIndicators" (keydown)="onIndicatorKeydown($event)">
                    <li *ngFor="let totalDot of totalDotsArray(); let i = index" [ngClass]="{ 'p-carousel-indicator': true, 'p-carousel-indicator-active': _page === i }" [attr.data-pc-section]="'indicator'">
                        <button
                            type="button"
                            [ngClass]="'p-carousel-indicator-button'"
                            (click)="onDotClick($event, i)"
                            [class]="indicatorStyleClass"
                            [ngStyle]="indicatorStyle"
                            [attr.aria-label]="ariaPageLabel(i + 1)"
                            [attr.aria-current]="_page === i ? 'page' : undefined"
                            [tabindex]="_page === i ? 0 : -1"
                        ></button>
                    </li>
                </ul>
            </div>
            <div class="p-carousel-footer" *ngIf="footerFacet || footerTemplate || _footerTemplate">
                <ng-content select="p-footer"></ng-content>
                <ng-container *ngTemplateOutlet="footerTemplate || _footerTemplate"></ng-container>
            </div>
        </div>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      providers: [CarouselStyle]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], {
    page: [{
      type: Input
    }],
    numVisible: [{
      type: Input
    }],
    numScroll: [{
      type: Input
    }],
    responsiveOptions: [{
      type: Input
    }],
    orientation: [{
      type: Input
    }],
    verticalViewPortHeight: [{
      type: Input
    }],
    contentClass: [{
      type: Input
    }],
    indicatorsContentClass: [{
      type: Input
    }],
    indicatorsContentStyle: [{
      type: Input
    }],
    indicatorStyleClass: [{
      type: Input
    }],
    indicatorStyle: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    circular: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    showIndicators: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    showNavigators: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    autoplayInterval: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    prevButtonProps: [{
      type: Input
    }],
    nextButtonProps: [{
      type: Input
    }],
    onPage: [{
      type: Output
    }],
    itemsContainer: [{
      type: ViewChild,
      args: ['itemsContainer']
    }],
    indicatorContent: [{
      type: ViewChild,
      args: ['indicatorContent']
    }],
    headerFacet: [{
      type: ContentChild,
      args: [Header]
    }],
    footerFacet: [{
      type: ContentChild,
      args: [Footer]
    }],
    itemTemplate: [{
      type: ContentChild,
      args: ['item', {
        descendants: false
      }]
    }],
    headerTemplate: [{
      type: ContentChild,
      args: ['header', {
        descendants: false
      }]
    }],
    footerTemplate: [{
      type: ContentChild,
      args: ['footer', {
        descendants: false
      }]
    }],
    previousIconTemplate: [{
      type: ContentChild,
      args: ['previousicon', {
        descendants: false
      }]
    }],
    nextIconTemplate: [{
      type: ContentChild,
      args: ['nexticon', {
        descendants: false
      }]
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
class CarouselModule {
  static ɵfac = function CarouselModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CarouselModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: CarouselModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [Carousel, SharedModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CarouselModule, [{
    type: NgModule,
    args: [{
      imports: [Carousel, SharedModule],
      exports: [Carousel, SharedModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Carousel, CarouselClasses, CarouselModule, CarouselStyle };
