<div class="board-main-wrapper">
    <div class="board-main-header hide-in-mobile">
        <h4>Alice Hasnolice - Logo Design Project</h4>
        <p>Due in 14 days</p>
    </div>

    <!-- Drag and drop section -->
    <div class="board">
        <div *ngFor="let list of lists" class="list">
            <h3>{{ list.name }}</h3>
            <div pDroppable (onDrop)="drop(list)" class="droppable-area">
                <div *ngFor="let item of list.items" pDraggable (onDragStart)="dragStart(item, list)"
                    (onDragEnd)="dragEnd()" class="draggable-item">
                    {{ item.name }}
                    <p>{{item.description}}</p>
                </div>
            </div>
            <button class="add-client-button"><span>Add a client</span> <img
                src="../../../../../../assets/images/icons/add-icon-board.svg" alt=""></button>
        </div>
    </div>
</div>