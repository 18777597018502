import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor() { }

  getProductsData() {
    return [
      {
        id: '1',
        name: 'Web Development & Design',
        image: 'services-1.png'
      },
      {
        id: '2',
        name: 'Graphic Design',
        image: 'services-2.png'
      },
      {
        id: '3',
        name: 'Content Writing & Copywriting',
        image: 'services-3.png'
      },
      {
        id: '4',
        name: 'Digital Marketing',
        image: 'services-4.png'
      },
      {
        id: '5',
        name: 'Mobile App Development',
        image: 'services-5.png'
      },
      {
        id: '6',
        name: 'Virtual Assistance',
        image: 'services-6.png'
      },{
        id: '7',
        name: 'Web Development & Design',
        image: 'services-1.png'
      },
      {
        id: '8',
        name: 'Graphic Design',
        image: 'services-2.png'
      },
      {
        id: '9',
        name: 'Content Writing & Copywriting',
        image: 'services-3.png'
      },
      {
        id: '10',
        name: 'Digital Marketing',
        image: 'services-4.png'
      },
      {
        id: '11',
        name: 'Mobile App Development',
        image: 'services-5.png'
      },
      {
        id: '12',
        name: 'Virtual Assistance',
        image: 'services-6.png'
      }
    ];
  }


  getWorkedCompanyLogos(){
    return [
      {
        id: '1',
        image: 'company-logo-1.png'
      },
      {
        id: '2',
        image: 'company-logo-2.png'
      },
      {
        id: '3',
        image: 'company-logo-3.png'
      },
      {
        id: '4',
        image: 'company-logo-4.png'
      },
      {
        id: '5',
        image: 'company-logo-5.png'
      },
      {
        id: '6',
        image: 'company-logo-6.png'
      }, {
        id: '7',
        image: 'company-logo-6.png'
      },
      {
        id: '8',
        image: 'company-logo-4.png'
      },
      {
        id: '9',
        image: 'company-logo-5.png'
      },
      {
        id: '10',
        image: 'company-logo-2.png'
      },
      {
        id: '11',
        image: 'company-logo-3.png'
      },
      {
        id: '12',
        image: 'company-logo-1.png'
      }
    ];
  }

  getProductsSmall() {
    return Promise.resolve(this.getProductsData());
  }

  getWorkedCompanyLogo(){
    return Promise.resolve(this.getWorkedCompanyLogos());
  }
}
