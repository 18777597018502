<div class="privacy-and-sharing-wrapper">
    <div class="privacy-and-sharing-header hide-in-mobile">
        <h4>Payments & Payouts</h4>
    </div>

    <div class="tab-view-main-wrapper">
        <p-tabView>
            <p-tabPanel header="Client Payment History" class="client-payment-history">
                <h4 class="sub-main-title">Client Payment History</h4>
                <p>Keep track of all your payments and refunds.</p>

                <div class="payment-history-status">
                    <div class="status-wrapper active">
                        <h6>All</h6>
                        <p>2</p>
                    </div>
                    <div class="status-wrapper">
                        <h6>Succeeded</h6>
                        <p>2</p>
                    </div>
                    <div class="status-wrapper">
                        <h6>Incomplete</h6>
                        <p>2</p>
                    </div>
                    <div class="status-wrapper">
                        <h6>Refunded</h6>
                        <p>2</p>
                    </div>
                </div>

                <p-table class="hide-in-tablet hide-in-mobile" [value]="paymentsAll"
                    [tableStyle]="{'min-width': '60rem'}">
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="date">
                                Date <p-sortIcon field="date" />
                            </th>
                            <th pSortableColumn="clientName">
                                Client Name <p-sortIcon field="clientName" />
                            </th>
                            <th pSortableColumn="amount">
                                Amount <p-sortIcon field="amount" />
                            </th>
                            <th pSortableColumn="status">
                                Status <p-sortIcon field="status" />
                            </th>
                            <th>
                                Invoice
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-payment>
                        <tr>
                            <td>
                                <div class="table-body-text border-left-radius">{{ payment.date }}</div>
                            </td>
                            <td>
                                <div class="table-body-text">{{ payment.clientName }}</div>
                            </td>
                            <td>
                                <div class="table-body-text">{{ payment.amount }}</div>
                            </td>
                            <td>
                                <div class="table-body-text">{{ payment.status }}</div>
                            </td>
                            <td>
                                <div class="table-body-text border-right-radius">
                                    <div class="table-link-wrapper">
                                        <a href="#" class="table-links">View invoice</a>
                                        <a href="#" class="table-links">Copy link</a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>

                <div class="hide-in-desktop payment-history-outer-wrapper">
                    <div class="sort-wrapper payout-history-sorting">
                        <div class="input-wrapper">
                            <p-dropdown [options]="sortingOptions" optionLabel="name" class="country-wrapper">
                                <ng-template pTemplate="selectedItem">
                                    <div class="" *ngIf="selectedOptionSort">
                                        <div class="name-option-selected">Sort By : <span>{{
                                                selectedOptionSort.name }}</span></div>
                                    </div>
                                </ng-template>
                                <ng-template let-sortingOption pTemplate="item">
                                    <div class="name-option-items">{{ sortingOption.name }}</div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="mt-payment-history-outer-wrapper">
                        <div class="mt-payment-history-inner-wrapper">
                            <div class="mt-body-content">
                                <div class="mt-body-inner">
                                    <h5>Walter White</h5>
                                    <h6>11/04/24</h6>
                                </div>
                                <div class="mt-body-inner">
                                    <h6>$600</h6>
                                    <h6>Payment Received</h6>
                                </div>
                            </div>
                            <div class="mt-button-wrapper">
                                <button class="mt-transaction-button">View invoice</button>
                                <button class="mt-transaction-button">Copy link</button>
                            </div>
                        </div>
                        <div class="mt-payment-history-inner-wrapper">
                            <div class="mt-body-content">
                                <div class="mt-body-inner">
                                    <h5>Walter White</h5>
                                    <h6>11/04/24</h6>
                                </div>
                                <div class="mt-body-inner">
                                    <h6>$600</h6>
                                    <h6>Payment Received</h6>
                                </div>
                            </div>
                            <div class="mt-button-wrapper">
                                <button class="mt-transaction-button">View invoice</button>
                                <button class="mt-transaction-button">Copy link</button>
                            </div>
                        </div>
                    </div>
                </div>

            </p-tabPanel>
            <p-tabPanel header="Payouts">
                <div class="payouts-wrapper">
                    <div class="available-balance-wrapper">
                        <h5>Available Balance</h5>
                        <h2>$1850.00</h2>
                    </div>

                    <h4 class="sub-main-title">Payout Method</h4>
                    <p>Select how you want funds to be transferred to your Sri Lankan account.</p>

                    <div class="payment-method-type">
                        <div class="input-wrapper">
                            <div class="field-checkbox">
                                <div class="payment-method-content-wrapper">
                                    <label for="ingredient1" class="ml-2">
                                        Automated
                                    </label>
                                    <p>Your full available balance will be
                                        transferred to Sri Lanka every Friday.</p>
                                </div>
                                <div>
                                    <p-radioButton name="sex" value="Male" [(ngModel)]="ingredient"
                                        inputId="ingredient1" />
                                </div>
                            </div>
                            <div class="field-checkbox">
                                <div class="payment-method-content-wrapper">
                                    <label for="ingredient2" class="ml-2">
                                        Manual
                                    </label>
                                    <p>You choose the amount and date
                                        of transfer.</p>
                                </div>
                                <div>
                                    <p-radioButton name="sex" value="Female" [(ngModel)]="ingredient"
                                        inputId="ingredient2" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="manualTransfer">
                        <ng-template [ngTemplateOutlet]="manualTransferTemp"></ng-template>
                    </div>

                    <div *ngIf="sheduledPayments">
                        <ng-template [ngTemplateOutlet]="sheduledPaymentsTemp"></ng-template>
                    </div>

                    <h4 class="sub-main-title">Payout Account</h4>
                    <p class="payout-wrapper-desc">Add two payout accounts using our secure payment system.</p>

                    <div class="account-wrapper">
                        <div *ngFor="let account of accounts" class="inner-payout-main"
                            [ngClass]="{'default-account': account.isDefault}">
                            <div class="img-account-wrapper">
                                <img src="../../../../../assets/images/banks/bank-nsb.png" alt="">
                                <div>
                                    <p class="bank-account-no">{{ account.accountNo }}</p>
                                    <p class="default-account-text hide-in-desktop" *ngIf="account.isDefault">Default
                                        Account</p>
                                </div>
                            </div>
                            <div class="button-main-wrapper">
                                <div class="edit-delete-button-wrapper">
                                    <button class="delete-button-wrapper"><img
                                            src="../../../assets/images/icons/delete-bin-white.svg" alt=""></button>
                                    <button class="underline-button">Edit</button>
                                </div>
                                <div class="default-radio-wrapper hide-in-mobile hide-in-tablet">
                                    <label for="radio{{ account.id }}">Default</label>
                                    <p-radioButton [(ngModel)]="selectedAccountId" [value]="account.id"
                                        inputId="radio{{ account.id }}" class="custom-radio" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <button class="primary-1-button w-fit add-second-account">Add a second account</button>

                    <h4 class="sub-main-title edit-account-information" *ngIf="updateBankingInformation">Edit Account
                        Information</h4>
                    <p class="payout-wrapper-desc" *ngIf="updateBankingInformation">Edit your bank account information.
                    </p>


                    <div class="banking-information-wrapper" *ngIf="updateBankingInformation">
                        <!-- <h2 class="hide-in-desktop hide-in-tablet wrapper-title">Banking Information</h2> -->
                        <div class="input-wrapper">
                            <label for="" class="label-secondary">Name</label>
                            <h4>William Darcy <span class="tooltip-icon-wrapper"><img class="tooltip-icon"
                                        (click)="openPopup()" src="../../../assets/images/icons/info-icon-tooltip.png"
                                        alt=""></span></h4>
                        </div>

                        <div class="input-wrapper">
                            <label for="" class="label-secondary">Bank Name</label>
                            <p-dropdown [options]="banks" optionLabel="name" placeholder="Your Bank"
                                class="bank-name m-w100" />
                        </div>
                        <div class="input-wrapper">
                            <label for="" class="label-secondary">Branch Name</label>
                            <p-dropdown [options]="branchs" optionLabel="name" placeholder="Branch"
                                class="branch-name m-w100" />
                        </div>

                        <div class="input-wrapper branch-number-wrapper">
                            <label for="" class="label-secondary">Branch Number</label>
                            <p-dropdown [options]="cities" optionLabel="name" placeholder="Branch Number"
                                class="branch-number m-w60" />
                        </div>

                        <div class="input-wrapper">
                            <label for="" class="label-secondary">Account Number</label>
                            <div class="input-container account-number m-w100">
                                <img src="../../../assets/images/banks/bank-nsb.png" alt="icon" class="input-icon">
                                <input type="text" class="register input-with-image-left" placeholder="Enter text here">
                            </div>
                        </div>
                        <div class="input-wrapper">
                            <label for="" class="label-secondary">Re-enter Account Number</label>
                            <div class="input-container account-number m-w100">
                                <img src="../../../assets/images/banks/bank-nsb.png" alt="icon" class="input-icon">
                                <input type="text" class="register input-with-image-left" placeholder="Enter text here">
                            </div>
                        </div>

                        <div class="button-wrapper-step">
                            <button class="primary-1-button w-fit">Save</button>
                        </div>
                    </div>

                    <!-- payouts history  -->
                    <div class="payout-history-wrapper" *ngIf="showPayoutHistory">
                        <h4 class="sub-main-title">Payout History</h4>
                        <p>Keep track of all your payouts.</p>

                        <p-table class="hide-in-tablet hide-in-mobile" [value]="payoutHistory"
                            [tableStyle]="{'min-width': '60rem'}">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th pSortableColumn="date">
                                        Date <p-sortIcon field="date" />
                                    </th>
                                    <th pSortableColumn="payoutId">
                                        Payout ID <p-sortIcon field="payoutId" />
                                    </th>
                                    <th pSortableColumn="amount">
                                        Amount <p-sortIcon field="amount" />
                                    </th>
                                    <th>
                                        Receipt
                                    </th>
                                    <th pSortableColumn="bankAccount">
                                        Bank <p-sortIcon field="bankAccount" />
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-payhistory>
                                <tr>
                                    <td>
                                        <div class="table-body-text border-left-radius">{{ payhistory.date }}</div>
                                    </td>
                                    <td>
                                        <div class="table-body-text">{{ payhistory.payoutId }}</div>
                                    </td>
                                    <td>
                                        <div class="table-body-text">{{ payhistory.amount }}</div>
                                    </td>
                                    <td>
                                        <div class="table-body-text">
                                            <div class="table-link-wrapper">
                                                <a href="#" class="table-links">view</a>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="table-body-text border-right-radius">
                                            <div class="img-name-wrapper">
                                                <img src="../../../../../assets/images/temp/bank.svg" alt="">
                                                <h6 class="name-wrapper">{{payhistory.bankAccount}}</h6>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>

                        <div class="hide-in-desktop mobile-tablet-exisiting-client-wrapper">
                            <div class="sort-wrapper">
                                <div class="input-wrapper">
                                    <p-dropdown [options]="cities" optionLabel="name" placeholder="Your Country"
                                        class="country-wrapper" />
                                </div>
                            </div>
                            <div class="mt-exisiting-outer-wrapper">
                                <div class="mt-exisiting-inner-wrapper">
                                    <div class="mt-header-wrapper">
                                        <div class="name-image-wrapper">
                                            <h4>Citizens Development Business Fina...</h4>
                                        </div>
                                    </div>
                                    <div class="mt-body-content">
                                        <div class="mt-body-inner">
                                            <h6>xxxxxxx5678</h6>
                                            <h6>$100</h6>
                                        </div>
                                        <div class="mt-body-inner">
                                            <h6>11/04/24</h6>
                                            <button class="mt-transaction-button">View paystub</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-exisiting-inner-wrapper">
                                    <div class="mt-header-wrapper">
                                        <div class="name-image-wrapper">
                                            <h4>Citizens Development Business Fina...</h4>
                                        </div>
                                    </div>
                                    <div class="mt-body-content">
                                        <div class="mt-body-inner">
                                            <h6>xxxxxxx5678</h6>
                                            <h6>$100</h6>
                                        </div>
                                        <div class="mt-body-inner">
                                            <h6>11/04/24</h6>
                                            <button class="mt-transaction-button">View paystub</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>


<ng-template #manualTransferTemp>
    <div class="manual-transfer-wrapper">
        <div class="amount-wrapper">
            <p>How much do you want to transfer?</p>
            <div class="input-wrapper-main">
                <input type="text" class="price-input" placeholder="Price">
            </div>
        </div>

        <div class="calender-wrapper">
            <p>Select a Friday for your funds to be transferred</p>
            <p-calendar class="max-w-full" [inline]="true" [showWeek]="false" [firstDayOfWeek]='1'
                [disabledDates]="disabledDates" (onMonthChange)="onMonthChange($event)" />
        </div>
        <button class="primary-1-button w-fit add-second-account">Schedule Payment</button>
    </div>
</ng-template>


<ng-template #sheduledPaymentsTemp>
    <div class="sheduled-payment-wrapper">
        <h5 class="hide-in-tablet hide-in-mobile">Scheduled Payments</h5>
        <p class="hide-in-tablet">Only 3 scheduled payouts are allowed</p>
        <div class="shedule-payment-wrapper-outer">
            <div class="shedule-inner-wrapper">
                <div class="payment-wrapper">
                    <h4>$100 on 11/04/24</h4>
                </div>
                <div class="shedule-button-wrapper">
                    <div class="edit-delete-button-wrapper">
                        <button class="delete-button-wrapper">
                            <img src="../../../assets/images/icons/delete-bin-white.svg" alt=""></button>
                    </div>
                </div>
            </div>
            <div class="shedule-inner-wrapper">
                <div class="payment-wrapper">
                    <h4>$100 on 11/04/24</h4>
                </div>
                <div class="shedule-button-wrapper">
                    <div class="edit-delete-button-wrapper">
                        <button class="delete-button-wrapper">
                            <img src="../../../assets/images/icons/delete-bin-white.svg" alt=""></button>
                    </div>
                </div>
            </div>
        </div>


        <button class="primary-1-button w-fit add-second-account">Schedule another payment</button>
    </div>
</ng-template>