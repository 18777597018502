import { Component, Input } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { PasswordModule } from 'primeng/password';
import { InputTextModule } from 'primeng/inputtext';
import { CommonModule } from '@angular/common';
import { InputOtpModule } from 'primeng/inputotp';
import { RouterModule, Router } from '@angular/router';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UsersService } from '../../../users.service';
import { concatMap } from 'rxjs';

export enum LOGIN_FORM_MODE {
  REGISTER = 'REGISTER',
  LOGIN = 'LOGIN'
}

@Component({
  selector: 'app-loginmodal',
  standalone: true,
  imports: [DialogModule, ButtonModule, InputTextModule, CommonModule, InputOtpModule, PasswordModule, RouterModule, ReactiveFormsModule],
  templateUrl: './loginmodal.component.html',
  styleUrl: './loginmodal.component.scss'
})
export class LoginmodalComponent {
  @Input() visible: boolean = false;
  profileExtraVisible: boolean = false;
  extraNumber: number = 0;
  extraNumber2: number = 0;

  constructor(
    private router: Router,
    private usersService: UsersService
  ) { }

  LFM = LOGIN_FORM_MODE;

  rightPanelState: 'default' | 'emailLogin' | 'confirmEmailOtp' | 'profileCreateTemp' = 'default';
  profileExtraState: number = 1;

  loginForm: FormGroup = new FormGroup({
    mode: new FormControl(LOGIN_FORM_MODE.REGISTER, [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(8)]),
  });

  conformForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    code: new FormControl('', [Validators.required, Validators.minLength(6)]),
  });

  createPorfileForm: FormGroup = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    middleName: new FormControl(''),
    lastName: new FormControl('', [Validators.required]),
  });

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  profileShow() {
    this.profileExtraVisible = true;
  }

  profileHide() {
    this.profileExtraVisible = false;
  }

  showDefault() {
    this.rightPanelState = 'default';
  }

  showEmailLogin(mode?: LOGIN_FORM_MODE) {
    this.loginForm.patchValue({ mode: mode ?? LOGIN_FORM_MODE.REGISTER });
    this.rightPanelState = 'emailLogin';
  }

  showOtpTemplate() {
    this.rightPanelState = 'confirmEmailOtp';
  }

  showProfileCreateTemp() {
    this.rightPanelState = 'profileCreateTemp';
    this.profileExtraVisible = false;
    this.visible = true;
  }

  goToHome()
  {
    this.profileExtraVisible = false;
    this.visible = false;
    this.rightPanelState = "default"
    this.profileExtraState = 1
  }

  goBack() {
    this.rightPanelState = 'default';
  }

  createMyAccount() {
    this.visible = false;
    this.profileExtraVisible = true;
  }

  nextExtraFrame(profileExtraState:number){
    this.profileExtraState = profileExtraState;
  }

  prevExtraFrame(profileExtraState:number) {
    this.profileExtraState = profileExtraState;
  }

  backMainRegisterModal()
  {
    this.visible = true;
    this.profileExtraVisible = false;
    this.rightPanelState = 'profileCreateTemp';
  }

  completeSellerProfile() {
    this.visible = false;
    this.profileExtraVisible = false;
    document.body.classList.remove("p-overflow-hidden");
    this.router.navigate(['/register']);
  }

  extraClicked(clickedExtraNumber: number) {
    console.log(clickedExtraNumber)
    this.extraNumber = clickedExtraNumber;
  }

  extra2Clicked(clickedExtraNumber2: number) {
    this.extraNumber2 = clickedExtraNumber2;
  }


  onLoginSubmit() {
    if (this.loginForm.value.mode === LOGIN_FORM_MODE.REGISTER) {
      this.usersService.register(this.loginForm.value).subscribe(() => {
        this.conformForm.patchValue({ email: this.loginForm.value.email });
        this.showOtpTemplate();
      });
    } else {
      this.usersService.login(this.loginForm.value.email, this.loginForm.value.password).subscribe((res) => {
        // save token to local storage
        localStorage.setItem('auth_token', res.access_token);

        this.usersService.profile().subscribe((profile) => {

          if (!profile.verified) {
            this.conformForm.patchValue({ email: this.loginForm.value.email });
            this.showOtpTemplate();
            return;
          }

          console.log(profile.currentStep);

          switch (profile.currentStep) {
            case 'REGISTER':
              this.conformForm.patchValue({ email: this.loginForm.value.email });
              this.showOtpTemplate();
              break;
            case 'VERIFY':
              this.showProfileCreateTemp();
              break;
            case 'PROFILE_NAME':
              this.completeSellerProfile();
              break;
          }
        });
      });
    }
  }

  onConfirmEmailSubmit() {
    this.usersService.verifyEmail(this.loginForm.value.email, this.conformForm.value.code).pipe(
      concatMap(() => this.usersService.login(this.loginForm.value.email, this.loginForm.value.password))
    ).subscribe((res) => {
      // save token to local storage
      localStorage.setItem('auth_token', res.access_token);
      this.showProfileCreateTemp();
    })
  }

  onNameSubmit() {
    this.usersService.patch({
      name: {
        first: this.createPorfileForm.value.firstName,
        ...(this.createPorfileForm.value.middleName && { middle: this.createPorfileForm.value.middleName}),
        last: this.createPorfileForm.value.lastName,
      }
    }).subscribe(() => { this.completeSellerProfile() });
  }
}