import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ID_TYPES, User, UsersService } from '../../../user.service';
import { CountryList, Country } from '../../../data/country-list';

@Component({
  selector: 'app-account-personal',
  standalone: false,
  templateUrl: './personal.component.html',
  styleUrl: './personal.component.scss',
})
export class PersonalComponent implements OnInit, OnChanges {
  @Output() formSubmitted: EventEmitter<void> = new EventEmitter<void>();
  @Input() name: User['name'] | undefined;
  @Input() personal: User['personal'] | undefined;

  countries: Country[] = [];
  selectedCountry: string | undefined;
  idTypes = Object.values(ID_TYPES).filter(
    (value) => typeof value === 'string'
  );

  personalForm = new FormGroup({
    name: new FormGroup({
      first: new FormControl('', {
        validators: [Validators.required, Validators.minLength(5)],
      }),
      last: new FormControl('', {
        validators: [Validators.required],
      }),
      middle: new FormControl('', { validators: [] }),
    }),
    personal: new FormGroup({
      phone: new FormControl('', {
        validators: [Validators.required],
      }),
      country: new FormControl('', {
        validators: [Validators.required],
      }),
      sex: new FormControl('', {
        validators: [Validators.required],
      }),
      address1: new FormControl('', {
        validators: [Validators.required],
      }),
      address2: new FormControl('', { nonNullable: true }),
      city: new FormControl('', {
        validators: [Validators.required],
      }),
      postalCode: new FormControl('', {
        validators: [Validators.required],
      }),
      idType: new FormControl('NATIONAL_ID', {
        validators: [Validators.required],
      }),
      idNumber: new FormControl('', {
        validators: [Validators.required],
      }),
      tinOrPin: new FormControl('', { nonNullable: true }),
    }),
  });

  constructor(private readonly usersService: UsersService) {}

  ngOnInit() {
    this.countries = CountryList;
  }

  getIdFieldLabel(type: string | null | undefined): string {
    switch (type) {
      case 'NATIONAL_ID':
        return 'National ID Number';
      case 'PASSPORT':
        return 'Passport Number';
      case 'DRIVERS_LICENSE':
        return "Driver's License Number";
    }

    return 'Other';
  }

  getCountryCode(country?: string | null | undefined): string {
    const countryObj = this.countries.find((c) => c.name === country);
    return countryObj ? countryObj.mobileCode : '';
  }

  submit(): void {
    if (this.personalForm.valid) {
      this.usersService
        .patch({
          personal: this.personalForm.value.personal as User['personal'],
          name: this.personalForm.value.name as User['name'],
        })
        .subscribe(() => this.formSubmitted.emit());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['personal']) {
      this.personalForm.patchValue({ personal: this.personal });
    }

    if (changes['name']) {
      this.personalForm.patchValue({ name: this.name });
    }
  }
}
