import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedComponentsModule } from './shared-components/shared-components.module';
import { appConfig } from './app.config';
import { PublicModule } from './public/public.module';
import { FreelancerModule } from './freelancer/freelancer.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    ToastModule,
    AppRoutingModule,
    PublicModule,
    FreelancerModule,
    SharedComponentsModule,
  ],
  providers: [...appConfig.providers, MessageService],
  bootstrap: [AppComponent],
})
export class AppModule {}
