<div class="container-fluid user-profile">
    <app-profileheader class="hide-in-mobile"></app-profileheader>

    <!-- //customer profile main wrapper  -->
    <div class="customer-profile-main-wrapper">
        <div class="left-side hide-in-mobile">
            <div class="user-profile-tab-list">
                <ul>
                    <li class="comming-soon-back" [class.active]="currentStep === 'ac'" (click)="tabStep('ac')">
                        <div class="tab-title-wrapper">
                            <img [src]="'../../../assets/images/icons/public-profile/' + (currentStep === 'ac' ? 'analytics-white' : 'analytics-black') + '.svg'"
                                alt="Analytics">
                            <h6 [class.active]="currentStep === 'ac'">Analytics <small>(Coming Soon!)</small></h6>
                        </div>
                    </li>
                    <!-- board module  -->
                    <li class="first-content" (click)="boradClicked()">
                        <div class="tab-title-wrapper">
                            <img src="../../../assets/images/icons/public-profile/board-black.svg"
                                alt="">
                            <h6 [class.active]="currentStep === 'bd'">Boards</h6>
                        </div>
                        <div class="sub-title-wrapper" *ngIf="currentStep === 'bd'" [ngClass]="'board-active'">
                            <div class="sub-tab-title-wrapper" [class.active]="boradStep === 'lm'"
                                (click)="boradStepHandler('lm')">
                                <h6 [class.active]="boradStep === 'lm'">Lead Management</h6>
                            </div>
                            <div class="sub-tab-title-wrapper" [class.active]="boradStep === 'pm'"
                                (click)="boradStepHandler('pm')">
                                <h6 class="project-management-wrapper" [class.active]="boradStep === 'pm'"><span>Project Management</span> <img *ngIf="boradStep === 'pm'" src="../../../assets/images/icons/add-icon-board-white.svg" alt=""></h6>
                                <div class="projects-list" *ngIf="boradStep === 'pm'">
                                    <div class="project-main-title-wrapper" [class.active]="selectedProject === 'p1'">
                                        <div class="project-main-wrapper">
                                            <img [src]="'../../../assets/images/icons/' + (selectedProject === 'p1' ? 'project-list-white' : 'project-list-black') + '.svg'" alt=""><span>Alice Hasnolice</span>
                                        </div>
                                        <div class="expanded-project-list" *ngIf="selectedProject === 'p1'" [class.active]="selectedProject === 'p1'">
                                            <div class="project-list-inner active">Logo Design Project
                                                <div class="project-line"></div>
                                            </div>
                                            <div class="project-list-inner">Web Design Project
                                                <div class="project-line"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="project-main-title-wrapper" [class.active]="selectedProject === 'p2'">
                                        <div class="project-main-wrapper">
                                            <img [src]="'../../../assets/images/icons/' + (selectedProject === 'p2' ? 'project-list-white' : 'project-list-black') + '.svg'" alt=""><span>Alice Hasnolice</span>
                                        </div>
                                        <div class="expanded-project-list" *ngIf="selectedProject === 'p2'" [class.active]="selectedProject === 'p2'">
                                            <div class="project-list-inner">Logo Design Project</div>
                                            <div class="project-list-inner">Web Design Project</div>
                                        </div>
                                        <div class="project-line"></div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </li>
                    <!-- other modules -->
                    <li *ngFor="let leftSide of leftSideList" [class.active]="currentStep === leftSide.key"
                        [ngClass]="{ 'comming-soon-back': leftSide.isCommingSoon }" (click)="tabStep(leftSide.key)">
                        <div class="tab-title-wrapper">
                            <img [src]="'../../../assets/images/icons/public-profile/' + (currentStep === leftSide.key ? leftSide.imageWhite : leftSide.imageBlack) + '.svg'"
                                alt="{{ leftSide.name }}">
                            <h6 [class.active]="currentStep === leftSide.key">{{ leftSide.name }} <small
                                    *ngIf='leftSide.isCommingSoon'>(Coming Soon!)</small></h6>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="right-side">
            <div class="step-templates-wrapper">
                <div *ngIf="boradStep === 'lm' && currentStep === 'bd'">
                    <div class="">
                        <app-leadmanagement></app-leadmanagement>
                    </div>
                </div>
                <div *ngIf="boradStep === 'pm' && currentStep === 'bd'">
                    <div *ngIf="!projectSelected" class="">
                        <app-projectmanagement></app-projectmanagement>
                    </div>
                    <div *ngIf="projectSelected" class="">
                        <app-project-details></app-project-details>
                    </div>
                </div>

                <div [hidden]="currentStep !== 'cn'">
                    <div class="main-profile-wrapper-include">
                        <app-contract></app-contract>
                    </div>
                </div>

                <div [hidden]="currentStep !== 'epp'">
                    <div class="main-profile-wrapper-include">
                        <app-publicprofile></app-publicprofile>
                    </div>
                </div>
                
                
                
                <div [hidden]="currentStep !== 'lis'">
                    <div class="main-profile-wrapper-include">
                        <app-loginsecurity></app-loginsecurity>
                    </div>
                </div>
                <div [hidden]="currentStep !== 'ps'">
                    <div class="main-profile-wrapper-include">
                        <app-privacysharing></app-privacysharing>
                    </div>
                </div>

                <!-- put order correctly  -->
                <div [hidden]="currentStep !== 'mc'">
                    <div class="main-profile-wrapper-include">
                        <app-messages></app-messages>
                    </div>
                </div>

                <div [hidden]="currentStep !== 'pp'">
                    <div class="main-profile-wrapper-include">
                        <app-paymentspayouts></app-paymentspayouts>
                    </div>
                </div>

                <div [hidden]="currentStep !== 'inc'">
                    <div class="main-profile-wrapper-include">
                        <app-invoicesclients></app-invoicesclients>
                    </div>
                </div>

                <div [hidden]="currentStep !== 'gg'">
                    <div class="main-profile-wrapper-include">
                        <app-gig></app-gig>
                    </div>
                </div>

                <div [hidden]="currentStep !== 'pi'">
                    <div class="main-profile-wrapper-include">
                        <app-peronalinformation></app-peronalinformation>
                    </div>
                </div>

                

            </div>
        </div>
    </div>
</div>