import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

import { NavbarComponent } from './layouts/navbar/navbar.component';
import { UserNavigationComponent } from './layouts/user-navigation/user-navigation.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { LoginmodalComponent } from './shared/components/loginmodal/loginmodal.component';
import { CommonModule } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    NavbarComponent,
    FooterComponent,
    LoginmodalComponent,
    UserNavigationComponent
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'afterfive';
  checkNavigation: string = "main";
  isMobile: boolean = false;

  constructor(private deviceService: DeviceDetectorService, private router: Router) {
		const deviceInfo = this.deviceService.getDeviceInfo();
		this.isMobile = this.deviceService.isMobile();
    // console.log(this.deviceService)
	}

  ngOnInit() {
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const currentUrl = event.urlAfterRedirects;

      // Extract the static part of the URL
      const staticPart = currentUrl.split('/')[1]; // Get the first segment after '/'

      // Check if the static part matches "user"
      if (staticPart === 'user') {
        // console.log("mobile",this.isMobile)
        this.checkNavigation = 'userNavigation'; // Value when static part is "user"
      } else {
        this.checkNavigation = 'main'; // Default value for other routes
      }
    });
  }
}
