import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private http: HttpClient) { }

  getSingedUrl(file: File) {
    //remove spaces from file name and replace with dashes and lowercase the file name
    const fileName = uuidv4() + file.name.replace(/\s/g, '-').toLowerCase();

    return this.http.get(`/sign-s3?file=${fileName}`).pipe(
      map(({signedUrl}: any) => ({signedUrl, fileName}))
    );
  }

  uploadFile(file: File, signedUrl: string) {
    return this.http.put(signedUrl, file, {
      headers: {
        'Content-Type': file.type
      }
    });
  }
}
