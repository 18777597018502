<div class="main-wrapper">
    <h1 class="main-title">
        Contracts
    </h1>

    <div class="tab-view-main-wrapper">
        <p-tabView>
            <p-tabPanel header="Contract History">
                <ng-template [ngTemplateOutlet]="contractInfoTemp"></ng-template>
            </p-tabPanel>

        </p-tabView>
    </div>

</div>

<ng-template #contractInfoTemp>
    <div class="table-wrapper exisiting-client-wrapper">
        <p-table class="hide-in-tablet hide-in-mobile" [value]="contracts" [tableStyle]="{'min-width': '60rem'}">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="freelancer">
                        Freelancer <p-sortIcon field="freelancer" />
                    </th>
                    <th pSortableColumn="projectType">
                        Project Type <p-sortIcon field="projectType" />
                    </th>
                    <th pSortableColumn="dueDate">
                        Project Due Date <p-sortIcon field="dueDate" />
                    </th>
                    <th pSortableColumn="total">
                        Total <p-sortIcon field="dueDate" />
                    </th>
                    <th pSortableColumn="status">
                        Status <p-sortIcon field="status" />
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-contract>
                <tr>
                    <td>
                        <div class="table-body-text border-left-radius">
                            <h6 class="name-wrapper">{{contract.freelancer}}</h6>
                        </div>
                    </td>
                    <td>
                        <div class="table-body-text">{{ contract.projectType }}</div>
                    </td>
                    <td>
                        <div class="table-body-text">{{ contract.dueDate }}</div>
                    </td>
                    <td>
                        <div class="table-body-text">{{ contract.dueDate }}</div>
                    </td>
                    <td>
                        <div class="table-body-text border-right-radius">
                            <div *ngIf="contract.status == 'signed'" class="success-pill">Signed</div>
                            <div *ngIf="contract.status == 'pending'" class="pending-pill">Pending</div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>

    </div>
</ng-template>