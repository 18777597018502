import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginStatusService {
  private readonly loginDialogVisibilitySubject = new Subject<boolean>();
  loginDialogVisibility$ = this.loginDialogVisibilitySubject.asObservable();

  sendLoginDialogVisibility(data: boolean) {
    this.loginDialogVisibilitySubject.next(data);
  }
}
