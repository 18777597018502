import { Routes } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { CustomerprofileComponent } from './pages/customerprofile/customerprofile.component';
import { ClientregistrationComponent } from './pages/clientregistration/clientregistration.component';
import { ClientprofileComponent } from './pages/clientprofile/clientprofile.component';
export const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'register', component: RegistrationComponent},
    { path: 'user/:profile_id', component: CustomerprofileComponent },
    { path: 'client/register', component: ClientregistrationComponent },
    { path: 'client/:profile_id', component: ClientprofileComponent },
];
