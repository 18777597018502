import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User, UsersService } from '../../../user.service';
import { FileUploadHandlerEvent, UploadEvent } from 'primeng/fileupload';
import { FileUploadService } from '../../../file-upload.service';
import { environment } from '../../../../environments/environment';

type ProfileVerification = User['profileVerification'];

@Component({
  selector: 'app-account-verification',
  standalone: false,
  templateUrl: './verification.component.html',
  styleUrl: './verification.component.scss',
})
export class VerificationComponent implements OnChanges {
  @Output() formSubmitted: EventEmitter<void> = new EventEmitter<void>();
  @Output() goBack: EventEmitter<void> = new EventEmitter<void>();

  @Input() verification: ProfileVerification | undefined;

  verificationForm = new FormGroup({
    front: new FormControl('', [Validators.required]),
    back: new FormControl('', [Validators.required]),
    selfie: new FormControl('', [Validators.required]),
  });

  constructor(
    private readonly usersService: UsersService,
    private readonly fileUploadService: FileUploadService
  ) {}

  submit(): void {
    if (this.verificationForm.valid) {
      this.usersService
        .patch({
          profileVerification: this.verificationForm
            .value as ProfileVerification,
        })
        .subscribe(() => {
          this.formSubmitted.emit();
        });
    }
  }

  back(): void {
    this.goBack.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['verification']) {
      this.verificationForm.patchValue({ ...this.verification });
    }
  }

  uploadHandler(event: FileUploadHandlerEvent, control: string) {
    const file = event.files[0];
    this.fileUploadService
      .getSingedUrl(file)
      .subscribe(({ signedUrl, fileName }) => {
        this.fileUploadService.uploadFile(file, signedUrl).subscribe(() => {
          console.log(
            'File uploaded',
            control,
            `${environment.uploadBucket}${fileName}`
          );
          this.verificationForm.patchValue({
            [control]: `${environment.uploadBucket}${fileName}`,
          });
        });
      });
  }
}
