<div class="privacy-and-sharing-wrapper">
    <div class="privacy-and-sharing-header hide-in-mobile">
        <h4>Privacy & Sharing</h4>
    </div>

    <div class="tab-view-main-wrapper">
        <p-tabView>
            <p-tabPanel header="Data">
                <div class="data-wrapper">
                    <h4 class="hide-in-mobile">Manage Your Account Data</h4>
                    <div class="inner-wrapper">
                        <div class="inner-main-wrapper">
                            <div class="main-title-data">
                                <h5>Request your personal data</h5>
                                <img src="../../../../../assets/images/icons/public-profile/next-arrow-black.svg" alt="">
                            </div>
                            <p>We’ll create a file for you to download your personal data.</p>
                        </div>
                        <div class="inner-main-wrapper">
                            <div class="main-title-data">
                                <h5>Delete your account</h5>
                                <img src="../../../../../assets/images/icons/public-profile/next-arrow-black.svg" alt="">
                            </div>
                            <p>This will permanently delete your account and your data, in accordance with applicable
                                law.</p>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Sharing">
                <div class="data-wrapper sharing-data">
                    <div class="inner-wrapper">
                        <div class="inner-main-wrapper">
                            <div class="main-title-data">
                                <h5>Activity Sharing</h5>
                            </div>
                            <p>Decide how your profile and activity are shown to others.</p>
                        </div>
                        <div class="inner-main-wrapper">
                            <div class="main-title-data">
                                <h5>Message read receipts</h5>
                            </div>
                            <p>When this is on, we’ll show people that you’ve read their messages. <span><a href="#">Learn more</a></span></p>
                        </div>
                    </div>
                    <div class="input-wrapper last-initial-wrapper do-not-diplay-input-switch">
                        <p-inputSwitch />
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>