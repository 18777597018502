export const EducationLevels = [
    'Highschool',
    'Associate of Applied Science',
    'Associate of Arts',
    'Associate of Fine Arts',
    'Associate of Science',
    'Bachelor of Applied Science',
    'Bachelor of Arts',
    'Bachelor of Business Administration',
    'Bachelor of Fine Arts',
    'Bachelor of Science',
    'Doctor of Business Administration',
    'Doctor of Dental Surgery',
    'Doctor of Education',
    'Doctor of Medicine',
    'Doctor of Nursing Practice',
    'Doctor of Philosophy',
    'Doctor of Physical Therapy',
    'Doctor of Psychology',
    'Doctor of Veterinary Medicine',
    'Juris Doctor',
    'Master of Arts',
    'Master of Business Administration',
    'Master of Education',
    'Master of Fine Arts',
    'Master of Public Administration',
    'Master of Public Health',
    'Master of Science',
    'Master of Science in Nursing',
    'Master of Social Work'
];