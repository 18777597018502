import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User, UsersService } from '../../../user.service';
import { matchValidator } from '../../../shared-components/match-validator.directive';
import { Bank, BankList } from '../../../data/bank-list';
import { SelectChangeEvent } from 'primeng/select';

@Component({
  selector: 'app-account-banking',
  standalone: false,
  templateUrl: './banking.component.html',
  styleUrl: './banking.component.scss',
})
export class BankingComponent implements OnInit, OnChanges {
  @Output() formSubmitted: EventEmitter<void> = new EventEmitter<void>();
  @Output() goBack: EventEmitter<void> = new EventEmitter<void>();

  @Input() banking: User['banking'] | undefined;

  banks: Bank[] = [];
  branches: string[] = [];

  bankingForm = new FormGroup({
    bank: new FormControl('', {
      validators: [Validators.required],
    }),
    branch: new FormControl('', {
      validators: [Validators.required],
    }),
    branchNumber: new FormControl('', {
      validators: [Validators.required],
    }),
    accountNumber: new FormControl('', {
      validators: [Validators.required],
    }),
    accountNumberConfirm: new FormControl('', {
      validators: [Validators.required, matchValidator('accountNumber')],
    }),
  });

  constructor(private readonly usersService: UsersService) {}

  ngOnInit(): void {
    this.banks = BankList;
  }

  submit(): void {
    if (this.bankingForm.valid) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { accountNumberConfirm, ...banking } = this.bankingForm.value;
      this.usersService
        .patch({
          banking: banking as User['banking'],
        })
        .subscribe(() => {
          this.formSubmitted.emit();
        });
    }
  }

  back(): void {
    this.goBack.emit();
  }

  onBankChange(event: SelectChangeEvent): void {
    this.branches =
      BankList.find((bank) => bank.name === event.value)?.branches || [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['banking']) {
      this.onBankChange({ value: this.banking?.bank } as SelectChangeEvent);
      this.bankingForm.patchValue({
        ...this.banking,
        accountNumberConfirm: this.banking?.accountNumber,
      });
    }
  }
}
