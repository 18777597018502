import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginStatusService } from '../../core/login-status.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

export enum VIEW {
  LOGIN = 'login',
  REGISTER = 'register',
  FORGOT_PASSWORD = 'forgot-password',
  RESET_PASSWORD = 'reset-password',
  VERIFY_EMAIL = 'verify-email',
}

@Component({
  selector: 'app-login-register',
  standalone: false,
  templateUrl: './login-register.component.html',
  styleUrl: './login-register.component.scss',
})
export class LoginRegisterComponent implements OnDestroy, OnInit {
  visible = false;
  views: typeof VIEW = VIEW;
  currentView = VIEW.LOGIN;

  private readonly loginDialogVisibilitySubscription: Subscription;

  constructor(
    private readonly loginStatusService: LoginStatusService,
    public route: ActivatedRoute
  ) {
    this.loginDialogVisibilitySubscription =
      this.loginStatusService.loginDialogVisibility$.subscribe(
        (data: boolean) => {
          this.visible = data;
        }
      );
  }

  ngOnInit(): void {
    this.route.url.subscribe((params) => {
      if (params[0]?.path === 'login') {
        this.visible = true;
        this.currentView = VIEW.LOGIN;
      } else if (params[0]?.path === 'reset-password') {
        this.visible = true;
        this.currentView = VIEW.RESET_PASSWORD;
      }
    });
  }

  onViewChange(event: VIEW): void {
    this.currentView = event;
  }

  ngOnDestroy(): void {
    this.loginDialogVisibilitySubscription.unsubscribe();
  }
}
