<p-floatlabel variant="in">
  <input
    pInputText
    [attr.id]="inputId"
    autocomplete="off"
    [formControl]="inputControl"
    fluid="true"
    [class.ng-dirty]="inputControl.dirty"
    [class.ng-touched]="inputControl.touched"
    [class.ng-invalid]="inputControl.invalid"
  />
  <label [attr.for]="inputId">{{ label() }}</label>
</p-floatlabel>
