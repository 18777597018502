import { Component, EventEmitter, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { VIEW } from '../login-register/login-register.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersService } from '../../user.service';
import { matchValidator } from '../../shared-components/match-validator.directive';

@Component({
  selector: 'app-register',
  standalone: false,
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
})
export class RegisterComponent {
  @Output() viewChanged: EventEmitter<VIEW> = new EventEmitter<VIEW>();

  registerForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
    confirmPassword: new FormControl('', [
      Validators.required,
      matchValidator('password'),
    ]),
  });

  constructor(
    private readonly userService: UsersService,
    private readonly messageService: MessageService
  ) {}

  showLogin(): void {
    this.viewChanged.emit(VIEW.LOGIN);
  }

  register(): void {
    if (this.registerForm.valid) {
      this.userService
        .register({
          email: this.registerForm.value.email,
          password: this.registerForm.value.password,
        })
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail:
                'Registration successful. Please login to verify your account.',
            });
            this.showLogin();
          },
        });
    }
  }
}
