<div class="flex justify-center items-center">
  <div class="container">
    <div class="grid md:grid-cols-2 hero-banner">
      <div class="flex items-center justify-start text my-8">
        <div class="p-4">
          <h6 class="text-lg">Welcome to</h6>
          <div>
            <img
              class="logo"
              src="../../../assets/images/logo/logo.svg"
              alt="AfterFive.app"
            />
          </div>
          <p>We are giving the freedom back to freelancers!</p>
          <p>
            Zero fees for your clients and a reasonable flat fee for you
            (freelancers).
          </p>
          <div class="my-4">
            <p-button
              (click)="showLoginRegisterDialog()"
              label="Become a Freelancer"
            />
          </div>
        </div>
      </div>
      <div class="flex items-center justify-center md:justify-end image my-8">
        <div class="p-4">
          <img
            class="aspect-square w-full object-cover rounded-lg shadow-2xl"
            src="../../../assets/images/home-page/home-page-hero-image.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="flex justify-center items-center">
  <div class="container">
    <h3 class="text-2xl font-semibold mb-4">Popular Services</h3>
    <p-carousel
      [value]="services"
      [numVisible]="4"
      [numScroll]="1"
      [circular]="true"
      [responsiveOptions]="responsiveOptions"
    >
      <ng-template let-service #item>
        <div class="m-2">
          <div class="mb-4">
            <div class="relative mx-auto">
              <img
                [src]="service.image"
                [alt]="service.name"
                class="w-full aspect-square object-cover rounded-lg"
              />
            </div>
          </div>
          <div class="mb-4 font-medium">{{ service.name }}</div>
        </div>
      </ng-template>
    </p-carousel>
  </div>
</div>

<div class="flex justify-center items-center">
  <div class="container">
    <div class="grid md:grid-cols-2 hero-banner">
      <div class="flex items-center justify-start text my-8">
        <div class="p-4 space-y-4">
          <h3 class="text-2xl font-semibold mb-4">Hello, We are afterFive</h3>
          <p>
            The premier freelancer marketplace based out of Portland, Oregon. We
            are dedicated to empowering freelancers by breaking traditional
            constraints and giving them the freedom they deserve.
          </p>
          <p>
            Our platform provides an innovative space for freelancers to connect
            with new clients and gives you the freedom to communicate on our
            platform or elsewhere. Our only request is that, as freelancers, you
            use our platform for invoicing. After all, we need to sustain our
            operations, too.
          </p>
          <p>
            We charge a straightforward, flat 15% fee. More importantly, we do
            not burden your clients with additional costs.
          </p>
          <p>Let’s redefine freelancing together!</p>
        </div>
      </div>
      <div class="flex items-center justify-center md:justify-end image my-8">
        <div class="p-4">
          <img
            class="aspect-square w-full object-cover rounded-lg shadow-2xl"
            src="../../../assets/images/home-page/home-about-us.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</div>

<app-login-register></app-login-register>
