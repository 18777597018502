<h3 class="text-2xl font-semibold mb-8">Reset your password</h3>
<div class="mb-4">
  Need a new code?
  <a (click)="showForgotPassword()" class="font-bold cursor-pointer"
    >Forgot Password</a
  >
</div>
<form [formGroup]="resetPasswordForm" (ngSubmit)="submit()">
  <div class="mb-4">
    <p-floatlabel variant="in">
      <input
        pInputText
        formControlName="email"
        id="email"
        autocomplete="off"
        fluid="true"
        type="email"
      />
      <label for="email">Email</label>
    </p-floatlabel>
  </div>
  <div class="mb-4">
    <p-floatlabel variant="in">
      <p-password
        id="password"
        formControlName="password"
        autocomplete="off"
        fluid="true"
        [feedback]="false"
      />
      <label for="password">New Password</label>
    </p-floatlabel>
  </div>
  <div class="mb-4">
    <p-floatlabel variant="in">
      <p-password
        id="confirmPassword"
        formControlName="confirmPassword"
        autocomplete="off"
        fluid="true"
        [feedback]="false"
      />
      <label for="confirmPassword">Confirm Password</label>
    </p-floatlabel>
  </div>
  <div class="mb-4">
    <p-inputotp formControlName="code" [length]="6" />
  </div>
  <div class="mb-4 flex justify-end items-center">
    <p-button
      label="Submit"
      size="large"
      [disabled]="!resetPasswordForm.valid"
      type="submit"
    />
  </div>
</form>
