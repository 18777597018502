<p-dialog
  [modal]="true"
  [(visible)]="visible"
  [style]="{ width: '50rem' }"
  maskStyleClass="backdrop-blur-sm"
  styleClass="!border-0 !bg-transparent rounded-3xl"
>
  <ng-template #headless>
    <div class="grid grid-cols-1 md:grid-cols-2">
      <div
        class="hidden md:block bg-(--color-primary-blue) p-8 text-white rounded-l-2xl"
      >
        <h3 class="text-2xl font-semibold">Success starts here</h3>
        <ul class="mt-8 space-y-4">
          <li>Over 600 categories</li>
          <li>Over 1000+ trust freelancers</li>
          <li>1000+ success stories</li>
        </ul>
      </div>
      <div class="bg-white p-8 rounded-r-2xl">
        <ng-container *ngIf="currentView === views.LOGIN">
          <app-login (viewChanged)="onViewChange($event)"></app-login>
        </ng-container>
        <ng-container *ngIf="currentView === views.REGISTER">
          <app-register (viewChanged)="onViewChange($event)"></app-register>
        </ng-container>
        <ng-container *ngIf="currentView === views.VERIFY_EMAIL">
          <app-verification
            (viewChanged)="onViewChange($event)"
          ></app-verification>
        </ng-container>
        <ng-container *ngIf="currentView === views.FORGOT_PASSWORD">
          <app-recover-password
            (viewChanged)="onViewChange($event)"
          ></app-recover-password>
        </ng-container>
        <ng-container *ngIf="currentView === views.RESET_PASSWORD">
          <app-reset-password
            (viewChanged)="onViewChange($event)"
          ></app-reset-password>
        </ng-container>
      </div>
    </div>
  </ng-template>
</p-dialog>
