<div class="personal-information-wrapper">
    <div class="verification-wrapper">
        <h2 class="hide-in-desktop hide-in-tablet wrapper-title">Verification
        </h2>
        <label class="label-secondary" for="">Identity Card</label>
        <h4 class="about-small-text mb-2">Make sure your photos aren’t blurry and the front of your
            identity card clearly shows your face</h4>

        <div class="image-upload-wrapper">
            <div class="main-card front-image" *ngIf="!frontIdUploaded">
                <img src="../../../assets/images/icons/bank-front.png" alt="">
                <h6>Upload front of ID</h6>
                <p>JPEG or PNG only</p>
            </div>
            <div class="main-card uploaded-image" *ngIf="frontIdUploaded">
                <img src="../../../../../assets/images/temp/temp-id-front.svg" alt="">
                <button>Change</button>
            </div>
            <div class="main-card back-image">
                <img src="../../../assets/images/icons/bank-back.png" alt="">
                <h6>Upload back of ID</h6>
                <p>JPEG or PNG only</p>
            </div>
        </div>

        <hr>

        <label class="label-secondary" for="">Verification Selfie</label>
        <h4 class="about-small-text mb-1">Upload a selfie of yourself while adhering to following
            guidelines : </h4>
        <div class="selfie-tips-wrapper">
            <div class="selfie-tips">
                <ol>
                    <li>
                        <span class="tips-number-text">Clear and Focused:</span>
                        <span class="tips-normal-text">The selfie must be sharp and not blurry.</span>
                    </li>
                    <li>
                        <span class="tips-number-text">Well-Lit:</span>
                        <span class="tips-normal-text">Use good lighting, preferably natural light,
                            ensuring there are no shadows on your face.</span>
                    </li>
                    <li>
                        <span class="tips-number-text">Face Centered:</span>
                        <span class="tips-normal-text">Your face should be centered in the frame.</span>
                    </li>
                    <li>
                        <span class="tips-number-text">Neutral Background:</span>
                        <span class="tips-normal-text">Use a plain, uncluttered background.</span>
                    </li>
                    <li>
                        <span class="tips-number-text">No Obstructions:</span>
                        <span class="tips-normal-text">Ensure no hats, sunglasses, or masks are covering
                            your face.</span>
                    </li>
                    <li>
                        <span class="tips-number-text">Neutral Expression:</span>
                        <span class="tips-normal-text">Maintain a neutral expression, avoiding extreme
                            facial expressions.</span>
                    </li>
                    <li>
                        <span class="tips-number-text">Single Person:</span>
                        <span class="tips-normal-text">Only your face should be visible; no other people
                            should be in the photo.</span>
                    </li>
                    <li>
                        <span class="tips-number-text">Recent Photo:</span>
                        <span class="tips-normal-text">The selfie should be a recent photo that
                            accurately represents your current appearance.</span>
                    </li>
                    <li>
                        <span class="tips-number-text">No Filters or Effects:</span>
                        <span class="tips-normal-text">Do not use any camera filters or effects.</span>
                    </li>
                    <li>
                        <span class="tips-number-text">Full Face Visible:</span>
                        <span class="tips-normal-text">Ensure your entire face, from the top of your head
                            to your chin, is visible in the selfie.</span>
                    </li>

                </ol>
            </div>
            <div class="selfie-tips-images">
                <img src="../../../assets/images/thumbnails/selfie-tips-image.png" alt="">
            </div>
        </div>
        <div class="main-card back-image">
            <img src="../../../assets/images/icons/selfie-verification-icon.png" alt="">
            <h6>Verification selfie</h6>
            <p>JPEG or PNG only</p>
        </div>

        <hr>

        <label class="label-secondary" for="">Resume / CV</label>
        <div class="main-card back-image">
            <img src="../../../assets/images/icons/cv-icon.png" alt="">
            <h6>Upload resume</h6>
            <p>PDF only</p>
        </div>

        <hr>

        <label class="label-secondary" for="">Degrees & Certifications</label>
        <h4 class="about-small-text mb-1">Make sure your documents aren’t blurry and clearly shows the
            contents.</h4>
        <div class="main-card back-image">
            <img src="../../../assets/images/icons/degree-icon.png" alt="">
            <h6>Upload</h6>
            <p>JPEG/PNG/PDF only</p>
        </div>

        <div class="w-fit">
            <button class="primary-3-button">Add another document</button>
        </div>

        <!-- <hr> -->


    </div>
</div>