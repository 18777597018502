import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

interface Requirement {
  question: string;
  type: string;
}

interface Gig {
  title: string;
  description: string;
  startingPrice: number;
  images: string[];
  requirements: Requirement[];
}

@Injectable({
  providedIn: 'root'
})
export class GigsService {

  constructor(private http: HttpClient) { }

  create(gig: any) {
    return this.http.post('/gigs', gig);
  }
}
