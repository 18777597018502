import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { User, UsersService } from '../../../user.service';
import { Category, CategoryList } from '../../../data/category-list';
import { SelectChangeEvent } from 'primeng/select';
import { getSkillsFrom } from './skills/skills.component';
import { getEducationFrom } from './education/education.component';
import { getLanguagesFrom } from './languages/languages.component';

@Component({
  selector: 'app-account-public-profile',
  standalone: false,
  templateUrl: './public-profile.component.html',
  styleUrl: './public-profile.component.scss',
})
export class PublicProfileComponent implements OnInit, OnChanges {
  @Output() formSubmitted: EventEmitter<void> = new EventEmitter<void>();
  @Output() goBack: EventEmitter<void> = new EventEmitter<void>();

  @Input() publicProfile: User['publicProfile'] | undefined;
  @Input() name: User['name'] | undefined;

  categories: Category[] = [];
  selectedSkills: string[] = [];

  publicProfileForm = new FormGroup({
    displayMiddleName: new FormControl(false, {
      validators: [Validators.required],
    }),
    displayLastName: new FormControl(false, {
      validators: [Validators.required],
    }),
    category: new FormControl('', { validators: [Validators.required] }),
    about: new FormControl('', { validators: [Validators.required] }),
    education: new FormArray<FormGroup>([], {
      validators: [Validators.minLength(1)],
    }),
    languages: new FormArray<FormGroup>([], {
      validators: [Validators.minLength(1)],
    }),
    skills: new FormArray<FormGroup>([], {
      validators: [Validators.minLength(1)],
    }),
    externalLinks: new FormGroup({
      upwork: new FormControl(''),
      fiverr: new FormControl(''),
    }),
  });

  get externalLinksForm() {
    return this.publicProfileForm.get('externalLinks') as FormGroup;
  }

  get skilsFormArray() {
    return this.publicProfileForm.get('skills') as FormArray;
  }

  get languagesFormArray() {
    return this.publicProfileForm.get('languages') as FormArray;
  }

  get educationFormArray() {
    return this.publicProfileForm.get('education') as FormArray;
  }

  constructor(private readonly usersService: UsersService) {}

  back(): void {
    this.goBack.emit();
  }

  submit(): void {
    if (this.publicProfileForm.valid) {
      this.usersService
        .patch({
          publicProfile: this.publicProfileForm.value as User['publicProfile'],
        })
        .subscribe(() => this.formSubmitted.emit());
    }
  }

  ngOnInit(): void {
    this.categories = CategoryList;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['publicProfile']) {
      for (const _s in this.publicProfile?.skills) {
        this.skilsFormArray.push(getSkillsFrom());
      }

      for (const _l in this.publicProfile?.languages) {
        this.languagesFormArray.push(getLanguagesFrom());
      }

      for (const _e in this.publicProfile?.education) {
        this.educationFormArray.push(getEducationFrom());
      }

      this.publicProfileForm.patchValue({ ...this.publicProfile });
      this.categoryChange({
        value: this.publicProfile?.category,
      } as SelectChangeEvent);
    }
  }

  categoryChange(event: SelectChangeEvent): void {
    this.selectedSkills =
      CategoryList.find((category) => category.name === event.value)?.skills ||
      [];
  }
}
