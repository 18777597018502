<div class="font-bold text-2xl mb-4">Banking Information</div>
<form [formGroup]="bankingForm" class="max-w-2xl" (ngSubmit)="submit()">
  <div class="mb-4">
    <p-fieldset legend="Bank Name">
      <p-select
        [options]="banks"
        optionLabel="name"
        optionValue="name"
        [filter]="true"
        filterBy="name"
        [showClear]="true"
        placeholder="Bank Name"
        fluid="true"
        size="large"
        formControlName="bank"
        (onChange)="onBankChange($event)"
      />
    </p-fieldset>
  </div>

  <div class="mb-4">
    <p-fieldset legend="Branch Name">
      <p-select
        [options]="branches"
        [filter]="true"
        [showClear]="true"
        placeholder="Branch Name"
        fluid="true"
        size="large"
        formControlName="branch"
    /></p-fieldset>
  </div>

  <div class="mb-4">
    <app-shared-text-input
      formControlName="branchNumber"
      label="Branch Number"
    ></app-shared-text-input>
  </div>

  <div class="mb-4">
    <p-inputgroup>
      <p-inputgroup-addon>
        <span class="bank-logo bank-logo-nsb"></span>
      </p-inputgroup-addon>
      <app-shared-text-input
        formControlName="accountNumber"
        label="Account Number"
      ></app-shared-text-input>
    </p-inputgroup>
  </div>
  <div class="mb-4">
    <p-inputgroup>
      <p-inputgroup-addon>
        <span class="bank-logo bank-logo-nsb"></span>
      </p-inputgroup-addon>
      <app-shared-text-input
        formControlName="accountNumberConfirm"
        label="Confirm Account Number"
      ></app-shared-text-input>
    </p-inputgroup>
  </div>

  <div class="flex py-6 gap-2">
    <p-button label="Back" severity="secondary" (onClick)="back()" />
    <p-button
      label="Next"
      (onClick)="submit()"
      [disabled]="!bankingForm.valid"
    />
  </div>
</form>
