import { Component } from '@angular/core';
import { DragDropModule } from 'primeng/dragdrop';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import {
  NgxIntlTelephoneInputModule, CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from "ngx-intl-telephone-input";
import { CalendarModule } from 'primeng/calendar';

interface Task {
  id: number;
  name: string;
  description: string;
}

interface List {
  name: string;
  items: Task[];
}

interface City {
  name: string;
  code: string;
}

@Component({
  selector: 'app-projectmanagement',
  standalone: true,
  imports: [CommonModule, DragDropModule, DialogModule, InputSwitchModule, DropdownModule, NgxIntlTelephoneInputModule, CalendarModule],
  templateUrl: './projectmanagement.component.html',
  styleUrl: './projectmanagement.component.scss'
})
export class ProjectmanagementComponent {

  SearchCountryField: any = SearchCountryField;
  PhoneNumberFormat: any = PhoneNumberFormat;
  cities: City[] | undefined;

  lists: List[] = [
    { name: 'On-Time', items: [{ id: 1, name: 'Alice Hasnolice', description: 'Logo Design' }, { id: 2, name: 'Bradley Cooper', description: 'Product Design' }] },
    { name: 'Due Soon', items: [{ id: 1, name: 'Alice Hasnolice', description: 'Website Design' }] },
    { name: 'Past-Due', items: [{ id: 1, name: 'Claudia Walsh', description: 'Mug Design' }] },
    { name: 'Delivered', items: [{ id: 1, name: 'Claudia Walsh', description: 'Compression Shirt Design' }] }
  ];

  draggedTask: Task | null = null;
  sourceList: List | null = null;

  addNewClientModal: boolean = false
  addNewClient: boolean = false

  projectMangeModal: boolean = false

  dragStart(task: Task, list: List) {
    this.draggedTask = task;
    this.sourceList = list;
  }

  drop(targetList: List) {
    if (this.draggedTask && this.sourceList) {
      // Remove task from source list
      const draggedTaskIndex = this.sourceList.items.indexOf(this.draggedTask);
      if (draggedTaskIndex > -1) {
        this.sourceList.items.splice(draggedTaskIndex, 1);
      }

      // Add task to target list
      targetList.items.push(this.draggedTask);

      // Reset the dragged task and source list
      this.draggedTask = null;
      this.sourceList = null;
    }
  }

  dragEnd() {
    this.draggedTask = null;
    this.sourceList = null;
  }

  ngOnInit() {
    this.cities = [
      { name: 'New York', code: 'NY' },
      { name: 'Rome', code: 'RM' },
      { name: 'London', code: 'LDN' },
      { name: 'Istanbul', code: 'IST' },
      { name: 'Paris', code: 'PRS' }
    ];
  }
}
