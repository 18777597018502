import { Component } from '@angular/core';
import { CarouselResponsiveOptions } from 'primeng/carousel';
import { LoginStatusService } from '../../core/login-status.service';

interface Service {
  name: string;
  image: string;
}

@Component({
  selector: 'app-home',
  standalone: false,
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {
  responsiveOptions: CarouselResponsiveOptions[] = [
    {
      breakpoint: '100rem',
      numVisible: 4,
      numScroll: 1,
    },
    {
      breakpoint: '60rem',
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: '30rem',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  services: Service[] = [
    {
      name: 'Web Development & Design',
      image: '../../../assets/images/services/services-1.png',
    },
    {
      name: 'Graphic Design',
      image: '../../../assets/images/services/services-2.png',
    },
    {
      name: 'Content Writing & Copywriting',
      image: '../../../assets/images/services/services-3.png',
    },
    {
      name: 'Digital Marketing',
      image: '../../../assets/images/services/services-4.png',
    },
    {
      name: 'Mobile App Development',
      image: '../../../assets/images/services/services-5.png',
    },
    {
      name: 'Virtual Assistance',
      image: '../../../assets/images/services/services-6.png',
    },
    {
      name: 'Web Development & Design',
      image: '../../../assets/images/services/services-1.png',
    },
    {
      name: 'Graphic Design',
      image: '../../../assets/images/services/services-2.png',
    },
    {
      name: 'Content Writing & Copywriting',
      image: '../../../assets/images/services/services-3.png',
    },
    {
      name: 'Digital Marketing',
      image: '../../../assets/images/services/services-4.png',
    },
    {
      name: 'Mobile App Development',
      image: '../../../assets/images/services/services-5.png',
    },
  ];

  constructor(private readonly loginStatusService: LoginStatusService) {}

  showLoginRegisterDialog() {
    this.loginStatusService.sendLoginDialogVisibility(true);
  }
}
