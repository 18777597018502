<div class="main-wrapper">
    <h1 class="main-title">
        Orders & Card Information
    </h1>

    <div class="tab-view-main-wrapper">
        <p-tabView>
            <p-tabPanel header="Card Information">
                <ng-template [ngTemplateOutlet]="cardInformationTemp"></ng-template>
            </p-tabPanel>

            <p-tabPanel header="Orders">
                <ng-template [ngTemplateOutlet]="orderInformationTemp"></ng-template>
            </p-tabPanel>
        </p-tabView>
    </div>

</div>


<ng-template #cardInformationTemp>
    <div class="card-information-wrapper mt-32">
        <h5 class="sub-title-heading">Cards</h5>
        <div class="card-information-wrapper">
            <div class="card-information-inner">
                <div class="card-infor">
                    <img src="../../../../../assets/images/cards/mastercard.svg" alt="">
                    <h4 class="card-no">xxxx xxxx xxxx 5678</h4>
                </div>
                <div class="button-wrapper">
                    <div class="edit-delete-2-button-wrapper">
                        <button class="delete-button-wrapper"><img
                                src="../../../assets/images/icons/delete-bin-white.svg" alt=""></button>
                        <button class="underline-button">Edit</button>
                    </div>
                    <div class="default-radio-wrapper hide-in-mobile">
                        <label for="radio1">Default</label>
                        <p-radioButton [(ngModel)]="selectedAccountId" [value]="1" inputId="radio1" class="custom-radio" />
                    </div>
                </div>
            </div>
        </div>

        <button class="primary-1-button">Add a backup card</button>

        <div *ngIf="addBackUpCard" class="add-backup-card-wrapper">
            <h5 class="sub-title-heading-add">Add a Backup Card</h5>
            <p class="small-title-para">Add a second payment method as your backup.</p>

            <div class="add-card-form-wrapper">
                <div class="input-wrapper mb-24">
                    <label class="label-secondary" for="">Name</label>
                    <h4 class="name-title">Travis Scott <span class="tooltip-icon-wrapper"><img class="tooltip-icon"
                                (click)="openPopup()" src="../../../assets/images/icons/info-icon-tooltip.png"
                                alt=""></span></h4>
                </div>

                <div class="input-wrapper card-wrapper mb-24">
                    <label for="" class="label-secondary">Card Type</label>
                    <p-dropdown [options]="cards" optionLabel="name" class="card-inner-wrapper m-w60">
                        <ng-template pTemplate="selectedItem">
                            <div class="flex align-items-center card-type-inner-title" *ngIf="selectedCard">
                                <img [src]="'../../../assets/images/cards/' + selectedCard.src" style="width: 58px" />
                                <div class="card-title">{{ selectedCard.name }}</div>
                            </div>
                        </ng-template>
                        <ng-template let-card pTemplate="item">
                            <div class="flex align-items-center card-type-inner-title">
                                <img [src]="'../../../assets/images/cards/' + card.src" style="width: 58px" />
                                <div>{{ card.name }}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>

                <div class="input-wrapper card-number mb-24">
                    <label for="" class="label-secondary">Card Number</label>
                    <input type="text" class="register account-number" placeholder="XXXX-XXXX-XXXX-XXXX">
                </div>

                <div class="input-wrapper card-expire-wrapper mb-24">
                    <label for="" class="label-secondary">Expiration Date</label>
                    <div class="account-number m-w100 card-expire-inner">
                        <input type="text" class="register" placeholder="Month">
                        <input type="text" class="register" placeholder="Year">
                    </div>
                </div>

                <div class="input-wrapper card-cvv">
                    <label for="" class="label-secondary">CVV</label>
                    <div class=" account-number m-w100">
                        <input type="text" class="register cvv-input" placeholder="XXX">
                    </div>
                </div>

                <div class="button-wrapper-step">
                    <button class="primary-1-button w-fit">Save</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #orderInformationTemp>
    <div class="order-information-wrapper mt-32">
        <h5 class="sub-title-heading">Current Orders</h5>

        <div class="table-wrapper order-table-wrapper">
            <p-table class="hide-in-tablet hide-in-mobile" [value]="orders" [style]="{'width': '100%'}">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="clientName">
                            Freelancer <p-sortIcon field="clientName" />
                        </th>
                        <th pSortableColumn="projectType">
                            Project Type <p-sortIcon field="projectType" />
                        </th>
                        <th pSortableColumn="dueDate">
                            Date <p-sortIcon field="dueDate" />
                        </th>
                        <th pSortableColumn="total">
                            Total <p-sortIcon field="dueDate" />
                        </th>
                        <th pSortableColumn="status">
                            Status <p-sortIcon field="status" />
                        </th>
                        <th>

                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-order>
                    <tr>
                        <td>
                            <div class="table-body-text border-left-radius">
                                <div class="img-name-wrapper">
                                    <img src="../../../../../assets/images/temp/order-1.svg" alt="">
                                    <h6 class="name-wrapper">{{order.freeLancer}}</h6>
                                </div>

                            </div>
                        </td>
                        <td>
                            <div class="table-body-text">{{ order.projectType }}</div>
                        </td>
                        <td>
                            <div class="table-body-text">{{ order.date }}</div>
                        </td>
                        <td>
                            <div class="table-body-text">{{ order.total }}</div>
                        </td>
                        <td>
                            <div class="table-body-text">
                                <div *ngIf="order.status == '1'" class="success-pill pill-width">{{order.statusTitle}}
                                </div>
                                <div *ngIf="order.status == '2'" class="inprogress-pill pill-width">
                                    {{order.statusTitle}}</div>
                                <div *ngIf="order.status == '3'" class="danger-pill pill-width">{{order.statusTitle}}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="table-body-text border-right-radius">
                                <button class="verticle-dot-button">
                                    <svg width="5" height="18" viewBox="0 0 5 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.5 14C3.60457 14 4.5 14.8954 4.5 16C4.5 17.1046 3.60457 18 2.5 18C1.39543 18 0.5 17.1046 0.5 16C0.5 14.8954 1.39543 14 2.5 14Z" fill="#0B0F4D" />
                                        <path d="M2.5 7C3.60457 7 4.5 7.89543 4.5 9C4.5 10.1046 3.60457 11 2.5 11C1.39543 11 0.5 10.1046 0.5 9C0.5 7.89543 1.39543 7 2.5 7Z" fill="#0B0F4D" />
                                        <path d="M2.5 0C3.60457 0 4.5 0.89543 4.5 2C4.5 3.10457 3.60457 4 2.5 4C1.39543 4 0.5 3.10457 0.5 2C0.5 0.89543 1.39543 0 2.5 0Z" fill="#0B0F4D" />
                                      </svg>
                                </button>
                            </div>
                            
                        </td>
                    </tr>
                </ng-template>
            </p-table>

        </div>
    </div>


</ng-template>

<!-- //popup  -->
<p-dialog [modal]="true" [(visible)]="popupopen" [style]="{ width: '280px' }">
    <p class="popup-modal-name-stat">First and last names are prefilled and uneditable because we only transfer money to
        the <img src="../../../assets/images/logo/logo.svg" alt=""> account holder’s name.</p>
</p-dialog>