<div class="font-bold text-2xl mb-4">Public Profile</div>
<form [formGroup]="publicProfileForm" class="max-w-2xl" (ngSubmit)="submit()">
  <div class="mb-4">
    <div class="grid md:grid-cols-2 gap-2">
      <div>
        <h3 class="text-sm font-bold">Middle Name</h3>
        <p>{{ name?.middle }}</p>
      </div>
      <div class="flex items-center">
        <label for="displayMiddleName" class="mr-2"
          >Display on the public profile</label
        >
        <p-toggleswitch formControlName="displayMiddleName">
          <ng-template #handle let-checked="checked">
            <span class="text-xs">{{ checked ? "Y" : "N" }}</span>
          </ng-template>
        </p-toggleswitch>
      </div>
    </div>
  </div>

  <div class="mb-4">
    <div class="grid md:grid-cols-2 gap-2">
      <div>
        <h3 class="text-sm font-bold">Last Name</h3>
        <p>{{ name?.last }}</p>
      </div>
      <div>
        <label for="displayLastName" class="mr-2"
          >Display on the public profile</label
        >
        <p-toggleswitch formControlName="displayLastName">
          <ng-template #handle let-checked="checked">
            <span class="text-xs">{{ checked ? "Y" : "N" }}</span>
          </ng-template>
        </p-toggleswitch>
      </div>
    </div>
  </div>

  <div class="mb-4">
    <p-fieldset legend="About">
      <p-floatlabel variant="in">
        <textarea
          rows="5"
          fluid="true"
          pTextarea
          formControlName="about"
        ></textarea>
        <label for="branch"
          >Write an introductory description about yourself.</label
        >
      </p-floatlabel>
    </p-fieldset>
  </div>

  <div class="mb-4">
    <p-fieldset legend="Category">
      <p-select
        [options]="categories"
        placeholder="Pick a category"
        fluid="true"
        size="large"
        formControlName="category"
        optionLabel="name"
        optionValue="name"
        (onChange)="categoryChange($event)"
    /></p-fieldset>
  </div>

  <div class="mb-4">
    <p-fieldset [legend]="publicProfileForm.value.category + ' Skills'">
      <app-account-public-profile-skills
        [form]="publicProfileForm"
        [section]="'skills'"
        [skillsList]="selectedSkills"
      ></app-account-public-profile-skills>
    </p-fieldset>
  </div>

  <div class="mb-4">
    <p-fieldset legend="Education">
      <app-account-public-profile-education
        [form]="publicProfileForm"
        [section]="'education'"
      ></app-account-public-profile-education>
    </p-fieldset>
  </div>

  <div class="mb-4">
    <p-fieldset legend="Languages">
      <app-account-public-profile-languages
        [form]="publicProfileForm"
        [section]="'languages'"
      ></app-account-public-profile-languages>
    </p-fieldset>
  </div>

  <ng-container [formGroup]="externalLinksForm">
    <p-fieldset legend="Profiles on Other Platforms">
      <div class="mb-4">
        <p-floatlabel variant="in">
          <input
            pInputText
            type="url"
            id="upwork"
            autocomplete="off"
            formControlName="upwork"
            fluid="true"
          />
          <label for="upwork">Upwork (optional)</label>
        </p-floatlabel>
      </div>
      <div class="mb-4">
        <p-floatlabel variant="in">
          <input
            pInputText
            type="url"
            id="fiverr"
            autocomplete="off"
            formControlName="fiverr"
            fluid="true"
          />
          <label for="fiver">Fiverr (optional)</label>
        </p-floatlabel>
      </div>
    </p-fieldset>
  </ng-container>

  <div class="flex py-6 gap-2">
    <p-button label="Back" severity="secondary" (onClick)="back()" />
    <p-button
      label="Next"
      (onClick)="submit()"
      [disabled]="!publicProfileForm.valid"
    />
  </div>
</form>
