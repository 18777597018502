<div class="container mb-5">
    <div class="hide-in-mobile hide-in-tablet">
        <app-clientprofileheader></app-clientprofileheader>
    </div>

    <div class="register-main-wrapper-inner">
        <div class="left-side hide-in-mobile">
            <div class="step-wrapper">
                <div class="stepper">
                    <div class="step" [class.active]="currentStep === 1" [class.completed]="currentStep > 1"
                        (click)="goToNextStep(1)">
                        <div class="step-number">
                            <ng-container *ngIf="currentStep > 1; else step1Number">
                                <img src="../../../assets/images/icons/correct-primary-icon.svg" alt="Completed Icon 1">
                            </ng-container>
                            <ng-template #step1Number>1</ng-template>
                        </div>
                        <div *ngIf="currentStep === 1 || currentStep > 1" class="step-title">Personal Information</div>
                    </div>

                    <div class="step" [class.active]="currentStep === 2" [class.completed]="currentStep > 2"
                        (click)="goToNextStep(2)">
                        <div class="step-number">
                            <ng-container *ngIf="currentStep > 2; else step2Number">
                                <img src="../../../assets/images/icons/correct-primary-icon.svg" alt="Completed Icon 2">
                            </ng-container>
                            <ng-template #step2Number>2</ng-template>
                        </div>
                        <div *ngIf="currentStep === 2 || currentStep > 2" class="step-title">Banking Information</div>
                    </div>
                </div>

            </div>
        </div>
        <!-- //mobile step section  -->
        <div class="top-step-wrapper hide-in-desktop hide-in-tablet">
            <div class="step-wrapper">
                <div class="stepper">
                    <div class="step" [class.active]="currentStep === 1" [class.completed]="currentStep > 1"
                        (click)="goToNextStep(1)">
                        <div class="step-number">
                            <ng-container *ngIf="currentStep > 1; else step1Number">
                                <div>1</div>
                                <img src="../../../assets/images/icons/orange-tic-icon.svg" alt="Completed Icon 1">
                            </ng-container>
                            <ng-template #step1Number>1</ng-template>
                        </div>
                    </div>

                    <div class="step" [class.active]="currentStep === 2" [class.completed]="currentStep > 2"
                        (click)="goToNextStep(2)">
                        <div class="step-number">
                            <ng-container *ngIf="currentStep > 2; else step2Number">
                                <div>2</div>
                                <img src="../../../assets/images/icons/orange-tic-icon.svg" alt="Completed Icon 2">
                            </ng-container>
                            <ng-template #step2Number>2</ng-template>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="right-side">
            <div class="step-templates-wrapper">
                <!-- //personal information step 1 -->
                <div [hidden]="currentStep !== 1">
                    <div class="public-profile-wrapper">
                        <h2 class="hide-in-desktop hide-in-tablet wrapper-title">Personal Information</h2>
                        <div class="input-wrapper">
                            <label class="label-secondary" for="">Name</label>
                            <h4>Travis Scott <span class="tooltip-icon-wrapper"><img class="tooltip-icon"
                                        (click)="openPopup()" src="../../../assets/images/icons/info-icon-tooltip.png"
                                        alt=""></span></h4>
                        </div>

                        <hr>

                        <div class="input-wrapper">
                            <label class="label-secondary" for="">Country</label>
                            <p-dropdown [options]="cities" optionLabel="name" placeholder="Your Country"
                                class="country-wrapper w-40 m-w100" />
                        </div>

                        <hr>

                        <div class="input-wrapper phone-number">
                            <label class="label-secondary" for="">Phone Number</label>
                            <intl-telephone-input [enablePlaceholder]="true" [searchCountryFlag]="true"
                                [separateDialCode]="false" [enableAutoCountrySelect]="true"
                                [searchCountryField]="SearchCountryField.All" [phoneValidation]="false"
                                [numberFormat]="PhoneNumberFormat.International" [inputId]="'phone'"
                                [separateDialCode]="true" [enablePlaceholder]="false" class="input-field">
                            </intl-telephone-input>

                            <ng-template #customItemTemplate let-item>
                                <div class="dropdown-item">
                                    <img [src]="item.flag" alt="{{ item.name }}" class="flag-icon">
                                    {{ item.name }} ({{ item.dialCode }})
                                </div>
                            </ng-template>
                        </div>

                        <hr>

                        <div class="input-wrapper">
                            <label class="label-secondary" for="">Language</label>
                            <p-dropdown [options]="cities" optionLabel="language" placeholder="Language"
                                class="country-wrapper w-40 m-w100" />
                        </div>

                        <hr>

                        <div class="input-wrapper">
                            <label class="label-secondary" for="">Sex</label>
                            <div class="field-checkbox">
                                <div class="">
                                    <label for="ingredient1" class="ml-2">
                                        Male
                                    </label>
                                </div>
                                <div>
                                    <p-radioButton name="sex" value="Male" [(ngModel)]="clientSex"
                                        inputId="ingredient1" />
                                </div>
                            </div>
                            <div class="field-checkbox">
                                <div class="">
                                    <label for="ingredient2" class="ml-2">
                                        Female
                                    </label>
                                </div>
                                <div>
                                    <p-radioButton name="sex" value="Female" [(ngModel)]="clientSex"
                                        inputId="ingredient2" />
                                </div>
                            </div>
                        </div>

                        <hr>

                        <div class="input-wrapper address-wrapper">
                            <label for="" class="label-secondary">Address</label>
                            <input type="text" class="register mb-1 m-w100" placeholder="Address Line 1">
                            <input type="text" class="register mb-1 m-w100" placeholder="Address Line 2">
                            <div class="address-city-state-wrapper">
                                <input type="text" class="register mb-1 m-w100" placeholder="City">
                                <input type="text" class="register mb-1 m-w100" placeholder="State">
                            </div>
                            <input type="text" class="register mb-1 m-w40" placeholder="ZIP Code">
                        </div>

                        <hr>

                        <div class="button-wrapper-step">
                            <button class="primary-1-button w-fit" (click)="goToNextStep(2)">Next</button>
                        </div>

                    </div>
                </div>
                <!-- //banking information step 2 -->
                <div [hidden]="currentStep !== 2">
                    <div class="banking-information-wrapper">
                        <!-- <h2 class="hide-in-desktop hide-in-tablet wrapper-title">Banking Information</h2> -->
                        <div class="input-wrapper">
                            <label for="" class="label-secondary">Name</label>
                            <h4>William Darcy <span class="tooltip-icon-wrapper"><img class="tooltip-icon"
                                        (click)="openPopup()" src="../../../assets/images/icons/info-icon-tooltip.png"
                                        alt=""></span></h4>
                        </div>

                        <div class="input-wrapper card-wrapper">
                            <label for="" class="label-secondary">Card Type</label>
                            <p-dropdown [options]="cards" optionLabel="name"
                                class="card-inner-wrapper m-w60">
                                <ng-template pTemplate="selectedItem">
                                    <div class="flex align-items-center card-type-inner-title" *ngIf="selectedCard">
                                        <img [src]="'../../../assets/images/cards/' + selectedCard.src"
                                            style="width: 58px" />
                                        <div class="card-title">{{ selectedCard.name }}</div>
                                    </div>
                                </ng-template>
                                <ng-template let-card pTemplate="item">
                                    <div class="flex align-items-center card-type-inner-title">
                                        <img [src]="'../../../assets/images/cards/' + card.src" style="width: 58px" />
                                        <div>{{ card.name }}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>

                        <div class="input-wrapper card-number">
                            <label for="" class="label-secondary">Card Number</label>
                            <input type="text" class="register account-number" placeholder="XXXX-XXXX-XXXX-XXXX">
                        </div>

                        <div class="input-wrapper card-expire-wrapper">
                            <label for="" class="label-secondary">Expiration Date</label>
                            <div class="account-number m-w100 card-expire-inner">
                                <input type="text" class="register" placeholder="Month">
                                <input type="text" class="register" placeholder="Year">
                            </div>
                        </div>

                        <div class="input-wrapper card-cvv">
                            <label for="" class="label-secondary">CVV</label>
                            <div class=" account-number m-w100">
                                <input type="text" class="register cvv-input" placeholder="XXX">
                            </div>
                        </div>

                        <div class="button-wrapper-step">
                            <button class="primary-1-button w-fit" (click)="goToNextStep(3)">Next</button>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>

<!-- //popup  -->
<p-dialog [modal]="true" [(visible)]="popupopen" [style]="{ width: '340px' }" [className]="'registerPopUp'">
    <p class="popup-modal-name-stat">First and last names are prefilled and uneditable because we only transfer money to
        the <img class="profile-name-popup-img" src="../../../assets/images/logo/logo.svg" alt=""> account holder’s
        name.</p>
</p-dialog>