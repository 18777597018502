<div class="mb-4">
  <p-fieldset legend="Country">
    <p-select
      [options]="countries"
      optionLabel="name"
      optionValue="name"
      [filter]="true"
      filterBy="name"
      [showClear]="true"
      placeholder="Select a Country"
      fluid="true"
      size="large"
      [formControl]="inputControl"
      appendTo="body"
    >
      <ng-template #selectedItem let-selectedOption>
        <div class="flex items-center gap-2">
          <img
            alt="flag"
            src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
            [class]="'flag flag-' + selectedOption.code.toLowerCase()"
            style="width: 18px"
          />
          <div>{{ selectedOption.name }}</div>
        </div>
      </ng-template>
      <ng-template let-country #item>
        <div class="flex items-center gap-2">
          <img
            alt="flag"
            src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
            [class]="'flag flag-' + country.code.toLowerCase()"
            style="width: 18px"
          />
          <div>{{ country.name }}</div>
        </div>
      </ng-template>
    </p-select>
  </p-fieldset>
</div>
