<h3 class="text-2xl font-semibold mb-8">Confirm Your email</h3>
<div class="mb-4">
  Enter the verification code we emailed
  <a (click)="showRegistration()" class="font-bold cursor-pointer">
    Use a different email
  </a>
</div>
<form [formGroup]="verification" (ngSubmit)="verifyEmail()">
  <div class="mb-4 flex justify-center">
    <p-inputotp formControlName="code" [length]="6" />
  </div>
  <div class="mb-4 flex justify-end items-center">
    <a (click)="resendCode()" class="font-bold cursor-pointer mr-4">
      Resend code
    </a>
    <p-button type="submit" label="Continue" size="large" />
  </div>
</form>
