import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientprofileheaderComponent } from '../../shared/components/clientprofileheader/clientprofileheader.component';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';

import { PeronalinformationComponent } from '../../shared/components/clientprofile/peronalinformation/peronalinformation.component';
import { OrdersandcardComponent } from '../../shared/components/clientprofile/ordersandcard/ordersandcard.component';
import { ContractsComponent } from '../../shared/components/clientprofile/contracts/contracts.component';
interface Navigation {
  name: string;
  key: string;
  imageWhite: string;
  imageBlack: string;
  isCommingSoon: boolean;
}

@Component({
  selector: 'app-clientprofile',
  standalone: true,
  imports: [CommonModule, FormsModule, ClientprofileheaderComponent, DropdownModule, RadioButtonModule, InputSwitchModule, InputTextareaModule, DialogModule, PeronalinformationComponent, OrdersandcardComponent, ContractsComponent],
  templateUrl: './clientprofile.component.html',
  styleUrl: './clientprofile.component.scss'
})
export class ClientprofileComponent {
  currentStep: string = 'oci';
  leftSideList: Navigation[] | undefined

  tabStep(step: string) {
    this.currentStep = step
  }

  ngOnInit() {

    this.leftSideList = [
      { name: "Boards", key: "bd", isCommingSoon: true, imageWhite: "public-profile-white", imageBlack: "public-profle-black" },
      { name: "Contracts", key: "cn", isCommingSoon: false, imageWhite: "public-profile-white", imageBlack: "public-profle-black" },
      { name: "Log in & Security", key: "lis", isCommingSoon: false, imageWhite: "login-security-white", imageBlack: "login-security-black" },
      { name: "Messages", key: "mc", isCommingSoon: true, imageWhite: "messages-white", imageBlack: "messages-black" },
      { name: "Order & Card Info", key: "oci", isCommingSoon: false, imageWhite: "messages-white", imageBlack: "messages-black" },
      { name: "Personal Information", key: "pi", isCommingSoon: false, imageWhite: "personal-information-white", imageBlack: "personal-information-black" },
      { name: "Privacy & Sharing", key: "ps", isCommingSoon: false, imageWhite: "privacy-sharing-white", imageBlack: "privacy-sharing-black" }
    ]
  }

}
