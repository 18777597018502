import { Component } from '@angular/core';
import { TabViewModule } from 'primeng/tabview';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'app-ordersandcard',
  standalone: true,
  imports: [
    TabViewModule,
    CommonModule,
    TableModule,
    RadioButtonModule,
    FormsModule,
    DialogModule,
    DropdownModule
  ],
  templateUrl: './ordersandcard.component.html',
  styleUrl: './ordersandcard.component.scss'
})
export class OrdersandcardComponent {
  selectedAccountId: number | null = null;
  addBackUpCard: boolean = true

  popupopen: boolean = false

  cards: [] | any;
  selectedCard = { name: 'Mastercard', src: 'mastercard.svg', code: 'mc' };

  orders: [] | any;

  openPopup() {
    this.popupopen = true
  }
  ngOnInit() {
    this.cards = [
      { name: 'Mastercard', src: 'mastercard.svg', code: 'mc' }
    ];

    this.orders = [
      {
        id: '1000',
        freeLancer: "Lana Del Rey",
        src:'',
        projectType: 'Vocal Coaching',
        date: '31/08/24',
        total: '$1350',
        status: "1",
        statusTitle:'Completed'
      },
      {
        id: '1001',
        freeLancer: "Pharrell Williams",
        src:'',
        projectType: 'Software Development',
        date: '31/08/24',
        total: '$3500',
        status: "2",
        statusTitle:'In Progress'
      },{
        id: '1002',
        freeLancer: "Lana Del Rey",
        src:'',
        projectType: 'Vocal Coaching',
        date: '31/08/24',
        total: '$1350',
        status: "3",
        statusTitle: "Payment Due"
      }]
  }

}
