import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

type User = {
  email: string;
  currentStep: string;
  verified: boolean;
  name: {
    first: string;
    middle: string;
    last: string;
  },
  personal: {
    country: string;
    phone: string;
    sex: string;
    address1: string;
    address2: string;
    city: string;
    postalCode: string;
    idType: string;
    idNumber: string;
    tinOrPin: string;
  },
  banking: {
    bank: string;
    branch: string;
    branchNumber: string;
    accountNumber: string;
  }
  publicProfile: {
    displayMiddleName: boolean;
    displayLastName: boolean;
    category: string;
    about: string;
    education: Array<{
      country: string;
      school: string;
      degree: string;
      major: string;
      year: number;
    }>;
    languages: Array<{
      name: string;
      level: string;
    }>;
    skills: Array<{
      name: string;
      level: string;
    }>;
    externalLinks: {
      upwork: string;
      fiverr: string;
    }
  },
  profileVerification: {
    front: string;
    back: string;
    selfie: string;
  }
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) { }

  register(userRegisterInfo: {email: string, password: string}) {
    return this.http.post('/users', userRegisterInfo);
  }

  verifyEmail(email: string, code: string) {
    return this.http.post<string>('/auth/verify-email', {email, code});
  }

  login(email: string, password: string) {
    return this.http.post<{access_token: string}>('/auth/login', {email, password});
  }

  profile() {
    return this.http.get<User>('/auth/profile');
  }

  patch(userData: Partial<User>) {
    return this.http.patch('/users/me', userData);
  }
}
