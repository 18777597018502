<form *ngIf="form" [formGroup]="form">
  <div class="flex justify-end items-center">
    Add up to 5 &nbsp;
    <p-button
      icon="pi pi-plus"
      size="large"
      [rounded]="true"
      (onClick)="addNew()"
      [disabled]="formArray.controls.length >= 5"
    />
  </div>
  <div [formArrayName]="section">
    <ng-container *ngFor="let entry of formArray.controls; let i = index">
      <div class="mb-4" *ngIf="entry.valid">
        <p-card header="{{ entry.value.degree }}, {{ entry.value.school }}">
          <p class="m-0">
            {{ entry.value.degree }} in {{ entry.value.major }} from
            {{ entry.value.school }} in {{ entry.value.country }}, graduating in
            {{ entry.value.year }}.
          </p>
          <div class="flex justify-end">
            <p-button
              icon="pi pi-pencil"
              [rounded]="true"
              severity="secondary"
              (onClick)="edit(i)"
              size="small"
              [text]="true"
            />
            <p-button
              icon="pi pi-times"
              [rounded]="true"
              severity="secondary"
              (onClick)="remove(i)"
              size="small"
              [text]="true"
            />
          </div>
        </p-card>
      </div>
    </ng-container>
  </div>
</form>

<p-dialog
  [modal]="true"
  [(visible)]="visible"
  [style]="{ width: '50rem', maxWidth: '100%' }"
>
  <form [formGroup]="innerForm" (ngSubmit)="save()">
    <div class="mb-4">
      <app-shared-country-select
        formControlName="country"
      ></app-shared-country-select>
    </div>
    <div class="mb-4">
      <p-fieldset legend="Degree">
        <p-select
          [options]="educationLevels"
          [filter]="true"
          [showClear]="true"
          placeholder="Degree"
          fluid="true"
          size="large"
          formControlName="degree"
          appendTo="body"
        />
      </p-fieldset>
    </div>
    <div class="mb-4">
      <div class="flex gap-2">
        <app-shared-text-input
          formControlName="school"
          label="School"
        ></app-shared-text-input>
      </div>
    </div>
    <div class="mb-4">
      <div class="flex gap-2">
        <div class="w-2/3">
          <app-shared-text-input
            formControlName="major"
            label="Major"
          ></app-shared-text-input>
        </div>
        <div class="w-1/3">
          <p-floatlabel variant="in">
            <p-inputnumber
              id="year"
              autocomplete="off"
              formControlName="year"
              fluid="true"
              [useGrouping]="false"
              [min]="1900"
              [max]="2025"
              maxlength="4"
            />
            <label for="year">Year</label>
          </p-floatlabel>
        </div>
      </div>
    </div>
    <div class="flex py-6 gap-2 justify-end">
      <p-button label="Cancel" severity="secondary" (onClick)="cancel()" />
      <p-button label="Save" type="submit" [disabled]="!innerForm.valid" />
    </div>
  </form>
</p-dialog>
