<div class="container mb-5">
    <div class="hide-in-mobile hide-in-tablet">
        <app-profileheader [profile]="profile"></app-profileheader>
    </div>

    <div class="register-main-wrapper-inner">
        <div class="left-side hide-in-mobile">
            <div class="step-wrapper">
                <div class="stepper">
                    <div class="step" [class.active]="currentStep === 1" [class.completed]="currentStep > 1"
                        (click)="goToNextStep(1)">
                        <div class="step-number">
                            <ng-container *ngIf="currentStep > 1; else step1Number">
                                <img src="../../../assets/images/icons/correct-primary-icon.svg" alt="Completed Icon 1">
                            </ng-container>
                            <ng-template #step1Number>1</ng-template>
                        </div>
                        <div *ngIf="currentStep === 1 || currentStep > 1" class="step-title">Personal Information</div>
                    </div>

                    <div class="step" [class.active]="currentStep === 2" [class.completed]="currentStep > 2"
                        (click)="goToNextStep(2)">
                        <div class="step-number">
                            <ng-container *ngIf="currentStep > 2; else step2Number">
                                <img src="../../../assets/images/icons/correct-primary-icon.svg" alt="Completed Icon 2">
                            </ng-container>
                            <ng-template #step2Number>2</ng-template>
                        </div>
                        <div *ngIf="currentStep === 2 || currentStep > 2" class="step-title">Banking Information</div>
                    </div>

                    <div class="step" [class.active]="currentStep === 3" [class.completed]="currentStep > 3"
                        (click)="goToNextStep(3)">
                        <div class="step-number">
                            <ng-container *ngIf="currentStep > 3; else step3Number">
                                <img src="../../../assets/images/icons/correct-primary-icon.svg" alt="Completed Icon 3">
                            </ng-container>
                            <ng-template #step3Number>3</ng-template>
                        </div>
                        <div *ngIf="currentStep === 3 || currentStep > 3" class="step-title">Public Profile Setup</div>
                    </div>

                    <div class="step" [class.active]="currentStep === 4" [class.completed]="currentStep > 4"
                        (click)="goToNextStep(4)">
                        <div class="step-number">
                            <ng-container *ngIf="currentStep > 4; else step4Number">
                                <img src="../../../assets/images/icons/correct-primary-icon.svg" alt="Completed Icon 4">
                            </ng-container>
                            <ng-template #step4Number>4</ng-template>
                        </div>
                        <div *ngIf="currentStep === 4 || currentStep > 4" class="step-title">Verification</div>
                    </div>

                    <div class="step" [class.active]="currentStep === 5" [class.completed]="currentStep > 5"
                        (click)="goToNextStep(5)">
                        <div class="step-number">
                            <ng-container *ngIf="currentStep > 5; else step5Number">
                                <img src="../../../assets/images/icons/correct-primary-icon.svg" alt="Completed Icon 5">
                            </ng-container>
                            <ng-template #step5Number>5</ng-template>
                        </div>
                        <div *ngIf="currentStep === 5 || currentStep > 5" class="step-title">Upload Your First Gig</div>
                    </div>
                </div>

            </div>
        </div>
        <!-- //mobile step section  -->
        <div class="top-step-wrapper hide-in-desktop hide-in-tablet">
            <div class="step-wrapper">
                <div class="stepper">
                    <div class="step" [class.active]="currentStep === 1" [class.completed]="currentStep > 1"
                        (click)="goToNextStep(1)">
                        <div class="step-number">
                            <ng-container *ngIf="currentStep > 1; else step1Number">
                                <div>1</div>
                                <img src="../../../assets/images/icons/orange-tic-icon.svg" alt="Completed Icon 1">
                            </ng-container>
                            <ng-template #step1Number>1</ng-template>
                        </div>
                    </div>

                    <div class="step" [class.active]="currentStep === 2" [class.completed]="currentStep > 2"
                        (click)="goToNextStep(2)">
                        <div class="step-number">
                            <ng-container *ngIf="currentStep > 2; else step2Number">
                                <div>2</div>
                                <img src="../../../assets/images/icons/orange-tic-icon.svg" alt="Completed Icon 2">
                            </ng-container>
                            <ng-template #step2Number>2</ng-template>
                        </div>
                    </div>

                    <div class="step" [class.active]="currentStep === 3" [class.completed]="currentStep > 3"
                        (click)="goToNextStep(3)">
                        <div class="step-number">
                            <ng-container *ngIf="currentStep > 3; else step3Number">
                                <div>3</div>
                                <img src="../../../assets/images/icons/orange-tic-icon.svg" alt="Completed Icon 3">
                            </ng-container>
                            <ng-template #step3Number>3</ng-template>
                        </div>
                    </div>

                    <div class="step" [class.active]="currentStep === 4" [class.completed]="currentStep > 4"
                        (click)="goToNextStep(4)">
                        <div class="step-number">
                            <ng-container *ngIf="currentStep > 4; else step4Number">
                                <div>4</div>
                                <img src="../../../assets/images/icons/orange-tic-icon.svg" alt="Completed Icon 4">
                            </ng-container>
                            <ng-template #step4Number>4</ng-template>
                        </div>
                    </div>

                    <div class="step" [class.active]="currentStep === 5" [class.completed]="currentStep > 5"
                        (click)="goToNextStep(5)">
                        <div class="step-number">
                            <ng-container *ngIf="currentStep > 5; else step5Number">
                                <div>5</div>
                                <img src="../../../assets/images/icons/orange-tic-icon.svg" alt="Completed Icon 5">
                            </ng-container>
                            <ng-template #step5Number>5</ng-template>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="right-side">
            <div class="step-templates-wrapper">
                <!-- //personal information step 1 -->
                <div [hidden]="currentStep !== 1">
                    <div class="public-profile-wrapper">
                        <form [formGroup]="personalInfo" (ngSubmit)="onPersonalInfoSubmit()">
                            <h2 class="hide-in-desktop hide-in-tablet wrapper-title">Personal Information</h2>
                            <div class="input-wrapper">
                                <label class="label-secondary" for="">Name</label>
                                <h4>{{profile?.name?.first}} {{profile?.name?.middle}} {{profile?.name?.last}} <span class="tooltip-icon-wrapper"><img class="tooltip-icon"
                                            (click)="openPopup()" src="../../../assets/images/icons/info-icon-tooltip.png"
                                            alt=""></span></h4>
                            </div>

                            <hr>

                            <div class="input-wrapper">
                                <label class="label-secondary" for="">Country</label>
                                <p-dropdown (onChange)="onProfileCountryChange()" formControlName="country" [options]="countries" optionLabel="name" placeholder="Your Country"
                                    class="country-wrapper w-40 m-w100" />
                            </div>

                            <hr>

                            <div class="input-wrapper phone-number">
                                <label class="label-secondary" for="">Phone Number</label>
                                <p-iconField iconPosition="right">
                                    <p-inputIcon styleClass="pi pi-spinner pi-spin" />
                                    <input type="text" formControlName="phone" pInputText />
                                </p-iconField>
                            </div>
                            
                            <hr>

                            <div class="input-wrapper">
                                <label class="label-secondary" for="">Sex</label>
                                <div class="field-checkbox">
                                    <div class="">
                                        <label for="sexMale" class="ml-2">
                                            Male
                                        </label>
                                    </div>
                                    <div>
                                        <p-radioButton value="Male" formControlName="sex"
                                            inputId="sexMale" />
                                    </div>
                                </div>
                                <div class="field-checkbox">
                                    <div class="">
                                        <label for="sexFemale" class="ml-2">
                                            Female
                                        </label>
                                    </div>
                                    <div>
                                        <p-radioButton value="Female" formControlName="sex"
                                            inputId="sexFemale" />
                                    </div>
                                </div>
                            </div>

                            
                            <hr>

                            <div class="input-wrapper address-wrapper">
                                <label for="" class="label-secondary">Address</label>
                                <input formControlName="address1" type="text" class="register mb-1 m-w100" placeholder="Address">
                                <input formControlName="address2" type="text" class="register mb-1 m-w100" placeholder="Apartment, suite, etc.">
                                <input formControlName="city" type="text" class="register mb-1 m-w100" placeholder="City">
                                <input formControlName="postalCode" type="text" class="register mb-1 m-w40" placeholder="Postal code">
                            </div>

                            <div class="input-wrapper">
                                <label for="" class="label-secondary">ID Type</label>
                                <div class="field-checkbox">
                                    <div class="">
                                        <label for="idDriversLicense" class="ml-2">
                                            Driver's License
                                        </label>
                                    </div>
                                    <div>
                                        <p-radioButton value="DRIVERS_LICENSE" formControlName="idType"
                                            inputId="idDriversLicense" />
                                    </div>
                                </div>
                                <div class="field-checkbox">
                                    <div class="">
                                        <label for="idPassport" class="ml-2">
                                            Passport
                                        </label>
                                    </div>
                                    <div>
                                        <p-radioButton value="PASSPORT" formControlName="idType"
                                            inputId="idPassport" />
                                    </div>
                                </div>
                                <div class="field-checkbox">
                                    <div class="">
                                        <label for="idNationalIDNumber" class="ml-2">
                                            National ID
                                        </label>
                                    </div>
                                    <div>
                                        <p-radioButton value="NATIONAL_ID" formControlName="idType"
                                            inputId="idNationalIDNumber" />
                                    </div>
                                </div>
                            </div>

                            <hr>

                            <div class="input-wrapper">
                                <label for="" class="label-secondary">{{ getIdFieldLabel(personalInfo.value.idType) }}</label>
                                <input type="text" formControlName="idNumber" class="register w-40 m-w100 t-w80" placeholder="">
                            </div>

                            <hr>

                            <div class="input-wrapper tin-pin-wrapper">
                                <label for="" class="label-secondary">TIN or PIN </label>
                                <input type="text" class="register w-40 m-w100 t-w80" placeholder="" formControlName="tinOrPin">
                            </div>

                            <hr>

                            <div class="button-wrapper-step">
                                <button [disabled]="!personalInfo.valid" class="primary-1-button w-fit">Next</button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- //banking information step 2 -->
                <div [hidden]="currentStep !== 2">
                    <div class="banking-information-wrapper">
                        <form [formGroup]="bankingInfo" (ngSubmit)="onBankingInfoSubmit()">
                            <h2 class="hide-in-desktop hide-in-tablet wrapper-title">Banking Information</h2>
                            <div class="input-wrapper">
                                <label for="" class="label-secondary">Name</label>
                                <h4>{{profile?.name?.first}} {{profile?.name?.middle}} {{profile?.name?.last}} <span class="tooltip-icon-wrapper"><img class="tooltip-icon"
                                            (click)="openPopup()" src="../../../assets/images/icons/info-icon-tooltip.png"
                                            alt=""></span></h4>
                            </div>

                            <div class="input-wrapper">
                                <label for="" class="label-secondary">Bank Name</label>
                                <p-dropdown formControlName="bank" [options]="banks" optionLabel="name" placeholder="Your Bank"
                                    class="bank-name m-w100" (onChange)="onBankingBankChange()" />
                            </div>
                            <div class="input-wrapper">
                                <label for="" class="label-secondary">Branch Name</label>
                                <p-dropdown formControlName="branch" [options]="branches" placeholder="Branch"
                                    class="branch-name m-w100" />
                            </div>

                            <div class="input-wrapper branch-number-wrapper">
                                <label for="" class="label-secondary">Branch Number</label>
                                <input formControlName="branchNumber" type="text" class="register input-with-image-left" placeholder="Branch Number">
                            </div>

                            <div class="input-wrapper">
                                <label for="" class="label-secondary">Account Number</label>
                                <div class="input-container account-number m-w100">
                                    <img src="../../../assets/images/banks/bank-nsb.png" alt="icon" class="input-icon">
                                    <input formControlName="accountNumber" type="number" min="0" class="register input-with-image-left" placeholder="Enter text here">
                                </div>
                            </div>
                            <div class="input-wrapper">
                                <label for="" class="label-secondary">Re-enter Account Number</label>
                                <div class="input-container account-number m-w100">
                                    <img src="../../../assets/images/banks/bank-nsb.png" alt="icon" class="input-icon">
                                    <input formControlName="accountNumberConfirm" type="number" class="register input-with-image-left" placeholder="Enter text here">
                                </div>
                            </div>

                            <div class="button-wrapper-step">
                                <button class="primary-1-button w-fit" [disabled]="!bankingInfo.valid">Next</button>
                            </div>
                        </form>
                    </div>

                </div>
                <!-- //edit publi profile step 3  -->
                <div [hidden]="currentStep !== 3">
                    <div class="public-profile-setup-wrapper">
                        <form [formGroup]="publicProfile" (ngSubmit)="onPublicProfileSubmit()">
                            <h2 class="hide-in-desktop hide-in-tablet wrapper-title">Edit Public Profile <br />
                                <small class="text-danger">All sections are required</small>
                            </h2>

                            <div class="input-wrapper">
                                <label class="label-secondary" for="">Name</label>
                                <h4>{{profile?.name?.first}} {{profile?.name?.middle}} {{profile?.name?.last}} <span class="tooltip-icon-wrapper"><img class="tooltip-icon"
                                            (click)="openPopup()" src="../../../assets/images/icons/info-icon-tooltip.png"
                                            alt=""></span></h4>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-12 col-md-4 col-lg-3">
                                    <div class="input-wrapper">
                                        <label class="label-secondary" for="">Middle Name</label>
                                        <h4>{{ profile?.name?.middle }}</h4>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-6">
                                    <div class="input-wrapper last-initial-wrapper do-not-diplay-input-switch">
                                        <label for="">Display on the public profile <span><p-inputSwitch formControlName="displayMiddleName" /></span></label>
                                    </div>
                                </div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-12 col-md-4 col-lg-3">
                                    <div class="input-wrapper">
                                        <label class="label-secondary" for="">Last Name</label>
                                        <h4>{{ profile?.name?.last }}</h4>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-6">
                                    <div class="input-wrapper last-initial-wrapper do-not-diplay-input-switch">
                                        <label for="">Display on the public profile <span><p-inputSwitch formControlName="displayLastName" /></span></label>
                                    </div>
                                </div>
                            </div>

                            <hr>

                            <div class="input-wrapper">
                                <label for="" class="label-secondary">Category</label>
                                <p-dropdown formControlName="category" [options]="categories" optionLabel="name" placeholder="Category"
                                    class="category-dropdown m-w100" />
                            </div>

                            <hr>

                            <div class="input-wrapper about-main-wrapper">
                                <label class="label-secondary" for="">About</label>
                                <div class="about-input-wrapper">
                                    <h4 class="about-small-text mb-1">Write an introductory description about yourself.</h4>
                                    <textarea class="text-area" formControlName="about" rows="7" cols="30" pInputTextarea></textarea>
                                </div>
                            </div>

                            <hr>

                            <!-- education  -->
                            <label for="" class="label-secondary">Education <button type="button" (click)="addEducationSection()">Add</button></label>
                            <div class="add-education-main-wrapper" formArrayName="education">
                                <ng-container *ngFor="let educcationForm of educationForms.controls; let i = index">
                                    <div [formGroupName]="i">
                                        <div class="input-wrapper">
                                            <h4 class="about-small-text">Country of school</h4>
                                            <p-dropdown formControlName="country" [options]="countries" optionLabel="name" placeholder=""
                                                class="country-school-dropdown m-w100" />
                                        </div>
                                        <div class="input-wrapper">
                                            <h4 class="about-small-text">School name</h4>
                                            <input formControlName="school" type="text" class="mb-0 register school-name m-w100" placeholder="">
                                        </div>
                                        <div class="digree-and-major-wrapper">
                                            <div class="input-wrapper m-w60">
                                                <h4 class="about-small-text">Degree</h4>
                                                <p-dropdown formControlName="degree" [options]="degrees" placeholder=""
                                                    class="degree-dropdown" />
                                            </div>
                                            <div class="input-wrapper m-w60">
                                                <h4 class="about-small-text">Major</h4>
                                                <input formControlName="major" type="text" class="mb-0 register major-input-field" placeholder="">
                                            </div>
                                        </div>
                                        <div class="input-wrapper">
                                            <h4 class="about-small-text">Year of graduation</h4>
                                            <p-dropdown formControlName="year" [options]="years" placeholder=""
                                                class="year-of-gradu-drop" />
                                        </div>
                                    </div>

                                    
                                    <button type="button" (click)="removeEducationSection(i)">Remove</button>
                                    <hr>
                                </ng-container>
                            </div>

                            <hr>

                            <!-- //languages -->
                            <label for="" class="label-secondary mb-0">Languages <button type="button" (click)="addLanguageSection()">Add</button></label>
                            <p class="small mb-1">Only 3 allowed</p>
                            <div class="language-wrapper-main" formArrayName="languages">
                                <ng-container *ngFor="let languagesForm of languagesForms.controls; let i = index">
                                    <div [formGroupName]="i">
                                        <div class="langague-main-first m-w100 t-w80">
                                            <div class="input-wrapper">
                                                <h4 class="about-small-text">Language</h4>
                                                <p-dropdown formControlName="name" [options]="languages" placeholder="" class="" />
                                            </div>
                                        </div>
                                        <div class="language-main-second">
                                            <div class="input-wrapper mb-0">
                                                <h4 class="about-small-text">Fluency Level</h4>
                                            </div>
                                            <div class="radio-wrapper">
                                                <div class="radio-container" *ngFor="let skill of langSkills">
                                                    <input formControlName="level" type="radio" id="language_{{skill}}_{{ i }}" [value]="skill" />
                                                    <label for="language_{{skill}}_{{ i }}" class="radio-label">{{ skill }}</label>
                                                </div>
                                            </div>
                                        </div>

                                        <button type="button" (click)="removeLanguageSection(i)">Remove</button>
                                    </div>
                                </ng-container>
                            </div>
                            
                            <hr>

                            <!-- //languages -->
                            <label for="" class="label-secondary mb-0">Skills <button type="button" (click)="addSkillSection()">Add</button></label>
                            <p class="small mb-1">Only 10 allowed</p>
                            <div class="skill-main-wrapper mb-5" formArrayName="skills">
                                <ng-container *ngFor="let skillForm of skillsForms.controls; let i = index">
                                    <div [formGroupName]="i">
                                        <div class="skill-main-first m-w100 t-w80">
                                            <div class="input-wrapper">
                                                <h4 class="about-small-text">Skill</h4>
                                                <p-dropdown formControlName="name" [options]="publicProfile.value.category.skills" placeholder="" class="" />
                                            </div>
                                        </div>
                                        <div class="skill-main-second">
                                            <div class="input-wrapper mb-0">
                                                <h4 class="about-small-text">Experience Level</h4>
                                            </div>
                                            <div class="radio-wrapper">
                                                <div class="radio-container" *ngFor="let skill of skillLevels">
                                                    <input formControlName="level" type="radio" id="language_{{skill}}_{{ i }}" [value]="skill" />
                                                    <label for="language_{{skill}}_{{ i }}" class="radio-label">{{ skill }}</label>
                                                </div>
                                            </div>
                                        </div>

                                        <button type="button" (click)="removeSkillSection(i)">Remove</button>
                                    </div>
                                </ng-container>
                            </div>
                            
                            <hr>

                            <!-- //profiles on other plat -->
                            <label for="" class="label-secondary mb-1">Profiles on Other Platforms</label>

                            <ng-container [formGroup]="externalLinksForm">
                                <div class="input-wrapper">
                                    <h4 class="about-small-text ot-plat-label">Upwork</h4>
                                    <input formControlName="upwork" type="url" class="w-40 register m-w100 t-w80"
                                        placeholder="Copy & paste your Upwork profile link here">
                                </div>
    
                                <div class="input-wrapper">
                                    <h4 class="about-small-text ot-plat-label">Fiverr</h4>
                                    <input formControlName="fiverr" type="url" class="w-40 register m-w100 t-w80"
                                        placeholder="Copy & paste your Fiverr profile link here">
                                </div>
                            </ng-container>

                            <hr>

                            <div class="button-wrapper-step">
                                <button class="primary-1-button w-fit" [disabled]="!publicProfile.valid">Next</button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- //verifications step 4  -->
                <div [hidden]="currentStep !== 4">
                    <form [formGroup]="profileVerification" (ngSubmit)="onProfileVerificationSubmit()">
                        <div class="verification-wrapper">
                            <h2 class="hide-in-desktop hide-in-tablet wrapper-title">Verification
                            </h2>
                            <label class="label-secondary" for="">Identity Card</label>
                            <h4 class="about-small-text mb-2">Make sure your photos aren’t blurry and the front of your
                                identity card clearly shows your face</h4>

                            <div class="image-upload-wrapper">
                                <div class="main-card front-image">
                                    <p-fileUpload 
                                            name="images[]"
                                            [multiple]="true" 
                                            accept="image/*" 
                                            fileLimit="1"
                                            (onSelect)="onUpload($event, pvFront)" 
                                            maxFileSize="1000000">
                                            <ng-template pTemplate="content">
                                                <!-- <ul *ngIf="gigInfo.value.images.length">
                                                    <li *ngFor="let file of gigInfo.value.images">
                                                        {{ file.name }} - {{ file.size }} bytes
                                                    </li>
                                                </ul> -->
                                            </ng-template>
                                        </p-fileUpload>
                                    <h6>Upload front of ID</h6>
                                    <p>JPEG or PNG only</p>
                                </div>
                                <div class="main-card back-image">
                                    <p-fileUpload 
                                            name="images[]"
                                            [multiple]="true" 
                                            accept="image/*" 
                                            fileLimit="1"
                                            (onSelect)="onUpload($event, pvBack)" 
                                            maxFileSize="1000000">
                                            <ng-template pTemplate="content">
                                                <!-- <ul *ngIf="gigInfo.value.images.length">
                                                    <li *ngFor="let file of gigInfo.value.images">
                                                        {{ file.name }} - {{ file.size }} bytes
                                                    </li>
                                                </ul> -->
                                            </ng-template>
                                        </p-fileUpload>
                                    <h6>Upload back of ID</h6>
                                    <p>JPEG or PNG only</p>
                                </div>
                            </div>

                            <hr>

                            <label class="label-secondary" for="">Verification Selfie</label>
                            <h4 class="about-small-text mb-1">Upload a selfie of yourself while adhering to following
                                guidelines : </h4>
                            <div class="selfie-tips-wrapper">
                                <div class="selfie-tips">
                                    <ol>
                                        <li>
                                            <span class="tips-number-text">Clear and Focused:</span>
                                            <span class="tips-normal-text">The selfie must be sharp and not blurry.</span>
                                        </li>
                                        <li>
                                            <span class="tips-number-text">Well-Lit:</span>
                                            <span class="tips-normal-text">Use good lighting, preferably natural light,
                                                ensuring there are no shadows on your face.</span>
                                        </li>
                                        <li>
                                            <span class="tips-number-text">Face Centered:</span>
                                            <span class="tips-normal-text">Your face should be centered in the frame.</span>
                                        </li>
                                        <li>
                                            <span class="tips-number-text">Neutral Background:</span>
                                            <span class="tips-normal-text">Use a plain, uncluttered background.</span>
                                        </li>
                                        <li>
                                            <span class="tips-number-text">No Obstructions:</span>
                                            <span class="tips-normal-text">Ensure no hats, sunglasses, or masks are covering
                                                your face.</span>
                                        </li>
                                        <li>
                                            <span class="tips-number-text">Neutral Expression:</span>
                                            <span class="tips-normal-text">Maintain a neutral expression, avoiding extreme
                                                facial expressions.</span>
                                        </li>
                                        <li>
                                            <span class="tips-number-text">Single Person:</span>
                                            <span class="tips-normal-text">Only your face should be visible; no other people
                                                should be in the photo.</span>
                                        </li>
                                        <li>
                                            <span class="tips-number-text">Recent Photo:</span>
                                            <span class="tips-normal-text">The selfie should be a recent photo that
                                                accurately represents your current appearance.</span>
                                        </li>
                                        <li>
                                            <span class="tips-number-text">No Filters or Effects:</span>
                                            <span class="tips-normal-text">Do not use any camera filters or effects.</span>
                                        </li>
                                        <li>
                                            <span class="tips-number-text">Full Face Visible:</span>
                                            <span class="tips-normal-text">Ensure your entire face, from the top of your head
                                                to your chin, is visible in the selfie.</span>
                                        </li>

                                    </ol>
                                </div>
                                <div class="selfie-tips-images">
                                    <img src="../../../assets/images/thumbnails/selfie-tips-image.png" alt="">
                                </div>
                            </div>
                            <div class="main-card back-image">
                                <p-fileUpload 
                                            name="images[]"
                                            [multiple]="true" 
                                            accept="image/*" 
                                            fileLimit="1"
                                            (onSelect)="onUpload($event, pvSelfie)" 
                                            maxFileSize="1000000">
                                            <ng-template pTemplate="content">
                                                <!-- <ul *ngIf="gigInfo.value.images.length">
                                                    <li *ngFor="let file of gigInfo.value.images">
                                                        {{ file.name }} - {{ file.size }} bytes
                                                    </li>
                                                </ul> -->
                                            </ng-template>
                                        </p-fileUpload>
                                <h6>Verification selfie</h6>
                                <p>JPEG or PNG only</p>
                            </div>

                            <hr>

                            <div class="button-wrapper-step">
                                <button class="primary-1-button w-fit" [disabled]="!profileVerification.valid">Next</button>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- //uploading first gig  -->
                <div [hidden]="currentStep !== 5">
                    <div class="gig-create-first">
                        <h2 class="hide-in-desktop hide-in-tablet wrapper-title">Upload Your First Gig</h2>
                        <div class="horizantal-step-section">
                            <div class="step-wrapper">
                                <div class="stepper">
                                    <div class="step" [class.active]="currentStepGig === 1" (click)="goToGigStep(1)">
                                        <div *ngIf="currentStepGig > 1" class="icon"><img
                                                src="../../../assets/images/icons/gig-complete-mark.png" alt="">
                                        </div>
                                        <div class="step-title" [class.active]="currentStepGig === 1">Overview</div>
                                        <div *ngIf="currentStepGig < 5" class="line"></div>
                                    </div>
                                    <div class="step" [class.active]="currentStepGig === 2" (click)="goToGigStep(2)">
                                        <div *ngIf="currentStepGig > 2" class="icon"><img
                                                src="../../../assets/images/icons/gig-complete-mark.png" alt="">
                                        </div>
                                        <div class="step-title" [class.active]="currentStepGig === 2">Pricing</div>
                                        <div *ngIf="currentStepGig < 5" class="line"></div>
                                    </div>
                                    <div class="step" [class.active]="currentStepGig === 3" (click)="goToGigStep(3)">
                                        <div *ngIf="currentStepGig > 3" class="icon"><img
                                                src="../../../assets/images/icons/gig-complete-mark.png" alt="">
                                        </div>
                                        <div class="step-title" [class.active]="currentStepGig === 3">Requirements
                                        </div>
                                        <div *ngIf="currentStepGig < 5" class="line"></div>
                                    </div>
                                    <div class="step" [class.active]="currentStepGig === 4" (click)="goToGigStep(4)">
                                        <div *ngIf="currentStepGig > 4" class="icon"><img
                                                src="../../../assets/images/icons/gig-complete-mark.png" alt="">
                                        </div>
                                        <div class="step-title" [class.active]="currentStepGig === 4">Gallery</div>
                                        <div *ngIf="currentStepGig < 5" class="line"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <form [formGroup]="gigInfo" (ngSubmit)="onGigInfoSubmit()">
                            <!-- //gig overview gigstep 1 -->
                            <div [hidden]="currentStepGig !== 1">
                                <div class="input-wrapper mb-1">
                                    <label for="" class="label-secondary">Gig Title</label>
                                    <input formControlName="title" type="text" class="mb-0 register gig-title-input"
                                        placeholder="I will do something 1'm really good at">
                                    <small class="text-right gig-title-input">(0/80)</small>
                                </div>

                                <div class="input-wrapper">
                                    <label class="label-secondary" for="">Gig Description</label>
                                    <!-- <textarea rows="7" cols="30" class="text-area" id="textEditor">
                                    </textarea> -->
                                    <quill-editor formControlName="description" #descriptionQuill class="gig-description" placeholder="Enter Text"
                                        [modules]="quillConfig"></quill-editor>
                                    <small class="text-right">(0/1200)</small>
                                </div>

                                <!-- <div class="input-wrapper">
                                    <label class="label-secondary" for="">Category</label>
                                    <div class="category-dropdown-wrapper">
                                        <p-dropdown [options]="cities" optionLabel="name" placeholder="Select a Category"
                                            class="m-w100" />
                                        <p-dropdown [options]="cities" optionLabel="name" placeholder="Select a Subcategory"
                                            class="m-w100" />
                                    </div>
                                </div> -->
                                <div class="button-wrapper-step">
                                    <button type="button" class="primary-1-button w-fit" (click)="goToGigStep(2)">Next</button>
                                </div>
                            </div>
                            <!-- //gig pricing gigstep 2 -->
                            <div [hidden]="currentStepGig !== 2">
                                <div class="input-wrapper">
                                    <label for="" class="label-secondary">Starting Price</label>
                                    <input formControlName="startingPrice" type="number" class="mb-0 w-60 register t-w80" placeholder="$USD">
                                    <small class="price-starting-small-text">(Starting price should be higher than
                                        $100)</small>
                                </div>
                                <div class="button-wrapper">
                                    <div class="w-fit">
                                        <button type="button" class="back-button-underline" (click)="goToGigStep(1)"><span><img
                                                    src="../../../../assets/images/icons/back-arrow-2.png"
                                                    alt=""></span>Back</button>
                                    </div>
                                    <div class="w-fit">
                                        <button type="button" class="primary-1-button" (click)="goToGigStep(3)">Next</button>
                                    </div>
                                </div>

                            </div>
                            <!-- //gig requirements gigstep 3 -->
                            <div [hidden]="currentStepGig !== 3" formArrayName="requirements">
                                <ng-container *ngFor="let gigRequirement of gigRequirements.controls; let i = index">
                                    <div [formGroupName]="i">
                                        <div class="input-wrapper">
                                            <label class="label-secondary" for="">Add a Question</label>
                                            <textarea formControlName="question" rows="7" cols="30" class="text-area"
                                                placeholder="Request necessary details from the client"></textarea>
                                            <small class="text-right">(0/400)</small>
                                        </div>

                                        <!-- <div class="input-wrapper">
                                            <label class="label-secondary" for="">Get the Answer in a Form of:</label>
                                            <div class="get-anwser-drop m-w100 t-w80">
                                                <p-dropdown [options]="cities" optionLabel="name" placeholder="Free Text"
                                                    class="" />
                                            </div>
                                        </div> -->
                                        <input type="hidden" formControlName="type" />
                                    </div>
                                </ng-container>
                                <div class="button-wrapper">
                                    <div class="w-fit">
                                        <button type="button" class="back-button-underline" (click)="goToGigStep(2)"><span><img
                                                    src="../../../../assets/images/icons/back-arrow-2.png"
                                                    alt=""></span>Back</button>
                                    </div>
                                    <div class="w-fit">
                                        <button type="button" class="primary-1-button" (click)="goToGigStep(4)">Next</button>
                                    </div>
                                </div>
                            </div>

                            <!-- //gig gallery gigstep 4 -->
                            <div [hidden]="currentStepGig !== 4">
                                <label class="label-secondary about-small-text mb-2" for="">To comply with AfterFive’s terms of service, make sure to upload only content you either own <br/> or you have the permission or license to use. (Images of 16:9 ratio are ideal)</label>



                                <div class="image-upload-wrapper">
                                    <p-fileUpload 
                                        name="images[]"
                                        [multiple]="true" 
                                        accept="image/*" 
                                        fileLimit="2"
                                        (onSelect)="onUploadMany($event, gigImages)" 
                                        maxFileSize="1000000">
                                        <ng-template pTemplate="content">
                                            <!-- <ul *ngIf="gigInfo.value.images.length">
                                                <li *ngFor="let file of gigInfo.value.images">
                                                    {{ file.name }} - {{ file.size }} bytes
                                                </li>
                                            </ul> -->
                                        </ng-template>
                                    </p-fileUpload>
                                </div>

                                <div class="button-wrapper">
                                    <div class="w-fit">
                                        <button type="button" class="back-button-underline" (click)="goToGigStep(4)"><span><img
                                                    src="../../../../assets/images/icons/back-arrow-2.png"
                                                    alt=""></span>Back</button>
                                    </div>
                                    <div class="w-fit">
                                        <button class="primary-1-button">Publish Gig</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<!-- //popup  -->
<p-dialog [modal]="true" [(visible)]="popupopen" [style]="{ width: '340px' }" [className]="'registerPopUp'">
    <p class="popup-modal-name-stat">First and last names are prefilled and uneditable because we only transfer money to
        the <img class="profile-name-popup-img" src="../../../assets/images/logo/logo.svg" alt=""> account holder’s
        name.</p>
</p-dialog>