import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';

interface City {
  name: string;
  code: string;
}

interface Year {
  name: string,
  code: string
}

@Component({
  selector: 'app-publicprofile',
  standalone: true,
  imports: [CommonModule, FormsModule, DropdownModule, RadioButtonModule, InputSwitchModule, InputTextareaModule, DialogModule],
  templateUrl: './publicprofile.component.html',
  styleUrl: './publicprofile.component.scss'
})

export class PublicprofileComponent {
  cities: City[] | undefined;
  popupopen: boolean = false

  addedLanguageList: boolean = true;
  isAddNewLanguageClicked: boolean = false
  addNewLanguage: boolean = false
  selectedRadio: string = '';

  addNewEducation: boolean = false;
  educationAddedList: boolean = true;
  isAddNewEductationClicked: boolean = false
  years: Year[] | any;

  addNewSkill: boolean = false;
  addedSkills: boolean = true;
  selectedSkilllevel: string = ''


  radios = [
    { id: 'radio1', value: 'radio1', label: 'Basic' },
    { id: 'radio2', value: 'radio2', label: 'Conversational' },
    { id: 'radio3', value: 'radio3', label: 'Fluent' },
    { id: 'radio4', value: 'radio4', label: 'Native' }
  ];

  skillRadios = [
    { id: 'radio1', value: 'radio1', label: 'Basic' },
    { id: 'radio2', value: 'radio2', label: 'Intermediate' },
    { id: 'radio3', value: 'radio3', label: 'Expert' }
  ]

  openPopup() {
    this.popupopen = true
  }

  ngOnInit() {
    this.cities = [
      { name: 'New York', code: 'NY' },
      { name: 'Rome', code: 'RM' },
      { name: 'London', code: 'LDN' },
      { name: 'Istanbul', code: 'IST' },
      { name: 'Paris', code: 'PRS' }
    ];
  }
}
