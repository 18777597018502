<div style="display: flex;flex-direction: column;min-height: 100vh;">

    <app-navbar *ngIf="checkNavigation == 'main'"></app-navbar>
    <app-user-navigation *ngIf="checkNavigation == 'userNavigation'"></app-user-navigation>
    
    <router-outlet></router-outlet>
    <app-footer></app-footer>

    <dialog>
        <p>Greetings, one and all!</p>
        <form method="dialog">
            <button>OK</button>
        </form>
    </dialog>
</div>