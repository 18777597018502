import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputOtpModule } from 'primeng/inputotp';
import { HomeComponent } from './home/home.component';
import { PublicRoutingModule } from './public-routing.module';
import { LoginRegisterComponent } from './login-register/login-register.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { VerificationComponent } from './verification/verification.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

@NgModule({
  declarations: [
    HomeComponent,
    LoginRegisterComponent,
    LoginComponent,
    RegisterComponent,
    VerificationComponent,
    PageNotFoundComponent,
    RecoverPasswordComponent,
    ResetPasswordComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    CarouselModule,
    DialogModule,
    InputTextModule,
    FloatLabelModule,
    PasswordModule,
    InputOtpModule,
  ],
  exports: [PublicRoutingModule],
})
export class PublicModule {}
