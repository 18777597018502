import { FormControl, ValidationErrors } from '@angular/forms';
   
export function confirmFieldValidator(confirmFieldInput: string) {
  let confirmFieldControl: FormControl;
  let passwordControl: FormControl;
  
  return (control: FormControl) : ValidationErrors | null => {
    if (!control.parent) {
      return null;
    }
  
    if (!confirmFieldControl) {
      confirmFieldControl = control;
      passwordControl = control.parent.get(confirmFieldInput) as FormControl;
      passwordControl.valueChanges.subscribe(() => {
        confirmFieldControl.updateValueAndValidity();
      });
    }

    if (passwordControl.value !== confirmFieldControl.value) {
      return { notMatch: true };
    }

    return null;
  };
}