import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
@Component({
  selector: 'app-contracts',
  standalone: true,
  imports: [CommonModule, TableModule, TabViewModule],
  templateUrl: './contracts.component.html',
  styleUrl: './contracts.component.scss'
})
export class ContractsComponent {
  contracts: [] | any;

  ngOnInit() {
    this.contracts = [
      {
        id: '1000',
        freelancer: "Pharrell Williams",
        projectType: 'Web Design & Development',
        dueDate: '31/08/24',
        total: '$1350',
        status: "signed"
      },
      {
        id: '1001',
        freelancer: "Latha Walpola",
        projectType: 'Web Design & Development',
        dueDate: '31/08/24',
        total: '$1350',
        status: "pending"
      }]
  }
}
