<header class="flex justify-center items-center p-2">
  <div class="container">
    <p-menubar [model]="items" class="test border-none">
      <ng-template #start>
        <a href="/"
          ><img
            class="logo"
            src="../../../assets/images/logo/logo.svg"
            alt="AfterFive.app"
        /></a>
      </ng-template>
      <ng-template #item let-item let-root="root">
        <a pRipple class="flex items-center p-menubar-item-link">
          <span>{{ item.label }}</span>
          <i
            *ngIf="item.items"
            [ngClass]="[
              'ml-auto pi',
              root ? 'pi-angle-down' : 'pi-angle-right'
            ]"
          ></i>
        </a>
      </ng-template>
      <ng-template #end>
        <div class="flex items-center gap-2">
          <p-avatar
            image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png"
            shape="circle"
          />
        </div>
      </ng-template>
    </p-menubar>
  </div>
</header>
