import {
  ApplicationConfig,
  inject,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePrimeNG } from 'primeng/config';
import { Observable, catchError } from 'rxjs';
import { MessageService } from 'primeng/api';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { environment } from '../environments/environment';
import { AfterFivePreset } from './after-five-preset';

function httpSetupInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  // get the token from the local storage
  const token = localStorage.getItem('auth_token');

  let apiCall: HttpRequest<unknown>;

  if (!req.url.startsWith('http')) {
    apiCall = req.clone({
      url: environment.apiUrl + req.url,
      setHeaders: { Authorization: `Bearer ${token}` },
    });
  } else {
    apiCall = req;
  }
  return next(apiCall);
}

function responseInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  const messageService = inject(MessageService);
  return next(req).pipe(
    catchError((err: HttpErrorResponse) => {
      const errors: string[] = Array.isArray(err.error.message)
        ? err.error.message
        : [err.error.message];
      messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: errors.join('\n'),
        life: 3000,
      });
      throw err;
    })
  );
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
        preset: AfterFivePreset,
        options: {
          darkModeSelector: false,
        },
      },
    }),
    provideHttpClient(
      withInterceptors([httpSetupInterceptor, responseInterceptor])
    ),
  ],
};
