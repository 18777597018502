<form *ngIf="form" [formGroup]="form">
  <div class="flex justify-end items-center">
    Add up to 3 &nbsp;
    <p-button
      icon="pi pi-plus"
      size="large"
      [rounded]="true"
      (onClick)="addNew()"
      [disabled]="formArray.controls.length >= 3"
    />
  </div>
  <div [formArrayName]="section">
    <ng-container *ngFor="let entry of formArray.controls; let i = index">
      <p-chip
        class="mr-2 mb-2"
        [label]="entry.value.name + ':' + entry.value.level"
        [removable]="true"
        (onRemove)="remove(i)"
      />
    </ng-container>
  </div>
</form>

<p-dialog
  [modal]="true"
  [(visible)]="visible"
  [style]="{ width: '50rem', maxWidth: '100%' }"
>
  <form [formGroup]="innerForm" (ngSubmit)="save()">
    <div class="mb-4">
      <p-fieldset legend="Language">
        <p-select
          [options]="languageList"
          [filter]="true"
          [showClear]="true"
          placeholder="Language"
          fluid="true"
          size="large"
          formControlName="name"
          appendTo="body"
        />
      </p-fieldset>
    </div>
    <div class="mb-4">
      <p-fieldset legend="Fluency Level">
        <div class="flex justify-center">
          <p-selectbutton
            [options]="fluencyLevels"
            formControlName="level"
            aria-labelledby="basic"
            size="large"
          />
        </div>
      </p-fieldset>
    </div>

    <div class="flex py-6 gap-2 justify-end">
      <p-button label="Cancel" severity="secondary" (onClick)="cancel()" />
      <p-button label="Save" type="submit" [disabled]="!innerForm.valid" />
    </div>
  </form>
</p-dialog>
