export interface Category {
    name: string;
    skills: string[];
}

export const CategoryList = [
    {
        name: 'Graphic Designer',
        skills: [
            'Adobe Photoshop',
            'Adobe Illustrator',
            'Adobe InDesign',
            'Typography',
            'Branding',
            'Layout Design',
            'Color Theory',
            'Vector Graphics',
            'Digital Illustration',
            'Print Design'
        ]
    },
    {
        name: 'Web Designer',
        skills: [
            'HTML/CSS',
            'Responsive Design',
            'Adobe XD',
            'Figma',
            'User Interface Design (UI)',
            'User Experience Design (UX)',
            'Wireframing',
            'Prototyping',
            'Graphic Design',
            'Web Accessibility'
        ]
    },
    {
        name: 'Web Developer',
        skills: [
            'HTML/CSS',
            'JavaScript',
            'PHP',
            'Python',
            'Ruby on Rails',
            'React',
            'Angular',
            'Node.js',
            'MySQL',
            'Git/GitHub'
        ]
    },
    {
        name: 'Illustrator',
        skills: [
            'Adobe Illustrator',
            'Adobe Photoshop',
            'Sketching',
            'Digital Painting',
            'Concept Art',
            'Character Design',
            'Storyboarding',
            'Vector Illustration',
            'Inking',
            'Color Theory'
        ]
    },
    {
        name: 'Photographer',
        skills: [
            'Camera Operation',
            'Photo Editing (Adobe Lightroom/Photoshop)',
            'Lighting Techniques',
            'Composition',
            'Portrait Photography',
            'Landscape Photography',
            'Product Photography',
            'Event Photography',
            'Post-Processing',
            'Photo Retouching'
        ]
    },
    {
        name: 'Videographer',
        skills: [
            'Camera Operation',
            'Video Editing (Adobe Premiere Pro/Final Cut Pro)',
            'Lighting Techniques',
            'Storyboarding',
            'Motion Graphics',
            'Audio Recording',
            'Color Grading',
            'Cinematography',
            'Drone Operation',
            'Scriptwriting'
        ]
    },
    {
        name: 'Animator',
        skills: [
            '2D Animation',
            '3D Animation',
            'Adobe After Effects',
            'Blender/Maya',
            'Storyboarding',
            'Character Animation',
            'Motion Graphics',
            'Rigging',
            'Stop Motion',
            'Visual Effects (VFX)'
        ]
    },
    {
        name: 'Copywriter',
        skills: [
            'Writing Skills',
            'SEO Writing',
            'Content Strategy',
            'Editing and Proofreading',
            'Research Skills',
            'Creative Writing',
            'Marketing Copy',
            'Blogging',
            'Social Media Content',
            'Persuasive Writing',
        ]
    },
    {
        name: 'Content Writer',
        skills: [
            'Research Skills',
            'Writing Skills',
            'Editing and Proofreading',
            'SEO Writing',
            'Blogging',
            'Technical Writing',
            'Content Strategy',
            'Storytelling',
            'Grammar and Punctuation',
            'Social Media Writing'
        ]
    },
    {
        name: 'Editor',
        skills: [
            'Proofreading',
            'Copy Editing',
            'Developmental Editing',
            'Fact-Checking',
            'Grammar and Punctuation',
            'Style Guide Knowledge (APA, MLA, etc.)',
            'Attention to Detail',
            'Structural Editing',
            'Content Review',
            'Rewriting'
        ]
    },
    {
        name: 'Social Media Manager',
        skills: [
            'Content Creation',
            'Social Media Strategy',
            'Analytics and Reporting',
            'Community Management',
            'Copywriting',
            'Graphic Design',
            'SEO',
            'Social Media Advertising',
            'Influencer Collaboration',
            'Trend Analysis',
        ]
    },
    {
        name: 'SEO Specialist',
        skills: [
            'Keyword Research',
            'On-Page SEO',
            'Off-Page SEO',
            'Technical SEO',
            'Content Optimization',
            'Link Building',
            'Analytics and Reporting',
            'SEO Tools (Google Analytics, SEMrush)',
            'HTML/CSS',
            'SEO Audits'
        ]
    },
    {
        name: 'Voice Actor',
        skills: [
            'Voice Modulation',
            'Diction and Clarity',
            'Script Interpretation',
            'Character Voices',
            'Audiobook Narration',
            'Commercial Voiceovers',
            'Recording Techniques',
            'Audio Editing',
            'Acting Skills',
            'Vocal Warm-ups',
        ]
    },
    {
        name: 'Musician/Composer',
        skills: [
            'Music Theory',
            'Instrument Proficiency',
            'Composition',
            'Arranging',
            'Music Production',
            'Audio Engineering',
            'Songwriting',
            'Performance Skills',
            'Sound Design',
            'Mixing and Mastering',
        ]
    },
    {
        name: 'Sound Designer',
        skills: [
            'Sound Effects Creation',
            'Audio Editing',
            'Foley Artistry',
            'Field Recording',
            'Audio Mixing',
            'Music Editing',
            'Pro Tools',
            'Logic Pro',
            'Synthesis',
            'ADR (Automated Dialogue Replacement)'
        ]
    },
    {
        name: 'Art Director',
        skills: [
            'Visual Communication',
            'Team Leadership',
            'Project Management',
            'Concept Development',
            'Branding',
            'Layout Design',
            'Typography',
            'Adobe Creative Suite',
            'Client Interaction',
            'Creative Strategy'
        ]
    },
    {
        name: 'Fashion Designer',
        skills: [
            'Sketching',
            'Pattern Making',
            'Sewing',
            'Fabric Knowledge',
            'Fashion Illustration',
            'Trend Analysis',
            'Collection Development',
            'CAD Software (CLO 3D, TUKAcad)',
            'Textile Design',
            'Color Theory',
        ]
    },
    {
        name: 'Interior Designer',
        skills: [
            'Space Planning',
            'AutoCAD',
            'SketchUp',
            'Interior Styling',
            'Color Theory',
            'Furniture Design',
            'Material Selection',
            '3D Rendering',
            'Project Management',
            'Client Consultation',
        ]
    },
    {
        name: 'Event Planner',
        skills: [
            'Event Coordination',
            'Budget Management',
            'Vendor Relations',
            'Venue Selection',
            'Logistics Planning',
            'Contract Negotiation',
            'Event Marketing',
            'Guest List Management',
            'On-Site Management',
            'Post-Event Evaluation',
        ]
    },
    {
        name: 'UX/UI Designer',
        skills: [
            'User Research',
            'Wireframing',
            'Prototyping',
            'User Testing',
            'Information Architecture',
            'Interaction Design',
            'Visual Design',
            'Usability Testing',
            'Adobe XD/Figma/Sketch',
            'HTML/CSS'
        ]
    }
];