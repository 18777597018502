<div class="container-fluid user-profile">
    <app-clientprofileheader class="hide-in-mobile"></app-clientprofileheader>

    <!-- //customer profile main wrapper  -->
    <div class="customer-profile-main-wrapper">
        <div class="left-side hide-in-mobile">
            <div class="user-profile-tab-list">
                <ul>
                  
                    <!-- other modules -->
                    <li *ngFor="let leftSide of leftSideList" [class.active]="currentStep === leftSide.key"
                        [ngClass]="{ 'comming-soon-back': leftSide.isCommingSoon }" (click)="tabStep(leftSide.key)">
                        <div class="tab-title-wrapper">
                            <img [src]="'../../../assets/images/icons/public-profile/' + (currentStep === leftSide.key ? leftSide.imageWhite : leftSide.imageBlack) + '.svg'"
                                alt="{{ leftSide.name }}">
                            <h6 [class.active]="currentStep === leftSide.key">{{ leftSide.name }} <small
                                    *ngIf='leftSide.isCommingSoon'>(Coming Soon!)</small></h6>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="right-side client-profile-right-side">
            <div class="step-templates-wrapper">

                <div [hidden]="currentStep !== 'pi'">
                    <div class="main-profile-wrapper-include">
                        <app-peronalinformation></app-peronalinformation>
                    </div>
                </div>

                <div [hidden]="currentStep !== 'oci'">
                    <div class="main-profile-wrapper-include">
                        <app-ordersandcard></app-ordersandcard>
                    </div>
                </div>

                <div [hidden]="currentStep !== 'cn'">
                    <div class="main-profile-wrapper-include">
                        <app-contracts></app-contracts>
                    </div>
                </div>


                

            </div>
        </div>
    </div>
</div>