<footer>
    <div class="footer-main-wrappper">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-3 col-lg-5">
                    <div class="logo-wrapper">
                        <img src="../../../assets/images/logo/logo-white.svg" alt="">
                    </div>
                </div>
                <div class="col-12 col-md-9 col-lg-7">
                    <div class="footer-link-wrapper">
                        <div class="footer-links">
                            <h6>PAGES</h6>
                            <ul>
                                <li>About</li>
                                <li>Contact</li>
                                <li>Credit program</li>
                            </ul>
                        </div>
                        <div class="footer-links">
                            <h6>COMPANY</h6>
                            <ul>
                                <li>About</li>
                                <li>Contact</li>
                                <li>Credit program</li>
                            </ul>
                        </div>
                        <div class="footer-links">
                            <h6>CONTACT US</h6>
                            <ul>
                                <!-- <li>(503) 680-6613</li> -->
                                <li>support&#64;afterfive.app</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</footer>