import { Component } from '@angular/core';
import { DragDropModule } from 'primeng/dragdrop';
import { CommonModule } from '@angular/common';

interface Task {
  id: number;
  name: string;
  description: string;
}

interface List {
  name: string;
  items: Task[];
}

@Component({
  selector: 'app-project-details',
  standalone: true,
  imports: [DragDropModule, CommonModule],
  templateUrl: './project-details.component.html',
  styleUrl: './project-details.component.scss'
})
export class ProjectDetailsComponent {

  lists: List[] = [
    { name: 'Idea Board', items: [{ id: 1, name: 'Alice Hasnolice', description: 'Logo Design' }, { id: 2, name: 'Bradley Cooper', description: 'Product Design' }] },
    { name: 'Color Palette', items: [{ id: 1, name: 'Alice Hasnolice', description: 'Website Design' }] },
    { name: 'Sketches', items: [{ id: 1, name: 'Claudia Walsh', description: 'Mug Design' }] },
    { name: 'Under Client’s Review', items: [{ id: 1, name: 'Claudia Walsh', description: 'Compression Shirt Design' }] }
  ];

  draggedTask: Task | null = null;
  sourceList: List | null = null;

  dragStart(task: Task, list: List) {
    this.draggedTask = task;
    this.sourceList = list;
  }

  drop(targetList: List) {
    if (this.draggedTask && this.sourceList) {
      // Remove task from source list
      const draggedTaskIndex = this.sourceList.items.indexOf(this.draggedTask);
      if (draggedTaskIndex > -1) {
        this.sourceList.items.splice(draggedTaskIndex, 1);
      }

      // Add task to target list
      targetList.items.push(this.draggedTask);

      // Reset the dragged task and source list
      this.draggedTask = null;
      this.sourceList = null;
    }
  }

  dragEnd() {
    this.draggedTask = null;
    this.sourceList = null;
  }

}
