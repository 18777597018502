import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { VIEW } from '../login-register/login-register.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersService } from '../../user.service';

@Component({
  selector: 'app-login',
  standalone: false,
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  @Output() viewChanged: EventEmitter<VIEW> = new EventEmitter<VIEW>();

  loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

  constructor(
    private readonly usersService: UsersService,
    private readonly router: Router
  ) {}

  showRegister(): void {
    this.viewChanged.emit(VIEW.REGISTER);
  }

  showForgotPassword(): void {
    this.viewChanged.emit(VIEW.FORGOT_PASSWORD);
  }

  showVerifyEmail(): void {
    this.viewChanged.emit(VIEW.VERIFY_EMAIL);
  }

  login(): void {
    this.usersService
      .login(this.loginForm.value.email, this.loginForm.value.password)
      .subscribe((res) => {
        // save token to local storage
        localStorage.setItem('auth_token', res.access_token);

        this.usersService.profile().subscribe((profile) => {
          if (!profile.verified) {
            this.showVerifyEmail();
            return;
          }

          // Navigate to the account page
          this.router.navigate(['/account']);
        });
      });
  }
}
