import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loginsecurity',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loginsecurity.component.html',
  styleUrl: './loginsecurity.component.scss'
})
export class LoginsecurityComponent {
  updatedPassword: boolean = false

  updatePassword() {
    this.updatedPassword = true
  }
}
