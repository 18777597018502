<div class="public-profile-wrapper">
    <div class="public-profile-header">
        <h4>Edit Public Profile</h4>
        <button class="primary-1-button hide-in-mobile">View Public Profile</button>
    </div>

    <div class="input-wrapper">
        <label class="label-secondary" for="">First Name</label>
        <h4 class="name-title">William<span class="tooltip-icon-wrapper"><img class="tooltip-icon"
                    (click)="openPopup()" src="../../../assets/images/icons/info-icon-tooltip.png" alt=""></span></h4>
    </div>

    <hr>

    <div class="row">
        <div class="col-5 col-md-4 col-lg-3">
            <div class="input-wrapper">
                <label class="label-secondary" for="">Middle Name</label>
                <h4 class="pub-second-name">Benjamin</h4>
            </div>
        </div>
        <div class="col-7 col-md-6 col-lg-6">
            <div class="input-wrapper last-initial-wrapper do-not-diplay-input-switch">
                <label for="">Display on the public profile <span><p-inputSwitch /></span></label>
            </div>
        </div>
    </div>

    <hr>

    <div class="row">
        <div class="col-5 col-md-4 col-lg-3">
            <div class="input-wrapper">
                <label class="label-secondary" for="">Last Name</label>
                <h4 class="pub-second-name">Darcy</h4>
            </div>
        </div>
        <div class="col-7 col-md-6 col-lg-6">
            <div class="input-wrapper last-initial-wrapper do-not-diplay-input-switch">
                <label for="">Display on the public profile <span><p-inputSwitch /></span></label>
            </div>
        </div>
    </div>

    <hr>

    <!-- <div class="input-wrapper">
        <label class="label-secondary" for="">Category</label>
        <p-dropdown [options]="cities" optionLabel="name" placeholder="Category" class="country-wrapper m-w100" />
    </div> -->
    <!-- category section  -->
    <div class="about-section-wrapper">
        <div class="about-main-text">
            <label for="" class="label-secondary mb-0">Category</label>
            <h4 class="category-title">Graphic Design</h4>
        </div>
        <div class="about-button">
            <button class="underline-button">Edit</button>
        </div>
    </div>

    <hr>

    <!-- about section  -->
    <div class="about-section-wrapper">
        <div class="about-main-text">
            <label for="" class="label-secondary mb-0">About</label>
            <p class="about-section">Lorem ipsum dolor sit amet consectetur.
                Libero nulla sagittis hendrerit scelerisque. Parturient odio morbi elementum ut duis
                varius aliquam sed suspendisse.</p>
        </div>
        <div class="about-button">
            <button class="underline-button">Edit</button>
        </div>
    </div>

    <hr>

    <!-- education  -->
    <label for="" class="label-secondary">Education <span class="edit-link-wrapper" *ngIf="addNewEducation"><button
                class="underline-button">Add</button></span></label>
    <div class="add-education-main-wrapper" *ngIf="isAddNewEductationClicked">
        <div class="input-wrapper">
            <h4 class="about-small-text">Country of school</h4>
            <p-dropdown [options]="cities" optionLabel="name" placeholder="" class="country-school-dropdown m-w100" />
        </div>
        <div class="input-wrapper">
            <h4 class="about-small-text">School name</h4>
            <input type="text" class="mb-0 register school-name m-w100" placeholder="">
        </div>
        <div class="row">
            <div class="col-12 col-md-12 col-lg-3">
                <div class="input-wrapper m-w60">
                    <h4 class="about-small-text">Degree</h4>
                    <p-dropdown [options]="cities" optionLabel="name" placeholder="" class="country-wrapper" />
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-3">
                <div class="input-wrapper m-w60">
                    <h4 class="about-small-text">Major</h4>
                    <input type="text" class="mb-0 register" placeholder="">
                </div>
            </div>
        </div>
        <div class="input-wrapper">
            <h4 class="about-small-text">Year of graduation</h4>
            <p-dropdown [options]="years" optionLabel="name" placeholder="" class="year-of-gradu-drop" />
        </div>

        <div class="w-fit">
            <button class="primary-3-button">Add</button>
        </div>
    </div>

    <div class="education-list" *ngIf="educationAddedList">
        <div class="added-education-main-wrapper">
            <div class="education-wrapper">
                <div class="education-details">
                    <h3>Cambridge University</h3>
                    <h5>United Kingdom</h5>
                    <h5>B.A - 2023</h5>
                </div>
                <div class="edit-delete-button-wrapper">
                    <button class="delete-button-wrapper"><img src="../../../assets/images/icons/delete-bin-white.svg"
                            alt=""></button>
                    <button class="underline-button">Edit</button>
                </div>
            </div>
        </div>
        <div class="add-another-education-wrapper">
            <img src="../../../assets/images/icons/school-hat.svg" alt="">
            <h3>Add another school</h3>
        </div>
    </div>

    <hr>

    <!-- //languages -->
    <label for="" class="label-secondary mb-0">Languages <span class="edit-link-wrapper" *ngIf="addNewLanguage"><button
                class="underline-button">Add</button></span></label>
    <p class="small mb-1">Only 3 allowed</p>
    <div class="language-wrapper-main" *ngIf="isAddNewLanguageClicked">
        <div class="langague-main-first m-w100 t-w80">
            <div class="input-wrapper">
                <h4 class="about-small-text">Language</h4>
                <p-dropdown [options]="cities" optionLabel="name" placeholder="" class="" />
            </div>
        </div>
        <div class="language-main-second">
            <div class="input-wrapper mb-0">
                <h4 class="about-small-text">Fluency Level</h4>
            </div>
            <div class="radio-wrapper">
                <div class="radio-container" *ngFor="let radio of radios">
                    <input type="radio" id="language_{{radio.id}}" name="customRadio" [(ngModel)]="selectedRadio"
                        [value]="radio.value" />
                    <label for="language_{{radio.id}}" class="radio-label">{{ radio.label }}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="added-languages-list" *ngIf="addedLanguageList">
        <div class="language-added-list">
            <div class="language-details">
                <h4>English - Native</h4>
            </div>
            <div class="edit-delete-2-button-wrapper">
                <button class="delete-button-wrapper"><img src="../../../assets/images/icons/delete-bin-white.svg"
                        alt=""></button>
                <button class="underline-button">Edit</button>
            </div>
        </div>

        <div class="language-added-list">
            <div class="language-details">
                <h4>Sinhala - Native</h4>
            </div>
            <div class="edit-delete-2-button-wrapper">
                <button class="delete-button-wrapper"><img src="../../../assets/images/icons/delete-bin-white.svg"
                        alt=""></button>
                <button class="underline-button">Edit</button>
            </div>
        </div>

    </div>
    <div class="w-fit button-wrapper-public-profile">
        <button class="primary-3-button" *ngIf="isAddNewLanguageClicked">Save</button>
        <button class="primary-3-button" *ngIf="addedLanguageList">Add another language</button>
    </div>

    <hr>

    <!-- //skills  -->
    <label for="" class="label-secondary mb-0">Skills</label>
    <p class="small mb-1">Only 10 allowed</p>
    <div class="slill-main-wrapper" *ngIf="addNewSkill">
        <div class="skill-wrapper-first m-w100 t-w80">
            <div class="input-wrapper">
                <h4 class="about-small-text">Skill</h4>
                <p-dropdown [options]="cities" optionLabel="name" placeholder="" class="" />
            </div>
        </div>
        <div class="skill-wrapper-second">
            <div class="input-wrapper mb-0">
                <h4 class="about-small-text">Experience Level</h4>
            </div>
            <div class="radio-wrapper">
                <div class="radio-container" *ngFor="let skillRadio of skillRadios">
                    <input type="radio" id="skill_{{skillRadio.id}}" name="skillStatus" [(ngModel)]="selectedSkilllevel"
                        [value]="skillRadio.value" />
                    <label for="skill_{{skillRadio.id}}" class="radio-label">{{ skillRadio.label
                        }}</label>
                </div>
            </div>
        </div>
    </div>

    <div class="added-skill-list" *ngIf="addedSkills">
        <div class="added-skills">
            <div class="language-details">
                <h4>UI/UX Design</h4>
            </div>
            <div class="edit-delete-2-button-wrapper">
                <button class="delete-button-wrapper"><img src="../../../assets/images/icons/delete-bin-white.svg"
                        alt=""></button>
                <button class="underline-button">Edit</button>
            </div>
        </div>
        <div class="added-skills">
            <div class="language-details">
                <h4>UI/UX Design</h4>
            </div>
            <div class="edit-delete-2-button-wrapper">
                <button class="delete-button-wrapper"><img src="../../../assets/images/icons/delete-bin-white.svg"
                        alt=""></button>
                <button class="underline-button">Edit</button>
            </div>
        </div>

    </div>
    <div class="w-fit button-wrapper-public-profile">
        <button class="primary-3-button" *ngIf="addNewSkill">Save</button>
        <button class="primary-3-button" *ngIf="addedSkills">Add another skill</button>
    </div>

    <hr>
    <!-- other platform section  -->
    <div class="other-platform-main-wrapper">
        <div class="platform-list">
            <label for="" class="label-secondary mb-0">Profiles on Other Platforms</label>
            <p class="about-section">Upwork-&#64;yohanr</p>
            <p class="about-section">Fiverr-&#64;mailyohan</p>
        </div>
        <div class="platform-button">
            <button class="underline-button">Edit</button>
        </div>
    </div>
</div>

<!-- //popup  -->
<p-dialog [modal]="true" [(visible)]="popupopen" [style]="{ width: '280px' }">
    <p class="popup-modal-name-stat">First and last names are prefilled and uneditable because we only transfer money to
        the <img src="../../../assets/images/logo/logo.svg" alt=""> account holder’s name.</p>
</p-dialog>