<div class="board-main-wrapper">
    <div class="board-main-header hide-in-mobile">
        <h4>Lead Management</h4>
        <button class="tutorial-button">Watch Tutorial</button>
    </div>

    <!-- Drag and drop section -->
    <div class="board">
        <div *ngFor="let list of lists" class="list">
            <h3>{{ list.name }}</h3>
            <div pDroppable (onDrop)="drop(list)" class="droppable-area">
                <div *ngFor="let item of list.items" pDraggable (onDragStart)="dragStart(item, list)"
                    (onDragEnd)="dragEnd()" class="draggable-item">
                    {{ item.name }}
                </div>
            </div>
            <button class="add-client-button"><span>Add a client</span> <img
                    src="../../../../../../assets/images/icons/add-icon-board.svg" alt=""></button>
        </div>
    </div>
</div>


<!-- // add new client & exisiting client  -->
<p-dialog [modal]="true" [(visible)]="addNewClientModal" class="add-new-client-main-wrapper">
    <div class="add-client-inner-wrapper">
        <h1>Add a client</h1>
        <div class="client-inner">
            <h4>Client Information</h4>
            <div class="exisiting-client-wrapper">
                <div class="existing-inner">
                    <h5>Existing client ?</h5>
                    <p>Toggle this if you have entered this client to afterFIVE.app before.</p>
                </div>
                <div class="inner-main-wrapper">
                    <p-inputSwitch />
                </div>
            </div>
            <div class="add-client-form-wrapper" *ngIf="addNewClient">
                <div class="">
                    <div class="input-wrapper">
                        <label class="label-secondary" for="">First Name</label>
                        <input type="text" class="mb-0 register" placeholder="First name of your client">
                    </div>
                    <div class="input-wrapper">
                        <label class="label-secondary" for="">Country</label>
                        <p-dropdown [options]="cities" optionLabel="name" placeholder="Your Country"
                            class="country-wrapper" />
                    </div>
                    <div class="input-wrapper phone-number">
                        <label class="label-secondary" for="">Phone Number</label>
                        <intl-telephone-input [enablePlaceholder]="true" [searchCountryFlag]="true"
                            [separateDialCode]="false" [enableAutoCountrySelect]="true"
                            [searchCountryField]="SearchCountryField.All" [phoneValidation]="false"
                            [numberFormat]="PhoneNumberFormat.International" [inputId]="'phone'"
                            [separateDialCode]="true" [enablePlaceholder]="false" class="input-field">
                        </intl-telephone-input>

                        <ng-template #customItemTemplate let-item>
                            <div class="dropdown-item">
                                <img [src]="item.flag" alt="{{ item.name }}" class="flag-icon">
                                {{ item.name }} ({{ item.dialCode }})
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div>
                    <div class="input-wrapper">
                        <label class="label-secondary" for="">Last Name</label>
                        <input type="text" class="mb-0 register" placeholder="Last name of your client">
                    </div>
                    <div class="input-wrapper">
                        <label class="label-secondary" for="">Business Name</label>
                        <input type="text" class="mb-0 register" placeholder="First name of your client">
                    </div>
                    <div class="input-wrapper">
                        <label class="label-secondary" for="">Email</label>
                        <input type="text" class="mb-0 register email-input" placeholder="Email address of your client">
                    </div>
                </div>
            </div>
            <div class="add-exisitng-client" *ngIf="!addNewClient">
                <div class="exisitng-lient-adding-form">
                    <div class="input-wrapper">
                        <label class="label-secondary" for="">Search Existing Client </label>
                        <input type="text" class="mb-0 register" placeholder="Type client’s name here">
                    </div>
                </div>

            </div>
            <div class="button-wrapper">
                <button class="add-client-button">Add Client</button>
            </div>

        </div>
    </div>
</p-dialog>