<div class="contract-wrapper">
    <div class="invoice-header hide-in-mobile">
        <h4>Contracts</h4>
    </div>

    <div class="tab-view-main-wrapper">
        <p-tabView>
            <p-tabPanel header="Create a New Contract">
                <div class="gig-create-first">
                    <!-- <h2 class="hide-in-desktop hide-in-tablet wrapper-title">Upload Your First Gig</h2> -->
                    <div class="horizantal-step-section">
                        <div class="step-wrapper">
                            <div class="stepper">
                                <div class="step step-first" [class.active]="currentStep === 1" (click)="goToStep(1)">
                                    <div class="step-title" [class.active]="currentStep === 1">Step 1</div>
                                    <div *ngIf="currentStep < 5" class="line"></div>
                                </div>
                                <div class="step" [class.active]="currentStep === 2" (click)="goToStep(2)">
                                    <div class="step-title" [class.active]="currentStep === 2">Step 2</div>
                                    <div *ngIf="currentStep < 5" class="line"></div>
                                </div>
                                <div class="step" [class.active]="currentStep === 3" (click)="goToStep(3)">
                                    <div class="step-title" [class.active]="currentStep === 3">Step 3
                                    </div>
                                    <div *ngIf="currentStep < 5" class="line"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- //step 1 -->
                    <div [hidden]="currentStep !== 1">
                        <div class="step-main-wrapper">
                            <div class="inner-wrapper existing-client-wrapper">
                                <div class="inner-main-wrapper">
                                    <div class="main-title-data">
                                        <h5>Existing client?</h5>
                                    </div>
                                    <p>Toggle this if you have entered this client to afterFIVE.app before.</p>
                                </div>
                                <div class="inner-main-wrapper">
                                    <p-inputSwitch />
                                </div>
                            </div>

                            <div *ngIf="addnewProjectExistingClinet" class="contract-exisiting-input-wrapper">
                                <ng-template [ngTemplateOutlet]="template1"></ng-template>
                            </div>

                            <div *ngIf="notExisitngClient" class="contract-not-exisiting-input-wrapper">
                                <ng-template [ngTemplateOutlet]="template2"></ng-template>
                            </div>
                        </div>

                        <div class="button-wrapper-step mt-40">
                            <button class="primary-1-button" (click)="goToStep(2)">Next</button>
                        </div>
                    </div>

                    <!-- //step 2 -->
                    <div [hidden]="currentStep !== 2">
                        <div class="step-main-wrapper">
                            <h5 class="project-main-lable-title">
                                Project One - Project X <span> (Web Design & Development)</span>
                            </h5>
                            <div class="input-wrapper mb-24">
                                <label for="" class="label-secondary">Number of Pages</label>
                                <div class="pages-input-mina-wrapper">
                                    <p-selectButton [options]="webpages" optionLabel="label"
                                        optionValue="value" />
                                        <input type="text" class="mb-0 w-60 register t-w80 m-w100" placeholder="Type">
                                </div>
                            </div>

                            <div class="page-wrapper">
                                <div class="input-wrapper mb-24">
                                    <label for="" class="label-secondary">Page 1</label>
                                    <input type="text" class="mb-0 w-60 register t-w80 m-w100" placeholder="Page Name">
                                </div>
                                <div class="input-wrapper mb-24">
                                    <label for="" class="label-secondary">Page 2</label>
                                    <input type="text" class="mb-0 w-60 register t-w80 m-w100" placeholder="Page Name">
                                </div>
                            </div>

                            <div class="input-wrapper mb-24">
                                <label for="" class="label-secondary">Project End Date</label>
                                <input type="text" class="mb-0 w-60 register t-w80 m-w100 project-end-date"
                                    placeholder="End Date of the Project">
                            </div>

                            <div class="input-wrapper mb-24">
                                <label for="" class="label-secondary">Number of Revisions</label>
                                <div class="pages-input-mina-wrapper">
                                    <p-selectButton [options]="revesions" optionLabel="label"
                                        optionValue="value" />
                                        <input type="text" class="mb-0 w-60 register t-w80 m-w100" placeholder="Type">
                                </div>
                            </div>

                            <div class="input-wrapper">
                                <label for="" class="label-secondary">Total Price</label>
                                <div class="custom-input-group">
                                    <div class="custom-input-group-prepend">
                                        <span class="custom-input-group-text">$USD</span>
                                    </div>
                                    <input type="text" class="price-input" placeholder="">
                                </div>
                            </div>


                        </div>


                        <div class="button-wrapper-step mt-40">
                            <button class="primary-1-button" (click)="goToStep(3)">Next</button>
                        </div>

                    </div>
                    <!-- //step 3 -->
                    <div [hidden]="currentStep !== 3">
                        <div class="step-three-erapper">
                            <img src="../../../../../assets/images/thumbnails/contract-sign.svg" class="contract-image" alt="">
                            
                        </div>
                        <div class="button-wrapper-step mt-40">
                            <button class="primary-1-button">Edit Contract</button>
                            <button class="primary-1-button">Send Contract</button>
                        </div>

                    </div>

                </div>

            </p-tabPanel>
            <p-tabPanel header="Contract History">
                <div class="table-wrapper exisiting-client-wrapper">
                    <p-table class="hide-in-tablet hide-in-mobile" [value]="contracts"
                        [tableStyle]="{'min-width': '60rem'}">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="clientName">
                                    Client Name <p-sortIcon field="clientName" />
                                </th>
                                <th pSortableColumn="projectType">
                                    Project Type <p-sortIcon field="projectType" />
                                </th>
                                <th pSortableColumn="dueDate">
                                    Project Due Date <p-sortIcon field="dueDate" />
                                </th>
                                <th pSortableColumn="total">
                                    Total <p-sortIcon field="dueDate" />
                                </th>
                                <th pSortableColumn="status">
                                    Status <p-sortIcon field="status" />
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-contract>
                            <tr>
                                <td>
                                    <div class="table-body-text border-left-radius">
                                        <h6 class="name-wrapper">{{contract.clientName}}</h6>
                                    </div>
                                </td>
                                <td>
                                    <div class="table-body-text">{{ contract.projectType }}</div>
                                </td>
                                <td>
                                    <div class="table-body-text">{{ contract.dueDate }}</div>
                                </td>
                                <td>
                                    <div class="table-body-text">{{ contract.dueDate }}</div>
                                </td>
                                <td>
                                    <div class="table-body-text border-right-radius">
                                        <div *ngIf="contract.status == 'signed'" class="success-pill">Signed</div>
                                        <div *ngIf="contract.status == 'pending'" class="pending-pill">Pending</div>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>

                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>


<!-- //popup  -->
<p-dialog [modal]="true" [(visible)]="popupopen" [style]="{ width: '280px' }">
    <p class="popup-modal-name-stat">First and last names are prefilled and uneditable because we only transfer money to
        the <img src="../../../assets/images/logo/logo.svg" alt=""> account holder’s name.</p>
</p-dialog>


<ng-template #template1>
    <div class="input-wrapper mb-24">
        <label class="label-secondary" for="">Client’s Name</label>
        <h4 class="name-title">Travis Scott</h4>
    </div>
    <div class="project-list-wrapper">
        <div class="project-list-main">
            <h5 class="project-main-lable">Project One</h5>
            <div class="project-inner-wrapper">
                <div class="project-inner">
                    <div class="content-wrapper">
                        <h4 class="project-main-title">Projet X</h4>
                        <h5 class="project-main-desctiption">Web Design & Development</h5>
                    </div>
                </div>
                <div class="button-wrapper">
                    <button class="underline-button">Edit</button>
                </div>
            </div>
        </div>
    </div>
    <div class="exisitng-wrapper-project-inner">
        <div class="input-wrapper mb-24 ">
            <label for="" class="label-secondary">Client’s Name</label>
            <input type="text" class="mb-0 register t-w80 m-w100" placeholder="Type client’s name here">
        </div>
        <div class="input-wrapper mb-24">
            <label class="label-secondary" for="">Project Category</label>
            <div class="category-dropdown-wrapper">
                <p-dropdown [options]="cities" optionLabel="name" placeholder="Select a Category" class="m-w100" />
            </div>
        </div>
        <div class="project-title-main-wrapper">
            <div class="input-wrapper">
                <label for="" class="label-secondary">Project Title</label>
                <div class="main-button-wrapper-wit-project">
                    <input type="text" class="mb-0 register t-w80 m-w100" placeholder="Project X">
                    <button class="gray-button">Add a second project</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #template2>
    <div class="not-exisiting-client-wrapper">
        <div class="input-wrapper mb-24 ">
            <label for="" class="label-secondary">First Name</label>
            <input type="text" class="mb-0 register t-w80 m-w100" placeholder="First name of your client">
        </div>
        <div class="input-wrapper mb-24 ">
            <label for="" class="label-secondary">Last Name</label>
            <input type="text" class="mb-0 register t-w80 m-w100" placeholder="Last name of your client">
        </div>
        <div class="input-wrapper mb-24">
            <label class="label-secondary" for="">Country</label>
            <div class="category-dropdown-wrapper">
                <p-dropdown [options]="cities" optionLabel="name" placeholder="Country of your client" class="m-w100" />
            </div>
        </div>
        <div class="input-wrapper phone-number mb-24">
            <label class="label-secondary" for="">Phone Number</label>
            <p-inputGroup>
                <p-inputGroupAddon class="phone-number-country-wrapper">
                    <img src="../../../assets/images/temp/france.svg" alt="">
                    <h6>+94</h6>
                </p-inputGroupAddon>
                <p-inputMask mask="99-999999" placeholder="99-999999" class="register phone-number-input m-w100" />
            </p-inputGroup>
        </div>
        <div class="input-wrapper mb-24 ">
            <label for="" class="label-secondary">Email</label>
            <input type="text" class="mb-0 register t-w80 m-w100" placeholder="Email address of your client">
        </div>
        <div class="input-wrapper mb-24">
            <label class="label-secondary" for="">Project Category</label>
            <div class="category-dropdown-wrapper">
                <p-dropdown [options]="cities" optionLabel="name" placeholder="Web Design & Development"
                    class="m-w100" />
            </div>
        </div>
        <div class="project-title-main-wrapper">
            <div class="input-wrapper">
                <label for="" class="label-secondary">Project Title</label>
                <div class="main-button-wrapper-wit-project">
                    <input type="text" class="mb-0 register t-w80 m-w100" placeholder="Project X">
                    <button class="gray-button">Add a second project</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>