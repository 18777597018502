import { Component, forwardRef, OnInit } from '@angular/core';
import { Country, CountryList } from '../../data/country-list';
import {
  ControlValueAccessor,
  FormControl,
  FormBuilder,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'app-shared-country-select',
  standalone: false,
  templateUrl: './country-select.component.html',
  styleUrl: './country-select.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountrySelectComponent),
      multi: true,
    },
  ],
})
export class CountrySelectComponent implements ControlValueAccessor, OnInit {
  inputControl: FormControl;

  countries: Country[] = [];

  constructor(private readonly fb: FormBuilder) {
    this.inputControl = this.fb.control('');
  }

  writeValue(value: string): void {
    this.inputControl.setValue(value);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.inputControl.valueChanges.subscribe(fn);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  registerOnTouched(_fn: () => void): void {
    // Implement if needed
  }

  ngOnInit(): void {
    this.countries = CountryList;
  }
}
