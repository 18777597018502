<h3 class="text-2xl font-semibold mb-8">Login with your email</h3>
<div class="mb-4">
  Don't have an account?
  <a (click)="showRegister()" class="font-bold cursor-pointer">Register</a>
</div>
<form [formGroup]="loginForm" (ngSubmit)="login()">
  <div class="mb-4">
    <p-floatlabel variant="in">
      <input
        pInputText
        type="email"
        formControlName="email"
        id="email"
        autocomplete="off"
        fluid="true"
      />
      <label for="email">Email</label>
    </p-floatlabel>
  </div>
  <div class="mb-4">
    <p-floatlabel variant="in">
      <p-password
        id="password"
        formControlName="password"
        autocomplete="off"
        fluid="true"
        [feedback]="false"
      />
      <label for="password">Password</label>
    </p-floatlabel>
  </div>
  <div class="mb-4 flex justify-end items-center">
    <a (click)="showForgotPassword()" class="font-bold cursor-pointer mr-4">
      Forgot password?
    </a>
    <p-button
      label="Login"
      size="large"
      [disabled]="!loginForm.valid"
      type="submit"
    />
  </div>
</form>
