import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import { ScrollService } from './core/services/scroll.service';
import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest, provideHttpClient, withInterceptors } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from './../environments/environment';

function httpSetupInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  // get the token from the local storage
  const token = localStorage.getItem('auth_token');
  
  let apiCall: HttpRequest<unknown>;

  if (!req.url.startsWith('http')) {
    apiCall = req.clone({ url: environment.apiUrl + req.url, setHeaders: { Authorization: `Bearer ${token}` } });
  } else {
    apiCall = req;
  }
  return next(apiCall);
}

function responseInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  return next(req).pipe(
    catchError((err: HttpErrorResponse) => {
      const errors: string[] = Array.isArray(err.error.message) ? err.error.message : [err.error.message];
      alert(errors.join('\n'));
      throw new Error(errors.join('\n'));
    })
  );
}


export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }), 
    provideRouter(routes), 
    provideAnimations(), 
    ScrollService,
    provideHttpClient(
      withInterceptors([httpSetupInterceptor, responseInterceptor])
    ),
  ],
};
