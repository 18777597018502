import { Component, forwardRef, input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormBuilder,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';

let uniqueId = 0;

@Component({
  selector: 'app-shared-text-input',
  standalone: false,
  templateUrl: './text-input.component.html',
  styleUrl: './text-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    },
  ],
})
export class TextInputComponent
  implements ControlValueAccessor, Validator, OnInit
{
  label = input('label');
  inputId = `text-input-${uniqueId++}`;

  inputControl: FormControl;

  ngOnInit() {
    if (!this.inputControl) {
      throw new Error('inputControl input is required');
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  _onChange = (_value: string) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  _onTouched = () => {};

  constructor(private readonly fb: FormBuilder) {
    this.inputControl = this.fb.control('');
  }

  writeValue(value: string): void {
    this.inputControl.setValue(value);
  }

  registerOnChange(fn: (value: string) => void): void {
    this._onChange = fn;
    this.inputControl.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return control.valid ? null : { invalid: true };
  }
}
