<div class="container">
    <div class="row align-item-center main-cover-wrapper">
        <div class="col-12 col-md-6">
            <div class="main-section-wrapper">
                <h6 class="">Welcome to</h6>
                <h3 class="">after<span>FIVE</span>.app</h3>
                <p>We are giving the freedom back to freelancers!</p>
                <p class="second-hero-text">Zero fees for your clients and a reasonable flat fee for you (freelancers).
                </p>
                <div class="mobile-center mobile-hero-button">
                    <button class="primary-button-desk" (click)="showEditProfileModal()">Become a Freelancer</button>
                </div>

            </div>
        </div>
        <div class="col-12 col-md-6 hide-in-mobile">
            <div class="img-wrapper hero-main-image">
                <img src="../../../assets/images/home-page/home-page-hero-image.png" alt="">
            </div>
        </div>
    </div>

    <div class="finder-wrapper" #searchModalContainer>
        <h3 class="">Find a Great Freelancer Here</h3>
        <div class="finder-inner-wrapper hide-in-mobile">
            <div class="freelance-type-wrapper" (click)="searchedClicked()">
                <h6>Freelancer Type</h6>
                <p>Search freelance Types</p>
            </div>
            <div class="verticle-line"></div>
            <div class="budget-wrapper" (click)="searchedClicked()">
                <h6>Budget</h6>
                <p>Your Budget</p>
            </div>
            <div class="verticle-line"></div>
            <div class="timeline-wrapper" (click)="searchedClicked()">
                <h6>Timeline</h6>
                <p>Timeline</p>
            </div>
            <div class="search-icon-wrapper">
                <div class="search-icon-button" (click)="search()">
                    <img src="../../../assets/images/icons/search-icon.png" alt="">
                </div>
            </div>
        </div>
        <div class="mobile-finder-wrapper hide-in-desktop hide-in-tablet" (click)="mobileSearchedClicked()">
            <div class="mobil-freelancer-wrapper">
                <p>Search by Freelancer Type</p>
            </div>
            <div class="mobile-search-icon-wrapper">
                <img src="../../../assets/images/icons/search-icon.png" alt="">
            </div>
        </div>
        <div  class="search-modal-container" *ngIf="isSearchedClicked">
            <div [hidden]="currentStep !== 0">
                <div class="category-type-wrapper">
                    <div class="category">
                        Web Development & Design
                    </div>
                    <div class="category">
                        Graphic Design
                    </div>
                    <div class="category">
                        Content Writing & Copywriting
                    </div>
                    <div class="category">
                        Digital Marketing
                    </div>
                    <div class="category">
                        Mobile App Development
                    </div>
                    <div class="category">
                        Virtual Assistance
                    </div>
                    <div class="category">
                        Video Production & Editing
                    </div>
                    <div class="category">
                        Photography
                    </div>
                    <div class="category">
                        Accounting & Bookkeeping
                    </div>
                    <div class="category">
                        Translation & Language Services
                    </div>
                    <div class="category">
                        Other
                    </div>
                </div>
                <button class="primary-button-search" (click)="nextStep(1)">Next</button>
            </div>
            <div [hidden]="currentStep !== 1">
                <div class="budget-type-wrapper">
                    <div class="budget">
                        $100 - $499
                    </div>
                    <div class="budget">
                        $500 - $999
                    </div>
                    <div class="budget">
                        $1000 - $4999
                    </div>
                    <div class="budget">
                        Over $5000
                    </div>
                </div>
                <div class="footer-button-wrapper-search">
                    <button class="primary-button-search-back" (click)="nextStep(0)"><img
                            src="../../../assets/images/icons/back-arrow.png" alt="">Back</button>
                    <button class="primary-button-search" (click)="nextStep(2)">Next</button>
                </div>
            </div>
            <div [hidden]="currentStep !== 2">
                <div class="timeline-type-wrapper">
                    <div class="timeline">
                        1 week
                    </div>
                    <div class="timeline">
                        1 month
                    </div>
                    <div class="timeline">
                        1-3 months
                    </div>
                    <div class="timeline">
                        3-6 months
                    </div>
                    <div class="timeline">
                        more than 6 months
                    </div>
                </div>
                <div class="footer-button-wrapper-search">
                    <button class="primary-button-search-back" (click)="nextStep(1)"><img
                            src="../../../assets/images/icons/back-arrow.png" alt="">Back</button>
                    <button class="primary-button-search" (click)="search()">Search</button>
                </div>
            </div>

        </div>
        <p-dialog header="" [modal]="true" [(visible)]="visible" [style]="{ width: '25rem' }" focusOnShow="false">
            <div class="mobile-container-finder search-modal-container">
                <div [hidden]="currentStep !== 0">
                    <h5>What can we help you with?</h5>
                    <div class="category-type-wrapper">
                        <div class="category">
                            Web Development & Design
                        </div>
                        <div class="category">
                            Graphic Design
                        </div>
                        <div class="category">
                            Content Writing & Copywriting
                        </div>
                        <div class="category">
                            Digital Marketing
                        </div>
                        <div class="category">
                            Mobile App Development
                        </div>
                        <div class="category">
                            Virtual Assistance
                        </div>
                        <div class="category">
                            Video Production & Editing
                        </div>
                        <div class="category">
                            Photography
                        </div>
                        <div class="category">
                            Accounting & Bookkeeping
                        </div>
                        <div class="category">
                            Translation & Language Services
                        </div>
                        <div class="category">
                            Other
                        </div>
                    </div>
                    <button class="primary-button-search" (click)="nextStep(1)">Next</button>
                </div>
                <div [hidden]="currentStep !== 1">
                    <h5>What is your budget?</h5>
                    <div class="budget-type-wrapper">
                        <div class="budget">
                            $100 - $499
                        </div>
                        <div class="budget">
                            $500 - $999
                        </div>
                        <div class="budget">
                            $1000 - $4999
                        </div>
                        <div class="budget">
                            Over $5000
                        </div>
                    </div>
                    <div class="footer-button-wrapper-search">
                        <button class="primary-button-search-back" (click)="nextStep(0)"><img
                                src="../../../assets/images/icons/back-arrow.png" alt="">Back</button>
                        <button class="primary-button-search" (click)="nextStep(2)">Next</button>
                    </div>
                </div>
                <div [hidden]="currentStep !== 2">
                    <h5>What is your timeline?</h5>
                    <div class="timeline-type-wrapper">
                        <div class="timeline">
                            1 week
                        </div>
                        <div class="timeline">
                            1 month
                        </div>
                        <div class="timeline">
                            1-3 months
                        </div>
                        <div class="timeline">
                            3-6 months
                        </div>
                        <div class="timeline">
                            more than 6 months
                        </div>
                    </div>
                    <div class="footer-button-wrapper-search">
                        <button class="primary-button-search-back" (click)="nextStep(1)"><img
                                src="../../../assets/images/icons/back-arrow.png" alt="">Back</button>
                        <button class="primary-button-search" (click)="search()">Search</button>
                    </div>
                </div>
    
            </div>
        </p-dialog>
    </div>

    <!-- popular servicess -->
    <h3 class="services-wrapper">Popular Services</h3>
    <div class="service-carousal">
        <p-carousel [value]="homeServices" [numVisible]="6" [numScroll]="3" [circular]="false"
            [responsiveOptions]="responsiveOptions">
            <ng-template let-service pTemplate="item">
                <div class="border-1">
                    <div class="image-wrapper-service-section">
                        <div class="relative mx-auto service-image-wrapper">
                            <img src="../../../assets/images/services/{{service.image}}" [alt]="service.name"
                                class="w-90" />
                        </div>
                    </div>
                    <div class="service-title">
                        {{ service.name }}
                    </div>
                </div>
            </ng-template>
        </p-carousel>
    </div>

    <!-- static content -->
    <div class="static-content-wrapper">
        <div class="row hide-in-tablet">
            <div class="col-12 col-md-4 hide-in-mobile">
                <img src="assets/images/logo/logo.svg" alt="Afterfive-Logo">
            </div>
            <div class="col-12 col-md-8">
                <h1 class="static-main-text">Reclaim your independence as a freelancer today.</h1>
            </div>
        </div>
        <h1 class="static-main-text hide-in-mobile hide-in-desktop show-in-tablet">Reclaim your independence as a freelancer today.</h1>
    </div>

    <!-- about us section -->
    <div class="about-us-content-wrapper">
        <div class="row">
            <div class="col-12 col-md-12 col-lg-6">
                <div class="about-us-inner-wrapper">
                    <h4 class="about-us-main-title">
                        Hello, We are <span>afterFive</span>
                    </h4>
                    <p>The premier freelancer marketplace based out of Portland, Oregon. We are dedicated to empowering
                        freelancers by breaking traditional constraints and giving them the freedom they deserve.</p>
                    <p>Our platform provides an innovative space for freelancers to connect with new clients and gives
                        you the freedom to communicate on our platform or elsewhere. Our only request is that, as
                        freelancers, you use our platform for invoicing. After all, we need to sustain our operations,
                        too.</p>
                    <p>We charge a straightforward, flat 15% fee. More importantly, we do not burden your clients with
                        additional costs.</p>
                    <p>Let’s redefine freelancing together!</p>
                </div>
                <div class="social-media-wrapper">
                    <a href="http://"><img src="../../../assets/images/icons/facebook-black-icon.svg" alt=""></a>
                    <a href=""><img src="../../../assets/images/icons/instagram-black-icon.svg" alt=""></a>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-6 hide-in-tablet">
                <div class="img-wrapper">
                    <img src="../../../assets/images/home-page/home-about-us.png" class="about-us-image" alt="">
                    <img class="home-page-about-icon hide-in-mobile"
                        src="../../../assets/images/icons/home-page-about-icon.png" alt="">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- hero section inner  -->
<div class="hero-section-inner-wrapper">
    <div class="background-image-wrapper">
        <div class="container">
            <div class="row main-hero-flex-wrapper">
                <div class="col-12 col-md-12 col-lg-3 main-hero-vector">
                    <img class="hero-vector-icon" src="../../../assets/images/icons/hero-section-vector.png" alt="">
                </div>
                <div class="col-12 col-md-12 col-lg-9">
                    <div class="hero-content-main-wrapper">
                        <h1>Join <span class="before-main">after<span class="main-five">FIVE</span>.app</span> today
                        </h1>
                        <div class="content-wrapper">
                            <div class="hero-content-inner">
                                <h6>Flat Fee Structure</h6>
                                <p>Our transparent 15% flat fee ensures you keep more of your earnings without
                                    unexpected
                                    costs
                                </p>
                            </div>

                            <div class="hero-content-inner">
                                <h6>Client Connections</h6>
                                <p>Easily connect with new clients through our platform, expanding your professional
                                    network
                                </p>
                            </div>

                            <div class="hero-content-inner">
                                <h6>Flexible Communication</h6>
                                <p>Use our platform for communication, or choose your preferred methods—your workflow,
                                    your
                                    choice.</p>
                            </div>

                            <div class="hero-content-inner">
                                <h6>Reliable Invoicing</h6>
                                <p>Simplified invoicing through our platform ensures you get paid efficiently and
                                    securely.
                                </p>
                            </div>
                        </div>

                    </div>
                    <div class="become-freelance-button mobile-text-align-center">
                        <button class="primary-button-desk" (click)="showEditProfileModal()">Become a Freelancer</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- we work with  -->
<div class="main-background">
    <div class="container">
        <h3 class="worked-company-title">Companies We Work With</h3>
        <div class="worked-company-carousal">
            <p-carousel [value]="homeWorkedCompany" [numVisible]="6" [numScroll]="3" [circular]="false"
                [responsiveOptions]="responsiveOptionsCompany">
                <ng-template let-companyLogo pTemplate="item">
                    <div class="company-logo-image-wrapper">
                        <img src="../../../assets/images/home-page/company-logos/{{companyLogo.image}}" width="100" />
                    </div>
                </ng-template>
            </p-carousel>
        </div>
    </div>
</div>

<!-- support banner -->

<div class="main-support-wrapper">
    <div class="container">
        <h4>support&#64;afterfive.app</h4>
    </div>
</div>


<!-- model list -->
<app-loginmodal #loginModal></app-loginmodal>