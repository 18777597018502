import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { UsersService } from '../../user.service';
import { VIEW } from '../login-register/login-register.component';
import { Router } from '@angular/router';
import { matchValidator } from '../../shared-components/match-validator.directive';

@Component({
  selector: 'app-reset-password',
  standalone: false,
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss',
})
export class ResetPasswordComponent {
  @Output() viewChanged: EventEmitter<VIEW> = new EventEmitter<VIEW>();

  constructor(
    private readonly usersService: UsersService,
    private readonly messageService: MessageService,
    private readonly router: Router
  ) {}

  resetPasswordForm = new FormGroup({
    email: new FormControl('', {
      validators: [Validators.required, Validators.email],
      nonNullable: true,
    }),
    password: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    confirmPassword: new FormControl('', [
      Validators.required,
      matchValidator('password'),
    ]),
    code: new FormControl<number | null>(null, {
      validators: [
        Validators.required,
        Validators.min(100000),
        Validators.max(999999),
      ],
      nonNullable: false,
    }),
  });

  showForgotPassword(): void {
    this.viewChanged.emit(VIEW.FORGOT_PASSWORD);
  }

  submit(): void {
    if (this.resetPasswordForm.valid) {
      const formValues = this.resetPasswordForm.getRawValue();
      this.usersService
        .resetPassword(
          formValues.email,
          formValues.password,
          formValues.code ?? 0
        )
        .subscribe(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Please use your new password to login.',
          });
          this.router.navigate(['/login']);
        });
    }
  }
}
