<h3 class="text-2xl font-semibold mb-8">Create an account</h3>
<div class="mb-4">
  Already have an account?
  <a (click)="showLogin()" class="font-bold cursor-pointer">Login</a>
</div>
<form [formGroup]="registerForm" (ngSubmit)="register()">
  <div class="mb-4">
    <p-floatlabel variant="in">
      <input
        pInputText
        type="email"
        id="email"
        autocomplete="off"
        formControlName="email"
        fluid="true"
      />
      <label for="email">Email</label>
    </p-floatlabel>
  </div>
  <div class="mb-4">
    <p-floatlabel variant="in">
      <p-password
        id="password"
        autocomplete="off"
        fluid="true"
        [feedback]="false"
        formControlName="password"
      />
      <label for="password">Password</label>
    </p-floatlabel>
  </div>
  <div class="mb-4">
    <p-floatlabel variant="in">
      <p-password
        id="confirmPassword"
        autocomplete="off"
        fluid="true"
        [feedback]="false"
        formControlName="confirmPassword"
      />
      <label for="confirmPassword">Confirm Password</label>
    </p-floatlabel>
  </div>
  <div class="mb-4 flex justify-end">
    <p-button
      label="Register"
      size="large"
      type="submit"
      [disabled]="!registerForm.valid"
    />
  </div>

  <div class="text-xs">
    <p>
      By joining, you agree to the afterfive.app Terms of Service and to
      occasionally receive emails from us. Please read our Privacy Policy to
      learn how we use your personal data.
    </p>
  </div>
</form>
