import { Component } from '@angular/core';
import { TabViewModule } from 'primeng/tabview';
import { CommonModule } from '@angular/common';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import {
  NgxIntlTelephoneInputModule, CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from "ngx-intl-telephone-input";

interface City {
  name: string;
  code: string;
}

@Component({
  selector: 'app-invoicesclients',
  standalone: true,
  imports: [TabViewModule, CommonModule, InputSwitchModule, DropdownModule, NgxIntlTelephoneInputModule, TableModule, ButtonModule],
  templateUrl: './invoicesclients.component.html',
  styleUrl: './invoicesclients.component.scss'
})
export class InvoicesclientsComponent {
  cities: City[] | undefined;
  sortingOptions: [] | any;
  selectedOptionSort =  { name: 'Most Recent', code: 'mr' };
  clients: [] | any;
  SearchCountryField: any = SearchCountryField;
  PhoneNumberFormat: any = PhoneNumberFormat;

  addNewClient: boolean = false
  generateInvoice: boolean = true
  updateClientInformation: boolean = false
  invoiceSentSuccess: boolean = false
  exisitingClient:boolean = true
  editClicked:boolean = false

  expandedRows = {};
  transactionShow: boolean = false
  editShowButton: boolean = false

  ngOnInit() {
    this.cities = [
      { name: 'New York', code: 'NY' },
      { name: 'Rome', code: 'RM' },
      { name: 'London', code: 'LDN' },
      { name: 'Istanbul', code: 'IST' },
      { name: 'Paris', code: 'PRS' }
    ];

    this.sortingOptions = [
      { name: 'Most Recent', code: 'mr' }
    ];

    this.clients = [
      {
        id: '1000',
        name: "Yohan Rozairo",
        email: 'mailyohan@gmail.com',
        phoneNumber: '+1-212-789-7890',
        flagImage: 'Vector.svg',
        country: "United States",
        transAction: [
          {
            id: "1000-1",
            date: "11/04/24",
            amount: "$1234",
            status: "Pending",
            invoiceViewLink: "link",
            invoiceCopyLink: "link",
            serviceProvided: "Mobile App Development",
          }, {
            id: "1000-2",
            date: "11/04/24",
            amount: "$1234",
            status: "Succeeded",
            invoiceViewLink: "link",
            invoiceCopyLink: "link",
            serviceProvided: "Logo Design",
          }
        ]
      },
      {
        id: '1001',
        name: "Alexander Bartholomew",
        email: 'alexbartholomew@gmail.com',
        phoneNumber: '+1-212-789-7890',
        flagImage: 'Vector.svg',
        country: "United States",
        transAction: [
          {
            id: "1001-1",
            date: "11/04/24",
            amount: "$1234",
            status: "Pending",
            invoiceViewLink: "link",
            invoiceCopyLink: "link",
            serviceProvided: "Mobile App Development",
          }
        ]
      }
    ]
  }
}
