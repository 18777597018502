<div class="message-inner-main-wrapper">
    <div class="message-left-side">
        <div class="privacy-and-sharing-wrapper">
            <div class="privacy-and-sharing-header">
                <h4>Messages</h4>
                <div class="button-wrapper">
                    <button class="search-button"><img src="../../../../../assets/images/icons/Magnifer.svg"
                            alt=""></button>
                    <button class="search-button"><img src="../../../../../assets/images/icons/filter.svg"
                            alt=""></button>
                </div>
            </div>

            <div class="filter-button-wrapper">
                <button class="filter-dropdown first-button">All <span><img
                            src="../../../../../assets/images/icons/dropdown-white.svg" alt=""></span></button>
                <button class="filter-dropdown">Unread</button>
            </div>

            <div class="contact-wrapper">
                <div class="contact-inner active">
                    <div class="image-wrapper-message">
                        <img src="../../../../../assets/images/temp/Ellipse 6.svg" alt="">
                    </div>
                    <div class="content-wrapper">
                        <div class="header-titles-wrapper">
                            <h4>Charlotte</h4>
                            <h3>17/8/24</h3>
                        </div>
                        <div class="message-wrapper">
                            hii, i'm doing great.. how about you?
                        </div>
                        <div class="date-time-wrapper">
                            <h4>July 17, 2024 <span><svg width="4" height="5" viewBox="0 0 4 5" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="2" cy="2.5" r="2" fill="#484844" />
                                    </svg></span>
                                14:54
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="contact-inner">
                    <div class="image-wrapper-message">
                        <img src="../../../../../assets/images/temp/Ellipse 6.svg" alt="">
                    </div>
                    <div class="content-wrapper">
                        <div class="header-titles-wrapper">
                            <h4>Charlotte</h4>
                            <h3>17/8/24</h3>
                        </div>
                        <div class="message-wrapper">
                            hii, i'm doing great.. how about you?
                        </div>
                        <div class="date-time-wrapper">
                            <h4>July 17, 2024 <span><svg width="4" height="5" viewBox="0 0 4 5" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="2" cy="2.5" r="2" fill="#484844" />
                                    </svg></span>
                                14:54
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="message-right-side">
        <div class="nme-header-wrapper">
            <h4>Charlotte</h4>
            <img src="../../../../../assets/images/temp/france.svg" alt="">
        </div>
        <div class="message-main-wrapper">
            <div class="message-list">
                <div class="message">
                    <p class="date-time-wrapper">June 27, 2024</p>
                    <div class="sender-message-wrapper">
                        <p class="time-wrapper">14:54</p>
                        <h4 class="message-content">Thank you !</h4>
                    </div>
                    <div class="receiver-message-wrapper">
                        <p class="time-wrapper">15:34</p>
                        <h4 class="message-content">It was a pleasure working with you !</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="message-input-wrapper">
            <div class="input-wrapper-text">
                <textarea rows="1"></textarea>
            </div>
        </div>
    </div>
</div>