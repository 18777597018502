import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabViewModule } from 'primeng/tabview';
import { InputSwitchModule } from 'primeng/inputswitch';

@Component({
  selector: 'app-privacysharing',
  standalone: true,
  imports: [CommonModule, TabViewModule, InputSwitchModule],
  templateUrl: './privacysharing.component.html',
  styleUrl: './privacysharing.component.scss'
})
export class PrivacysharingComponent {

}
