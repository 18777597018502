import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { StepperModule } from 'primeng/stepper';
import { ButtonModule } from 'primeng/button';
import { SelectModule } from 'primeng/select';
import { InputGroupModule } from 'primeng/inputgroup';
import { FieldsetModule } from 'primeng/fieldset';
import { PanelModule } from 'primeng/panel';
import { TextareaModule } from 'primeng/textarea';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { AccountComponent } from './account/account.component';
import { FreelancerRoutingModule } from './freelancer-routing.module';
import { PersonalComponent } from './account/personal/personal.component';
import { BankingComponent } from './account/banking/banking.component';
import { PublicProfileComponent } from './account/public-profile/public-profile.component';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { ToggleSwitchModule } from 'primeng/toggleswitch';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ChipModule } from 'primeng/chip';
import { FileUploadModule } from 'primeng/fileupload';
import { EducationComponent } from './account/public-profile/education/education.component';
import { LanguagesComponent } from './account/public-profile/languages/languages.component';
import { SkillsComponent } from './account/public-profile/skills/skills.component';
import { VerificationComponent } from './account/verification/verification.component';

@NgModule({
  declarations: [
    AccountComponent,
    PersonalComponent,
    BankingComponent,
    PublicProfileComponent,
    EducationComponent,
    LanguagesComponent,
    SkillsComponent,
    VerificationComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    StepperModule,
    FloatLabelModule,
    ButtonModule,
    InputTextModule,
    SelectModule,
    InputGroupModule,
    InputGroupAddonModule,
    RadioButtonModule,
    FieldsetModule,
    ToggleButtonModule,
    InputNumberModule,
    TextareaModule,
    PanelModule,
    DialogModule,
    CardModule,
    ChipModule,
    FileUploadModule,
    ToggleSwitchModule,
    SelectButtonModule,
  ],
  exports: [FreelancerRoutingModule],
})
export class FreelancerModule {}
