import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { EducationLevels } from '../../../../data/education-level-list';

export function getEducationFrom(): FormGroup {
  return new FormGroup({
    country: new FormControl('', [Validators.required]),
    school: new FormControl('', [Validators.required]),
    degree: new FormControl('', [Validators.required]),
    major: new FormControl('', [Validators.required]),
    year: new FormControl('1950', [Validators.required]),
  });
}

@Component({
  selector: 'app-account-public-profile-education',
  standalone: false,
  templateUrl: './education.component.html',
  styleUrl: './education.component.scss',
})
export class EducationComponent implements OnInit {
  @Input({ required: true }) form: FormGroup | undefined;
  @Input({ required: true }) section = '';

  visible = false;
  educationLevels: string[] = [];
  position = 0;
  innerForm = getEducationFrom();
  // data: Education[] = [];

  ngOnInit(): void {
    this.educationLevels = EducationLevels;

    if (!this.form) {
      throw new Error('form is required');
    }
  }

  get formArray() {
    return this.form?.get(this.section) as FormArray;
  }

  addNew() {
    this.innerForm.reset();
    this.position = this.formArray?.length || 0;
    this.visible = true;
  }

  save() {
    if (this.innerForm.valid) {
      this.formArray?.removeAt(this.position);
      this.formArray?.insert(this.position, getEducationFrom());
      this.formArray?.at(this.position)?.patchValue(this.innerForm.value);
      this.visible = false;
    }
  }

  cancel() {
    this.visible = false;
  }

  edit(index: number) {
    this.position = index;
    this.innerForm.patchValue(this.formArray?.at(index)?.value);
    this.visible = true;
  }

  remove(index: number) {
    this.formArray?.removeAt(index);
    this.innerForm.reset();
  }
}
