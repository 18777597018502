<div class="font-bold text-2xl mb-4">Profile Verification</div>
<form [formGroup]="verificationForm" class="max-w-2xl" (ngSubmit)="submit()">
  <div class="mb-4">
    <div class="text-lg font-bold">Profile Verification</div>
    <p>
      Make sure your photos aren’t blurry and the front of your identity card
      clearly shows your face
    </p>
  </div>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-4 file-upload-container">
    <div class="mb-4">
      <p-fileupload
        (uploadHandler)="uploadHandler($event, 'front')"
        [multiple]="false"
        accept="image/*"
        maxFileSize="1000000"
        mode="advanced"
        auto="true"
        customUpload="true"
      >
        <ng-template #empty>
          <div *ngIf="verificationForm.value.front">
            <img
              class="max-h-40"
              [src]="verificationForm.value.front"
              alt="ID Front"
            />
          </div>
          <div *ngIf="!verificationForm.value.front">
            Upload the front side of your ID.
          </div>
        </ng-template>
      </p-fileupload>
    </div>
    <div class="mb-4">
      <p-fileupload
        (uploadHandler)="uploadHandler($event, 'back')"
        [multiple]="false"
        accept="image/*"
        maxFileSize="1000000"
        mode="advanced"
        auto="true"
        customUpload="true"
      >
        <ng-template #empty>
          <div *ngIf="verificationForm.value.back">
            <img
              class="max-h-40"
              [src]="verificationForm.value.back"
              alt="ID Back"
            />
          </div>
          <div *ngIf="!verificationForm.value.back">
            Upload the back side of your ID.
          </div>
        </ng-template>
      </p-fileupload>
    </div>
  </div>
  <div class="mb-4">
    <div class="text-lg font-bold">Verification Selfie</div>
    <p>Upload a selfie of yourself while adhering to following guidelines :</p>
    <ul class="list-disc list-inside p-4 text-sm">
      <li>
        <span class="font-bold">Clear and Focused:</span> The selfie must be
        sharp and not blurry.
      </li>
      <li>
        <span class="font-bold">Well-Lit:</span> Use good lighting, preferably
        natural light, ensuring there are no shadows on your face.
      </li>
      <li>
        <span class="font-bold">Face Centered:</span> Your face should be
        centered in the frame.
      </li>
      <li>
        <span class="font-bold">Neutral Background:</span> Use a plain,
        uncluttered background.
      </li>
      <li>
        <span class="font-bold">No Obstructions:</span> Ensure no hats,
        sunglasses, or masks are covering your face.
      </li>
      <li>
        <span class="font-bold">Neutral Expression:</span> Maintain a neutral
        expression, avoiding extreme facial expressions.
      </li>
      <li>
        <span class="font-bold">Single Person:</span> Only your face should be
        visible; no other people should be in the photo.
      </li>
      <li>
        <span class="font-bold">Recent Photo:</span> The selfie should be a
        recent photo that accurately represents your current appearance.
      </li>
      <li>
        <span class="font-bold">No Filters or Effects:</span> Do not use any
        camera filters or effects.
      </li>
      <li>
        <span class="font-bold">Full Face Visible:</span> Ensure your entire
        face, from the top of your head to your chin, is visible in the selfie.
      </li>
    </ul>
  </div>
  <div class="mb-4">
    <img src="../../../assets/images/thumbnails/selfie-tips-image.png" alt="" />
  </div>
  <div class="mb-4 file-upload-container">
    <p-fileupload
      (uploadHandler)="uploadHandler($event, 'selfie')"
      [multiple]="false"
      accept="image/*"
      maxFileSize="1000000"
      mode="advanced"
      auto="true"
      customUpload="true"
    >
      <ng-template #empty>
        <div *ngIf="verificationForm.value.selfie">
          <img
            class="max-h-40"
            [src]="verificationForm.value.selfie"
            alt="ID Back"
          />
        </div>
        <div *ngIf="!verificationForm.value.selfie">
          Upload the selfi with your ID.
        </div>
      </ng-template>
    </p-fileupload>
  </div>
  <div class="mb-4">
    <div class="text-sm text-red-600">
      <p>
        <span class="font-bold">Disclaimer:</span> We do not store these images
        permanently. They will be deleted from our servers after 7 days and will
        only be used for verification purposes.
      </p>
    </div>
  </div>
  <div class="flex py-6 gap-2">
    <p-button label="Back" severity="secondary" (onClick)="back()" />
    <p-button
      label="Finish"
      (onClick)="submit()"
      [disabled]="!verificationForm.valid"
    />
  </div>
</form>
