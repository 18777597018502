<!-- desktop navigaton  -->
<nav class="navbar navbar-expand-lg navbar-light main-navbar-wrapper hide-in-mobile">
    <div class="container-fluid">
        <!-- Logo -->
        <a class="navbar-brand" routerLink="/">
            <img src="assets/images/logo/logo.svg" alt="Afterfive-Logo">
        </a>

        <!-- Centered Links -->
        <div class="navbar-nav mx-auto" *ngIf="isLogedIn">
            <a class="nav-link navbar-links" href="#">Home</a>
            <a class="nav-link navbar-links" href="#">Gig Page</a>
            <a class="nav-link navbar-links" href="#">Features</a>
        </div>

        <!-- End Button -->
        <div class="navbar-nav" *ngIf="!isRegister">
            <div *ngIf="!isLogedIn" class="profile-navigation">
                <div class="profile-image-wrapper-bf">
                    <img src="../../../assets/images/icons/user-bf-log.svg" alt="">
                </div>
            </div>
            <!-- <button  class="primary-button" (click)="showEditProfileModal()">Become a Freelancer</button> -->
            <div *ngIf="isLogedIn" class="after-login-wrapper">
                <img class="message-icon" src="../../../assets/images/icons/messages.png" alt="">
                <div class="notification-wrapper">
                    <img class="notification-icon" src="../../../assets/images/icons/notification-icon.png" alt="">
                    <div class="red-dot-notification"></div>
                </div>

                <div class="user-profile-wrapper">
                    <img src="../../../assets/images/users/default-user-profile.png" alt="">
                </div>
            </div>

        </div>
    </div>
</nav>

<!-- mobile navigation  -->
<nav class="only-mobile navbar navbar-expand-lg navbar-light main-navbar-wrapper hide-in-desktop hide-in-tablet">
    <div class="container d-flex justify-content-between align-items-center">
        <!-- Toggle button for mobile (left end if logged in) -->
        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas"
            data-bs-target="#navbarOffcanvas" aria-controls="navbarOffcanvas" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Logo (center) -->
        <a class="navbar-brand mx-auto" routerLink="/">
            <img src="assets/images/logo/logo.svg" alt="Afterfive-Logo">
        </a>

        <!-- Profile navigation (right end if not logged in) -->
        <div *ngIf="!isRegister"  class="profile-navigation">
            <div *ngIf="!isLogedIn">
                <div class="profile-image-wrapper-bf">
                    <img src="../../../assets/images/icons/user-bf-log.svg" alt="">
                </div>
            </div>
        </div>



        <!-- Empty div to take up space when not logged in -->
        <div *ngIf="!isRegister">
            <div *ngIf="!isLogedIn" class="ms-auto"></div>
        </div>

    </div>

    <!-- Offcanvas content -->
    <div class="offcanvas offcanvas-start mobile-navigation-user-tablist" tabindex="-1" id="navbarOffcanvas" aria-labelledby="navbarOffcanvasLabel"
        #navbarOffcanvas>
        <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="navbarOffcanvasLabel">
                <!-- <img src="assets/images/logo/logo.svg" alt="Afterfive-Logo"> -->
            </h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <div class="navbar-nav">
                <div class="image-wrapper-name-title">
                    <img src="../../../assets/images/users/default-user-profile.png" alt="">
                    <h4>William Darcy</h4>
                </div>
                <div class="user-profile-tab-list">
                    <ul>
                        <li class="comming-soon-back" [class.active]="currentStep === 'ac'" (click)="tabStep('ac')">
                            <div class="tab-title-wrapper">
                                <img [src]="'../../../assets/images/icons/public-profile/' + (currentStep === 'ac' ? 'analytics-white' : 'analytics-black') + '.svg'"
                                    alt="Analytics">
                                <h6 [class.active]="currentStep === 'ac'">Analytics <!-- <small>(Coming Soon!)</small> --></h6> 
                                
                            </div>
                        </li>
                        <!-- board module  -->
                        <li class="first-content" (click)="boradClicked()">
                            <div class="tab-title-wrapper">
                                <img [src]="currentStep === 'bd' ? '../../../assets/images/icons/public-profile/board-white.svg' : '../../../assets/images/icons/public-profile/board-black.svg'"
                                    alt="">
                                <h6 [class.active]="currentStep === 'bd'">Boards</h6>
                            </div>
                            <div class="sub-title-wrapper" *ngIf="currentStep === 'bd'" [ngClass]="'board-active'">
                                <div class="sub-tab-title-wrapper" [class.active]="boradStep === 'lm'"
                                    (click)="boradStepHandler('lm')">
                                    <h6 [class.active]="boradStep === 'lm'">Lead Management</h6>
                                </div>
                                <div class="sub-tab-title-wrapper" [class.active]="boradStep === 'pm'"
                                    (click)="boradStepHandler('pm')">
                                    <h6 [class.active]="boradStep === 'pm'">Project Management</h6>
                                </div>
                            </div>
    
                        </li>
                        <!-- other modules -->
                        <li *ngFor="let leftSide of leftSideList" [class.active]="currentStep === leftSide.key"
                            [ngClass]="{ 'comming-soon-back': leftSide.isCommingSoon }" (click)="tabStep(leftSide.key)">
                            <div class="tab-title-wrapper">
                                <img [src]="'../../../assets/images/icons/public-profile/' + (currentStep === leftSide.key ? leftSide.imageWhite : leftSide.imageBlack) + '.svg'"
                                    alt="{{ leftSide.name }}">
                                <h6 [class.active]="currentStep === leftSide.key">{{ leftSide.name }} 
                                    <!-- <small *ngIf='leftSide.isCommingSoon'>(Coming Soon!)</small>-->
                                </h6> 
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="log-out-button">
                    <button class="log-out-button"><img src="../../../assets/images/icons/logout.svg" alt=""><span>Log Out</span></button>
                </div>
            </div>
        </div>
    </div>
</nav>