import { Component, EventEmitter, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { VIEW } from '../login-register/login-register.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsersService } from '../../user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-verification',
  standalone: false,
  templateUrl: './verification.component.html',
  styleUrl: './verification.component.scss',
})
export class VerificationComponent {
  @Output() viewChanged: EventEmitter<VIEW> = new EventEmitter<VIEW>();

  verification: FormGroup = new FormGroup({
    code: new FormControl('', [Validators.required]),
  });

  constructor(
    private readonly userService: UsersService,
    private readonly messageService: MessageService,
    private readonly router: Router
  ) {}

  showRegistration(): void {
    this.viewChanged.emit(VIEW.REGISTER);
  }

  resendCode(): void {
    this.userService.resendVerificationEmail().subscribe(() => {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Code has been sent to your email',
        life: 3000,
      });
    });
  }

  verifyEmail(): void {
    this.userService.verifyEmail(this.verification.value.code).subscribe({
      next: () => {
        this.router.navigate(['/account']);
      },
    });
  }
}
