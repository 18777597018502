import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileheaderComponent } from '../../shared/components/profileheader/profileheader.component';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { PublicprofileComponent } from '../../shared/components/userprofile/publicprofile/publicprofile.component';
import { LoginsecurityComponent } from '../../shared/components/userprofile/loginsecurity/loginsecurity.component';
import { PrivacysharingComponent } from '../../shared/components/userprofile/privacysharing/privacysharing.component';
import { PeronalinformationComponent } from '../../shared/components/userprofile/peronalinformation/peronalinformation.component';
import { InvoicesclientsComponent } from '../../shared/components/userprofile/invoicesclients/invoicesclients.component';
import { PaymentspayoutsComponent } from '../../shared/components/userprofile/paymentspayouts/paymentspayouts.component';
import { GigComponent } from '../../shared/components/userprofile/gig/gig.component';
import { MessagesComponent } from '../../shared/components/userprofile/messages/messages.component';
import { LeadmanagementComponent } from '../../shared/components/userprofile/boards/leadmanagement/leadmanagement.component';
import { ProjectmanagementComponent } from '../../shared/components/userprofile/boards/projectmanagement/projectmanagement.component';
import { ProjectDetailsComponent } from '../../shared/components/userprofile/boards/project-details/project-details.component';
import { ContractComponent } from '../../shared/components/userprofile/contract/contract.component';
interface Navigation {
  name: string;
  key: string;
  imageWhite: string;
  imageBlack: string;
  isCommingSoon: boolean;
}

interface Navigation {
  name: string;
  key: string;
  imageWhite: string;
  imageBlack: string;
  isCommingSoon: boolean;
}

@Component({
  selector: 'app-customerprofile',
  standalone: true,
  imports: [CommonModule, FormsModule, ProfileheaderComponent, DropdownModule, RadioButtonModule, InputSwitchModule, InputTextareaModule, DialogModule, PublicprofileComponent, LoginsecurityComponent, PrivacysharingComponent, PeronalinformationComponent, InvoicesclientsComponent, PaymentspayoutsComponent, GigComponent, MessagesComponent, LeadmanagementComponent, ProjectmanagementComponent, ProjectDetailsComponent, ContractComponent],
  templateUrl: './customerprofile.component.html',
  styleUrl: './customerprofile.component.scss'
})
export class CustomerprofileComponent {
  currentStep: string = 'cn';
  boradStep: string = 'pm';
  selectedProject:string='p1'
  projectSelected:boolean = true
  
  ingredient!: string;

  leftSideList: Navigation[] | undefined

  radios = [
    { id: 'radio1', value: 'radio1', label: 'Basic' },
    { id: 'radio2', value: 'radio2', label: 'Conversational' },
    { id: 'radio3', value: 'radio3', label: 'Fluent' },
    { id: 'radio4', value: 'radio4', label: 'Native' }
  ];

  tabStep(step: string) {
    this.currentStep = step
  }

  boradClicked() {
    this.currentStep = "bd"
  }

  boradStepHandler(boradStepM: string) {
    this.boradStep = boradStepM;
  }



  ngOnInit() {

    this.leftSideList = [
      { name: "Contracts", key: "cn", isCommingSoon: false, imageWhite: "public-profile-white", imageBlack: "public-profle-black" },
      { name: "Edit Public Profile", key: "epp", isCommingSoon: false, imageWhite: "public-profile-white", imageBlack: "public-profle-black" },
      { name: "Gigs", key: "gg", isCommingSoon: false, imageWhite: "gig-white", imageBlack: "gig-black" },
      { name: "Invoice & Clients", key: "inc", isCommingSoon: false, imageWhite: "invoice-clients-white", imageBlack: "invoice-clients-black" },
      { name: "Log in & Security", key: "lis", isCommingSoon: false, imageWhite: "login-security-white", imageBlack: "login-security-black" },
      { name: "Messages", key: "mc", isCommingSoon: true, imageWhite: "messages-white", imageBlack: "messages-black" },
      { name: "Payments & Payouts", key: "pp", isCommingSoon: false, imageWhite: "payments-payouts-white", imageBlack: "payments-payouts-black" },
      { name: "Personal Information", key: "pi", isCommingSoon: false, imageWhite: "personal-information-white", imageBlack: "personal-information-black" },
      { name: "Privacy & Sharing", key: "ps", isCommingSoon: false, imageWhite: "privacy-sharing-white", imageBlack: "privacy-sharing-black" },
    ]
  }
}
