import { Component, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FileUploadModule } from 'primeng/fileupload';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProfileheaderComponent } from '../../shared/components/profileheader/profileheader.component';
import { DialogModule } from 'primeng/dialog';

import { InputMaskModule } from 'primeng/inputmask';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';

import { DeviceDetectorService } from 'ngx-device-detector';
import {
  NgxIntlTelephoneInputModule, CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from "ngx-intl-telephone-input";

import { QuillModule } from 'ngx-quill'
import { UsersService } from '../../users.service';
import { CountryList } from '../../data/country-list';
import { confirmFieldValidator } from '../../validators/confirm-field.validator';
import { BankList } from '../../data/bank-list';
import { CategoryList } from '../../data/category-list';
import { SmoothScrollService } from '../../core/services/smooth-scroll.service';
import { EducationLevels } from '../../data/education-level-list';
import { LanguagesList } from '../../data/languages-list';
import { GigsService } from '../../gigs.service';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { FileUploadService } from '../../file-upload.service';
import { Router } from '@angular/router';

interface UploadEvent {
  originalEvent: Event;
  files: File[];
}

interface City {
  name: string;
  code: string;
}

interface Bank {
  name: string
  code: string
}

interface Branch {
  name: string
  code: string
}

interface Year {
  name: string,
  code: string
}

@Component({
  selector: 'app-registration',
  standalone: true,
  imports: [
    CommonModule, 
    DropdownModule, 
    CheckboxModule, 
    RadioButtonModule, 
    FormsModule, 
    InputSwitchModule, 
    InputTextareaModule, 
    ProfileheaderComponent, 
    DialogModule, 
    InputMaskModule, 
    NgxIntlTelephoneInputModule, 
    QuillModule, 
    ReactiveFormsModule,
    IconFieldModule,
    InputIconModule,
    FileUploadModule
  ],
  templateUrl: './registration.component.html',
  styleUrl: './registration.component.scss'
})
export class RegistrationComponent {
  @ViewChild('phoneInput') phoneInput!: ElementRef;

  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  deviceType: string;

  aboutContent: boolean = false;
  addAboutContent: boolean = false;
  isAddAboutClicked: boolean = true;
  isEditClicked: boolean = false;

  addNewEducation: boolean = false;
  educationAddedList: boolean = false;
  isAddNewEductationClicked: boolean = true

  addNewLanguage: boolean = false;
  addedLanguageList: boolean = false;
  isAddNewLanguageClicked: boolean = true

  addNewSkill: boolean = true;
  addedSkills: boolean = false;

  selectedCountry: any;
  phoneMask: string = '';

  currentStep: number = 1;
  currentStepGig: number = 1;
  cities: City[] | undefined;
  years: Year[] | any;
  ingredient!: string;
  idType: string = "1";
  selectedRadio: string = '';
  selectedSkilllevel: string = ''
  popupopen: boolean = false

  tinPinSwitch: boolean = true;

  
  

  skillRadios = [
    { id: 'radio1', value: 'radio1', label: 'Basic' },
    { id: 'radio2', value: 'radio2', label: 'Intermediate' },
    { id: 'radio3', value: 'radio3', label: 'Expert' }
  ]

  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic'],           // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }]
      ],

    }
  };

  SearchCountryField: any = SearchCountryField;
  PhoneNumberFormat: any = PhoneNumberFormat;
  profile: any;

  client: S3Client;
  countries = CountryList;
  banks = BankList;
  branches: Branch[] = [];
  categories = CategoryList;
  degrees = EducationLevels;
  languages = LanguagesList;
  langSkills = ['Basic', 'Conversational', 'Fluent', 'Native'];
  skillLevels = ['Basic', 'Intermediate', 'Expert'];

  personalInfo: FormGroup = new FormGroup({
    country: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
    sex: new FormControl('', [Validators.required]),
    address1: new FormControl('', [Validators.required]),
    address2: new FormControl(''),
    city: new FormControl('', [Validators.required]),
    postalCode: new FormControl('', [Validators.required]),
    idType: new FormControl('NATIONAL_ID', [Validators.required]),
    idNumber: new FormControl('', [Validators.required]),
    tinOrPin: new FormControl(''),
  });

  bankingInfo: FormGroup = new FormGroup({
    bank: new FormControl('', [Validators.required]),
    branch: new FormControl('', [Validators.required]),
    branchNumber: new FormControl('', [Validators.required]),
    accountNumber: new FormControl('', [Validators.required, confirmFieldValidator('accountNumberConfirm')]),
    accountNumberConfirm: new FormControl('', [Validators.required]),
  });

  publicProfile: FormGroup = new FormGroup({
    displayMiddleName: new FormControl(false, [Validators.required]),
    displayLastName: new FormControl(false, [Validators.required]),
    category: new FormControl('', [Validators.required]),
    about: new FormControl('', [Validators.required]),
    education: new FormArray([]),
    languages: new FormArray([]),
    skills: new FormArray([]),
    externalLinks: new FormGroup({
      upwork: new FormControl(''),
      fiverr: new FormControl('')
    })
  });

  gigInfo: FormGroup = new FormGroup({
    title: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    startingPrice: new FormControl('', [Validators.required, Validators.min(100)]),
    images: new FormArray([
      new FormControl('', [Validators.required]),
      new FormControl('', [Validators.required]),
    ]),
    requirements: new FormArray([
      new FormGroup({
        question: new FormControl('', [Validators.required]),
        type: new FormControl('FREE_TEXT', [Validators.required]),
      }),
    ]),
  });

  profileVerification: FormGroup = new FormGroup({
    front: new FormControl('', [Validators.required]),
    back: new FormControl('', [Validators.required]),
    selfie: new FormControl('', [Validators.required]),
  });

  get educationForms() : FormArray {
    return this.publicProfile.get('education') as FormArray;
  }

  get languagesForms() : FormArray {
    return this.publicProfile.get('languages') as FormArray;
  }

  get skillsForms() : FormArray {
    return this.publicProfile.get('skills') as FormArray;
  }

  get externalLinksForm() : FormGroup {
    return this.publicProfile.get('externalLinks') as FormGroup;
  }

  get gigImages() : FormArray<FormControl> {
    return this.gigInfo.get('images') as FormArray<FormControl>;
  }

  get gigRequirements() : FormArray {
    return this.gigInfo.get('requirements') as FormArray;
  }

  get pvSelfie() {
    return this.profileVerification.get('selfie') as FormControl;
  }

  get pvFront() {
    return this.profileVerification.get('front') as FormControl;
  }

  get pvBack() {
    return this.profileVerification.get('back') as FormControl;
  }

  constructor(
    private deviceService: DeviceDetectorService, 
    private smoothScrollService :SmoothScrollService,
    private usersService: UsersService,
    private gigsService: GigsService,
    private fileUploadService: FileUploadService,
    private router: Router,
  ) {
    const deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();
    this.deviceType = deviceInfo.deviceType;

    this.years = [];

    this.client = new S3Client({ region: "us-east-1" });
  }

  getIdFieldLabel(type: string) {
    switch (type) {
      case 'NATIONAL_ID':
        return 'National ID Number';
      case 'PASSPORT':
        return 'Passport Number';
      case 'DRIVERS_LICENSE':
        return 'Driver\'s License Number';
    }

    return 'Other';
  }

  addEducationSection() {
    this.educationForms.push(new FormGroup({
      country: new FormControl('', [Validators.required]),
      school: new FormControl('', [Validators.required]),
      degree: new FormControl('', [Validators.required]),
      major: new FormControl('', [Validators.required]),
      year: new FormControl('1950', [Validators.required]),
    }));
  }

  removeEducationSection(i: number) {
    this.educationForms.removeAt(i);
  }

  addLanguageSection() {
    // max is 3 
    if (this.languagesForms.length >= 3) {
      return;
    }
    
    this.languagesForms.push(new FormGroup({
      name: new FormControl('', [Validators.required]),
      level: new FormControl('', [Validators.required]),
    }));
  };

  removeLanguageSection(i: number) {
    this.languagesForms.removeAt(i);
  }

  addSkillSection() {
    // max is 10
    if (this.languagesForms.length >= 10) {
      return;
    }

    this.skillsForms.push(new FormGroup({
      name: new FormControl('', [Validators.required]),
      level: new FormControl('', [Validators.required]),
    }));
  }

  removeSkillSection(i: number) {
    this.skillsForms.removeAt(i);
  }

  onProfileCountryChange() {
    this.personalInfo.patchValue({ phone: this.personalInfo.value.country.mobileCode });
  }

  onBankingBankChange() {
    this.branches = this.bankingInfo.value.bank.branches;
  }

  onPersonalInfoSubmit() {
    this.usersService.patch({
      personal: {
        ...this.personalInfo.value,
        country: this.personalInfo.value.country.name,
      }
    }).subscribe(() => {
      this.goToNextStep(2);
    });
  }

  onBankingInfoSubmit() {
    this.usersService.patch({
      banking: {
        ...this.bankingInfo.value,
        bank: this.bankingInfo.value.bank.name,
      }
    }).subscribe(() => {
      this.goToNextStep(3);
    });
  }

  onPublicProfileSubmit() {
    this.usersService.patch({
      publicProfile: {
        ...this.publicProfile.value,
        category: this.publicProfile.value.category.name,
        education: this.publicProfile.value.education.map((edu: any) => ({
          ...edu,
          country: edu.country.name,
        })),
      }
    }).subscribe(() => {
      this.goToNextStep(4);
    });
  }

  onGigInfoSubmit() {
    this.gigsService.create(this.gigInfo.value).subscribe(() => {
      this.router.navigate([`/user/${this.profile.id}`]);
    });
  }

  onProfileVerificationSubmit() {
    this.usersService.patch({
      profileVerification: this.profileVerification.value,
    }).subscribe(() => {
      this.goToNextStep(5);
    });
  }

  aboutContentAdding() {
    this.isAddAboutClicked = true;
    this.aboutContent = false;
    this.addAboutContent = false;
  }

  onCountryChange(event: any) {
    this.phoneMask = this.selectedCountry?.mask || '';
  }

  openPopup() {
    this.popupopen = true
  }

  goToNextStep(stepnumber: number) {
    this.smoothScrollService.smoothScrollTo(0, 500)
    this.currentStep = stepnumber;
  }
  goToGigStep(gigStepNumber: number) {
    this.smoothScrollService.smoothScrollTo(0, 500)
    this.currentStepGig = gigStepNumber;
  }

  ngOnInit() {
    this.smoothScrollService.smoothScrollTo(0, 500)

    this.generateYears();

    this.usersService.profile().subscribe(profile => {
      this.profile = profile;

      if (profile.personal) {
        this.personalInfo.patchValue({
          ...profile.personal,
          country: this.countries.find(country => country.name === profile.personal.country)
        });
      }
      
      if (profile.banking) {
        this.bankingInfo.patchValue({
          ...profile.banking,
          bank: this.banks.find(bank => bank.name === profile.banking.bank),
        });
        this.onBankingBankChange();
        this.bankingInfo.patchValue({
          branch: profile.banking.branch,
        });
      }

      if (profile.publicProfile) {
        for(let edu of profile.publicProfile.education) {
          this.addEducationSection();
        }
  
        for(let lang of profile.publicProfile.languages) {
          this.addLanguageSection();
        }
  
        for(let skill of profile.publicProfile.skills) {
          this.addSkillSection();
        }

        this.publicProfile.patchValue({
          ...profile.publicProfile,
          category: this.categories.find((c: any) => c.name === profile.publicProfile.category),
          education: profile.publicProfile.education.map((edu: any) => ({
            ...edu,
            country: this.countries.find((c: any) => c.name === edu.country),
            year: edu.year.toString()
          }))
        });
      }
    });
  }

  generateYears(): void {
    const startYear = 1982;
    const endYear = 2025;

    for (let year = startYear; year <= endYear; year++) {
      this.years.push(year.toString());
    }
  }

  onUpload(event: any, control: FormControl) {
    for(let file of event.files) {
      this.fileUploadService.getSingedUrl(file).subscribe(({signedUrl, fileName}) => {
        this.fileUploadService.uploadFile(file, signedUrl).subscribe(() => {
          control.setValue(`https://s3.amazonaws.com/upload.staging.afterfive.app/uploads/${fileName}`);
        });
      });
    }
  }

  onUploadMany(event: any, control: FormArray<FormControl>) {
    const uploadedFiles = control.value;
    uploadedFiles.splice(-event.files.length);
    for(let file of event.files) {
      this.fileUploadService.getSingedUrl(file).subscribe(({signedUrl, fileName}) => {
        this.fileUploadService.uploadFile(file, signedUrl).subscribe(() => {
          uploadedFiles.push(`https://s3.amazonaws.com/upload.staging.afterfive.app/uploads/${fileName}`);
        });
      });
    }

    control.setValue(uploadedFiles);
  }
}