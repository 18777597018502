import { Component } from '@angular/core';
import { TabViewModule } from 'primeng/tabview';
import { QuillModule } from 'ngx-quill'
import { DropdownModule } from 'primeng/dropdown';
import { CommonModule } from '@angular/common';

interface City {
  name: string;
  code: string;
}

@Component({
  selector: 'app-gig',
  standalone: true,
  imports: [TabViewModule, QuillModule, DropdownModule, CommonModule],
  templateUrl: './gig.component.html',
  styleUrl: './gig.component.scss'
})
export class GigComponent {
  currentStepGig: number = 1;

  cities: City[] | undefined;

  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic'],           // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }]
      ],

    }
  };

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  goToGigStep(gigStepNumber: number) {
    this.scrollToTop()
    this.currentStepGig = gigStepNumber;
  }
}
