import { Component, ViewChild, ElementRef } from '@angular/core';
import { ClientprofileheaderComponent } from '../../shared/components/clientprofileheader/clientprofileheader.component';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DialogModule } from 'primeng/dialog';
import { InputMaskModule } from 'primeng/inputmask';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  NgxIntlTelephoneInputModule, CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from "ngx-intl-telephone-input";

import { QuillModule } from 'ngx-quill'
import { UsersService } from '../../users.service';

interface City {
  name: string;
  code: string;
}


@Component({
  selector: 'app-clientregistration',
  standalone: true,
  imports: [ClientprofileheaderComponent, CommonModule, DropdownModule, CheckboxModule, RadioButtonModule, FormsModule, InputSwitchModule, InputTextareaModule, DialogModule, InputMaskModule, NgxIntlTelephoneInputModule, QuillModule],
  templateUrl: './clientregistration.component.html',
  styleUrl: './clientregistration.component.scss'
})
export class ClientregistrationComponent {
  currentStep: number = 1
  popupopen: boolean = false;
  cities: City[] | undefined;
  cards: [] | any;
  selectedCard = { name: 'Mastercard', src: 'mastercard.svg', code: 'mc' };

  clientSex!: string;

  constructor(private usersService: UsersService) { }

  openPopup() {
    this.popupopen = true
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  goToNextStep(stepnumber: number) {
    this.scrollToTop()
    this.currentStep = stepnumber;
  }

  SearchCountryField: any = SearchCountryField;
  PhoneNumberFormat: any = PhoneNumberFormat;

  profile: any;

  ngOnInit() {
    this.scrollToTop()
    this.cities = [
      { name: 'New York', code: 'NY' },
      { name: 'Rome', code: 'RM' },
      { name: 'London', code: 'LDN' },
      { name: 'Istanbul', code: 'IST' },
      { name: 'Paris', code: 'PRS' }
    ];

    this.cards = [
      { name: 'Mastercard', src: 'mastercard.svg', code: 'mc' }
    ];

    this.usersService.profile().subscribe(profile => {
      this.profile = profile;
    });
  }
}
