import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenubarModule } from 'primeng/menubar';
import { AvatarModule } from 'primeng/avatar';
import { SelectModule } from 'primeng/select';
import { FieldsetModule } from 'primeng/fieldset';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CountrySelectComponent } from './country-select/country-select.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TextInputComponent } from './text-input/text-input.component';
import { FloatLabel } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    CountrySelectComponent,
    TextInputComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MenubarModule,
    AvatarModule,
    SelectModule,
    FieldsetModule,
    FloatLabel,
    InputTextModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    CountrySelectComponent,
    TextInputComponent,
  ],
})
export class SharedComponentsModule {}
