import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-profileheader',
  standalone: true,
  imports: [],
  templateUrl: './profileheader.component.html',
  styleUrl: './profileheader.component.scss'
})
export class ProfileheaderComponent {
  @Input() profile: any;
}
