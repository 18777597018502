import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export enum ID_TYPES {
  NATIONAL_ID,
  PASSPORT,
  DRIVERS_LICENSE,
}

export enum LANGUAGE_FLUENCY {
  BASIC,
  CONVERSATIONAL,
  FLUENT,
  NATIVE,
}

export interface User {
  email: string;
  currentStep: string;
  verified: boolean;
  name: {
    first: string;
    middle: string;
    last: string;
  };
  personal: {
    country: string;
    phone: string;
    sex: string;
    address1: string;
    address2: string;
    city: string;
    postalCode: string;
    idType: string;
    idNumber: string;
    tinOrPin: string;
  };
  banking: {
    bank: string;
    branch: string;
    branchNumber: string;
    accountNumber: string;
  };
  publicProfile: {
    displayMiddleName: boolean;
    displayLastName: boolean;
    category: string;
    about: string;
    education: {
      country: string;
      school: string;
      degree: string;
      major: string;
      year: number;
    }[];
    languages: {
      name: string;
      level: string;
    }[];
    skills: {
      name: string;
      level: string;
    }[];
    externalLinks: {
      upwork: string;
      fiverr: string;
    };
  };
  profileVerification: {
    front: string;
    back: string;
    selfie: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private readonly http: HttpClient) {}

  register(userRegisterInfo: { email: string; password: string }) {
    return this.http.post('/users', userRegisterInfo);
  }

  verifyEmail(code: string) {
    return this.http.post<string>('/users/verify-email', { code });
  }

  login(email: string, password: string) {
    return this.http.post<{ access_token: string }>('/auth/login', {
      email,
      password,
    });
  }

  profile() {
    return this.http.get<User>('/auth/profile');
  }

  patch(userData: Partial<User>) {
    return this.http.patch('/users/me', userData);
  }

  resendVerificationEmail() {
    return this.http.post('/users/resend-verification-email', {});
  }

  forgotPassword(email: string) {
    return this.http.post('/users/forgot-password', { email });
  }

  resetPassword(email: string, password: string, code: number) {
    return this.http.post('/users/reset-password', { email, password, code });
  }
}
