<div class="invoice-wrapper">
    <div class="invoice-header hide-in-mobile">
        <h4>Invoice & Clients</h4>
    </div>

    <div class="tab-view-main-wrapper">
        <p-tabView>
            <p-tabPanel header="Generate an Invoice">
                <div class="data-wrapper">
                    <div class="inner-wrapper">
                        <div class="inner-main-wrapper">
                            <div class="main-title-data">
                                <h5>Existing client?</h5>
                            </div>
                            <p>Toggle this if you have entered this client to afterFIVE.app before.</p>
                        </div>
                        <div class="inner-main-wrapper">
                            <p-inputSwitch />
                        </div>
                    </div>
                </div>

                <div *ngIf="exisitingClient">
                    <ng-template [ngTemplateOutlet]="exisitingClientTemp"></ng-template>
                </div>

                <div *ngIf="addNewClient">
                    <ng-template [ngTemplateOutlet]="addNewClinetTemplate"></ng-template>
                </div>

                <div *ngIf="generateInvoice">
                    <ng-template [ngTemplateOutlet]="invoiceGenerate"></ng-template>
                </div>

                <div *ngIf="updateClientInformation">
                    <ng-template [ngTemplateOutlet]="clientInformationUpdateTemp"></ng-template>
                </div>

                <div *ngIf="invoiceSentSuccess">
                    <ng-template [ngTemplateOutlet]="invoiceSentSuccessTemp"></ng-template>
                </div>


            </p-tabPanel>
            <p-tabPanel header="Existing Clients">
                <div class="table-wrapper exisiting-client-wrapper">
                    <p-table class="hide-in-tablet hide-in-mobile" [value]="clients"
                        [tableStyle]="{'min-width': '60rem'}">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="name">
                                    Name <p-sortIcon field="name" />
                                </th>
                                <th pSortableColumn="email">
                                    Email <p-sortIcon field="email" />
                                </th>
                                <th pSortableColumn="phoneNumber">
                                    Phone Number <p-sortIcon field="phoneNumber" />
                                </th>
                                <th>

                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-client>
                            <tr>
                                <td>
                                    <div class="table-body-text border-left-radius">
                                        <div class="img-name-wrapper">
                                            <img src="../../../../../assets/images/temp/{{client.flagImage}}" alt="">
                                            <h6 class="name-wrapper">{{client.name}}</h6>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="table-body-text">{{ client.email }}</div>
                                </td>
                                <td>
                                    <div class="table-body-text">{{ client.phoneNumber }}</div>
                                </td>
                                <td>
                                    <div class="table-body-text border-right-radius">
                                        <div class="table-link-wrapper">
                                            <a href="#" class="table-links">View invoice</a>
                                        </div>
                                        <div class="button-wrapper">
                                            <button class="table-dropdown-wrapper">Transactions <img
                                                    src="../../../../../assets/images/icons/dropdown-black.svg"
                                                    alt=""></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>

                    <div class="hide-in-desktop mobile-tablet-exisiting-client-wrapper">
                        <div class="sort-wrapper">
                            <div class="input-wrapper">
                                <p-dropdown [options]="sortingOptions" optionLabel="name"
                                    class="country-wrapper">
                                    <ng-template pTemplate="selectedItem">
                                        <div class="" *ngIf="selectedOptionSort">
                                            <div class="name-option-selected">Sort By : <span>{{ selectedOptionSort.name }}</span></div>
                                        </div>
                                    </ng-template>
                                    <ng-template let-sortingOption pTemplate="item">
                                        <div class="name-option-items">{{ sortingOption.name }}</div>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="mt-exisiting-outer-wrapper">
                            <div class="mt-exisiting-inner-wrapper">
                                <div class="mt-header-wrapper">
                                    <div class="name-image-wrapper">
                                        <img src="../../../../../assets/images/temp/france.svg" alt="">
                                        <h4>Walter White</h4>
                                    </div>
                                    <div class="mt-button-wrapper">
                                        <button class="">Edit</button>
                                    </div>
                                </div>
                                <div class="mt-body-content">
                                    <div class="mt-body-inner">
                                        <h5>Email</h5>
                                        <h6>mailyohan&#64;gmail.com</h6>
                                    </div>
                                    <div class="mt-body-inner">
                                        <h5>Phone</h5>
                                        <h6>+82 XX XXXX YYYY</h6>
                                    </div>
                                </div>
                                <button class="mt-transaction-button" *ngIf="!editClicked">Transaction history</button>

                                <div class="mt-exisiting-edit-client" *ngIf="editClicked">
                                    <div class="input-wrapper phone-number">
                                        <label class="label-secondary" for="">Phone Number</label>
                                        <intl-telephone-input [enablePlaceholder]="true" [searchCountryFlag]="true"
                                            [separateDialCode]="false" [enableAutoCountrySelect]="true"
                                            [searchCountryField]="SearchCountryField.All" [phoneValidation]="false"
                                            [numberFormat]="PhoneNumberFormat.International" [inputId]="'phone'"
                                            [separateDialCode]="true" [enablePlaceholder]="false" class="input-field">
                                        </intl-telephone-input>

                                        <ng-template #customItemTemplate let-item>
                                            <div class="dropdown-item">
                                                <img [src]="item.flag" alt="{{ item.name }}" class="flag-icon">
                                                {{ item.name }} ({{ item.dialCode }})
                                            </div>
                                        </ng-template>
                                    </div>

                                    <div class="input-wrapper">
                                        <label class="label-secondary">Email</label>
                                        <input type="text" class="mb-0 register" placeholder=""
                                            value="mailyohan@gmail.com">
                                    </div>
                                </div>
                                <div class="w-fit mt-exisiting-edit-client-button" *ngIf="editClicked">
                                    <button class="primary-3-button">Update</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>

<ng-template #exisitingClientTemp>
    <div class="add-new-client-wrapper">

        <div class="input-wrapper">
            <label class="label-secondary" for="">Search Existing Client </label>
            <input type="text" class="mb-0 register" placeholder="Type client’s name here">
        </div>

    </div>
</ng-template>

<ng-template #addNewClinetTemplate>
    <div class="add-new-client-wrapper">
        <h3>Add a New Client</h3>
        <div class="input-wrapper">
            <h4 class="about-small-text">First Name</h4>
            <input type="text" class="mb-0 register" placeholder="First name of your client">
        </div>
        <div class="input-wrapper">
            <h4 class="about-small-text">Last Name</h4>
            <input type="text" class="mb-0 register" placeholder="Last name of your client">
        </div>
        <div class="input-wrapper">
            <label class="label-secondary" for="">Country</label>
            <p-dropdown [options]="cities" optionLabel="name" placeholder="Your Country" class="country-wrapper" />
        </div>
        <div class="input-wrapper phone-number">
            <label class="label-secondary" for="">Phone Number</label>
            <intl-telephone-input [enablePlaceholder]="true" [searchCountryFlag]="true" [separateDialCode]="false"
                [enableAutoCountrySelect]="true" [searchCountryField]="SearchCountryField.All" [phoneValidation]="false"
                [numberFormat]="PhoneNumberFormat.International" [inputId]="'phone'" [separateDialCode]="true"
                [enablePlaceholder]="false" class="input-field">
            </intl-telephone-input>

            <ng-template #customItemTemplate let-item>
                <div class="dropdown-item">
                    <img [src]="item.flag" alt="{{ item.name }}" class="flag-icon">
                    {{ item.name }} ({{ item.dialCode }})
                </div>
            </ng-template>
        </div>
        <div class="input-wrapper">
            <h4 class="about-small-text">Email</h4>
            <input type="text" class="mb-0 register email-input" placeholder="Email address of your client">
        </div>
        <div class="w-fit">
            <button class="primary-3-button">Save</button>
        </div>
    </div>
</ng-template>

<ng-template #invoiceGenerate>
    <div class="education-list">
        <div class="added-education-main-wrapper">
            <div class="education-wrapper">
                <div class="education-details">
                    <h3>Yohan Rozairo</h3>
                    <h5>mailyohan&#64;gmail.com</h5>
                </div>
                <div class="edit-delete-button-wrapper">
                    <button class="delete-button-wrapper"><img src="../../../assets/images/icons/delete-bin-white.svg"
                            alt=""></button>
                    <button class="underline-button">Edit</button>
                </div>
            </div>
        </div>
    </div>

    <div class="button-wrapper-main">
        <h4 class="sub-main-title">Have you completed all the work you agreed to?</h4>
        <div class="button-wrapper">
            <div class="w-fit">
                <button class="primary-3-button">Yes</button>
            </div>
            <div class="w-fit">
                <button class="secondary-button">No</button>
            </div>
        </div>
    </div>

    <div class="generate-invoice">
        <h4 class="sub-main-title">Services Provided</h4>
        <p>A brief description about the work you’ve done.</p>

        <div class="invoice-gen-wrapper">
            <div class="invoice-input-wrapper">
                <div class="input-wrapper-main">
                    <input type="text" class="service-title-input" placeholder="Service Description">

                    <div class="custom-input-group">
                        <div class="custom-input-group-prepend">
                            <span class="custom-input-group-text">$USD</span>
                        </div>
                        <input type="text" class="price-input" placeholder="Add price">
                    </div>
                </div>
                <button class="gray-button">Add another service</button>
                <div class="total-input-wrapper">
                    <h5 class="show-in-tablet grand-total-title hide-in-desktop">Grand Total</h5>
                    <div class="custom-input-group">
                        <div class="custom-input-group-prepend">
                            <span class="custom-input-group-text">$USD</span>
                        </div>
                        <input type="text" class="price-input" placeholder="">
                    </div>
                </div>

                <div class="invoice-gen-button">
                    <button class="primary-3-button">Send Invoice</button>
                </div>
            </div>
        </div>

        <div class="hide-in-desktop take-home-wrapper">
            <div class="take-home-inner">
                <h3>Your take-home pay from this invoice</h3>
                <h2>$1560</h2>
            </div>
        </div>
    </div>


</ng-template>

<ng-template #clientInformationUpdateTemp>
    <div class="add-new-client-wrapper update-client">
        <h3>Edit Client Information</h3>
        <div class="input-wrapper">
            <h4 class="about-small-text">First Name</h4>
            <input type="text" class="mb-0 register" placeholder="First name of your client" value="Yohan">
        </div>
        <div class="input-wrapper">
            <h4 class="about-small-text">Last Name</h4>
            <input type="text" class="mb-0 register" placeholder="Last name of your client" value="Rozairo">
        </div>
        <div class="input-wrapper">
            <label class="label-secondary" for="">Country</label>
            <p-dropdown [options]="cities" optionLabel="name" placeholder="Your Country" class="country-wrapper" />
        </div>
        <div class="input-wrapper phone-number">
            <label class="label-secondary" for="">Phone Number</label>
            <intl-telephone-input [enablePlaceholder]="true" [searchCountryFlag]="true" [separateDialCode]="false"
                [enableAutoCountrySelect]="true" [searchCountryField]="SearchCountryField.All" [phoneValidation]="false"
                [numberFormat]="PhoneNumberFormat.International" [inputId]="'phone'" [separateDialCode]="true"
                [enablePlaceholder]="false" class="input-field">
            </intl-telephone-input>

            <ng-template #customItemTemplate let-item>
                <div class="dropdown-item">
                    <img [src]="item.flag" alt="{{ item.name }}" class="flag-icon">
                    {{ item.name }} ({{ item.dialCode }})
                </div>
            </ng-template>
        </div>
        <div class="input-wrapper">
            <h4 class="about-small-text">Email</h4>
            <input type="text" class="mb-0 register email-input" placeholder="Email address of your client">
        </div>
        <div class="w-fit">
            <button class="primary-3-button">Save</button>
        </div>
    </div>

</ng-template>

<ng-template #invoiceSentSuccessTemp>
    <div class="hide-in-mobile">
        <div class="invoice-success-message">
            <h3>Your invoice has been sent to Yohan Rozairo at mailyohan&#64;gmail.com.</h3>
        </div>
    </div>

    <div class="hide-in-desktop hide-in-tablet">
        <div class="invoice-success-message-sub">
            <h3 class="first-title">Your invoice has been sent to</h3>
            <h3>Yohan Rozairo</h3>
            <p>mailyohan&#64;gmail.com</p>
            <p>$1260</p>
        </div>
    </div>

    <div class="sent-button-wrapper">
        <div class="w-fit">
            <button class="primary-3-button">View Invoice</button>
        </div>
        <div class="w-fit">
            <button class="primary-3-button">Copy Invoice Link</button>
        </div>
        <div class="w-fit">
            <button class="primary-3-button">Payment History</button>
        </div>
    </div>

</ng-template>