<div class="personal-information-wrapper">
    <h1 class="main-title">
        Personal Information
    </h1>

    <div class="information-inner-wrapper">
        <h4>First Name</h4>
        <p>Travis</p>
    </div>
    <hr />

    <div class="information-inner-wrapper">
        <h4>Last Name</h4>
        <p>Scott</p>
    </div>
    <hr />

    <div class="information-inner-wrapper group-content">
        <div class="content-section">
            <h4>Address</h4>
            <p>1810 SW Fifth Avenue, Suite 710, Portland, OR 97201</p>
        </div>
        <div class="button-section">
            <button class="underline-button">Edit</button>
        </div>
    </div>
    <hr />

    <div class="information-inner-wrapper">
        <h4>Email Address</h4>
        <p>cactusjack&#64;gmail.com</p>
    </div>
    <hr />


    <div class="information-inner-wrapper group-content">
        <div class="content-section">
            <h4>Phone Number</h4>
            <p>+1 503-555-6975</p>
        </div>
        <div class="button-section">
            <button class="underline-button">Edit</button>
        </div>
    </div>
    <hr />

    <div class="information-inner-wrapper group-content">
        <div class="content-section">
            <h4>Language</h4>
            <p>English</p>
        </div>
        <div class="button-section">
            <button class="underline-button">Edit</button>
        </div>
    </div>
    <hr />
</div>