import { Component, OnInit } from '@angular/core';
import { User, UsersService } from '../../user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account',
  standalone: false,
  templateUrl: './account.component.html',
  styleUrl: './account.component.scss',
})
export class AccountComponent implements OnInit {
  user: User | undefined;

  steps = ['Personal', 'Banking', 'Public Profile', 'Verification'];
  currentStep = 1;

  constructor(
    private readonly usersService: UsersService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.usersService.profile().subscribe((user) => {
      this.user = user;
    });
  }

  activateCallback(step: number) {
    if (step <= 4) {
      this.currentStep = step;
    } else {
      this.router.navigate(['/dashboard']);
    }
  }
}
