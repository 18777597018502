import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

interface Navigation {
  name: string;
  key: string;
  imageWhite: string;
  imageBlack: string;
  isCommingSoon: boolean;
}

@Component({
  selector: 'app-user-navigation',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './user-navigation.component.html',
  styleUrl: './user-navigation.component.scss'
})
export class UserNavigationComponent {
  isLogedIn: boolean = false
  isRegister: boolean = false;
  leftSideList: Navigation[] | undefined
  currentStep: string = 'gg';
  boradStep: string = 'lm';

  tabStep(step: string) {
    this.currentStep = step
  }

  boradClicked() {
    this.currentStep = "bd"
  }

  boradStepHandler(boradStepM: string) {
    this.boradStep = boradStepM;
  }

  ngOnInit() {

    this.leftSideList = [
      { name: "Edit Public Profile", key: "epp", isCommingSoon: false, imageWhite: "public-profile-white", imageBlack: "public-profle-black" },
      { name: "Gigs", key: "gg", isCommingSoon: false, imageWhite: "gig-white", imageBlack: "gig-black" },
      { name: "Invoice & Clients", key: "inc", isCommingSoon: false, imageWhite: "invoice-clients-white", imageBlack: "invoice-clients-black" },
      { name: "Log in & Security", key: "lis", isCommingSoon: false, imageWhite: "login-security-white", imageBlack: "login-security-black" },
      { name: "Messages", key: "mc", isCommingSoon: true, imageWhite: "messages-white", imageBlack: "messages-black" },
      { name: "Payments & Payouts", key: "pp", isCommingSoon: false, imageWhite: "payments-payouts-white", imageBlack: "payments-payouts-black" },
      { name: "Personal Information", key: "pi", isCommingSoon: false, imageWhite: "personal-information-white", imageBlack: "personal-information-black" },
      { name: "Privacy & Sharing", key: "ps", isCommingSoon: false, imageWhite: "privacy-sharing-white", imageBlack: "privacy-sharing-black" },
    ]
  }
}
