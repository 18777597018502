import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from 'primeng/dragdrop';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import {
  NgxIntlTelephoneInputModule, CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from "ngx-intl-telephone-input";

interface Task {
  id: number;
  name: string;
}

interface List {
  name: string;
  items: Task[];
}

interface City {
  name: string;
  code: string;
}

@Component({
  selector: 'app-leadmanagement',
  standalone: true,
  imports: [CommonModule, DragDropModule, DialogModule, InputSwitchModule, DropdownModule, NgxIntlTelephoneInputModule],
  templateUrl: './leadmanagement.component.html',
  styleUrls: ['./leadmanagement.component.scss']
})
export class LeadmanagementComponent {
  SearchCountryField: any = SearchCountryField;
  PhoneNumberFormat: any = PhoneNumberFormat;
  cities: City[] | undefined;

  lists: List[] = [
    { name: 'Potential Clients', items: [{ id: 1, name: 'NBA' }, { id: 2, name: 'WNBA' }] },
    { name: 'Existing Clients', items: [{ id: 1, name: 'NBA' }, { id: 2, name: 'WNBA' }] },
    { name: 'Proposal Sent', items: [{ id: 1, name: 'NBA' }, { id: 2, name: 'WNBA' }] },
    { name: 'Follow-Up Call Made', items: [{ id: 1, name: 'NBA' }, { id: 2, name: 'WNBA' }] },
    { name: 'Under Contract', items: [{ id: 1, name: 'NBA' }, { id: 2, name: 'WNBA' }] },
    { name: 'Potential Clients', items: [{ id: 1, name: 'NBA' }, { id: 2, name: 'WNBA' }] }
  ];

  draggedTask: Task | null = null;
  sourceList: List | null = null;

  addNewClientModal: boolean = false
  addNewClient: boolean = false

  dragStart(task: Task, list: List) {
    this.draggedTask = task;
    this.sourceList = list;
  }

  drop(targetList: List) {
    if (this.draggedTask && this.sourceList) {
      // Remove task from source list
      const draggedTaskIndex = this.sourceList.items.indexOf(this.draggedTask);
      if (draggedTaskIndex > -1) {
        this.sourceList.items.splice(draggedTaskIndex, 1);
      }

      // Add task to target list
      targetList.items.push(this.draggedTask);

      // Reset the dragged task and source list
      this.draggedTask = null;
      this.sourceList = null;
    }
  }

  dragEnd() {
    this.draggedTask = null;
    this.sourceList = null;
  }

  ngOnInit() {
    this.cities = [
      { name: 'New York', code: 'NY' },
      { name: 'Rome', code: 'RM' },
      { name: 'London', code: 'LDN' },
      { name: 'Istanbul', code: 'IST' },
      { name: 'Paris', code: 'PRS' }
    ];
  }
}
