<!-- desktop navigaton  -->
<nav class="navbar navbar-expand-lg navbar-light main-navbar-wrapper hide-in-mobile">
    <div class="container">
        <!-- Logo -->
        <a class="navbar-brand" routerLink="/">
            <img src="assets/images/logo/logo.svg" alt="Afterfive-Logo">
        </a>

        <!-- Centered Links -->
        <!-- <div class="navbar-nav mx-auto">
            <a class="nav-link navbar-links" href="#">Home</a>
            <a class="nav-link navbar-links" href="#">Gig Page</a>
            <a class="nav-link navbar-links" href="#">Features</a>
        </div> -->

        <!-- End Button -->
        <div class="navbar-nav" *ngIf="!isRegister">
            <div *ngIf="!isLogedIn" class="profile-navigation">
                <div class="profile-image-wrapper-bf">
                    <img src="../../../assets/images/icons/user-bf-log.svg" alt="">
                </div>
            </div>
            <!-- <button  class="primary-button" (click)="showEditProfileModal()">Become a Freelancer</button> -->
            <div *ngIf="isLogedIn" class="after-login-wrapper">
                <img class="message-icon" src="../../../assets/images/icons/messages.png" alt="">
                <div class="notification-wrapper">
                    <img class="notification-icon" src="../../../assets/images/icons/notification-icon.png" alt="">
                    <div class="red-dot-notification"></div>
                </div>

                <div class="user-profile-wrapper">
                    <img src="../../../assets/images/users/default-user-profile.png" alt="">
                </div>
            </div>

        </div>
    </div>
</nav>

<!-- mobile navigation  -->
<nav class="only-mobile navbar navbar-expand-lg navbar-light main-navbar-wrapper hide-in-desktop hide-in-tablet">
    <div class="container d-flex justify-content-between align-items-center">
        <!-- Toggle button for mobile (left end if logged in) -->
        <button *ngIf="isLogedIn" class="navbar-toggler" type="button" data-bs-toggle="offcanvas"
            data-bs-target="#navbarOffcanvas" aria-controls="navbarOffcanvas" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Logo (center) -->
        <a class="navbar-brand mx-auto" routerLink="/" [ngStyle]="isRegister ? { 'padding-left': '0' } : { 'padding-left': '13%' }">
            <img src="assets/images/logo/logo.svg" alt="Afterfive-Logo">
        </a>

        <!-- Profile navigation (right end if not logged in) -->
        <div *ngIf="!isRegister"  class="profile-navigation">
            <div *ngIf="!isLogedIn">
                <div class="profile-image-wrapper-bf">
                    <img src="../../../assets/images/icons/user-bf-log.svg" alt="">
                </div>
            </div>
        </div>



        <!-- Empty div to take up space when not logged in -->
        <div *ngIf="!isRegister">
            <div *ngIf="!isLogedIn" class="ms-auto"></div>
        </div>

    </div>

    <!-- Offcanvas content -->
    <div class="offcanvas offcanvas-start" tabindex="-1" id="navbarOffcanvas" aria-labelledby="navbarOffcanvasLabel"
        #navbarOffcanvas>
        <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="navbarOffcanvasLabel">
                <img src="assets/images/logo/logo.svg" alt="Afterfive-Logo">
            </h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <div class="navbar-nav">
                <!-- <a class="nav-link navbar-links" href="#">Home</a>
                <a class="nav-link navbar-links" href="#">Gig Page</a>
                <a class="nav-link navbar-links" href="#">Features</a> -->
                <a class="primary-button" href="#" (click)="showEditProfileModal()">Become a Freelancer</a>
            </div>
        </div>
    </div>
</nav>

<app-loginmodal #loginModal></app-loginmodal>