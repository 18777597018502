import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { LoginmodalComponent } from '../../shared/components/loginmodal/loginmodal.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RouterModule, NavigationEnd, Router } from '@angular/router';

@Component({
	selector: 'app-navbar',
	standalone: true,
	imports: [CommonModule, ButtonModule, LoginmodalComponent, RouterModule],
	templateUrl: './navbar.component.html',
	styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnInit {
	@ViewChild('loginModal') loginModal!: LoginmodalComponent;
	@ViewChild('navbarOffcanvas') navbarOffcanvas!: ElementRef;

	isLogedIn: boolean = false
	showDiv: boolean = false

	isRegister: boolean = false;
	isMobile: boolean;
	isTablet: boolean;
	isDesktop: boolean;

	constructor(private deviceService: DeviceDetectorService, private router: Router) {
		const deviceInfo = this.deviceService.getDeviceInfo();
		this.isMobile = this.deviceService.isMobile();
		this.isTablet = this.deviceService.isTablet();
		this.isDesktop = this.deviceService.isDesktop();
	}

	showEditProfileModal() {
		// Close the offcanvas
		const offcanvasElement = this.navbarOffcanvas.nativeElement;
		offcanvasElement.classList.remove('show');
		offcanvasElement.style.visibility = 'hidden';
		offcanvasElement.setAttribute('aria-hidden', 'true');

		// Remove backdrop if it exists
		const backdrop = document.querySelector('.offcanvas-backdrop');
		if (backdrop) {
			backdrop.remove();
		}
		document.body.style.overflow = '';
		document.body.style.padding = '';
		if (this.loginModal) {
			this.loginModal.show();
		}
	}

	ngOnInit(): void {
		this.router.events.subscribe((event: any) => {
			if (event instanceof NavigationEnd) {
				// Logic that should run on router change
				this.onRouterChange();
			}
		});
	}

	onRouterChange(): void {
		if (window.location.pathname === "/register") {
			this.isRegister = true;
		} else {
			this.isRegister = false;
		}
	}
}
