import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

export function getSkillsFrom(): FormGroup {
  return new FormGroup({
    name: new FormControl('', [Validators.required]),
    level: new FormControl('', [Validators.required]),
  });
}

@Component({
  selector: 'app-account-public-profile-skills',
  standalone: false,
  templateUrl: './skills.component.html',
  styleUrl: './skills.component.scss',
})
export class SkillsComponent implements OnInit {
  @Input({ required: true }) form: FormGroup | undefined;
  @Input({ required: true }) section = '';
  @Input({ required: true }) skillsList: string[] = [];

  visible = false;
  experienceLevels: string[] = [];
  position = 0;
  innerForm = getSkillsFrom();
  // data: Education[] = [];

  ngOnInit(): void {
    this.skillsList = [];
    this.experienceLevels = ['Basic', 'Intermediate', 'Expert'];

    if (!this.form) {
      throw new Error('form is required');
    }
  }

  get formArray() {
    return this.form?.get(this.section) as FormArray;
  }

  addNew() {
    this.innerForm.reset();
    this.position = this.formArray?.length || 0;
    this.visible = true;
  }

  save() {
    if (this.innerForm.valid) {
      this.formArray?.removeAt(this.position);
      this.formArray?.insert(this.position, getSkillsFrom());
      this.formArray?.at(this.position)?.patchValue(this.innerForm.value);
      this.visible = false;
    }
  }

  cancel() {
    this.visible = false;
  }

  edit(index: number) {
    this.position = index;
    this.innerForm.patchValue(this.formArray?.at(index)?.value);
    this.visible = true;
  }

  remove(index: number) {
    this.formArray?.removeAt(index);
    this.innerForm.reset();
  }
}
