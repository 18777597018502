<div
  class="flex flex-col items-center justify-center min-h-dvh bg-gray-100 text-gray-800"
>
  <h1 class="text-9xl font-extrabold text-blue-500">404</h1>
  <h2 class="text-4xl font-bold mt-4">Page Not Found</h2>
  <p class="text-lg mt-2 text-gray-600">
    Sorry, the page you are looking for does not exist.
  </p>
  <a
    href="/"
    class="mt-6 px-6 py-3 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 transition"
  >
    Go Back Home
  </a>
</div>
