import { definePreset } from '@primeng/themes';
import Aura from '@primeng/themes/aura';

export const AfterFivePreset = definePreset(Aura, {
  semantic: {
    primary: {
      50: '#FFF4D4',
      100: '#FFE8AA',
      200: '#FFDB80',
      300: '#FFCE55',
      400: '#FDC12B',
      500: '#F9BD23',
      600: '#D7A51F',
      700: '#B68E1B',
      800: '#967717',
      900: '#755F13',
      950: '#55480F',
    },
  },
});
