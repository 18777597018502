import { Component, OnInit, ViewChild, ElementRef, Renderer2, ChangeDetectorRef } from '@angular/core';
import { CarouselModule } from 'primeng/carousel';
import { ButtonModule } from 'primeng/button';
import { HomeService } from '../../core/services/home.service';
import { LoginmodalComponent } from '../../shared/components/loginmodal/loginmodal.component';
import { DialogModule } from 'primeng/dialog';

interface Services {
  id?: string;
  name?: string;
  image?: string;
}

interface Company {
  image?: string;
}

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CarouselModule, ButtonModule, LoginmodalComponent, DialogModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  providers: [HomeService]
})
export class HomeComponent implements OnInit {
  homeServices: Services[] | any;
  homeWorkedCompany: Company[] | any;
  responsiveOptions: any[] | undefined;
  responsiveOptionsCompany: any[] | undefined;
  visible: boolean = false;

  @ViewChild('loginModal') loginModal!: LoginmodalComponent;
  @ViewChild('searchModalContainer') searchModalContainer!: ElementRef;
  @ViewChild('mobileModalContent') mobileModalContent!: ElementRef;

  constructor(private homeService: HomeService, private renderer: Renderer2, private cdr: ChangeDetectorRef) { }

  currentStep: number = 0;
  isSearchedClicked: boolean = false;

  showEditProfileModal() {
    if (this.loginModal) {
      this.loginModal.show();
    }
  }

  nextStep(stepGiven: number) {
    this.currentStep = stepGiven
  }

  searchedClicked() {
    this.isSearchedClicked = true;
    this.currentStep = 0;
    this.cdr.detectChanges();
    setTimeout(() => {
      const element = this.renderer.selectRootElement('.finder-wrapper', true);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  }

  mobileSearchedClicked() {
    this.visible = true;
    this.currentStep = 0
  }

  search() {
    this.isSearchedClicked = false;
  }

  ngOnInit() {
    this.homeService.getProductsSmall().then((service) => {
      this.homeServices = service;
    });

    this.homeService.getWorkedCompanyLogo().then((companylogos) => {
      this.homeWorkedCompany = companylogos;
    });


    this.responsiveOptions = [
      {
        breakpoint: '1199px',
        numVisible: 4,
        numScroll: 1
      },
      {
        breakpoint: '991px',
        numVisible: 4,
        numScroll: 1
      },
      {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1
      }
    ]

    this.responsiveOptionsCompany = [
      {
        breakpoint: '1199px',
        numVisible: 4,
        numScroll: 1
      },
      {
        breakpoint: '991px',
        numVisible: 4,
        numScroll: 1
      },
      {
        breakpoint: '767px',
        numVisible: 2,
        numScroll: 2
      }
    ]
  }

}

